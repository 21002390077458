import { API_URL } from '../../../../constants';

const USERS_URL = `${API_URL}/users`;
const AD_USERS_URL = `${API_URL}/ad-users`;

const paths = {
  getUsers: (): string => `${USERS_URL}`,
  postUser: (): string => `${USERS_URL}`,
  putUser: (id: string) => `${USERS_URL}/${id}`,
  deleteUser: (id: string) => `${USERS_URL}/${id}`,
  getAdUsers: () => `${AD_USERS_URL}`,
  getUserImage: (email: string) => `${USERS_URL}/${email}/image`,
};

export default paths;
