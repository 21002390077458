/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './globalLimitsCard.style';
import { GlobalLimits } from '../models/limitRule';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { updateGlobalLimits } from '../stores/limitRulesSlice';
import { selectPrincipalRoles } from '../../../auth/store/principalSlice';
import { Role } from '../../../auth/models/principal';
import { toLocaleAmount } from '../../../../shared/utils/utils';
import { FormField } from '../../../../shared/components/FormField';

export default function GlobalLimitsCard({ globalLimits }: { globalLimits: GlobalLimits }): JSX.Element {
  const dispatch = useAppDispatch();
  const principalRoles = useAppSelector(selectPrincipalRoles);
  const [isEditable, setIsEditable] = useState(false);

  const updatedRules = (updatedLimits: GlobalLimits) => {
    dispatch(updateGlobalLimits(updatedLimits));
    setIsEditable(false);
  };

  if (isEditable) {
    return (
      <WritableGlobalRuleCard
        rules={globalLimits}
        onSave={updatedRules}
        onCancel={() => {
          setIsEditable(false);
        }}
      />
    );
  }
  return (
    <ReadonlyGlobalRuleCard
      rules={globalLimits}
      canEdit={principalRoles.includes(Role.GROUP_ADMIN)}
      onEdit={() => setIsEditable(true)}
    />
  );
}

const ReadonlyGlobalRuleCard = ({
  rules,
  onEdit,
  canEdit,
}: {
  rules: GlobalLimits;
  onEdit: () => void;
  canEdit: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.ruleCard}>
      <FormField label={t('limits.fashion')} inline viewComponent={`${toLocaleAmount(rules.fashionAmount)}€`} />
      <FormField label={t('limits.jewelry')} inline viewComponent={`${toLocaleAmount(rules.jewelryAmount)}€`} />
      {canEdit && (
        <div css={style.ruleGroupButton}>
          <Button css={style.globalLimitsButtons} onClick={() => onEdit()} icon='edit' />
        </div>
      )}
    </div>
  );
};

interface WritableGlobalRuleCardProps {
  onSave: (rules: GlobalLimits) => void;
  onCancel: () => void;
  rules: GlobalLimits;
}
const WritableGlobalRuleCard = ({ onSave, onCancel, rules }: WritableGlobalRuleCardProps) => {
  const { t } = useTranslation();
  const [currentFashionAmount, setCurrentFashionAmount] = useState(rules.fashionAmount.toString());
  const [currentJewelryAmount, setCurrentJewelryAmount] = useState(rules.jewelryAmount.toString());
  const fashionAmountIsValid = useMemo(
    () => !Number.isNaN(+currentFashionAmount) && +currentFashionAmount >= 0,
    [currentFashionAmount]
  );

  const onFashionAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setCurrentFashionAmount('');
      return;
    }
    const regex = /^(([1-9]\d{0,7})|0)$/;
    if (value !== '' && !regex.test(value)) {
      return;
    }
    setCurrentFashionAmount(value);
  };

  const jewelryAmountIsValid = useMemo(
    () => !Number.isNaN(+currentJewelryAmount) && +currentJewelryAmount >= 0,
    [currentJewelryAmount]
  );

  const onJewelryAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setCurrentJewelryAmount('');
      return;
    }
    const regex = /^(([1-9]\d{0,7})|0)$/;
    if (value !== '' && !regex.test(value)) {
      return;
    }
    setCurrentJewelryAmount(value);
  };

  const saveGlobalLimits = () => {
    if (fashionAmountIsValid && jewelryAmountIsValid) {
      onSave({
        fashionAmount: currentFashionAmount === '' ? rules.fashionAmount : +currentFashionAmount,
        jewelryAmount: currentJewelryAmount === '' ? rules.jewelryAmount : +currentJewelryAmount,
        updateInfo: rules.updateInfo,
      });
    }
  };

  return (
    <div css={style.ruleCard}>
      <FormField
        label={t('limits.fashion')}
        inline
        editMode
        editComponent={
          <Input
            label={{ content: 'EUR', style: { paddingTop: '1.2rem' } }}
            labelPosition='right'
            placeholder={t('limits.maxAmt')}
            value={currentFashionAmount}
            onChange={onFashionAmountChange}
            css={style.amountInput}
            size='mini'
          />
        }
      />
      <FormField
        label={t('limits.jewelry')}
        inline
        editMode
        editComponent={
          <Input
            label={{ content: 'EUR', style: { paddingTop: '1.2rem' } }}
            labelPosition='right'
            placeholder={t('limits.maxAmt')}
            value={currentJewelryAmount}
            onChange={onJewelryAmountChange}
            css={style.amountInput}
            size='mini'
          />
        }
      />
      <div css={style.ruleGroupButton}>
        <Button
          css={style.globalLimitsButtons}
          onClick={() => {
            onCancel();
          }}
          icon='cancel'
        />
        <Button css={style.globalLimitsButtons} onClick={saveGlobalLimits} icon='save' />
      </div>
    </div>
  );
};
