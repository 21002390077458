import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const pageContainer = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const discountRuleSetFormContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const noItemsLabel = css`
  flex: 1;
  ${globalStyle.bodySmall}
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const mainConstraints = css`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 14px;
  gap: 20px;
  flex-wrap: wrap;
`;

const readonlyField = css`
  padding: 0 11px;
`;

const brandField = css`
  min-width: 200px;
`;

const saveDiscountRuleSetButtonContainer = css`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: max-content;
  @media (max-width: 767px) {
    margin-top: 55px;
  }
`;

const addDiscountRuleBtnContainer = css`
  margin-bottom: 20px;
`;

const saveRuleButton = (label: string): SerializedStyles => css`
  ${globalStyle.labeledButton(label)};
  &&&& {
    right: 100px;
  }
`;

const addRuleButton = (label: string): SerializedStyles => css`
  ${globalStyle.labeledButton(label)};
`;

const modalContent = css`
  &&&& {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 14px 14px 14px;
    gap: 14px;
  }
`;

const modalActions = css`
  &&& {
    padding-top: 14px !important;
    border: none;
    background-color: transparent;
    @media (max-width: 768px) {
      padding: 1rem 1rem !important;
    }
  }
`;

const refuteBtn = css`
  ${globalStyle.secondaryButton};
  flex: 1;
  @media (max-width: 768px) {
    margin: 0 !important;
    margin-left: 0.75rem !important;
    margin-right: 0.25rem !important;
  }
`;

const confirmBtn = css`
  ${globalStyle.primaryButton};
  flex: 1;
  @media (max-width: 768px) {
    margin: 0 !important;
    margin-left: 0.75rem !important;
    margin-right: 0.25rem !important;
  }
`;

const modalContainer = css`
  ${globalStyle.bodyNormal}
  &&& {
    max-width: 360px;
    border-radius: 10px;
    padding: 14px 0 0;
  }
`;

const modalErrorButton = css`
  ${globalStyle.primaryButton};
  @media (max-width: 768px) {
    margin: 0 !important;
    margin-left: 0.75rem !important;
    margin-right: 0.25rem !important;
  }
`;

const legendSection = css`
  ${globalStyle.bodySmall}
  && {
    font-size: 10px;
  }
`;

export default {
  pageContainer,
  addDiscountRuleBtnContainer,
  discountRuleSetFormContainer,
  saveRuleButton,
  addRuleButton,
  saveDiscountRuleSetButtonContainer,
  mainConstraints,
  readonlyField,
  brandField,
  noItemsLabel,
  modalContent,
  modalActions,
  refuteBtn,
  confirmBtn,
  modalContainer,
  modalErrorButton,
  legendSection,
};
