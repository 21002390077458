import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import { Product, ProductRequest } from '../models/product';
import api from '../utils/api';

interface ProductSearchSlice {
  data: Product[];
  isFetching: boolean;
  error: string;
  searchBoxFilter: string;
  isFetchingByBarcode: boolean;
  productByBarcode: Product | null;
}

const initialState: ProductSearchSlice = {
  data: [],
  isFetching: false,
  error: '',
  searchBoxFilter: '',
  productByBarcode: null,
  isFetchingByBarcode: false,
};

export const productSearchSlice = createSlice({
  name: 'productSearchSlice',
  initialState,
  reducers: {
    startFetch: (state: Draft<ProductSearchSlice>): ProductSearchSlice => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchProducts: (
      state: Draft<ProductSearchSlice>,
      payloadAction: PayloadAction<Product[]>
    ): ProductSearchSlice => {
      return {
        ...state,
        data: payloadAction.payload,
        isFetching: false,
      };
    },
    httpError: (state: Draft<ProductSearchSlice>, payloadAction: PayloadAction<string>): ProductSearchSlice => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    setSearchBoxFilter: (state: Draft<ProductSearchSlice>, action: PayloadAction<string>): ProductSearchSlice => ({
      ...state,
      searchBoxFilter: action.payload,
    }),
    startFetchByBarcode: (state: Draft<ProductSearchSlice>): ProductSearchSlice => ({
      ...state,
      isFetchingByBarcode: true,
    }),
    finishFetchByBarcode: (
      state: Draft<ProductSearchSlice>,
      action: PayloadAction<Product | null>
    ): ProductSearchSlice => ({
      ...state,
      productByBarcode: action.payload,
      isFetchingByBarcode: false,
    }),
    reset: (): ProductSearchSlice => initialState,
  },
});

export const {
  startFetch,
  finishFetchProducts,
  httpError,
  setSearchBoxFilter,
  reset,
  startFetchByBarcode,
  finishFetchByBarcode,
} = productSearchSlice.actions;

export const searchProductByBarcode =
  (storeCode: string, sku: string): AppThunk<Promise<Product | null>> =>
  async (dispatch, state): Promise<Product | null> => {
    dispatch(startFetchByBarcode());
    try {
      const { brand, mail, countryCode, isSpecialCaseGoldCard, isSpecialCaseSilverCard } = state().employeeDiscountCard.data;
      const request: ProductRequest = {
      employeeEmail: mail,
      employeeBrandCode: brand.code,
      employeeCountryCode: countryCode,
      isGoldCard: isSpecialCaseGoldCard,
      isSilverCard: isSpecialCaseSilverCard

      };
      const product = await api.getProduct(storeCode, sku, request);
      dispatch(finishFetchByBarcode(product));
      return product;
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      dispatch(finishFetchByBarcode(null));
      return null;
    }
  };

const fetchProducts =
  (storeCode: string, filter: string): AppThunk =>
  async (dispatch, state) => {
    dispatch(startFetch());
    try {
      const { brand, mail, countryCode, isSpecialCaseGoldCard, isSpecialCaseSilverCard } = state().employeeDiscountCard.data;
      const request: ProductRequest = {
      employeeEmail: mail,
      employeeBrandCode: brand.code,
      employeeCountryCode: countryCode,
      isGoldCard: isSpecialCaseGoldCard,
      isSilverCard: isSpecialCaseSilverCard

      };
      const products = await api.getProducts(storeCode, filter, request);
      dispatch(finishFetchProducts(products));
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
      dispatch(finishFetchProducts([]));
    }
  };

export const onChangeSearchBoxFilter =
  (storeCode: string, filterValue: string): AppThunk =>
  async dispatch => {
    dispatch(setSearchBoxFilter(filterValue));

    if (filterValue.trim().length < 3) {
      dispatch(finishFetchProducts([]));
    }
    if (filterValue.trim().length >= 3) {
      dispatch(fetchProducts(storeCode, filterValue));
    }
  };

export const resetProductSearch = (): AppThunk => dispatch => {
  dispatch(reset());
};
export const selectProducts = (state: RootState): Product[] => state.productSearch.data;

export const selectSearchBoxFilter = (state: RootState): string => state.productSearch.searchBoxFilter;
export const selectIsFetching = (state: RootState): boolean => state.productSearch.isFetching;
export const selectIsFetchingByBarcode = (state: RootState): boolean => state.productSearch.isFetchingByBarcode;
export default productSearchSlice.reducer;
