/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, Image, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'react-device-detect';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import style from './myDigitalCard.style';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  fetchEmployeeCard,
  selectMyEmployeeCard,
  selectIsFetchingMyEmployeeCard,
  selectMyEmployeeCardInitials,
  getAppleWalletEmployeeCard,
  // getGoogleWalletEmployeeCard,
} from '../store/principalEmployeeCardSlice';
import QrCodeModal from '../../../../shared/components/QrCodeModal';
import { getThemedLogo } from '../../../../shared/models/brand';
import { fetchSpecialCases } from '../../../admin/discounts/store/specialCasesSlice';
import { EmployeeCardActionsModal, DetailAction } from '../../../../shared/components/EmployeeCardActionsModal';

export const MyDigitalCard = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const myEmployeeCard = useAppSelector(selectMyEmployeeCard);
  const employeeInitials = useAppSelector(selectMyEmployeeCardInitials);
  const isFetching = useAppSelector(selectIsFetchingMyEmployeeCard);
  const [qrCodeModalOpen, setqrCodeModalOpen] = useState(false);
  const [actionsModalOpen, setActionsModalOpen] = useState(false);
  const employeeCardRef = useRef(null);

  useEffect(() => {
    dispatch(fetchEmployeeCard());
    if (myEmployeeCard.isSpecialCaseGoldCard) {
      dispatch(fetchSpecialCases());
    }
  }, [dispatch, myEmployeeCard.isSpecialCaseGoldCard]);

  const handleActions = (): DetailAction[] => {
    const actions: DetailAction[] = ['exportPdf'];

    if (isIOS) {
      actions.push('addToAppleWallet');
    }
    // if (isAndroid) {
    //   actions.push('addToGoogleWallet');
    // }

    return actions;
  };

  const handleCardPdfDownload = async () => {
    if (employeeCardRef.current != null) {
      const container = employeeCardRef.current as HTMLElement;
      try {
        const canvas = await html2canvas(container, {
          allowTaint: true,
          useCORS: true,
          scale: 1.5,
        });
        const imgData = canvas.toDataURL('image/svg');
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const imgWidth = 90;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const x = (pageWidth - imgWidth) / 2;
        const y = 10;
        pdf.addImage(imgData, 'SVG', x, y, imgWidth, imgHeight);
        pdf.save(`employee_card_${myEmployeeCard.givenName}_${myEmployeeCard.surname}`);
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
  };

  const shareToAppleWallet = () => {
    dispatch(getAppleWalletEmployeeCard());
  };

  // const shareToGoogleWallet = () => {
  //   dispatch(getGoogleWalletEmployeeCard());
  // };

  return (
    <div css={style.container}>
      {isFetching ? (
        <Loader size='big' active />
      ) : (
        <>
          {myEmployeeCard.isBlocked === true && (
            <>
              <Icon name='lock' css={style.lockIcon} />
              <div css={style.blockedCardMessage}>
                <strong>{t('myDigitalCard.blockedCardMessage')}</strong>
              </div>
            </>
          )}
          <div css={style.wrapper}>
            <Button onClick={() => setActionsModalOpen(true)} css={style.downloadButton}>
              <Icon name='download' css={style.downloadIcon} size='big' />
            </Button>
            <div
              ref={employeeCardRef}
              css={style.contentContainer(
                myEmployeeCard.brand.code,
                myEmployeeCard.isBlocked,
                myEmployeeCard.isSpecialCaseGoldCard,
                myEmployeeCard.isSpecialCaseSilverCard
              )}>
              <div css={style.content}>
                <Image
                  src={getThemedLogo(
                    myEmployeeCard.brand.code,
                    myEmployeeCard.isSpecialCaseGoldCard,
                    myEmployeeCard.isSpecialCaseSilverCard
                  )}
                  size='small'
                />
                {myEmployeeCard.photo === 'no-image' ? (
                  <div css={style.employeeInitials}>{employeeInitials}</div>
                ) : (
                  <div css={style.circularImage}>
                    <Image src={myEmployeeCard.photo} size='small' centered />
                  </div>
                )}
                <div css={style.nameSection}>
                  <div css={style.name}>{`${myEmployeeCard.givenName} ${myEmployeeCard.surname}`}</div>
                </div>
                <div css={style.emailSection}>
                  {myEmployeeCard.workdayId != null && (
                    <div css={style.info}>
                      {t('myPersonalInformation.workdayId')} - {myEmployeeCard.workdayId}
                    </div>
                  )}
                  <div css={style.info}>{myEmployeeCard.mail}</div>
                  <div css={style.info}>{myEmployeeCard.country.toUpperCase()}</div>
                </div>
                <div css={style.qrCode} onClick={() => (myEmployeeCard.isBlocked ? null : setqrCodeModalOpen(true))}>
                  <Image src={myEmployeeCard.qrCode} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <EmployeeCardActionsModal
        onCancel={() => setActionsModalOpen(false)}
        open={actionsModalOpen}
        downloadPdf={handleCardPdfDownload}
        addToAppleWallet={shareToAppleWallet}
        // addToGoogleWallet={shareToGoogleWallet}
        actions={handleActions()}
      />
      <QrCodeModal onClose={() => setqrCodeModalOpen(false)} image={myEmployeeCard.qrCode} open={qrCodeModalOpen} />
    </div>
  );
};
