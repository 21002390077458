/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useAppDispatch } from '../../../../core/hooks';
import { deleteDiscountRule, editRule } from '../store/discountRuleSetDetailSlice';
import { OrderedDiscountRule } from '../models/discountRuleSet';
import { DiscountRuleDetail } from './DiscountRuleDetail';
import DiscountRuleModalForm from './DiscountRuleModalForm';
import { DeleteDiscountRuleSetModal } from './DeleteDiscountRuleSetModal';
import style from './discountRuleCard.style';

interface DiscountRuleCardInterface {
  discountRule: OrderedDiscountRule;
  ruleIndex: number;
}

export const DiscountRuleCard = ({ discountRule, ruleIndex }: DiscountRuleCardInterface): JSX.Element => {
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [isEditingRule, setIsEditingRule] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <div css={style.discountRuleCard}>
      <div css={style.discountRuleCardContent}>
        <DiscountRuleDetail rule={discountRule} ruleIndex={ruleIndex} />
        <div css={style.buttonContainer}>
          <Button
            css={style.discountRuleButtons}
            onClick={() => {
              setIsEditingRule(true);
            }}
            icon='edit'
          />
          <DiscountRuleModalForm
            open={isEditingRule}
            onClose={() => setIsEditingRule(false)}
            onRuleEdit={rule => {
              const editRequest = { rule, ruleIndex };
              dispatch(editRule(editRequest));
            }}
            discountRule={discountRule}
          />
          <Button css={style.discountRuleButtons} onClick={() => setOpenDeleteRuleModal(true)} icon='trash' />
          <DeleteDiscountRuleSetModal
            onDelete={() => {
              setOpenDeleteRuleModal(false);
              dispatch(deleteDiscountRule({ ruleIndex }));
            }}
            onCancel={() => setOpenDeleteRuleModal(false)}
            open={openDeleteRuleModal}
          />
        </div>
      </div>
    </div>
  );
};
