/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { checkRole, Role } from '../features/auth/models/principal';
import style from './homePageUnderSecurity.style';
import { RequireAuth } from '../core/components/RequireAuth';
import { useAppSelector } from '../core/hooks';
import { selectPrincipal, selectPrincipalRoles } from '../features/auth/store/principalSlice';
import { getRedirectPagePath } from '../core/services/webStorageService';
import { updatePWAManifest } from '../core/utils';

export const RoleSelectionMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const principal = useAppSelector(selectPrincipal);
  const roles = useAppSelector(selectPrincipalRoles);

  useEffect(() => {
    updatePWAManifest('dc-manifest');
  });

  const getValidRedirectPathByRole = (selectedRole: Role): string => {
    let fallbackPath = '';
    switch (selectedRole) {
      case Role.DC_EMPLOYEE:
        fallbackPath = '/privilege-card/employee';
        break;
      case Role.ADMIN:
        fallbackPath = '/privilege-card/admin';
        break;
      case Role.GROUP_ADMIN:
        fallbackPath = '/privilege-card/admin';
        break;
      case Role.SALES_ASSISTANT:
        fallbackPath = '/privilege-card/sales-assistant';
        break;
      default:
        fallbackPath = '/login-error';
    }

    const invalidRedirectPath = [
      '/',
      '/login',
      '/business-card/login',
      '/privilege-card',
      '/privilege-card/home',
      '/privilege-card/login',
    ];
    const hasInvalidRedirect = getRedirectPagePath() && invalidRedirectPath.includes(getRedirectPagePath());
    if (hasInvalidRedirect) {
      return fallbackPath;
    }
    return getRedirectPagePath();
  };

  if (roles.length === 1) {
    return <Navigate to={getValidRedirectPathByRole(roles[0])} />;
  }

  if (roles.length === 2 && checkRole(principal, Role.GROUP_ADMIN) && checkRole(principal, Role.ADMIN)) {
    return <Navigate to={getValidRedirectPathByRole(Role.ADMIN)} />;
  }

  return (
    <RequireAuth>
      <div css={style.container}>
        <div css={style.content}>
          <div css={style.titleContainer}>
            <div css={style.title}>{t('roleSelection.title')}</div>
          </div>
          <div css={style.bodyContainer}>
            {(roles.includes(Role.ADMIN) || roles.includes(Role.GROUP_ADMIN)) && (
              <Button value={Role.ADMIN} css={style.roleBtn} onClick={() => navigate('/privilege-card/admin/home')}>
                {t('roleSelection.admin')}
              </Button>
            )}
            {/* <Button value={Role.DC_EMPLOYEE} css={style.roleBtn} onClick={() => navigate('/privilege-card/employee/my-business-card')}>
              {t('roleSelection.employeeBusinessCard')}
            </Button> */}
            {roles.includes(Role.DC_EMPLOYEE) && (
              <Button
                value={Role.DC_EMPLOYEE}
                css={style.roleBtn}
                onClick={() => navigate('/privilege-card/employee/my-card')}>
                {t('roleSelection.employee')}
              </Button>
            )}
            {roles.includes(Role.SALES_ASSISTANT) && (
              <Button
                value={Role.SALES_ASSISTANT}
                css={style.roleBtn}
                onClick={() => navigate('/privilege-card/sales-assistant/card-search')}>
                {t('roleSelection.salesAssistant')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </RequireAuth>
  );
};
