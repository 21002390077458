import { css, SerializedStyles } from '@emotion/react';
import style from '../../../../shared/global.style';

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
`;

const searchBox = css`
  ${style.bodySmall};
  &&&& {
    width: 100%;
    max-width: 400px;
    display: flex;

    i {
      color: var(--dcd-gray-color-50);
      opacity: 1;
    }

    input {
      border-radius: 20px;
      border-color: var(--dcd-gray-color-50);
      color: var(--dcd-gray-color-50);
      &:focus {
        border-color: var(--dcd-gray-color-80);
        color: var(--dcd-gray-color-80);

        + i {
          color: var(--dcd-gray-color-80);
        }
      }
    }
  }
`;

const addButton = (label: string): SerializedStyles => css`
  ${style.labeledButton(label)};
`;

const configurationMessage = css`
  ${style.bodySmall};
  padding: 20px 12px;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  gap: 10px;
  align-items: center;
  &&& {
    font-style: italic;
  }
`;

const warningIcon = css`
  color: var(--dcd-secondary-color-90) !important;
`;

const header = css`
  display: flex;
  margin-bottom: 20px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
`;

const cardsContainer = css`
  flex-grow: 1;
  padding-top: 20px;
  overflow: auto;
`;

const noItemsLabel = css`
  ${style.bodySmall}
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export default {
  container,
  searchBox,
  addButton,
  configurationMessage,
  header,
  cardsContainer,
  noItemsLabel,
  warningIcon,
};
