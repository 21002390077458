import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const filters = css`
  flex-grow: 1;
  text-align: left;
  overflow: auto;
`;
const filterSection = css`
  padding-bottom: 28px;
`;
const radioGroupLabel = css`
  ${globalStyle.bodyNormal};
  padding-bottom: 28px;
`;
const radioGroup = css`
  padding-left: 10px;
`;

const radioButton = css`
  &.ui.radio.checkbox input:checked ~ .box:before,
  &.ui.radio.checkbox input:checked ~ label:before {
    background-color: var(--dcd-secondary-color-100);
    border-color: var(--dcd-secondary-color-100);
  }
  &.ui.radio.checkbox input:checked ~ .box:after,
  &.ui.radio.checkbox input:checked ~ label:after {
    background-color: var(--dcd-white-color);
  }
`;

const applyButton = css`
  ${globalStyle.primaryButton};
`;

const datePickerContainer = css`
  .react-datepicker__input-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    .react-datepicker__close-icon {
      position: relative;
      &::after {
        background-color: var(--dcd-pink-color);
      }
    }
  }
`;

const datePicker = css`
  ${globalStyle.bodySmall};
  border: 1px solid var(--dcd-kering-primary-color-20);
  border-radius: 2px;
  padding: 8px;
`;

export default {
  applyButton,
  container,
  filters,
  filterSection,
  radioGroupLabel,
  radioGroup,
  radioButton,
  datePicker,
  datePickerContainer,
};
