import { css } from '@emotion/react';
import style from '../global.style';

const container = (inline: boolean) => css`
  display: flex;
  ${!inline && 'flex-direction: column'};
  word-break: break-word;
`;

const label = (inline: boolean, alignment: string) => css`
  &&&&& {
    ${style.bodySmall};
    background-color: transparent;
    ${inline ? ' padding: 10px 10px 10px 0' : ' padding: 8px 0'};
    color: var(--dcd-gray-color);
    text-align: ${alignment};
  }
`;

const valueContainer = (alignment: string) => css`
  display: flex;
  align-items: center;
  min-height: 42px;
  max-width: 100%;
  ${alignment === 'center' && 'margin: 0 auto'};
  text-align: ${alignment};
`;

const viewModeValue = css`
  ${style.bodySmall};
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 10px;
`;

const star = css`
  ${style.bodySmall};
  padding-left: 5px;
`;

const validationPopup = css`
  z-index: 1 !important;
  && {
    ${style.bodySmall};
    background-color: var(--dcd-error);
    color: var(--dcd-white-color);
    font-weight: 600;
    opacity: 0.9;
    padding: 1px 10px;
  }

  &&&:before {
    background-color: var(--dcd-error);
  }
`;

export default { container, label, valueContainer, viewModeValue, star, validationPopup };
