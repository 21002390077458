import { css } from '@emotion/react';

const container = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const headerContainer = css`
  position: relative;
  width: 100%;
  margin-bottom: 14px;
`;

const storeSelectionContainer = css`
  flex: 1;
  width: 100%;
  height: 0;
`;

export default {
  container,
  headerContainer,
  storeSelectionContainer,
};
