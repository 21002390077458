/** @jsxImportSource @emotion/react */
import React from 'react';
import { Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../core/hooks';
import style from './personalInformationContent.style';
import { selectPrincipal } from '../../../auth/store/principalSlice';

export function PersonalInformationContent() {
  const principal = useAppSelector(selectPrincipal);
  const { t } = useTranslation();
  return (
    <div className='infoContainer' css={style.bodyContainer}>
      <div>
        <div css={style.labelAndInputContainer}>
          <Label css={style.labelItem}>{t('myPersonalInformation.name')}</Label>
          <div css={style.inputField}>{`${principal?.firstName} ${principal?.familyName}`}</div>
        </div>
        <div css={style.labelAndInputContainer}>
          <Label css={style.labelItem}>{t('myPersonalInformation.workdayId')}</Label>
          <div css={style.inputField}>{principal?.workdayID}</div>
        </div>
        <div css={style.labelAndInputContainer}>
          <Label css={style.labelItem}>{t('myPersonalInformation.email')}</Label>
          <div css={style.inputField}>{principal?.email}</div>
        </div>
        <div css={style.labelAndInputContainer}>
          <Label css={style.labelItem}>{t('myPersonalInformation.phoneNumber')}</Label>
          <div css={style.inputField}>{principal?.mobilePhone}</div>
        </div>
        <div css={style.labelAndInputContainer}>
          <Label css={style.labelItem}>{t('myPersonalInformation.country')}</Label>
          <div css={style.inputField}>{principal?.countryDescription}</div>
        </div>
        <div css={style.labelAndInputContainer}>
          <Label css={style.labelItem}>{t('myPersonalInformation.house')}</Label>
          <div css={style.inputField}>{principal?.brand.description}</div>
        </div>
      </div>
    </div>
  );
}
