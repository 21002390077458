/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import Header from '../../../../shared/components/Header';
import { selectEmployeeCard } from '../../cardSearch/store/employeeDiscountCardSlice';
import { TrafficLightsCard } from '../components/TrafficLightsCard';
import { SearchTrafficLightsBox } from '../components/SearchTrafficLightsBox';
import { changeSearchBoxFilter } from '../store/trafficLightsFilterSlice';
import style from './trafficLights.style';
import {
  fetchTrafficLights,
  resetTrafficLights,
  selectFilteredTrafficLights,
  selectIsFetchingTrafficLights,
} from '../store/trafficLightsSlice';
import { AllCategoriesCard } from '../components/AllCategoriesCard';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';

export const TrafficLights = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const employeeCard = useAppSelector(selectEmployeeCard);
  const trafficLights = useAppSelector(selectFilteredTrafficLights);
  const isFetching = useAppSelector(selectIsFetchingTrafficLights);
  const salesAssistantBrand = useAppSelector(selectSelectedStore)?.brand;
  useEffect(() => {
    if (employeeCard.mail !== '') {
      dispatch(resetTrafficLights);
      dispatch(
        fetchTrafficLights({
          employeeEmail: employeeCard.mail,
          employeeBrandCode: employeeCard.brand.code,
          employeeCountryCode: employeeCard.countryCode,
          salesAssistantBrandCode: salesAssistantBrand?.code || '',
        })
      );
    }
  }, [employeeCard.mail, employeeCard.brand, employeeCard.countryCode, dispatch, salesAssistantBrand?.code]);

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(changeSearchBoxFilter(value));
  }, 500);

  return (
    <div style={{ height: '100%' }}>
      {employeeCard.mail === '' && (
        <Dimmer active>
          {t('salesAssistant.productsSearch.pleaseSelectCard')} {t('salesAssistant.productsSearch.click')}{' '}
          <Link to='/privilege-card/sales-assistant/card-search'>{t('salesAssistant.productsSearch.here')}</Link>{' '}
          {t('salesAssistant.productsSearch.toRedirect')}
        </Dimmer>
      )}
      <div css={style.container}>
        <div css={style.headerContainer}>
          <Header title={t('salesAssistant.trafficLights.trafficLights')} />
        </div>
        <div css={style.contentContainer}>
          <div css={style.filtersContainer}>
            <SearchTrafficLightsBox key='historySearch' onChange={value => debounced(value)} />
          </div>
          <div css={style.trafficLightsListContainer}>
            {isFetching ? (
              <Loader active inline='centered' />
            ) : trafficLights.length === 0 ? (
              <p css={style.noItemsMessage}>{t('salesAssistant.trafficLights.emptyTrafficLights')}</p>
            ) : (
              trafficLights.map(tl => {
                if (tl.category === 'ALL_CATEGORIES') {
                  return (
                    <AllCategoriesCard
                      key={tl.category}
                      storeTypology={tl.storeTypology}
                      iconColor={tl.color}
                      trafficLightsLength={trafficLights.length}
                    />
                  );
                }
                return <TrafficLightsCard key={tl.category} productCategory={tl.category} storeTypology={tl.storeTypology} iconColor={tl.color} />;
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
