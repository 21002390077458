/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../../../../shared/components/FormField';
import style from './itemsCountField.style';

export default function ItemsCountField({
  label,
  mandatory = false,
  onChange,
  countValue,
  disabled = false,
  showValidation,
  validationMessage = '',
}: {
  label: string;
  mandatory?: boolean;
  countValue: number | null;
  onChange: (count: number | null) => void;
  disabled?: boolean;
  showValidation: boolean;
  validationMessage?: string;
}) {
  const { t } = useTranslation();
  const [itemsCount, setItemsCount] = useState(countValue?.toString() || '');
  const isValidValue = (value: string) => {
    const regex = /^([1-9]\d*)$/;
    return regex.test(value);
  };

  const onItemsCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      setItemsCount('');
      onChange(null);
      return;
    }

    if (!isValidValue(value)) {
      return;
    }
    setItemsCount(value);
    onChange(+value);
  };

  useEffect(() => {
    setItemsCount(countValue?.toString() || '');
  }, [countValue]);

  return (
    <FormField
      label={label}
      mandatory
      editMode
      validationMessages={showValidation && !isValidValue(itemsCount) ? [validationMessage] : []}
      editComponent={
        <Input
          placeholder={t('limitRuleset.quantity')}
          css={style.limitInput}
          value={itemsCount || ''}
          onChange={onItemsCountChange}
          disabled={disabled}
        />
      }
    />
  );
}
