/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { useAppDispatch } from '../../../../core/hooks';
import { DiscountRule, DiscountRuleSet } from '../models/discountRuleSet';
import { deleteDiscountRuleSet } from '../store/discountRuleSetsSlice';
import { FormField } from '../../../../shared/components/FormField';
import { DeleteDiscountRuleSetModal } from './DeleteDiscountRuleSetModal';
import style from './discountRuleSetCard.style';
import { CreationData } from '../../../../shared/components/CreationData';

interface DiscountRuleSetCardProps {
  discountRuleSet: DiscountRuleSet;
}
export default function DiscountRuleSetCard({ discountRuleSet }: DiscountRuleSetCardProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const orderedDiscountRules = [...discountRuleSet.discountRules].reverse();

  const navigateToDiscountRuleSetDetailPage = () => {
    navigate(`/privilege-card/admin/discount-rule-set/${discountRuleSet.id}`);
  };

  return (
    <div css={style.wrapper}>
      <div css={style.ruleCard}>
        <div css={style.ruleCardContent}>
          <FormField
            label={t('discounts.ruleset.brand')}
            align='center'
            mandatory
            viewComponent={discountRuleSet.brand.description}
          />
          <FormField
            label={t('discounts.ruleset.employeeType')}
            align='center'
            mandatory
            viewComponent={discountRuleSet.employeeTypes
              .map(type => t(`discounts.ruleset.employeeTypes.${type}`))
              .join(', ')}
          />
          {orderedDiscountRules.map(rule => (
            <DiscountRuleDetail
              rule={rule}
              key={`${discountRuleSet.id}${rule.discountPercentage}${rule.constraints.toString()}`}
            />
          ))}
        </div>
        <div css={style.buttonContainer}>
          <Button css={style.limitRuleSetsButtons} onClick={navigateToDiscountRuleSetDetailPage} icon='edit' />
          <Button css={style.limitRuleSetsButtons} onClick={() => setOpen(true)} icon='trash' />
          <DeleteDiscountRuleSetModal
            onDelete={() => {
              setOpen(false);
              dispatch(deleteDiscountRuleSet(discountRuleSet.id));
            }}
            onCancel={() => setOpen(false)}
            open={open}
          />
        </div>
      </div>
      <div css={style.creationDataContainer}>
        <CreationData creationInfo={discountRuleSet.creationInfo} updateInfo={discountRuleSet.updateInfo} />
      </div>
    </div>
  );
}
interface DiscountRuleDetailProps {
  rule: DiscountRule;
}
const DiscountRuleDetail = ({ rule }: DiscountRuleDetailProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.discountRuleDetailContainer}>
      <Icon name='square' css={style.ruleIcon} />
      <div css={style.discountRuleDetail}>
        <div>
          <strong>{t(`discounts.rule.countries`)}</strong>:{' '}
          <span>
            {rule.constraints.countries === null
              ? t(`discounts.rule.allCountries`)
              : rule.constraints.countries.map(c => c.description).join(', ')}
          </span>
        </div>
        <div>
          <strong>{t(`discounts.rule.productCategories`)}</strong>:{' '}
          <span>
            {rule.constraints.productCategories === null
              ? t(`discounts.rule.allProductCategories`)
              : rule.constraints.productCategories.join(', ')}
          </span>
        </div>
        <div>
          <strong>{t(`discounts.rule.discountPercentage`)}</strong>: <span>{`${rule.discountPercentage}%`}</span>
        </div>
      </div>
    </div>
  );
};
