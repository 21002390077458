import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { from, take } from 'rxjs';
import { AxiosError, AxiosResponse } from 'axios';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import api from '../utils/api';
import { OAUTH_CLIENT_ID, OAUTH_TOKEN_URL, REDIRECT_URI } from '../../../constants';
import { getRedirectPagePath } from '../../../core/services/webStorageService';
import { useAppSelector } from '../../../core/hooks';
import { selectPrincipal } from '../store/principalSlice';

interface OauthReceiverPros {
  onAuthSuccess: (token: string) => void;
}

export const OauthReceiver = ({ onAuthSuccess }: OauthReceiverPros): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [processing, setProcessing] = useState(true);
  const principal = useAppSelector(selectPrincipal);

  useEffect(() => {
    const { code } = qs.parse(location.search);
    const abortController = new AbortController();
    from(api.getToken(OAUTH_TOKEN_URL, code, REDIRECT_URI, OAUTH_CLIENT_ID, abortController))
      .pipe(take(1))
      .subscribe({
        next: (result: AxiosResponse) => {
          onAuthSuccess(result.data.access_token);
        },
        error: e => {
          if (e.code !== AxiosError.ERR_CANCELED) {
            navigate('/login-error');
          }
        },
        complete: () => setProcessing(false),
      });
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (processing || principal == null) {
    return (
      <Dimmer active inverted style={{ backgroundColor: 'var(--dcd-secondary-color-40)' }}>
        <Loader size='big' />
      </Dimmer>
    );
  }

  if (getRedirectPagePath() !== '/') {
    return <Navigate to={getRedirectPagePath()} />;
  }

  return <Navigate to='/privilege-card/home' />;
};
