import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import { ProductSku } from '../models/productSku';
import api from '../utils/api';

interface ProductSkusSliceState {
  data: ProductSku[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: ProductSkusSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const productSkusSlice = createSlice({
  name: 'productSkus',
  initialState,
  reducers: {
    startFetch: (state: Draft<ProductSkusSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<ProductSkusSliceState>, action: PayloadAction<ProductSku[]>) => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    httpError: (state: Draft<ProductSkusSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError } = productSkusSlice.actions;

export const fetchProductSkus =
  (brandCode: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const productSkus = await api.getProductSkusByBrand(brandCode);
      dispatch(finishFetch(productSkus));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectProductSkus = (state: RootState): ProductSku[] | null => state.productSkus.data;
export const selectIsFetchingSkus = (state: RootState): boolean => state.productSkus.isFetching;

export default productSkusSlice.reducer;
