/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { OrderedDiscountRule } from '../models/discountRuleSet';
import style from './discountRuleDetail.style';

interface DiscountRuleDetailProps {
  rule: OrderedDiscountRule;
  ruleIndex: number;
}
export const DiscountRuleDetail = ({ rule, ruleIndex }: DiscountRuleDetailProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.discountRuleDetailContainer}>
      <div css={style.iconAndConstraintsContainer}>
        <div css={style.priorityContainer}>
          <span>{ruleIndex}</span> <Icon name='bars' css={style.editableRuleIcon} />
        </div>

        <div css={style.discountRuleDetail}>
          <div>
            <strong>{t(`discounts.rule.productCategories`)}</strong>:{' '}
            <span>
              {rule.constraints.productCategories === null
                ? t(`discounts.rule.allProductCategories`)
                : rule.constraints.productCategories.join(', ')}
            </span>
          </div>
          <div>
            <strong>{t(`discounts.rule.countries`)}</strong>:{' '}
            <span>
              {rule.constraints.countries === null
                ? t(`discounts.rule.allCountries`)
                : rule.constraints.countries.map(c => c.description).join(', ')}
            </span>
          </div>
          <div>
            <strong>{t(`discounts.rule.discountPercentage`)}</strong>: <span>{`${rule.discountPercentage}%`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
