/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  changeCountryFilter,
  getOtherCountries,
  selectCountryFilter,
} from '../store/employeePurchaseLimitFiltersSlice';
import { selectSortedAvailableCountries } from '../store/employeePurchaseLimitSlice';
import { Country } from '../../../../shared/models/country';
import { ModalPage } from '../../../../shared/components/ModalPage';
import { RadioGroup, RadioItem } from '../../../../shared/components/RadioGroup';
import style from './discountFilters.style';
import i18n from '../../../../i18n';

interface PurchaseFiltersProps {
  onBack: () => void;
}

export default function DiscountFilters({ onBack }: PurchaseFiltersProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectCountryFilter);
  const [country, setCountry] = useState(filter);
  const applyFilters = () => {
    dispatch(changeCountryFilter(country));
    onBack();
  };
  return (
    <ModalPage onClose={onBack} title={t('purchaseLimits.filters')}>
      <div css={style.container}>
        <div css={style.filters}>
          <CountryRadioGroup value={country.code} onClick={setCountry} />
        </div>
        <Button css={style.applyButton} onClick={applyFilters}>
          {t('purchaseLimits.applyFilters')}
        </Button>
      </div>
    </ModalPage>
  );
}

interface CountryRadioGroupProps {
  onClick: (country: Country) => void;

  value: string;
}

const CountryRadioGroup = ({ value, onClick }: CountryRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableCountries = useAppSelector(selectSortedAvailableCountries);
  const otherCountries = getOtherCountries(i18n.language);

  const countries: RadioItem[] = useMemo(() => {
    return [..._.sortBy(availableCountries, ['description']), otherCountries].map(ac => ({
      code: ac.code,
      description: ac.description,
    }));
  }, [availableCountries, otherCountries]);

  return (
    <RadioGroup
      label={t('purchaseLimits.filterByCountry')}
      items={countries}
      value={value}
      onClick={v => {
        if (v === '') {
          onClick(otherCountries);
        } else {
          const selectedCountry = countries.find(c => c.code === v) as Country;
          onClick(selectedCountry);
        }
      }}
    />
  );
};
