import { css } from '@emotion/react';

const search = css`
  &&&& {
    width: 100%;
    display: flex;

    i {
      color: var(--dcd-gray-color-50);
      opacity: 1;
    }

    input {
      border-radius: 20px;
      border-color: var(--dcd-gray-color-50);
      color: var(--dcd-gray-color-50);
      &:focus {
        border-color: var(--dcd-gray-color-80);
        color: var(--dcd-gray-color-80);

        + i {
          color: var(--dcd-gray-color-80);
        }
      }
    }
  }
`;

export default {
  search,
};
