import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toastService } from '../../../../core/services/toastService';
import type { AppThunk, RootState } from '../../../../core/store';
import { DiscountRuleSet } from '../models/discountRuleSet';
import api from '../utils/api';
import { selectFilters } from './discountRuleSetsFiltersSlice';

interface DiscountRuleSetsSliceState {
  discountRuleSets: DiscountRuleSet[];
  isFetching: boolean;
  error: string;
}

const initialState: DiscountRuleSetsSliceState = {
  discountRuleSets: [],
  isFetching: false,
  error: '',
};

export const discountRuleSetsSlice = createSlice({
  name: 'discountRuleSetsSlice',
  initialState,
  reducers: {
    startFetch: (state: Draft<DiscountRuleSetsSliceState>): DiscountRuleSetsSliceState => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<DiscountRuleSetsSliceState>,
      payloadAction: PayloadAction<DiscountRuleSet[]>
    ): DiscountRuleSetsSliceState => {
      return {
        ...state,
        isFetching: false,
        error: '',
        discountRuleSets: payloadAction.payload,
      };
    },
    removeLimitRule: (
      state: Draft<DiscountRuleSetsSliceState>,
      { payload: id }: PayloadAction<string>
    ): DiscountRuleSetsSliceState => {
      const updatedDiscountRuleSets = state.discountRuleSets.filter(ruleset => ruleset.id !== id);
      return {
        ...state,
        discountRuleSets: updatedDiscountRuleSets,
      };
    },
    httpError: (
      state: Draft<DiscountRuleSetsSliceState>,
      payloadAction: PayloadAction<string>
    ): DiscountRuleSetsSliceState => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError, removeLimitRule } = discountRuleSetsSlice.actions;

export const deleteDiscountRuleSet =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      const discountRuleSetId = await api.deleteDiscountRuleSet(id);
      dispatch(removeLimitRule(discountRuleSetId));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchDiscountRuleSets = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const discountRuleSets = await api.getDiscountRuleSets();
    dispatch(finishFetch(discountRuleSets));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};
const selectDiscountRuleSets = (state: RootState): DiscountRuleSet[] => state.discountRuleSets.discountRuleSets;

export const selectSortedDiscountRuleSets = createSelector(
  selectDiscountRuleSets,
  (discountRuleSets: DiscountRuleSet[]): DiscountRuleSet[] =>
    _.sortBy(discountRuleSets, ['brand.description', 'employeeTypes[0]'])
);

export const selectFilteredDiscountRuleSets = createSelector(
  selectFilters,
  selectSortedDiscountRuleSets,
  (filters, limits): DiscountRuleSet[] => {
    return limits.filter(
      limit =>
        (filters.employeeType !== '' ? limit.employeeTypes.includes(filters.employeeType) : true) &&
        (filters.brand !== '' ? limit.brand.description === filters.brand : true)
    );
  }
);

export default discountRuleSetsSlice.reducer;
