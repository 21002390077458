/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { selectPrincipalSalesAssistantStores } from '../../../auth/store/principalSlice';
import { CodeScanner } from '../components/CodeScanner';
import { EmployeeCardsDropdown } from '../components/EmployeeCardsDropdown';
import { resetSelectedStore, setSelectedStore } from '../store/salesAssistantSlice';
import Header from '../../../../shared/components/Header';
import { SalesAssistantStore } from '../../../auth/models/principal';
import style from './cardSearch.style';
import { StoreSelection } from '../../../../shared/components/StoreSelection';
import { MyStoresSelection } from '../../storeSelection/components/MyStoresSelection';

enum Steps {
  StoreSelectionStep,
  CardSearchStep,
}
export const CardSearch = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const salesAssistantStores = useAppSelector(selectPrincipalSalesAssistantStores);
  const [step, setStep] = useState<Steps>();

  const onStoreSelection = useCallback(
    (store: SalesAssistantStore) => {
      dispatch(setSelectedStore(store));
      setStep(Steps.CardSearchStep);
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(resetSelectedStore());
    if (salesAssistantStores?.length === 1) {
      onStoreSelection(salesAssistantStores[0]);
    } else {
      setStep(Steps.StoreSelectionStep);
    }
  }, [dispatch, onStoreSelection, salesAssistantStores]);

  return (
    <div css={style.container}>
      {step == null && <div>{t('salesAssistant.cardSearch.noStoreConfigured')}</div>}
      {step === Steps.StoreSelectionStep &&
        salesAssistantStores != null &&
        (salesAssistantStores.length > 0 ? (
          <div css={style.storeSelectionContainerWithHeader}>
            <Header title={t('salesAssistant.cardSearch.selectStore')} arrowBackEnable={false} />
            <div css={style.storeSelectionContainer}>
              <StoreSelection
                onSelection={onStoreSelection}
                stores={salesAssistantStores}
                selectedStores={salesAssistantStores}
              />
            </div>
          </div>
        ) : (
          <div css={style.storeSelectionContainerWithHeader}>
            <div css={style.headerContainer}>
              <Header title={t('salesAssistant.cardSearch.selectMyStores')} arrowBackEnable={false} />
            </div>
            <div css={style.storeSelectionContainer}>
              <MyStoresSelection />
            </div>
          </div>
        ))}
      {step === Steps.CardSearchStep && salesAssistantStores != null && (
        <>
          <div css={style.headerContainer}>
            <Header
              title={t('salesAssistant.cardSearch.cardSearch')}
              arrowBackEnable={salesAssistantStores.length > 1}
              onBackCustom={() => setStep(Steps.StoreSelectionStep)}
            />
          </div>
          <div css={style.dropdownContainer}>
            <EmployeeCardsDropdown
              onSelection={employeeId => {
                navigate(`/privilege-card/sales-assistant/card-search/${employeeId}`);
              }}
            />
          </div>
          <div css={style.scannerContainer}>
            <CodeScanner />
          </div>
        </>
      )}
    </div>
  );
};
