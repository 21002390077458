import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { EmployeeCard, getEmptyEmployeeCard } from '../../../../shared/models/employeeCard';

interface EmployeeDiscountCardSlice {
  data: EmployeeCard;
  isFetching: boolean;
  error: string;
}

const initialState: EmployeeDiscountCardSlice = {
  data: getEmptyEmployeeCard(),
  isFetching: false,
  error: '',
};

export const employeeDiscountCardSlice = createSlice({
  name: 'employeeDiscountCardSlice',
  initialState,
  reducers: {
    startFetch: (state: Draft<EmployeeDiscountCardSlice>): EmployeeDiscountCardSlice => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchEmployee: (
      state: Draft<EmployeeDiscountCardSlice>,
      payloadAction: PayloadAction<EmployeeCard>
    ): EmployeeDiscountCardSlice => {
      return {
        ...state,
        data: payloadAction.payload,
        isFetching: false,
      };
    },
    httpError: (
      state: Draft<EmployeeDiscountCardSlice>,
      payloadAction: PayloadAction<string>
    ): EmployeeDiscountCardSlice => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    reset: (): EmployeeDiscountCardSlice => {
      return initialState;
    },
  },
});

export const { startFetch, finishFetchEmployee, httpError, reset } = employeeDiscountCardSlice.actions;

export const fetchEmployeeCard =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const employee = await api.getEmployeeCard(id);
      dispatch(finishFetchEmployee(employee));
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };

export const resetEmployeeCard = (): AppThunk => async dispatch => {
  dispatch(reset());
};

export const selectEmployeeCard = (state: RootState): EmployeeCard => state.employeeDiscountCard.data;
export const selectEmployeeCardInitials = createSelector(selectEmployeeCard, employeeCard => {
  return `${employeeCard.givenName.charAt(0).toUpperCase()}${employeeCard.surname.charAt(0).toUpperCase()}`;
});

export const selectIsFetchingEmployeeCard = (state: RootState): boolean => state.employeeDiscountCard.isFetching;

export default employeeDiscountCardSlice.reducer;
