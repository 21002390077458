export interface Product {
  storeCode: string;
  sku: string;
  brandNumericCode: string;
  name: string;
  description: string;
  category: string;
  subCategory: string;
  color: string;
  price?: number;
  priceCurrency?: string;
  image: string;
  isEligible: boolean;
  discount: number;
}

export interface ProductResponse {
  storeCode: string;
  sku: string;
  brandNumericCode: string;
  name: string;
  description: string;
  category: string;
  subCategory: string;
  color: string;
  price?: number;
  priceCurrency?: string;
  image: string;
  isEligible: boolean;
  discount: number;
}

export const toProduct = (response: ProductResponse): Product => {
  return {
    storeCode: response.storeCode,
    sku: response.sku,
    brandNumericCode: response.brandNumericCode,
    name: response.name,
    description: response.description,
    category: response.category,
    subCategory: response.subCategory,
    color: response.color,
    price: response.price,
    priceCurrency: response.priceCurrency,
    image: response.image,
    isEligible: response.isEligible,
    discount: response.discount
  };
};

export const emptyProduct = (): Product => {
  return {
    storeCode: '',
    sku: '',
    brandNumericCode: '',
    name: '',
    description: '',
    category: '',
    subCategory: '',
    color: '',
    price: undefined,
    priceCurrency: undefined,
    image: '',
    isEligible: true,
    discount: 0
  };
};

export interface ProductRequest {
  employeeEmail: string
  employeeBrandCode: string
  employeeCountryCode: string
  isGoldCard: boolean | undefined
  isSilverCard: boolean | undefined
}
