export interface Country {
  id: string;
  code: string;
  description: string;
}

interface CountryResponse {
  id: string;
  code: string;
  description: string;
}

export const toCountry = (response: CountryResponse): Country => ({
  id: response.id,
  code: response.code,
  description: response.description,
});

export const getEmptyCountry = (): Country => ({
  id: '',
  code: '',
  description: '',
});
