import { css } from '@emotion/react';
import globalStyle from '../global.style';

const textContainer = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: auto;
  padding-top: 10px;
`;

const text = css`
  ${globalStyle.bodySmall};
  &&& {
    line-height: 10px;
    font-style: italic;
    font-size: 13px;
  }
  display: block;
  margin-block: 0px;
`;

export default {
  textContainer,
  text,
};
