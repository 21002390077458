import { css } from '@emotion/react';
import style from '../../../../../shared/global.style';

const modalContainer = css`
  && {
    max-width: 500px;
  }
  &.visible.transition {
    display: flex !important;
  }
  flex-direction: column;
  height: 50%;
`;

const header = css`
  text-align: center;
  &&& {
    background-color: var(--dcd-secondary-color-70);
    color: var(--dcd-gray-color);
    border: none;
  }
`;

const loaderContainer = css`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-color: var(--dcd-white-color);
`;

const loader = css`
  &&&& {
    :after {
      border-color: var(--dcd-kering-primary-color) transparent transparent;
    }
  }
`;

const buttonsAndFileContainer = css`
  display: flex;
  flex-direction: column;
`;

const buttonsContainer = css`
  width: 100%;
  display: flex;
  padding-top: 30px;
  justify-content: center;
  gap: 15px;
`;

const chooseFileButton = css`
  ${style.secondaryButton};
  &&& {
    margin-right: 5px;
    width: 205px;
  }
`;

const downloadTemplateButton = css`
  ${style.secondaryButton};
  &&& {
    margin-right: 5px;
  }
`;

const resetFilePathButton = css`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const confirmButton = css`
  ${style.secondaryButton};
  height: 39px;
  && {
    margin-right: 5px;
  }
`;

const filepathLabel = css`
  ${style.bodySmall};
  padding-top: 20px;
`;

const loadedFileLabel = css`
  ${style.bodySmall};
  font-weight: 500;
`;

const content = css`
  height: 100%;
  &&& {
    display: flex;
    padding: 20px 10px 15px 20px;
    flex-direction: column;
  }
`;

const footerButtonsContainer = css`
  display: flex;
  margin-top: auto;
  align-self: flex-end;
`;

const initialStep = css`
  height: 100%;
  ul {
    margin: 0;
    padding-top: 15px;
  }
`;

const selectedStep = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export default {
  modalContainer,
  header,
  loaderContainer,
  loader,
  buttonsAndFileContainer,
  buttonsContainer,
  footerButtonsContainer,
  chooseFileButton,
  downloadTemplateButton,
  confirmButton,
  filepathLabel,
  resetFilePathButton,
  loadedFileLabel,
  content,
  initialStep,
  selectedStep,
};
