import { useState } from 'react';
import { useAppDispatch } from '../../../core/hooks';
import { getJwtToken } from '../../../core/services/webStorageService';
import { refreshTokenAndSetTimeout, setTokenAndSetTimeout } from '../store/principalSlice';

export const useOauthToken = () => {
  const dispatch = useAppDispatch();
  const [refreshEnabled, setRefreshEnabled] = useState(false);

  const refreshAndSetTimeout = async () => {
    dispatch(refreshTokenAndSetTimeout());
  };

  const setTokenAndTimeout = (token: string) => {
    dispatch(setTokenAndSetTimeout(token));
    setRefreshEnabled(true);
  };

  const handleTokenRefresh = () => {
    const token = getJwtToken();
    if (!refreshEnabled && token) {
      refreshAndSetTimeout();
    }
  };

  return { handleTokenRefresh, setTokenAndTimeout };
};
