import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ko';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import i18n from '../../../../i18n';
import api from '../utils/api';
import { Purchase } from '../models/purchase';
import { checkValueInclude } from '../../../../shared/utils/utils';
import { selectGeneralFilters, selectSearchBoxFilter } from './purchaseHistoryFiltersSlice';

interface PurchasesPayload {
  purchases: Purchase[];
}

interface PurchaseHistorySliceState {
  data: {
    purchaseHistoryByYear: Purchase[];
    currentYearPurchaseHistory: Purchase[];
    currentYearPurchaseHistoryInEuros: Purchase[];
  };
  purchasesYear: number;
  isFetchingPurchaseHistoryByYear: boolean;
  isFetchingCurrentYearPurchaseHistory: boolean;
  isFetchingCurrentYearPurchaseHistoryInEuros: boolean;
  error: string;
}

const initialState: PurchaseHistorySliceState = {
  data: {
    purchaseHistoryByYear: [],
    currentYearPurchaseHistory: [],
    currentYearPurchaseHistoryInEuros: [],
  },
  purchasesYear: new Date().getFullYear(),
  isFetchingPurchaseHistoryByYear: false,
  isFetchingCurrentYearPurchaseHistory: false,
  isFetchingCurrentYearPurchaseHistoryInEuros: false,
  error: '',
};

export const purchaseHistorySlice = createSlice({
  name: 'purchaseHistory',
  initialState,
  reducers: {
    startFetchingPurchasesByYear: (state: Draft<PurchaseHistorySliceState>): PurchaseHistorySliceState => {
      return {
        ...state,
        isFetchingPurchaseHistoryByYear: true,
      };
    },
    finishFetchingPurchasesByYear: (
      state: Draft<PurchaseHistorySliceState>,
      payloadAction: PayloadAction<PurchasesPayload>
    ): PurchaseHistorySliceState => {
      return {
        ...state,
        data: { ...state.data, purchaseHistoryByYear: payloadAction.payload.purchases },
        isFetchingPurchaseHistoryByYear: false,
        error: '',
      };
    },
    startFetchingCurrentYearPurchases: (state: Draft<PurchaseHistorySliceState>): PurchaseHistorySliceState => {
      return {
        ...state,
        isFetchingCurrentYearPurchaseHistory: true,
      };
    },
    finishFetchingCurrentYearPurchases: (
      state: Draft<PurchaseHistorySliceState>,
      payloadAction: PayloadAction<PurchasesPayload>
    ): PurchaseHistorySliceState => {
      return {
        ...state,
        data: { ...state.data, currentYearPurchaseHistory: payloadAction.payload.purchases },
        isFetchingCurrentYearPurchaseHistory: false,
        error: '',
      };
    },
    startFetchingCurrentYearPurchasesInEuros: (state: Draft<PurchaseHistorySliceState>): PurchaseHistorySliceState => {
      return {
        ...state,
        isFetchingCurrentYearPurchaseHistoryInEuros: true,
      };
    },
    finishFetchingCurrentYearPurchasesInEuros: (
      state: Draft<PurchaseHistorySliceState>,
      payloadAction: PayloadAction<PurchasesPayload>
    ): PurchaseHistorySliceState => {
      return {
        ...state,
        data: { ...state.data, currentYearPurchaseHistoryInEuros: payloadAction.payload.purchases },
        isFetchingCurrentYearPurchaseHistoryInEuros: false,
        error: '',
      };
    },
    setPurchasesYear: (
      state: Draft<PurchaseHistorySliceState>,
      payloadAction: PayloadAction<number>
    ): PurchaseHistorySliceState => {
      return {
        ...state,
        purchasesYear: payloadAction.payload,
      };
    },
    httpError: (
      state: Draft<PurchaseHistorySliceState>,
      payloadAction: PayloadAction<string>
    ): PurchaseHistorySliceState => {
      return {
        ...state,
        isFetchingPurchaseHistoryByYear: false,
        isFetchingCurrentYearPurchaseHistory: false,
        isFetchingCurrentYearPurchaseHistoryInEuros: false,
      };
    },
  },
});

export const {
  startFetchingPurchasesByYear,
  finishFetchingPurchasesByYear,
  startFetchingCurrentYearPurchases,
  finishFetchingCurrentYearPurchases,
  startFetchingCurrentYearPurchasesInEuros,
  finishFetchingCurrentYearPurchasesInEuros,
  setPurchasesYear,
  httpError,
} = purchaseHistorySlice.actions;

export const fetchMyPurchasesByYear =
  (purchaseYear: number): AppThunk =>
  async dispatch => {
    dispatch(startFetchingPurchasesByYear());
    try {
      const purchasesByYear = await api.getMyPurchasesByYear(purchaseYear);
      dispatch(finishFetchingPurchasesByYear({ purchases: purchasesByYear }));
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };

export const fetchAllCurrentYearPurchases = (): AppThunk => async dispatch => {
  dispatch(startFetchingCurrentYearPurchases());
  dispatch(startFetchingCurrentYearPurchasesInEuros());
  try {
    const currentYearPurchases = await api.getMyCurrentYearPurchases();
    const currentYearPurchasesInEuros = await api.getMyCurrentYearPurchasesInEuros();
    dispatch(finishFetchingCurrentYearPurchases({ purchases: currentYearPurchases }));
    dispatch(finishFetchingCurrentYearPurchasesInEuros({ purchases: currentYearPurchasesInEuros }));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
  }
};

export const addPurchasesYear =
  (year: number): AppThunk =>
  async dispatch => {
    dispatch(setPurchasesYear(year));
  };

export const selectMyPurchaseHistoryByYear = (state: RootState): Purchase[] =>
  state.purchaseHistory.data.purchaseHistoryByYear;

export const selectMyCurrentYearPurchaseHistory = (state: RootState): Purchase[] =>
  state.purchaseHistory.data.currentYearPurchaseHistory;

export const selectMyCurrentYearPurchaseHistoryInEuros = (state: RootState): Purchase[] =>
  state.purchaseHistory.data.currentYearPurchaseHistoryInEuros;

export const selectPurchasesYear = (state: RootState): number => state.purchaseHistory.purchasesYear;

const selectGeneralFilteredPurchaseHistory = createSelector(
  selectMyPurchaseHistoryByYear,
  selectGeneralFilters,
  selectPurchasesYear,
  (purchaseHistory, filter, purchaseYear): Purchase[] =>
    purchaseHistory.filter(
      purchase =>
        checkValueInclude(purchase.quantity < 0 ? 'Refunded' : '', filter.refundedStatusName) &&
        checkValueInclude(purchase.store.brand.description, filter.brand.description) &&
        checkValueInclude(purchase.productCategory, filter.category) &&
        checkValueInclude(purchase.store.locationName, filter.storeName) &&
        (filter.monthCode === ''
          ? checkValueInclude(purchase.date, purchaseYear.toString())
          : checkDateFilters(purchase.date, filter.monthCode, filter.dateRange))
    )
);

const selectFilteredBySearchBoxPurchaseHistory = createSelector(
  selectGeneralFilteredPurchaseHistory,
  selectSearchBoxFilter,
  (purchaseHistory, filter): Purchase[] =>
    purchaseHistory.filter(
      purchase =>
        checkValueInclude(purchase.store.brand.description, filter) ||
        checkValueInclude(purchase.productCategory, filter) ||
        checkValueInclude(purchase.productName, filter) ||
        checkValueInclude(purchase.store.locationName, filter) ||
        ('refunded'.includes(filter.toLowerCase()) && purchase.quantity < 0) ||
        checkValueInclude(purchase.date, filter)
    )
);

export const selectFilteredPurchaseHistoryGroupedByMonth = createSelector(
  selectFilteredBySearchBoxPurchaseHistory,
  (purchaseHistory): { [monthKey: string]: Purchase[] } => {
    const { language } = i18n;
    const year = (purchase: Purchase) => moment(purchase.date, 'DD-MM-YYYY').format('YYYY');
    const month = (purchase: Purchase) => moment(purchase.date, 'DD-MM-YYYY').format('MM');
    const monthName = (purchase: Purchase) => moment(purchase.date, 'DD-MM-YYYY').locale(language).format('MMMM YYYY');
    const day = (purchase: Purchase) => moment(purchase.date, 'DD-MM-YYYY').format('DD');
    const purchaseHistoryOrderedFromLatest = _.orderBy(
      purchaseHistory,
      [year, month, day, 'quantity'],
      ['desc', 'desc', 'desc', 'asc']
    );
    return _.groupBy(purchaseHistoryOrderedFromLatest, monthName);
  }
);

export const selectIsFetchingPurchaseHistoryByYear = (state: RootState): boolean =>
  state.purchaseHistory.isFetchingPurchaseHistoryByYear;

export const selectIsFetchingMyCurrentYearPurchaseHistory = (state: RootState): boolean =>
  state.purchaseHistory.isFetchingCurrentYearPurchaseHistory;

export const selectIsFetchingMyCurrentYearPurchaseHistoryInEuros = (state: RootState): boolean =>
  state.purchaseHistory.isFetchingCurrentYearPurchaseHistoryInEuros;

export default purchaseHistorySlice.reducer;

const checkDateFilters = (dateToCheck: string, filterMonth: string, range: Array<Date | null>): boolean => {
  const formattedDate = moment(dateToCheck, 'DD-MM-YYYY').format('YYYY-MM-DD');
  if (filterMonth === 'CUSTOM') {
    return checkDateRange(formattedDate, range);
  }
  return checkMonthDate(formattedDate, filterMonth);
};

const checkDateRange = (dateToCheck: string, range: Array<Date | null>): boolean => {
  const [startDate, endDate] = range;
  if (startDate == null) {
    return true;
  }
  if (moment(dateToCheck).isSameOrAfter(startDate, 'days') && endDate == null) {
    return true;
  }
  return moment(dateToCheck).isSameOrAfter(startDate, 'days') && moment(dateToCheck).isSameOrBefore(endDate, 'days');
};

const checkMonthDate = (dateToCheck: string, filterMonth: string): boolean => {
  return moment(dateToCheck).format('MM') === filterMonth;
};
