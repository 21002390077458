import { css } from '@emotion/react';
import globalStyle from '../global.style';

const storesByBrandContainer = css`
  overflow: auto;
`;
const storeSelectionButtonsContainer = css`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const storeButton = (isSelected: boolean) => {
  return css`
    ${isSelected ? globalStyle.primaryButton : globalStyle.secondaryButton};
    white-space: nowrap;
    && {
      border: 2px solid var(--dcd-pink-color);
    }

    width: calc(50% - 10px);
    @media (max-width: 580px) {
      width: 100%;
    }

    && {
      padding: 12px;
      height: 55px;
      min-width: 0;
      margin: 0;
    }
  `;
};
const storesBrandDescription = css`
  text-align: center;
`;
const storeName = css`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
`;

export default {
  storesByBrandContainer,
  storeSelectionButtonsContainer,
  storeButton,
  storesBrandDescription,
  storeName,
};
