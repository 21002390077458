import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import {
  DiscountRule,
  DiscountRuleSet,
  DiscountRuleSetCreationRequest,
  DiscountRuleSetUpdateRequest,
  getEmptyRuleSet,
  OrderedDiscountRule,
} from '../models/discountRuleSet';
import { Brand } from '../../../../shared/models/brand';
import { toastService } from '../../../../core/services/toastService';
import api from '../utils/api';

interface OrderedDiscountRuleSet {
  id: string;
  brand: Brand;
  employeeTypes: string[];
  discountRules: OrderedDiscountRule[];
}

interface DiscountRuleSetDetailSliceState {
  initialDiscountRuleSet: DiscountRuleSet | null;
  ruleset: OrderedDiscountRuleSet;
  isFetching: boolean;
  error: string;
}

interface EditRulePayload {
  ruleIndex: number;
  rule: OrderedDiscountRule;
}

interface DeleteDiscountRulePayload {
  ruleIndex: number;
}

const initialState: DiscountRuleSetDetailSliceState = {
  initialDiscountRuleSet: null,
  ruleset: getEmptyRuleSet(),
  isFetching: false,
  error: '',
};

export const discountRuleSetDetail = createSlice({
  name: 'discountRuleSetDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<DiscountRuleSetDetailSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (
      state: Draft<DiscountRuleSetDetailSliceState>,
      payloadAction: PayloadAction<DiscountRuleSet>
    ): DiscountRuleSetDetailSliceState => {
      return {
        initialDiscountRuleSet: payloadAction.payload,
        ruleset: payloadAction.payload,

        isFetching: false,
        error: '',
      };
    },
    setBrand: (
      state: Draft<DiscountRuleSetDetailSliceState>,
      payloadAction: PayloadAction<Brand>
    ): DiscountRuleSetDetailSliceState => {
      return {
        ...state,
        ruleset: { ...state.ruleset, brand: payloadAction.payload },
      };
    },
    setEmployeeTypes: (
      state: Draft<DiscountRuleSetDetailSliceState>,
      payloadAction: PayloadAction<string[]>
    ): DiscountRuleSetDetailSliceState => {
      return {
        ...state,
        ruleset: { ...state.ruleset, employeeTypes: payloadAction.payload },
      };
    },
    setDiscountRules: (
      state: Draft<DiscountRuleSetDetailSliceState>,
      payloadAction: PayloadAction<OrderedDiscountRule[]>
    ): DiscountRuleSetDetailSliceState => {
      return {
        ...state,
        ruleset: { ...state.ruleset, discountRules: payloadAction.payload },
      };
    },
    addRule: (
      state: Draft<DiscountRuleSetDetailSliceState>,
      payloadAction: PayloadAction<DiscountRule>
    ): DiscountRuleSetDetailSliceState => {
      const discountRules = [
        ...state.ruleset.discountRules,
        { id: state.ruleset.discountRules.length.toString(), ...payloadAction.payload },
      ];
      return {
        ...state,
        ruleset: { ...state.ruleset, discountRules },
      };
    },
    editRule: (
      state: Draft<DiscountRuleSetDetailSliceState>,
      payloadAction: PayloadAction<EditRulePayload>
    ): DiscountRuleSetDetailSliceState => {
      const { ruleIndex, rule } = payloadAction.payload;
      const discountRules = state.ruleset.discountRules.map((d, i): OrderedDiscountRule => {
        if (i === ruleIndex) {
          return {
            id: d.id,
            constraints: rule.constraints,
            discountPercentage: rule.discountPercentage,
          };
        }
        return d;
      });
      return {
        ...state,
        ruleset: { ...state.ruleset, discountRules },
      };
    },
    deleteDiscountRule: (
      state: Draft<DiscountRuleSetDetailSliceState>,
      payloadAction: PayloadAction<DeleteDiscountRulePayload>
    ): DiscountRuleSetDetailSliceState => {
      const { ruleIndex } = payloadAction.payload;
      const discountRules = state.ruleset.discountRules.filter((d, i) => i !== ruleIndex);

      return {
        ...state,
        ruleset: { ...state.ruleset, discountRules },
      };
    },
    httpError: (state: Draft<DiscountRuleSetDetailSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    reset: () => initialState,
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  reset,
  addRule,
  editRule,
  deleteDiscountRule,
  setEmployeeTypes,
  setDiscountRules,
  setBrand,
} = discountRuleSetDetail.actions;

export const resetDiscountRuleSet = (): AppThunk => async dispatch => {
  dispatch(reset());
};

export const fetchDiscountRuleSet =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const ruleSet: DiscountRuleSet = await api.getDiscountRuleSet(id);
      dispatch(finishFetch(ruleSet));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const createDiscountRuleSet =
  (
    brandCode: string,
    employeeTypes: string[],
    discountRules: DiscountRule[]
  ): AppThunk<Promise<DiscountRuleSet | null>> =>
  async (dispatch): Promise<DiscountRuleSet | null> => {
    try {
      const creationRequest: DiscountRuleSetCreationRequest = {
        brandCode,
        employeeTypes,
        discountRules,
      };
      const createdDiscountRuleSet = await api.createDiscountRuleSet(creationRequest);
      dispatch(finishFetch(createdDiscountRuleSet));
      toastService.success();
      return createdDiscountRuleSet;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const updateDiscountRuleSet =
  (id: string, employeeTypes: string[], discountRules: DiscountRule[]): AppThunk<Promise<DiscountRuleSet | null>> =>
  async (dispatch): Promise<DiscountRuleSet | null> => {
    try {
      const updateRequest: DiscountRuleSetUpdateRequest = {
        employeeTypes,
        discountRules,
      };
      const updatedDiscountRuleSet = await api.updateDiscountRuleSet(id, updateRequest);
      dispatch(finishFetch(updatedDiscountRuleSet));
      toastService.success();
      return updatedDiscountRuleSet;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const selectDiscountRules = (state: RootState): OrderedDiscountRule[] =>
  state.discountRuleSetDetail.ruleset?.discountRules || [];

export const selectReversedDiscountRules = createSelector(
  selectDiscountRules,
  (discountRules: OrderedDiscountRule[]): OrderedDiscountRule[] => [...discountRules].reverse()
);

export const selectDiscountRuleSet = (state: RootState): OrderedDiscountRuleSet => state.discountRuleSetDetail.ruleset;
const selectInitialDiscountRuleSet = (state: RootState): DiscountRuleSet | null =>
  state.discountRuleSetDetail.initialDiscountRuleSet;
export const selectIsFetchingDiscountRuleSet = (state: RootState): boolean => state.discountRuleSetDetail.isFetching;

export const selectDiscountRuleSetBrand = (state: RootState): Brand => state.discountRuleSetDetail.ruleset.brand;
export const selectDiscountRuleSetEmployeeTypes = (state: RootState): string[] =>
  state.discountRuleSetDetail.ruleset.employeeTypes || [];
export const selectIsPristineDiscountRuleSet = createSelector(
  selectDiscountRuleSet,
  selectInitialDiscountRuleSet,
  (ruleSet, initialRuleSet): boolean => _.isEqual(ruleSet, initialRuleSet)
);

export default discountRuleSetDetail.reducer;
