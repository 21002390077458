import { Brand, BrandResponse, toBrand } from '../../../../shared/models/brand';
import { Country } from '../../../../shared/models/country';
import { CreationInfo } from '../../../../shared/models/creation-info';

export interface DiscountRuleSet {
  id: string;
  brand: Brand;
  employeeTypes: string[];
  discountRules: OrderedDiscountRule[];
  creationInfo: CreationInfo | null;
  updateInfo: CreationInfo | null;
}
interface DiscountRuleSetResponse {
  id: string;
  brand: BrandResponse;
  employeeTypes: string[];
  discountRules: DiscountRule[];
  creationInfo: CreationInfo | null;
  updateInfo: CreationInfo | null;
}
export interface DiscountRuleResponse {
  constraints: DiscountConstraints;
  discountPercentage: number;
}

export interface DiscountRule {
  constraints: DiscountConstraints;
  discountPercentage: number;
}
export interface OrderedDiscountRule extends DiscountRule {
  id: string;
}
export interface DiscountConstraints {
  countries: Country[] | null;
  productCategories: string[] | null;
}

export interface DiscountRuleSetCreationRequest {
  brandCode: string;
  employeeTypes: string[];
  discountRules: DiscountRule[];
}

export interface DiscountRuleSetUpdateRequest {
  employeeTypes: string[];
  discountRules: DiscountRule[];
}

export const toDiscountRuleSet = (response: DiscountRuleSetResponse): DiscountRuleSet => {
  return {
    id: response.id,
    brand: toBrand(response.brand),
    employeeTypes: response.employeeTypes,
    discountRules: response.discountRules.map((rule, index) => toOrderedDiscountRule(rule, index)),
    creationInfo: response?.creationInfo,
    updateInfo: response?.updateInfo,
  };
};

export const toDiscountRule = (response: DiscountRuleResponse): DiscountRule => {
  return {
    discountPercentage: response.discountPercentage,
    constraints: {
      countries: response.constraints.countries,
      productCategories: response.constraints.productCategories,
    },
  };
};

const toOrderedDiscountRule = (response: DiscountRuleResponse, index: number): OrderedDiscountRule => {
  return {
    id: index.toString(),
    discountPercentage: response.discountPercentage,
    constraints: {
      countries: response.constraints.countries,
      productCategories: response.constraints.productCategories,
    },
  };
};

export const getEmptyRuleSet = (): DiscountRuleSet => ({
  id: '',
  brand: { code: '', description: '' },
  employeeTypes: [],
  discountRules: [],
  creationInfo: null,
  updateInfo: null,
});
