import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const tabsContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const selectedTab = css`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  background-color: var(--dcd-secondary-color-40);
`;

const tabButton = (selected: boolean): SerializedStyles => {
  return css`
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 0;
    width: 50%;
    text-align: center;
    ${globalStyle.bodyNormal};
    ${selected ? selectedTab : ''};
  `;
};

const filtersContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  ${globalStyle.stickyHeader(`var(--dcd-white-color)`)};
`;

const searchBoxContainer = css`
  flex: 1;
  margin-right: 15px;
`;

const filterIconContainer = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const filterIcon = css``;

const historyLabel = css`
  ${globalStyle.bodyNormal};
  && {
    margin: 8px 0;
  }
`;

const filterBadge = css`
  ${globalStyle.bodySmall};
  && {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dcd-secondary-color-40);
    border-radius: 20px;
    margin-right: 5px;
    padding: 4px 8px;
  }
`;

const badgesWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const badgesContainer = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const purchaseHistoryCardsContainer = css`
  padding: 0 5px;
  flex: 1;
  overflow: auto;
`;

const purchaseContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const noItemsLabel = css`
  ${globalStyle.bodySmall}
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

const searchBarAndYearDropdownContainer = css`
  display: flex;
  width: 100%;
`;
const yearSelectionDropdown = css`
  &&& {
    min-width: 0;
    min-height: 0;
    width: 70px;
    height: 35px;
    font-size: 13px;
    border-radius: 0 20px 20px 0;
    /* border-left: none; */
    background-color: var(--dcd-secondary-color-30);
    border-color: var(--dcd-gray-color-50);
    padding: 10px 15px 11px 8px;
    i.dropdown.icon {
      padding: 10px 6px 11px 0;
      color: var(--dcd-kering-primary-color);
    }
  }
  &.ui.dropdown.selection.active .menu,
  &.ui.selection.dropdown:focus {
    border-color: var(--dcd-gray-color-70);
  }
  &.ui.dropdown .menu > .item {
    background-color: transparent;
    font-weight: 500;
    padding: 10px 11px !important;
  }
  && .menu > .item:hover {
    background-color: var(--dcd-gray-color-30);
  }
  &&.ui.dropdown .menu .active.item {
    font-weight: 600;
  }
  &&.ui.dropdown .menu {
    min-width: 0;
    min-height: 0;
  }
`;

export default {
  tabsContainer,
  tabButton,
  filtersContainer,
  filterIconContainer,
  filterIcon,
  searchBoxContainer,
  historyLabel,
  badgesWrapper,
  filterBadge,
  badgesContainer,
  purchaseContainer,
  noItemsLabel,
  purchaseHistoryCardsContainer,
  searchBarAndYearDropdownContainer,
  yearSelectionDropdown,
};
