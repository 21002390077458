import { css } from '@emotion/react';

const specialCasesContainer = css`
  display: flex;
  border-bottom: solid 2px var(--dcd-secondary-color-70);
  padding-bottom: 5px;
  margin-bottom: 14px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const specialCasesCardsContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const specialCasesWrapper = css`
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
`;

const creationDataContainer = css`
  margin-left: auto;
  padding: 0 10px 10px 0;
`;

export default {
  specialCasesContainer,
  specialCasesCardsContainer,
  specialCasesWrapper,
  creationDataContainer,
};
