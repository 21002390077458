import { css } from '@emotion/react';

const actionBtn = css`
  &&& {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
  }
`;

const modalContainer = css`
  && {
    display: flex;
    max-width: 300px;
    flex-direction: column;
  }
`;

const modalHeader = css`
  text-align: start;
  &&& {
    background-color: var(--dcd-secondary-color-70);
    color: var(--dcd-gray-color);
    border: none;
  }
`;

const modalContent = css`
  &&& {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    gap: 5px;
  }
`;

const modalItem = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const modalButton = css`
  && {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: transparent;
  }
`;

export default {
  actionBtn,
  modalContainer,
  modalContent,
  modalHeader,
  modalItem,
  modalButton,
};
