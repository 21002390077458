import axios from 'axios';
import paths from './paths';
import { Filters, UserActivitiesWithCount, toUserActivitiesWithCount } from '../models/userActivity';
import { Brand, toBrand } from '../../../../shared/models/brand';

const api = {
  getUserActivities: async (
    page: number,
    searchBoxFilter: string,
    filters: Filters
  ): Promise<UserActivitiesWithCount> => {
    const response = await axios.get(
      paths.getUserActivities(
        page,
        searchBoxFilter,
        filters.brand,
        filters.activitySection,
        filters.activityType,
        filters.dateRange
      )
    );
    return toUserActivitiesWithCount(response.data);
  },
  getUserActivitiesBrands: async (): Promise<Brand[]> => {
    const response = await axios.get(paths.getBrands());
    return response.data.map(toBrand);
  },
};

export default api;
