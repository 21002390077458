/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { RequireAuth } from '../core/components/RequireAuth';
import { updatePWAManifest } from '../core/utils';
import { BusinessCardPage } from './BusinessCardPage';

interface PageProps {
  children: JSX.Element;
}

export const BusinessCardPageUnderSecurity = (props: PageProps) => {
  const { children } = props;
  useEffect(() => {
    updatePWAManifest('bc-manifest');
  });

  return (
    <RequireAuth>
      <BusinessCardPage>{children}</BusinessCardPage>
    </RequireAuth>
  );
};
