/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { Id, toast, ToastOptions } from 'react-toastify';
import './toast.css';

const commonOptions: ToastOptions = { icon: false, theme: 'colored', autoClose: 2000 };

export const toastService = {
  success: (): Id =>
    toast.success('', {
      ...commonOptions,
      className: 'success-toast',
      icon: true,
      bodyClassName: 'success-toast-body',
      theme: 'colored',
      progress: undefined,
    }),
  warning: (msg: string | ReactNode, options?: ToastOptions): Id =>
    toast.warning(msg, { ...commonOptions, ...options }),
  error: (msg: string | ReactNode, options?: ToastOptions): Id => toast.error(msg, { ...commonOptions, ...options }),
  info: (msg: string | ReactNode, options?: ToastOptions): Id => toast.info(msg, { ...commonOptions, ...options }),
  default: (msg: string | ReactNode, options?: ToastOptions): Id => toast(msg, options),
  dark: (msg: string | ReactNode, options?: ToastOptions): Id => toast.dark(msg, options),
};
