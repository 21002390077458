import { css } from '@emotion/react';
import globalStyle from '../global.style';

const headerContainer = css`
  display: flex;
  min-width: 230px;
  width: 100%;
  padding: 14px 0 14px;
`;

const headerArrow = css`
  cursor: pointer;
  color: var(--dcd-gray-color);
  :hover {
    color: var(--dcd-gray-color-70);
  }
  position: absolute;
`;

const headerTitle = css`
  ${globalStyle.bodyNormal};
  width: 100%;
  min-height: 22px;
  text-align: center;
`;

export default {
  headerContainer,
  headerArrow,
  headerTitle,
};
