import { Brand } from '../../../../shared/models/brand';
import { Role } from '../../../auth/models/principal';

export interface User {
  id: string;
  email: string;
  firstName: string;
  familyName: string;
  roles: Role[];
  brands: Brand[];
}

export const getEmptyUser = (): User => ({
  id: '',
  email: '',
  firstName: '',
  familyName: '',
  roles: [],
  brands: [],
});

export interface UserCreationRequest {
  firstName: string;
  familyName: string;
  email: string;
  roles: Role[];
  brands: string[];
}

export interface UserUpdateRequest {
  roles: Role[];
  brands: string[];
}

export interface AdUser {
  email: string;
  firstName: string;
  familyName: string;
}

interface UserResponse {
  id: string;
  email: string;
  firstName: string;
  familyName: string;
  roles: Role[];
  brands: Brand[];
}

interface AdUserResponse {
  mail: string;
  givenName: string;
  surname: string;
}

export const toUser = (user: UserResponse): User => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    familyName: user.familyName,
    roles: user.roles,
    brands: user.brands,
  };
};

export const toAdUser = (adUser: AdUserResponse): AdUser => {
  return {
    email: adUser.mail,
    firstName: adUser.givenName,
    familyName: adUser.surname,
  };
};
