import { API_URL } from '../../../../constants';

const paths = {
  getMyPurchasesByYear: (purchasesYear: number): string =>
    `${API_URL}/my-purchases-by-year?purchasesYear=${purchasesYear}`,
  getMyCurrentYearPurchases: (): string => `${API_URL}/my-current-year-purchases`,
  getMyCurrentYearPurchasesInEuros: (): string => `${API_URL}/my-current-year-purchases-in-euros`,
  getMyPurchasesBrands: (): string => `${API_URL}/my-purchases/brands`,
  getMyPurchasesStores: (): string => `${API_URL}/my-purchases/stores`,
  getMyPurchasesCategories: (): string => `${API_URL}/my-purchases/categories`,
};

export default paths;
