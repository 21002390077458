import axios from 'axios';
import paths from './paths';
import { QuotaLimitsByCategories, toQuotaLimitsByCategories } from '../models/QuotaLimit';
import { DiscountRule, toDiscountRule } from '../../../admin/discounts/models/discountRuleSet';

const api = {
  getQuotaLimitsByCategories: async (
    brandCode: string,
    employeeCountryCode: string,
    employeeCategory: string,
    storeCountryCode: string
  ): Promise<QuotaLimitsByCategories> => {
    const response = await axios.get(
      paths.getQuotaLimitsByCategories(brandCode, employeeCountryCode, employeeCategory, storeCountryCode)
    );
    return toQuotaLimitsByCategories(response.data);
  },
  getSalesAssistantDiscountRules: async (
    brandCode: string,
    countryCode: string,
    employeeType: string
  ): Promise<DiscountRule[]> => {
    const response = await axios.get(paths.getSalesAssistantDiscountRules(brandCode, countryCode, employeeType));
    return response.data.map(toDiscountRule);
  },
};
export default api;
