/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Icon, Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import style from './userActivitiesFilters.style';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  changeFilters,
  selectFilters,
  createFilters,
  selectSortedBrands,
  selectActivitySections,
} from '../stores/userActivitiesFiltersSlice';
import { ModalPage } from '../../../../shared/components/ModalPage';
import { Brand } from '../../../../shared/models/brand';
import { RadioGroup, RadioItem } from '../../../../shared/components/RadioGroup';
import { ActivitySection, Filters, getActivityTypesBySection } from '../models/userActivity';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import { Role } from '../../../auth/models/principal';
import { setPage } from '../stores/userActivitiesSlice';

export const UserActivitiesFilters = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const principal = useAppSelector(selectPrincipal);
  const filters = useAppSelector(selectFilters);
  const [brandFilter, setBrandFilter] = useState(filters.brand);
  const [activitySectionFilter, setActivitySectionFilter] = useState(filters.activitySection);
  const [activityTypeFilter, setActivityTypeFilter] = useState(filters.activityType);
  const [dateFilter, setDateFilter] = useState(filters.dateCode);
  const [dateRangeFilter, setDateRangeFilter] = useState<Array<Date | null>>(filters.dateRange);
  const [open, setOpen] = React.useState(false);
  dispatch(createFilters());
  useEffect(() => {
    setBrandFilter(filters.brand);
    setActivitySectionFilter(filters.activitySection);
    setActivityTypeFilter(filters.activityType);
    setDateRangeFilter(filters.dateRange);
    setDateFilter(filters.dateCode);
  }, [filters]);

  const applyFilters = () => {
    const filtersToApply: Filters = {
      brand: brandFilter,
      activitySection: activitySectionFilter,
      activityType: activityTypeFilter,
      dateCode: dateFilter,
      dateRange: dateRangeFilter,
    };
    dispatch(setPage(1));
    dispatch(changeFilters(filtersToApply));
    setOpen(false);
  };

  return (
    <>
      <Icon
        name='filter'
        size='large'
        link
        onClick={() => {
          setOpen(true);
        }}
      />
      {open && (
        <ModalPage
          onClose={() => {
            setOpen(false);
          }}
          title={t('userActivities.filters')}>
          <div css={style.container}>
            <div css={style.filters}>
              {principal?.roles.includes(Role.GROUP_ADMIN) && (
                <BrandRadioGroup value={brandFilter} onClick={setBrandFilter} />
              )}
              <ActivitySectionRadioGroup onSectionClick={setActivitySectionFilter} onTypeClick={setActivityTypeFilter} value={activitySectionFilter} />
              <ActivityTypeRadioGroup
                onClick={setActivityTypeFilter}
                typeValue={activityTypeFilter}
                sectionValue={activitySectionFilter}
              />
              <DateRadioItem
                onClick={setDateFilter}
                value={dateFilter}
                dateRange={dateRangeFilter}
                onRangeSelection={setDateRangeFilter}
              />
            </div>
            <Button css={style.applyButton} onClick={applyFilters}>
              {t('userActivities.applyFilters')}
            </Button>
          </div>
        </ModalPage>
      )}
    </>
  );
};

interface BrandRadioGroupProps {
  onClick: (code: string) => void;
  value: string;
}

const BrandRadioGroup = ({ value, onClick }: BrandRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const userActivitiesBrands: Brand[] = useAppSelector(selectSortedBrands);

  const brands: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('userActivities.allBrands') },
      ...userActivitiesBrands.map(b => ({ code: b.description, description: b.description })),
    ],
    [userActivitiesBrands, t]
  );

  return <RadioGroup label={t('userActivities.filterByBrand')} items={brands} value={value} onClick={onClick} />;
};

interface ActivitySectionRadioGroupProps {
  onSectionClick: (code: string) => void;
  onTypeClick: (code: string) => void;
  value: string;
}

const ActivitySectionRadioGroup = ({ onSectionClick, onTypeClick, value }: ActivitySectionRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const activitySections: ActivitySection[] = useAppSelector(selectActivitySections);

  const sections: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('userActivities.allActivitySections') },
      ...activitySections.map(a => ({ code: a, description: a })),
    ],
    [activitySections, t]
  );

  return (
    <section css={style.filterSection}>
      <div css={style.radioGroupLabel}>{t('userActivities.filterByActivitySection')}</div>
      <Form css={style.radioGroup}>
        {sections.map(s => (
          <Form.Field key={s.code}>
            <Radio
              css={style.radioButton}
              name='radioGroup'
              value={s.code}
              label={s.description}
              checked={s.code === value}
              onClick={() => {
                onSectionClick(s.code);
                onTypeClick('');
              }}
            />
          </Form.Field>
        ))}
      </Form>
    </section>
  );
};

interface ActivityTypeRadioGroupProps {
  onClick: (code: string) => void;
  typeValue: string;
  sectionValue: string;
}

const ActivityTypeRadioGroup = ({ onClick, typeValue, sectionValue }: ActivityTypeRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const activityTypes = getActivityTypesBySection(sectionValue as ActivitySection);

  const types: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('userActivities.allActivityTypes') },
      ...activityTypes.map(a => ({ code: a, description: a })),
    ],
    [activityTypes, t]
  );

  return (
    <RadioGroup label={t('userActivities.filterByActivityType')} items={types} value={typeValue} onClick={onClick} />
  );
};

interface DateRadioItemProps {
  onClick: (code: string) => void;
  value: string;
  dateRange: Array<Date | null>;
  onRangeSelection: (range: Array<Date | null>) => void;
}

const DateRadioItem = ({ onClick, value, dateRange, onRangeSelection }: DateRadioItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [startDate, endDate] = dateRange;
  const allDatesItem: RadioItem = { code: '', description: t('userActivities.allDates') };
  const dateItem: RadioItem = { code: 'dateRangeItem', description: '' };
  const today = new Date();
  const threeYearsAgo = new Date(today.getFullYear() - 3, today.getMonth(), today.getDate());

  return (
    <section css={style.filterSection}>
      <div css={style.radioGroupLabel}>{t('userActivities.filterByDate')}</div>
      <Form css={style.radioGroup}>
        <Form.Field key={allDatesItem.description}>
          <Radio
            css={style.radioButton}
            name='radioGroup'
            value={allDatesItem.code}
            label={allDatesItem.description}
            checked={allDatesItem.code === value}
            onClick={() => {
              onClick(allDatesItem.code);
              onRangeSelection([null, null]);
            }}
          />
        </Form.Field>
        <Form.Field key={dateItem.code} style={{ display: 'flex', alignItems: 'center' }}>
          <Radio
            css={style.radioButton}
            name='radioGroup'
            value={dateItem.code}
            checked={dateItem.code === value}
            onClick={() => {
              onClick(dateItem.code);
              onRangeSelection([null, null]);
            }}
          />
          <div css={style.datePickerContainer}>
            <DatePicker
              minDate={threeYearsAgo}
              maxDate={today}
              disabled={value !== dateItem.code}
              css={style.datePicker}
              placeholderText={t('userActivities.selectRange')}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(update: Array<Date | null>) => {
                onRangeSelection(update);
              }}
              withPortal
            />
          </div>
        </Form.Field>
      </Form>
    </section>
  );
};
