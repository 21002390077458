import { Store, toStore } from './store';

export interface Purchase {
  employeeEmail: string;
  employeeCountry: string;
  productCategory: string;
  productSubCategory: string | null;
  sku: string;
  productName: string;
  quantity: number;
  unitPrice: number;
  discountPercentage: number;
  discountedPrice: number;
  date: string;
  store: Store;
  discountedPriceConverted: MoneyAmount;
}

interface MoneyAmount {
  value: number | null;
  currency: string;
}

export interface PurchaseResponse {
  employeeEmail: string;
  employeeCountry: string;
  productCategory: string;
  productSubCategory: string | null;
  sku: string;
  productName: string;
  quantity: number;
  unitPrice: number;
  discountPercentage: number;
  discountedPrice: number;
  store: Store;
  date: string;
  discountedPriceConverted: MoneyAmount;
}

export const toPurchase = (response: PurchaseResponse): Purchase => ({
  ...response,
  store: toStore(response.store),
});
