import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const page = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

const translation = css`
  flex: 0 1 auto;
  width: 60px;
  height: 60px;
  text-align: right;
`;

const title = css`
  ${globalStyle.bodyNormal};
  && {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    padding-top: 35px;
  }
`;

const bodyContainer = css`
  padding: 30px 20px;
  margin-top: 25px;
  align-self: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const link = css`
  &&&&& {
    ${globalStyle.bodySmall};
    color: var(--dcd-gray-color);
    line-height: 24px;
    border-bottom: 1px solid #c2c2c2;
    padding: 18px 0;
    :hover {
      text-decoration: underline;
      color: var(--dcd-gray-color) !important;
    }
  }
`;
const btn = css`
  background: none !important;
  padding: 18px 0 !important;
  text-align: start !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--dcd-gray-color) !important;
  :hover {
    text-decoration: underline;
  }
  border-bottom: 1px solid #c2c2c2 !important;
`;

const languageDropdwon = css`
  margin-left: auto;
`;

export default {
  page,
  translation,
  title,
  bodyContainer,
  link,
  btn,
  languageDropdwon,
};
