import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const card = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 18px;
  margin: 3px;
  color: var(--dcd-gray-color-70);
`;

const content = css`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const title = css`
  ${globalStyle.bodySmall};
  width: 190%;
  word-wrap: break-word;
  margin-bottom: 0;
  && {
    font-weight: 500;
  }
`;

const threshold = css`
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
`;

export default {
  card,
  content,
  title,
  threshold,
};
