import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';
import { getBackgroundColor, getFontColor } from '../../../../shared/models/employeeCard';
import goldCardBackground from '../../../../assets/gold-card-background.jpg';
import silverCardBackground from '../../../../assets/silver-card-background.jpg';

const container = css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`;

const contentContainer = css`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--dcd-white-color);
  justify-content: space-evenly;
  align-items: center;
`;

const getBackgroundImage = (
  isSpecialCaseGoldCard: boolean | undefined,
  isSpecialCaseSilverCard: boolean | undefined
): string => {
  if (isSpecialCaseGoldCard) {
    return goldCardBackground;
  }
  if (isSpecialCaseSilverCard) {
    return silverCardBackground;
  }

  return '';
};

const cardBackground = (
  brandCode: string,
  isBlocked: boolean | undefined,
  isSpecialCaseGoldCard: boolean | undefined,
  isSpecialCaseSilverCard: boolean | undefined,
  isEligible?: boolean
): SerializedStyles => {
  return css`
    background-color: ${getBackgroundColor(brandCode, isBlocked, isEligible)};
    background-image: url(${getBackgroundImage(isSpecialCaseGoldCard, isSpecialCaseSilverCard)});
    background-size: cover;
    background-position: center;
    color: ${getFontColor(brandCode, isSpecialCaseGoldCard, isSpecialCaseSilverCard)};
    height: 100%;
    width: 100%;
    max-width: 350px;
    max-height: 556px;
    border-radius: 10px;
    box-shadow: 0 0 4px var(--dcd-pink-color-60);
    opacity: ${isBlocked || isEligible === false ? 0.1 : 1};
  `;
};

const wrapper = css`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 350px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const content = css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px 20px 20px 20px;
`;

const circularImage = css`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const employeeInitials = css`
  ${globalStyle.bodyNormal};
  &&& {
    font-size: 55px;
  }
  letter-spacing: -3px;
  color: var(--dcd-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--dcd-pink-color-90);
  height: 150px;
  width: 150px;
`;

const nameSection = css`
  text-align: center;
`;

const emailSection = css`
  text-align: center;
`;

const name = css`
  ${globalStyle.bodyNormal};

  &&& {
    font-weight: 700;
  }
`;

const job = css`
  ${globalStyle.bodyNormal};
`;

const info = css`
  ${globalStyle.bodySmall};
  font-size: 10px;
`;

const discount = css`
  font-size: 36px;
`;
const cardSearchPageButtons = css`
  ${globalStyle.primaryButton};
  &&& {
    padding: 16px 15px;
    font-size: 12px;
    align-items: center;
    flex: 1;
  }
`;

const buttonsContainer = css`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 350px;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 5px;
  margin-top: 23px;
`;

const qrCode = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  cursor: pointer;
  img {
    border-radius: 5px;
  }
`;

const headerContainer = css`
  position: relative;
  width: 100%;
  display: flex;
`;

const blockedCardMessage = css`
  color: var(--dcd-black-color);
  border-radius: 10px;
  text-align: center;
  position: absolute;
  z-index: 1;
  max-width: 270px;
  bottom: 42%;
`;

const lockIcon = css`
  color: var(--dcd-gray-color-70);
  text-align: center;
  position: absolute;
  z-index: 1;
  &&& {
    margin: 0;
    ::before {
      font-size: 80px;
      position: absolute;
      left: -3%;
      bottom: 89%;
    }
  }
`;

const downloadButton = css`
  position: absolute;
  top: -15px;
  right: -15px;
  &&& {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const downloadIcon = css`
  margin: 0 0 4px !important;
  color: var(--dcd-pink-color-100);
`;

export default {
  container,
  headerContainer,
  contentContainer,
  cardBackground,
  wrapper,
  content,
  employeeInitials,
  circularImage,
  nameSection,
  emailSection,
  name,
  job,
  info,
  discount,
  cardSearchPageButtons,
  buttonsContainer,
  qrCode,
  blockedCardMessage,
  lockIcon,
  downloadButton,
  downloadIcon,
};
