/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { MyStoresSelection } from '../components/MyStoresSelection';
import Header from '../../../../shared/components/Header';
import style from './myStoreSelectionPage.style';

export const MyStoresSelectionPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div css={style.container}>
      <div css={style.headerContainer}>
        <Header title={t('seeMore.storeSelection')} arrowBackEnable />
      </div>
      <div css={style.storeSelectionContainer}>
        <MyStoresSelection />
      </div>
    </div>
  );
};
