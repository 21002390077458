/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './basketHeader.style'

interface BasketHeaderProps {
  title: string;
}

export const BasketHeader = ({ title }: BasketHeaderProps): JSX.Element => {

  return (
    <div css={style.headerContainer}>
      <h4 css={style.headerTitle}>
        <span>{title}</span>
      </h4>
    </div>
  );
}