/** @jsxImportSource @emotion/react */
import React from 'react';
import { Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Store } from '../models/store';
import style from './purchaseCard.style';
import { Brand, getLogo, isAvailableLogo } from '../../../../shared/models/brand';
import { toLocaleAmount } from '../../../../shared/utils/utils';

interface PurchaseCardProps {
  brand: Brand;
  productCategory: string;
  product: string;
  quantity: number;
  amountValue: number;
  amountCurrency: string;
  store: Store;
  date: string;
  onClick: () => void;
}

export function PurchaseCard({
  brand,
  date,
  amountCurrency,
  amountValue,
  product,
  productCategory,
  quantity,
  store,
  onClick,
}: PurchaseCardProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div css={style.card} onClick={onClick}>
      <div css={style.brandContainer}>
        {isAvailableLogo(brand.code) && <Image css={style.brand} src={getLogo(brand.code)} />}
        {!isAvailableLogo(brand.code) && <div css={style.temporaryLogo}>{brand.description}</div>}
      </div>
      <div css={style.infoContainer}>
        <div css={style.product}>
          <div css={style.productNameText}>{product}</div>
        </div>
        <div css={style.detail}>{store.locationName}</div>
        <div css={style.detail}>{date}</div>
        <div css={style.detail}>{productCategory}</div>
      </div>
      <div css={style.priceContainer}>
        <div css={style.productNameText}>({quantity})</div>
        {quantity < 0 ? (
          <div css={style.refundedPrice}>
            <div style={{ textDecoration: 'line-through' }}>{`${toLocaleAmount(
              Math.round(Math.abs(amountValue * quantity))
            )} ${amountCurrency}`}</div>
            <div css={style.refundedText}>{t('purchaseHistory.refundedStatus')}</div>
          </div>
        ) : (
          <div css={style.fullPrice}>
            <div>{`${toLocaleAmount(Math.round(Math.abs(amountValue * quantity)))} ${amountCurrency}`}</div>
          </div>
        )}
      </div>
    </div>
  );
}
