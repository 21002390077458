import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import _ from 'lodash';
import api from '../utils/api';
import type { AppThunk, RootState } from '../../../core/store';
import { SalesAssistantStore, Principal, Role } from '../models/principal';
import { Language, DiscountCardUserPreference, BusinessCardUserPreference } from '../models/userPreference';
import { Brand } from '../../../shared/models/brand';
import { setJwtToken } from '../../../core/services/webStorageService';
import { OAUTH_TOKEN_EXPIRATION } from '../../../constants';
import { fetchBrands } from '../../../shared/store/brandsSlice';
import { fetchEmployeeTypes } from '../../../shared/store/employeeTypesSlice';
import { toastService } from '../../../core/services/toastService';

interface PrincipalSliceState {
  data: Principal | null;
  isFetching: boolean;
  error: string;
}

const initialState: PrincipalSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const principalSlice = createSlice({
  name: 'principal',
  initialState,
  reducers: {
    startFetch: (state: Draft<PrincipalSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<PrincipalSliceState>, action: PayloadAction<Principal>) => {
      return { isFetching: false, data: { ...action.payload }, error: '' };
    },
    httpError: (state: Draft<PrincipalSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    updateDiscountCardPreferences: (
      state: Draft<PrincipalSliceState>,
      action: PayloadAction<DiscountCardUserPreference>
    ) => {
      if (state.data == null) {
        return state;
      }
      return {
        ...state,
        data: { ...state.data, discountCardPreferences: action.payload },
      };
    },
    updateBusinessCardPreferences: (
      state: Draft<PrincipalSliceState>,
      action: PayloadAction<BusinessCardUserPreference>
    ) => {
      if (state.data == null) {
        return state;
      }
      return {
        ...state,
        data: { ...state.data, businessCardPreferences: action.payload },
      };
    },
  },
});

export const { startFetch, finishFetch, httpError, updateDiscountCardPreferences, updateBusinessCardPreferences } =
  principalSlice.actions;

export const fetchPrincipal = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const principal = await api.getPrincipal();
    dispatch(finishFetch(principal));
    i18next.changeLanguage(principal.discountCardPreferences.language);
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const updateDiscountCardLanguage =
  (language: Language): AppThunk =>
  async dispatch => {
    try {
      i18next.changeLanguage(language);
      api.updateDiscountCardLanguage(language);
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const acceptDiscountCardPolicies = (): AppThunk => async dispatch => {
  try {
    const response = await api.acceptDiscountCardPolicies();
    dispatch(updateDiscountCardPreferences(response));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const updateStores =
  (jdaCodes: string[]): AppThunk =>
  async dispatch => {
    try {
      await api.updateStores(jdaCodes);
      dispatch(refreshTokenAndSetTimeout());
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateDiscountCardFirstAccess =
  (isFirstAccess: boolean): AppThunk =>
  async dispatch => {
    try {
      const response = await api.updateDiscountCardFirstAccess(isFirstAccess);
      dispatch(updateDiscountCardPreferences(response));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const acceptBusinessCardPolicies = (): AppThunk => async dispatch => {
  try {
    const response = await api.acceptBusinessCardPolicies();
    dispatch(updateBusinessCardPreferences(response));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const setTokenAndSetTimeout =
  (token: string): AppThunk =>
  async dispatch => {
    try {
      setJwtToken(token);
      setTimeout(async () => {
        await refreshTokenAndSetTimeout();
      }, OAUTH_TOKEN_EXPIRATION);
      dispatch(fetchBrands());
      dispatch(fetchEmployeeTypes());

      dispatch(fetchPrincipal());
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const refreshTokenAndSetTimeout = (): AppThunk => async dispatch => {
  try {
    const response = await api.refreshToken();
    dispatch(setTokenAndSetTimeout(response.data.access_token));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const selectPrincipal = (state: RootState): Principal | null => state.principal.data;
export const selectIsDiscountCardFirstAccess = (state: RootState): boolean =>
  state.principal.data?.discountCardPreferences.isFirstAccess || false;
export const selectDiscountCardPolicies = (state: RootState): boolean =>
  state.principal.data?.discountCardPreferences.policiesAccepted || false;
export const selectBusinessCardPolicies = (state: RootState): boolean =>
  state.principal.data?.businessCardPreferences.policiesAccepted || false;
export const selectPrincipalRoles = (state: RootState): Role[] => state.principal.data?.roles || [];
const selectPrincipalBrands = (state: RootState): Brand[] => state.principal.data?.brands || [];
export const selectSortedPrincipalBrands = createSelector(selectPrincipalBrands, (brands: Brand[]): Brand[] => {
  return _.sortBy(brands, ['description']);
});
export const selectPrincipalBrandCode = (state: RootState): string | null => state.principal.data?.brand.code || null;
export const selectPrincipalSalesAssistantStores = (state: RootState): SalesAssistantStore[] | null =>
  state.principal.data?.salesAssistantStores || null;

export default principalSlice.reducer;
