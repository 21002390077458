/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import { LogoutModal } from '../../../../shared/components/LogoutModal';
import style from './seeMore.style';
import { getEmployeeConditionsOfUsePdf, getEmployeePrivacyPolicyPdf } from '../store/SeeMoreSlice';
import { SelectLanguageDropdown } from '../../../../shared/components/SelectLanguageDropdown';

export const SeeMore = (): JSX.Element => {
  const principal = useAppSelector(selectPrincipal);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const languageSelectionEnabled = true;

  const downloadEmployeePrivacyPolicyPdf = async () => {
    await dispatch(getEmployeePrivacyPolicyPdf());
  };

  const downloadEmployeeConditionsOfUsePdf = async () => {
    await dispatch(getEmployeeConditionsOfUsePdf());
  };

  return (
    <div css={style.page}>
      <div css={style.languageDropdwon}>{languageSelectionEnabled && <SelectLanguageDropdown />}</div>
      <div css={style.username}>
        {principal?.firstName} {principal?.familyName}
      </div>
      <div css={style.bodyContainer}>
        <Link css={style.link} to='/privilege-card/employee/my-personal-information'>
          {t('seeMore.myPersonalInformation')}
        </Link>
        <a
          css={style.link}
          target='_blank'
          href='https://keringconnect.service-now.com/kb_view.do?sysparm_article=KB0036477'
          rel='noreferrer'>
          {t('seeMore.purchaseLimit')}
        </a>
        <Button css={style.btn} content={t('seeMore.privacyPolicy')} onClick={downloadEmployeePrivacyPolicyPdf} />
        <Button css={style.btn} content={t('seeMore.conditionsOfUse')} onClick={downloadEmployeeConditionsOfUsePdf} />
        {principal != null && principal.roles.length > 1 && (
          <Link css={style.link} to='/privilege-card/home'>
            {t('seeMore.switchRole')}
          </Link>
        )}
        <LogoutModal />
      </div>
    </div>
  );
};
