import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { SalesAssistantStore } from '../../../auth/models/principal';

interface StoresSlice {
  data: SalesAssistantStore[];
  isFetching: boolean;
  error: string;
  searchBoxFilter: string;
}

const initialState: StoresSlice = {
  data: [],
  isFetching: false,
  error: '',
  searchBoxFilter: '',
};

export const storesSlice = createSlice({
  name: 'storesSlice',
  initialState,
  reducers: {
    startFetch: (state: Draft<StoresSlice>): StoresSlice => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchStores: (
      state: Draft<StoresSlice>,
      payloadAction: PayloadAction<SalesAssistantStore[]>
    ): StoresSlice => {
      return {
        ...state,
        data: payloadAction.payload,
        isFetching: false,
      };
    },
    httpError: (state: Draft<StoresSlice>, payloadAction: PayloadAction<string>): StoresSlice => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    setSearchBoxFilter: (state: Draft<StoresSlice>, action: PayloadAction<string>): StoresSlice => ({
      ...state,
      searchBoxFilter: action.payload,
    }),
    reset: (): StoresSlice => initialState,
  },
});

export const { startFetch, finishFetchStores, httpError, setSearchBoxFilter, reset } = storesSlice.actions;

const fetch = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const stores = await api.getStores();
    dispatch(finishFetchStores(stores));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
    dispatch(finishFetchStores([]));
  }
};

export const fetchStores = (): AppThunk => async dispatch => {
  dispatch(fetch());
};

export const resetStores = (): AppThunk => async dispatch => {
  dispatch(reset());
};

export const setStoresSearchBoxFilter =
  (filter: string): AppThunk =>
  async dispatch => {
    dispatch(setSearchBoxFilter(filter));
  };

export const selectStores = (state: RootState): SalesAssistantStore[] => state.stores.data;

export const selectSearchBoxFilter = (state: RootState): string => state.stores.searchBoxFilter;
export const selectIsFetching = (state: RootState): boolean => state.stores.isFetching;

export const selectFilteredStores = createSelector(selectStores, selectSearchBoxFilter, (stores, filter) => {
  return stores.filter(
    store =>
      filter.trim() === '' ||
      store.name.toLowerCase().includes(filter.toLowerCase()) ||
      store.storeCode.toLowerCase().includes(filter.toLowerCase())
  );
});

export default storesSlice.reducer;
