import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: max-content;
`;

const card = css`
  gap: 18px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin: 7px 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const cardInfo = css`
  font-weight: 500;
  //display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;
const cardName = css`
  overflow: hidden;
  white-space: break-spaces;
`;

const cardMail = css`
  font-weight: 400;
  overflow: hidden;
  white-space: pre-line;
  word-break: break-all;
`;

const userImageContainer = css`
  margin-right: 10px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    height: 60px;
    width: 60px;
  }
`;

const userImage = css`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 60px;
    width: 60px;
    &&& {
      font-size: 15px;
    }
  }
`;

const userInitials = css`
  ${globalStyle.bodyNormal};
  &&& {
    font-size: 25px;
  }
  color: var(--dcd-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--dcd-pink-color-90);
  height: 80px;
  width: 80px;

  @media (max-width: 600px) {
    height: 60px;
    width: 60px;
    &&& {
      font-size: 15px;
    }
  }
`;

export default {
  container,
  card,
  cardInfo,
  cardMail,
  cardName,
  userInitials,
  userImageContainer,
  userImage,
};
