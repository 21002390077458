/** @jsxImportSource @emotion/react */
import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './mainMenu.style';

export const SalesAssistantMainMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (currentPath: string, pathContains = false) => {
    if (pathContains) {
      return location.pathname.includes(currentPath);
    }

    return location.pathname === currentPath;
  };

  return (
    <div css={style.menuContainer}>
      <Menu css={style.menu} icon='labeled' fluid widths={4} borderless>
        <Menu.Item
          name='card'
          active={
            isActive('/privilege-card/sales-assistant/card-search', true) ||
            isActive('/privilege-card/sales-assistant/product-search', true) ||
            isActive('/privilege-card/sales-assistant/check-quota', true)
          }
          onClick={() => navigate('/privilege-card/sales-assistant/card-search')}>
          <Icon name='qrcode' />
          <span css={style.menuText}>{t('mainMenu.card')}</span>
        </Menu.Item>
        <Menu.Item
          name='limit'
          active={isActive('/privilege-card/sales-assistant/purchase-limit')}
          onClick={() => navigate('/privilege-card/sales-assistant/purchase-limit')}>
          <Icon name='tags' flipped='horizontally' />
          <span css={style.menuText}>{t('mainMenu.limit')}</span>
        </Menu.Item>
        <Menu.Item
          name='specialCases'
          active={isActive('/privilege-card/sales-assistant/special-cases')}
          onClick={() => navigate('/privilege-card/sales-assistant/special-cases')}>
          <Icon name='check circle outline' />
          <span css={style.menuText}>{t('mainMenu.specialCases')}</span>
        </Menu.Item>
        <Menu.Item
          name='seeMore'
          active={isActive('/privilege-card/sales-assistant/see-more')}
          onClick={() => navigate('/privilege-card/sales-assistant/see-more')}>
          <Icon name='bars' />
          <span css={style.menuText}>{t('mainMenu.seeMore')}</span>
        </Menu.Item>
      </Menu>
    </div>
  );
};
