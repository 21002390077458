import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { BusinessCard, getEmptyBusinessCard } from '../models/business-card.model';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';

interface PrincipalBusinessCardSliceState {
  data: BusinessCard;
  isFetching: boolean;
  error: string;
}

const initialState: PrincipalBusinessCardSliceState = {
  data: getEmptyBusinessCard(),
  isFetching: false,
  error: '',
};

export const principalBusinessCardSlice = createSlice({
  name: 'principalBusinessCard',
  initialState,
  reducers: {
    startFetch: (state: Draft<PrincipalBusinessCardSliceState>) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetch: (state: Draft<PrincipalBusinessCardSliceState>, payloadAction: PayloadAction<BusinessCard>) => {
      return {
        data: payloadAction.payload,
        isFetching: false,
        error: '',
      };
    },
    httpError: (state: Draft<PrincipalBusinessCardSliceState>, payloadAction: PayloadAction<string>) => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
  },
});

export const { startFetch, finishFetch, httpError } = principalBusinessCardSlice.actions;

export const fetchBusinessCard = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const businessCard = await api.getMyBusinessCard();
    dispatch(finishFetch(businessCard));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
  }
};

export const getAppleWalletBusinessCard = (): AppThunk => async dispatch => {
  try {
    const templateBlob = await api.getMyAppleWalletBusinessCard();
    const url = window.URL.createObjectURL(new Blob([templateBlob], { type: 'application/vnd.apple.pkpass' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'business-card.pkpass');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window.navigator as any).addPasses) {
      const passData = await templateBlob.arrayBuffer();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.navigator as any).addPasses([passData]);
    }
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const getGoogleWalletBusinessCard = (): AppThunk => async dispatch => {
  try {
    const googleWalletAddPassLink = await api.addToGoogleWallet();
    const link = document.createElement('a');
    link.href = googleWalletAddPassLink;
    link.target = '_blank';
    link.click();
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const selectMyBusinessCard = (state: RootState): BusinessCard => state.principalBusinessCard.data;

export const selectIsFetchingMyBusinessCard = (state: RootState): boolean => state.principalBusinessCard.isFetching;

export default principalBusinessCardSlice.reducer;
