import { StoreTypologyEnum } from "../../../../shared/models/storeTypology";

export interface TrafficLightsByCategoryAndStoreTypology {
  category: string;
  storeTypology: StoreTypologyEnum | null;
  color: string;
}

export interface TrafficLightsReadingRequest {
  employeeEmail: string;
  employeeBrandCode: string;
  employeeCountryCode: string;
  salesAssistantBrandCode: string;
}

export interface TrafficLightsResponse {
  category: string;
  storeTypology: StoreTypologyEnum | null;
  color: string;
}

export const toTrafficLights = (response: TrafficLightsResponse): TrafficLightsByCategoryAndStoreTypology => {
  return {
    category: response.category,
    storeTypology: response.storeTypology,
    color: response.color,
  };
};
