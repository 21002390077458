import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const ruleCard = css`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 20px;
  ${globalStyle.bodySmall};
  height: max-content;
  min-height: 82px;
  padding-left: 35px;
  width: 100%;
  position: relative;
`;
const readableRuleField = css`
  ${globalStyle.bodySmall};
  display: flex;
  align-items: center;
`;
const ruleFieldValue = css`
  ${globalStyle.bodySmall};
  border: 1px solid #d9d9d9;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  margin-left: 10px;
  min-width: 85px;
`;
const ruleGroupButton = css`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: end;
  margin-left: auto;
  width: 100px;
`;

const amountInput = css`
  && {
    input {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      width: 85px;
      padding: 0 0 0 10px;
      font-size: 15px;
      line-height: 2.8rem;
    }
  }
`;

const button = css`
  width: 125px;
  ${globalStyle.secondaryButton}
`;

const globalLimitsButtons = css`
  ${globalStyle.secondaryButton}
`;

export default {
  ruleCard,
  readableRuleField,
  ruleFieldValue,
  ruleGroupButton,
  amountInput,
  button,
  globalLimitsButtons,
};
