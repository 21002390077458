import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import type { AppThunk } from '../../../../core/store';
import i18n from '../../../../i18n';

interface SeeMoreSliceState {
  error: string;
}

const initialState = {
  error: '',
};

export const seeMoreSlice = createSlice({
  name: 'seeMoreSlice',
  initialState,
  reducers: {
    httpError: (state: Draft<SeeMoreSliceState>, payloadAction: PayloadAction<string>): SeeMoreSliceState => {
      return {
        ...state,
        error: payloadAction.payload,
      };
    },
  },
});

export const { httpError } = seeMoreSlice.actions;

export const getEmployeePrivacyPolicyPdf = (): AppThunk => async dispatch => {
  try {
    const templateBlob = await api.getEmployeePrivacyPolicyPdf();
    const url = window.URL.createObjectURL(new Blob([templateBlob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'employee-privacy-policy.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const getEmployeeConditionsOfUsePdf = (): AppThunk => async dispatch => {
  try {
    const templateBlob = await api.getEmployeeConditionsOfUsePdf(i18n.language);
    const url = window.URL.createObjectURL(new Blob([templateBlob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'employee-conditions-of-use.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};
export default seeMoreSlice.reducer;
