import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { BlockedEmployee, isEmployeeBlocked, getEmptyBlockedEmployee } from '../models/blockedEmployee';
import { toastService } from '../../../../core/services/toastService';

interface BlockedEmployeesSliceState {
  data: BlockedEmployee[];
  isFetching: boolean;
  error: string;
  filter: string;
  showBlockedOnlyFilter: boolean;
}

const initialState: BlockedEmployeesSliceState = {
  data: [],
  isFetching: false,
  error: '',
  filter: '',
  showBlockedOnlyFilter: false,
};

export const blockedEmployeesSlice = createSlice({
  name: 'blockedEmployees',
  initialState,
  reducers: {
    startFetch: (state: Draft<BlockedEmployeesSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<BlockedEmployeesSliceState>, payloadAction: PayloadAction<BlockedEmployee[]>) => {
      return {
        ...state,
        isFetching: false,
        error: '',
        data: [...payloadAction.payload],
      };
    },
    httpError: (state: Draft<BlockedEmployeesSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    addBlockedEmployee: (state: Draft<BlockedEmployeesSliceState>, payloadAction: PayloadAction<BlockedEmployee>) => {
      return { ...state, data: [...state.data, payloadAction.payload] };
    },
    editBlockedEmployee: (state: Draft<BlockedEmployeesSliceState>, payloadAction: PayloadAction<BlockedEmployee>) => {
      const BlockedEmployees = [...state.data];
      const existingItemIndex = BlockedEmployees.findIndex((e: BlockedEmployee) => e.id === payloadAction.payload.id);
      if (existingItemIndex > -1) {
        BlockedEmployees[existingItemIndex] = payloadAction.payload;
      }
      return {
        ...state,
        data: BlockedEmployees,
      };
    },
    removeBlockedEmployee: (state: Draft<BlockedEmployeesSliceState>, payloadAction: PayloadAction<string>) => {
      return {
        ...state,
        data: state.data.filter((blockedEmployee: BlockedEmployee) => blockedEmployee.id !== payloadAction.payload),
      };
    },
    removeEmptyBlockedEmployee: (state: Draft<BlockedEmployeesSliceState>) => {
      return {
        ...state,
        data: state.data.filter((blockedEmployee: BlockedEmployee) => blockedEmployee.id !== ''),
      };
    },
    addEmptyBlockedEmployee: (state: Draft<BlockedEmployeesSliceState>) => {
      return {
        ...state,
        data: state.data.find(d => d.id === '') != null ? [...state.data] : [...state.data, getEmptyBlockedEmployee()],
      };
    },
    setFilter: (state: Draft<BlockedEmployeesSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      filter: payloadAction.payload,
    }),
    toggleOnlyBlockedFilter: (state: Draft<BlockedEmployeesSliceState>): BlockedEmployeesSliceState => ({
      ...state,
      showBlockedOnlyFilter: !state.showBlockedOnlyFilter,
    }),
  },
});

export const { startFetch, finishFetch, httpError, removeBlockedEmployee, setFilter, toggleOnlyBlockedFilter } =
  blockedEmployeesSlice.actions;

export const fetchBlockedEmployees = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const blockedEmployees = await api.getBlockedEmployees();
    dispatch(finishFetch(blockedEmployees));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const deleteBlockedEmployee =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      await api.deleteBlockedEmployee(id);
      dispatch(removeBlockedEmployee(id));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectBlockedEmployees = (state: RootState): BlockedEmployee[] => state.blockedEmployees.data;
export const selectIsFetching = (state: RootState): boolean => state.blockedEmployees.isFetching;
export const selectFilter = (state: RootState) => state.blockedEmployees.filter;
export const selectShowOnlyBlockedFilter = (state: RootState): boolean => state.blockedEmployees.showBlockedOnlyFilter;

export const selectSortedBlockedEmployees = createSelector(
  selectBlockedEmployees,
  (blockedEmployees: BlockedEmployee[]): BlockedEmployee[] => {
    return _.sortBy(blockedEmployees, ['firstName']);
  }
);

export const selectFilteredAndSortedBlockedEmployees = createSelector(
  selectSortedBlockedEmployees,
  selectFilter,
  selectShowOnlyBlockedFilter,
  (blockedEmployees, filter, onlyBlocked) => {
    return blockedEmployees.filter(
      (blockedEmployee: BlockedEmployee) =>
        (onlyBlocked ? isEmployeeBlocked(blockedEmployee) : true) &&
        (blockedEmployee.email.toLowerCase().includes(filter.toLowerCase()) ||
          blockedEmployee.firstName.toLowerCase().includes(filter.toLowerCase()) ||
          blockedEmployee.familyName.toLowerCase().includes(filter.toLowerCase()))
    );
  }
);

export const selectIsEmailNotUsed = createSelector(
  [selectBlockedEmployees, (state, email, id) => ({ email, id })],
  (blockedEmployees, value) => {
    return !blockedEmployees.some(
      blockedEmployee =>
        blockedEmployee.email.toLowerCase() === value.email.toLowerCase() && blockedEmployee.id !== value.id
    );
  }
);

export function useEmailSelector(email: string, id: string) {
  return useSelector((state: RootState) => selectIsEmailNotUsed(state, email, id));
}

export default blockedEmployeesSlice.reducer;
