/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'semantic-ui-react';
import style from './employeeCardActionsModal.style';
import { BusinessCard } from '../../features/employee/businessCard/models/business-card.model';

export type DetailAction = 'exportPdf' | 'addToAppleWallet' | 'addToGoogleWallet' | 'shareContact';

interface CardActionsModalProps {
  downloadPdf?: () => void;
  shareContact?: () => void;
  addToAppleWallet?: () => void;
  addToGoogleWallet?: () => void;
  onCancel: () => void;
  open: boolean;
  actions: DetailAction[];
  businessCard?: BusinessCard;
  vCard?: string;
}

export const EmployeeCardActionsModal: React.FC<CardActionsModalProps> = ({
  downloadPdf,
  shareContact,
  addToAppleWallet,
  addToGoogleWallet,
  onCancel,
  open,
  actions,
  businessCard,
  vCard,
}: CardActionsModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal size='mini' open={open} css={style.modalContainer} onClose={onCancel} closeIcon>
      <Modal.Header css={style.modalHeader}>{t('myDigitalCard.actionsModalTitle')}</Modal.Header>
      <Modal.Content css={style.modalContent}>
        {actions.includes('exportPdf') && (
          <Button onClick={downloadPdf} css={style.actionBtn}>
            <Icon size='large' name='file pdf' />
            {t('myDigitalCard.downloadPdf')}
          </Button>
        )}
        {actions.includes('addToAppleWallet') && (
          <Button onClick={addToAppleWallet} css={style.actionBtn}>
            <Icon size='large' name='apple' />
            {t('myDigitalCard.addToAppleWallet')}
          </Button>
        )}
        {actions.includes('addToGoogleWallet') && (
          <Button onClick={addToGoogleWallet} css={style.actionBtn}>
            <Icon size='large' name='google' />
            {t('myDigitalCard.addToGoogleWallet')}
          </Button>
        )}
        {actions.includes('shareContact') && (
          <Button onClick={shareContact} css={style.modalButton}>
            <Icon name='user' />
            Share contact
          </Button>
        )}
      </Modal.Content>
    </Modal>
  );
};
