/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Image, Loader } from 'semantic-ui-react';
import style from './specialCasesList.style';
import { SpecialUser } from '../../../admin/discounts/models/specialCases';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  fetchGoldCardImageIfIsNotPresent,
  selectSpecialCaseGoldCardImage,
} from '../../../admin/discounts/store/specialCasesSlice';

interface SpecialUserImageProps {
  user: SpecialUser;
}

const SpecialUserImage = ({ user }: SpecialUserImageProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const userInitials = `${user.firstName.charAt(0).toUpperCase()}${user.familyName.charAt(0).toUpperCase()}`;

  useEffect(() => {
    dispatch(fetchGoldCardImageIfIsNotPresent(user.email));
  }, [dispatch, user.email]);

  const image = useAppSelector(selectSpecialCaseGoldCardImage(user.email));

  return (
    <div css={style.userImageContainer}>
      {image ? (
        image === 'no-image' ? (
          <div css={style.userInitials}>{userInitials}</div>
        ) : (
          <div css={style.userImage}>
            <Image src={image} size='tiny' centered />
          </div>
        )
      ) : (
        <Loader inline active size='mini' />
      )}
    </div>
  );
};
interface specialCaseListProps {
  userList: SpecialUser[];
}

export default function SpecialCasesList({ userList }: specialCaseListProps) {
  return (
    <div css={style.container}>
      {userList.map(card => {
        return (
          <div css={style.card} key={card.email}>
            <SpecialUserImage user={card} />
            <div css={style.cardInfo}>
              <div css={style.cardName}>
                {card.firstName} {card.familyName}
              </div>
              <div css={style.cardMail}>{card.email}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
