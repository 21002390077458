/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './deleteDiscountRuleSetModal.style';

interface DeleteDiscountRuleSetModalProps {
  onDelete: () => void;
  onCancel: () => void;
  open: boolean;
}

export const DeleteDiscountRuleSetModal: React.FC<DeleteDiscountRuleSetModalProps> = ({
  onDelete,
  onCancel,
  open,
}: DeleteDiscountRuleSetModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal css={style.modalContainer} size='mini' open={open}>
      <Modal.Content css={style.modalContent}>
        <Modal.Description>
          <p>{t('limits.limitRules.deleteLimitRuleSet.warningMessage')}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions css={style.modalActions}>
        <Button css={style.cancelBtn} content={t('limits.limitRules.deleteLimitRuleSet.no')} onClick={onCancel} />
        <Button
          css={style.confirmDeleteBtn}
          content={t('limits.limitRules.deleteLimitRuleSet.yes')}
          onClick={onDelete}
        />
      </Modal.Actions>
    </Modal>
  );
};
