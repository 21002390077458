import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { EmployeeCard, getEmptyEmployeeCard } from '../../../../shared/models/employeeCard';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';

interface PrincipalEmployeeCardSliceState {
  data: EmployeeCard;
  isFetching: boolean;
  error: string;
}

const initialState: PrincipalEmployeeCardSliceState = {
  data: getEmptyEmployeeCard(),
  isFetching: false,
  error: '',
};

export const principalEmployeeCardSlice = createSlice({
  name: 'principalEmployeeCard',
  initialState,
  reducers: {
    startFetch: (state: Draft<PrincipalEmployeeCardSliceState>) => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetch: (state: Draft<PrincipalEmployeeCardSliceState>, payloadAction: PayloadAction<EmployeeCard>) => {
      return {
        data: payloadAction.payload,
        isFetching: false,
        error: '',
      };
    },
    httpError: (state: Draft<PrincipalEmployeeCardSliceState>, payloadAction: PayloadAction<string>) => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
  },
});

export const { startFetch, finishFetch, httpError } = principalEmployeeCardSlice.actions;

export const fetchEmployeeCard = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const employeeCard = await api.getMyCard();
    dispatch(finishFetch(employeeCard));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
  }
};

export const getAppleWalletEmployeeCard = (): AppThunk => async dispatch => {
  try {
    const templateBlob = await api.getMyAppleWalletEmployeeCard();
    const url = window.URL.createObjectURL(new Blob([templateBlob], { type: 'application/vnd.apple.pkpass' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'employee-card.pkpass');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window.navigator as any).addPasses) {
      const passData = await templateBlob.arrayBuffer();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.navigator as any).addPasses([passData]);
    }
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const getGoogleWalletEmployeeCard = (): AppThunk => async dispatch => {
  try {
    const googleWalletAddPassLink = await api.addToGoogleWallet();
    const link = document.createElement('a');
    link.href = googleWalletAddPassLink;
    link.target = '_blank';
    link.click();
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const selectMyEmployeeCard = (state: RootState): EmployeeCard => state.principalEmployeeCard.data;
export const selectMyEmployeeCardInitials = createSelector(selectMyEmployeeCard, employeeCard => {
  return `${employeeCard.givenName.charAt(0).toUpperCase()}${employeeCard.surname.charAt(0).toUpperCase()}`;
});

export const selectIsFetchingMyEmployeeCard = (state: RootState): boolean => state.principalEmployeeCard.isFetching;

export default principalEmployeeCardSlice.reducer;
