import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const wrapper = css`
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px var(--dcd-secondary-color-70);
  padding-bottom: 20px;
  padding-right: 10px;
`;

const globalLimitsContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const title = css`
  ${globalStyle.bodyMedium};
  width: 130px;
  @media (max-width: 600px) {
    display: none;
  }
`;
const rulesWrapper = css`
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
`;

export default {
  wrapper,
  globalLimitsContainer,
  title,
  rulesWrapper,
};
