import axios from 'axios';
import paths from './paths';
import { BusinessCard, toBusinessCard } from '../models/business-card.model';

const api = {
  getMyBusinessCard: async (): Promise<BusinessCard> => {
    const response = await axios.get(paths.getMyBusinessCard());
    return toBusinessCard(response.data);
  },
  addToGoogleWallet: async (): Promise<string> => {
    const response = await axios.get(paths.addToGoogleWallet());
    return response.data;
  },
  getMyAppleWalletBusinessCard: async (): Promise<Blob> => {
    const response = await axios.get(paths.getMyAppleWalletBusinessCard(), { responseType: 'blob' });
    return response.data;
  },
};
export default api;
