/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { Country } from '../../../../shared/models/country';
import { RadioGroup, RadioItem } from '../../../../shared/components/RadioGroup';
import {
  changeFilters,
  Filters,
  otherCountries,
  selectEmployeeTypes,
  selectSAPurchaseLimitFilters,
  selectSortedCountriesFilteredByStoreBrand,
} from '../store/salesAssistantPurchaseLimitFilterSlice';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';
import { ModalPage } from '../../../../shared/components/ModalPage';
import style from './purchaseFiltersModal.style';

interface PurchaseFiltersModalProps {
  onBack: () => void;
  hideCountry: boolean;
}

export const PurchaseFiltersModal = ({ onBack, hideCountry = false }: PurchaseFiltersModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectSAPurchaseLimitFilters);
  const [countryFilter, setCountryFilter] = useState(filters.country);
  const [employeeTypeFilter, setEmployeeTypeFilter] = useState(filters.employeeType);
  const salesAssistantStore = useAppSelector(selectSelectedStore);

  useEffect(() => {
    setCountryFilter(filters.country);
    setEmployeeTypeFilter(filters.employeeType);
  }, [filters]);

  const applyFilters = () => {
    const filtersToApply: Filters = {
      country: countryFilter,
      employeeType: employeeTypeFilter,
      brand: salesAssistantStore?.brand.code || '',
    };
    dispatch(changeFilters(filtersToApply));
    onBack();
  };
  return (
    <ModalPage onClose={onBack} title={t('purchaseHistory.filters')}>
      <div css={style.container}>
        <div css={style.filters}>
          <EmployeeTypeRadioGroup value={employeeTypeFilter} onClick={setEmployeeTypeFilter} />
          {!hideCountry && <CountryRadioGroup value={countryFilter.code} onClick={setCountryFilter} />}
        </div>
        <Button css={style.applyButton} onClick={applyFilters}>
          {t('purchaseHistory.applyFilters')}
        </Button>
      </div>
    </ModalPage>
  );
};

interface CountryRadioGroupProps {
  onClick: (country: Country) => void;
  value: string;
}

const CountryRadioGroup = ({ value, onClick }: CountryRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableCountries = useAppSelector(selectSortedCountriesFilteredByStoreBrand);
  const countries: RadioItem[] = useMemo(
    () => [...availableCountries.map(c => ({ code: c.code, description: c.description })), otherCountries],
    [availableCountries]
  );

  return (
    <RadioGroup
      label={t('limits.limitRules.filterByCountry')}
      items={countries}
      value={value}
      onClick={v => {
        if (v === '') {
          onClick({ id: '', code: '', description: t('purchaseLimits.otherCountries') });
        } else {
          const selectedCountry = countries.find(c => c.code === v) as Country;
          onClick(selectedCountry);
        }
      }}
    />
  );
};

interface EmployeeTypeRadioGroupProps {
  onClick: (code: string) => void;
  value: string;
}

const EmployeeTypeRadioGroup = ({ value, onClick }: EmployeeTypeRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableEmployeeTypes = useAppSelector(selectEmployeeTypes);

  const employeeTypes: RadioItem[] = useMemo(
    () => [
      ...availableEmployeeTypes.map(e => ({
        code: e,
        description: t(`salesAssistant.purchaseLimits.employeeTypes.${e}`),
      })),
    ],
    [availableEmployeeTypes, t]
  );

  return (
    <RadioGroup
      label={t('limits.limitRules.filterByEmployeeType')}
      items={employeeTypes}
      value={value}
      onClick={onClick}
    />
  );
};
