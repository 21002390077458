import { API_URL } from '../../../../constants';

const CARDS_URL = `${API_URL}/employee-cards`;

const paths = {
  getEmployeeCards: (filter: string): string => `${CARDS_URL}?filter=${filter}`,
  getEmployeeCard: (id: string): string => `${CARDS_URL}/${id}`,
};

export default paths;
