import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';

const scrollbar = css`
  *::-webkit-scrollbar {
    width: 10px;
  }

  // firefox
  * {
    scrollbar-width: thin;
  }

  @media (max-width: 1200px) {
    *::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
  }
`;

const h2 = css`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 22px;
    b,
    strong {
      font-weight: 700;
    }
  }
`;

const bodyNormal = css`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    b,
    strong {
      font-weight: 700;
    }
  }
`;

const bodyMedium = css`
  && {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

const bodySmall = css`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    b,
    strong {
      font-weight: 700;
    }
  }
`;

const genericButton = css`
  && {
    padding: 16px 32px;
    border-radius: 8px;
    cursor: pointer;
    min-width: max-content;
    min-height: max-content;
  }
`;
const primaryButton = css`
  ${genericButton};

  && {
    color: var(--dcd-white-color);
    background-color: var(--dcd-pink-color);

    &&&:hover {
      color: var(--dcd-white-color);
      background-color: var(--dcd-pink-color-70);
    }

    &&:focus {
      color: var(--dcd-white-color);
      background-color: var(--dcd-pink-color-80);
    }

    &&:disabled {
      color: var(--dcd-gray-color-60);
      background-color: var(--dcd-gray-color-40);
    }
  }
`;

const secondaryButton = css`
  ${genericButton};

  && {
    color: var(--dcd-pink-color);
    background-color: var(--dcd-white-color);
    border: 2px solid var(--dcd-pink-color);
    padding: 14px 30px;

    &&:hover {
      color: var(--dcd-white-color);
      background-color: var(--dcd-pink-color-70);
      border-color: var(--dcd-pink-color-70);
    }

    &&:disabled {
      color: var(--dcd-gray-color-disabled);
      border-color: var(--dcd-gray-color-disabled);
      background-color: var(--dcd-white-color);
    }
  }
`;

const labeledButton = (label: string): SerializedStyles => css`
  &&&& {
    position: absolute;
    z-index: 10;
    bottom: 20px;
    right: 20px;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    background-color: var(--dcd-pink-color-80);
    color: var(--dcd-white-color);
  }

  @media (min-width: 768px) {
    &&&& {
      position: unset;
      z-index: unset;
      bottom: unset;
      right: unset;
      width: fit-content;
      height: unset;
      border-radius: 8px;
      background-color: var(--dcd-pink-color-80);
      color: var(--dcd-white-color);

      :after {
        content: '${label}';
        padding-left: 5px;
      }
    }
  }
`;
const stickyHeader = (backgroundColor: string, top = '-20px') => css`
  background-color: ${backgroundColor};
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: ${top};
  margin-left: -20px;
  margin-right: -20px;
  z-index: 1;
`;

export default {
  h2,
  bodySmall,
  bodyMedium,
  bodyNormal,
  scrollbar,
  primaryButton,
  secondaryButton,
  labeledButton,
  stickyHeader,
};
