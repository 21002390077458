/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './selectEmployeeCategory.style';
import Header from '../../../../shared/components/Header';
import { useAppSelector } from '../../../../core/hooks';
import { selectEmployeeTypes } from '../store/salesAssistantPurchaseLimitFilterSlice';

interface SelectEmployeeCategoryProps {
  onChange: (value: string) => void;
}

export const SelectEmployeeCategory = ({ onChange }: SelectEmployeeCategoryProps): JSX.Element => {
  const { t } = useTranslation();
  const availableEmployeeTypes = useAppSelector(selectEmployeeTypes);

  return (
    <div css={style.contentContainer}>
      <div css={style.headerContainer}>
        <Header title={t('salesAssistant.purchaseLimits.purchaseLimits')} arrowBackEnable={false} />
      </div>
      <h3 css={style.selectEmployeeCategoryTitle}>{t('salesAssistant.purchaseLimits.selectEmployeeCategory')}</h3>
      <div css={style.selectEmployeeCategoryContainer}>
        {availableEmployeeTypes.map(employeeType => (
          <Button key={employeeType} css={style.employeeCategoryButton} onClick={() => onChange(employeeType)}>
            {t(`salesAssistant.purchaseLimits.employeeTypes.${employeeType}`)}
          </Button>
        ))}
      </div>
    </div>
  );
};
