import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const discountRuleCard = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 25px;
`;
const discountRuleCardContent = css`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  @media (max-width: 539px) {
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--dcd-gray-color-60);
  }
`;

const discountRuleButtons = css`
  ${globalStyle.secondaryButton};
`;

const noItemsLabel = css`
  flex: 1;
  ${globalStyle.bodySmall}
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const buttonContainer = css`
  margin-left: auto;
  display: flex;
  align-self: flex-start;
  width: 100px;
`;

export default {
  discountRuleCard,
  discountRuleCardContent,
  discountRuleButtons,
  noItemsLabel,
  buttonContainer,
};
