/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  emptyBasket,
  selectBasket,
  selectBasketCurrency,
  selectBasketErrorMessages,
  selectBasketGrouped,
  selectBasketHasErrors,
  selectBasketHasWarnings,
  selectBasketPrice,
  selectBasketWarningMessages,
  updateBasket,
} from '../store/basketSlice';
import { GroupedBasketProduct } from '../models/basket';
import style from './productsBasket.style';
import { Product, emptyProduct } from '../models/product';
import { fetchImage } from '../store/productImageSlice';
import { selectEmployeeCard } from '../../cardSearch/store/employeeDiscountCardSlice';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';
import { SalesAssistantStore } from '../../../auth/models/principal';
import { toastService } from '../../../../core/services/toastService';
import { BasketHeader } from '../components/BasketHeader';
import { DeleteBasketItemModal } from '../components/DeleteBasketItemModal';

interface ProductsBasketProps {
  setIsProductSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product>>;
  currentProductsList: Product[]
  setCurrentProductsList: React.Dispatch<React.SetStateAction<Product[]>>;

}

export const ProductsBasket = ({ setIsProductSelected, setSelectedProduct, currentProductsList, setCurrentProductsList }: ProductsBasketProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const basketProducts = useAppSelector(selectBasket);
  const basketProductsGrouped = useAppSelector(selectBasketGrouped);
  const basketPrice = useAppSelector(selectBasketPrice);
  const basketCurrency = useAppSelector(selectBasketCurrency);
  const basketHasErrors = useAppSelector(selectBasketHasErrors);
  const basketHasWarnings = useAppSelector(selectBasketHasWarnings);
  const basketErrorMessages = useAppSelector(selectBasketErrorMessages);
  const basketWarningMessages = useAppSelector(selectBasketWarningMessages);

  const employeeCard = useAppSelector(selectEmployeeCard);
  const selectedSalesAssistantStore = useAppSelector(selectSelectedStore) as SalesAssistantStore;
  const [selectedItems, setSelectedItems] = useState(basketProducts)

  const removeItemsFromBasket = (product: GroupedBasketProduct) => {
    dispatch(updateBasket(employeeCard.mail, employeeCard.brand.code, selectedItems.filter((i) => i.sku !== product.sku).map((i) => i.sku), selectedSalesAssistantStore.storeCode))
    setCurrentProductsList(currentProductsList.filter((p) => p.sku !== product.sku))
    setSelectedProduct(emptyProduct())
    setTimeout(() => {
      toastService.success()
    }, 1000)
  }

  useEffect(() => {
    setSelectedItems(basketProducts)
  }, [basketProducts])

  return (
    <div css={style.container}>
      <BasketHeader title={t('salesAssistant.productsBasket.basket')} />
      <div css={style.content}>
        <div css={style.productCardContainer}>
          {basketProducts.length === 0 && (
            <div css={style.noProducts}>{t('salesAssistant.productsBasket.emptyBasket')}</div>
          )}
          {basketProductsGrouped.map((p: GroupedBasketProduct) => (
            <BasketProductCard
              product={p}
              key={`${p.sku}-${Math.random()}`}
              onProductSelection={() => {
                const fromBasketProductToProduct = currentProductsList.find((sp) => sp.sku === p.sku)
                if(fromBasketProductToProduct != null) {
                  setIsProductSelected(true)
                  setSelectedProduct(fromBasketProductToProduct)
                  dispatch(fetchImage(fromBasketProductToProduct.image))
                }
              }}
              removeItems={removeItemsFromBasket}
            />
          ))}
        </div>
        {basketProducts.length > 0 && (
          <BasketResult
            basketCurrency={basketCurrency}
            basketPrice={basketPrice}
            basketHasErrors={basketHasErrors}
            basketHasWarnings={basketHasWarnings}
            basketErrorMessages={basketErrorMessages}
            basketWarningMessages={basketWarningMessages}
          />
        )}
          <Button
            css={style.basketButton}
            onClick={() => {
              dispatch(emptyBasket());
              setCurrentProductsList([])
              setSelectedProduct(emptyProduct())
              toastService.success()
            }}
            content={t('salesAssistant.productsBasket.clearAll')}
            disabled={basketProducts.length === 0}
          />
      </div>
    </div>
  );
}
const BasketResult = ({
  basketCurrency,
  basketPrice,
  basketHasErrors,
  basketHasWarnings,
  basketErrorMessages,
  basketWarningMessages,
}: {
  basketHasErrors: boolean;
  basketHasWarnings: boolean;
  basketErrorMessages: string[];
  basketWarningMessages: string[];
  basketPrice: string;
  basketCurrency: string;
}) => {
  const { t } = useTranslation();
  return (
    <div css={style.basketResult(basketHasErrors, basketHasWarnings)}>
      <Icon
        size='large'
        name={basketHasErrors ? 'dont' : 'check circle outline'}
        color={basketHasErrors ? 'red' : basketHasWarnings ? 'yellow' : 'green'}
      />
      <div css={style.basketResultMessages}>
        {basketHasErrors && basketErrorMessages.map(message => <div key={message}>{message}</div>)}
        {!basketHasErrors &&
          basketHasWarnings &&
          basketWarningMessages.map(message => <div key={message}>{message}</div>)}
        {!basketHasErrors && (
          <div>
            {t('salesAssistant.productsBasket.basketSuccess')} {t('salesAssistant.productsBasket.totalPrice')}{' '}
            <strong>{basketPrice}</strong> <strong>{basketCurrency}</strong>
          </div>
        )}
      </div>
    </div>
  );
};
interface BasketProductCardProps {
  product: GroupedBasketProduct;
  onProductSelection: () => void;
  removeItems: (product: GroupedBasketProduct) => void
}

const BasketProductCard = ({ product, onProductSelection, removeItems }: BasketProductCardProps) => {
  console.log(product)
  const { t } = useTranslation();
  const [openDeleteBasketItemModal, setOpenDeleteBasketItemModal] = useState(false)
  return (
    <div css={style.cardAndIconContainer}>
      <Card css={style.productCard} onClick={onProductSelection}>
        <Card.Content>
          <Card.Header css={style.productCardHeader}>
            <div css={style.cardContent}>
              <Icon name='shopping bag' size='big' />
              <div css={style.cardInfo}>
                <span css={style.productName}>
                  {product.name} - {product.sku}
                </span>
                <span css={style.productCategory}>{product.category}</span>
              </div>
              <div css={style.cardRightColumn}>
                <span>
                  {t('salesAssistant.productsBasket.quantity')} {product.counter}
                </span>
                <div css={style.productCardDiscountedPrice}>
                  <span>{`${product.discount}%`}</span>
                  <span>{product.discountedPrice} {product.priceCurrency}</span>
                </div>
              </div>
            </div>
          </Card.Header>
        </Card.Content>
      </Card>
      <Button icon css={style.trashButton} onClick={() => setOpenDeleteBasketItemModal(true)}><Icon name='trash alternate outline' size='large' color='black'/></Button>
      <DeleteBasketItemModal 
       onDelete={() => {
          setOpenDeleteBasketItemModal(false);
          removeItems(product)
        }}
        onCancel={() => setOpenDeleteBasketItemModal(false)}
        open={openDeleteBasketItemModal}
      
      />
    </div>
    
  );
};
