import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100% - 56px);
  height: 100%;
`;

const contentContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 335px;
`;

const card = css`
  background-color: var(--dcd-white-color);
  color: var(--dcd-black-color);
  height: 100%;
  max-height: 556px;
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  margin-top: 14px;
  box-shadow: 0 0 4px var(--dcd-pink-color-60);
  position: relative;
  margin-bottom: 10px;
  position: relative;
`;

const content = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
`;

const nameSection = css`
  text-align: center;
  word-wrap: break-word;
`;

const emailSection = css`
  text-align: left;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const name = css`
  ${globalStyle.bodyNormal};
  &&& {
    font-weight: 700;
  }
`;

const info = css`
  ${globalStyle.bodySmall};
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 10px;
`;

const infoText = css`
  width: 100%;
`;

const infoIcon = css`
  && {
    display: flex;
    align-items: center;
  }
`;

const buttonContainer = css`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 5px;
`;

const button = css`
  ${globalStyle.primaryButton};
  &&& {
    padding: 16px 15px;
    font-size: 12px;
    align-items: center;
    flex: 1;
    margin: 0;
  }
`;

const buttonIcon = css`
  &&& {
    line-height: unset;
    padding-left: 5px;
    font-size: 1.4em;
  }
`;

const walletIcon = css`
  &&& {
    font-size: 1.4em;
  }
`;

const walletButton = css`
  ${globalStyle.primaryButton};
  &&& {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 15px;
    gap: 10px;
    flex: 1;
  }
`;

const shareButton = css`
  position: absolute;
  top: -20px;
  right: -20px;
  &&& {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const shareIcon = css`
  && {
    margin: 0 0 4px !important;
    color: var(--dcd-black-color);
  }
`;

const qrCode = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  cursor: pointer;
  img {
    border-radius: 5px;
  }
  position: relative;
`;

const logoContainer = css`
  position: absolute;
  background-color: var(--dcd-white-color);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 40px;
  width: 40px;
`;

const policiesLinkContainer = css`
  margin-bottom: 10px;
`;

const policiesLink = css`
  ${globalStyle.bodySmall};
  && {
    font-size: 12px;
    font-style: italic;
  }
  color: inherit;
  text-decoration: none;
  transition: text-decoration 0.2s;
  :hover {
    color: var(--dcd-pink-color-90);
  }
`;
export default {
  container,
  contentContainer,
  card,
  content,
  nameSection,
  emailSection,
  name,
  info,
  infoText,
  infoIcon,
  shareButton,
  shareIcon,
  buttonContainer,
  button,
  buttonIcon,
  walletIcon,
  walletButton,
  qrCode,
  logoContainer,
  policiesLinkContainer,
  policiesLink,
};
