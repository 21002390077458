import { API_URL } from '../../../../constants';
import { NonEligibleConstraint } from '../models/nonEligibleConstraint';

const GLOBAL_LIMITS = `${API_URL}/global-limits`;
const LIMIT_RULES = `${API_URL}/limit-rules`;
const BRANDS = `${API_URL}/brands`;

const paths = {
  getGlobalLimits: (): string => `${GLOBAL_LIMITS}`,
  getGlobalLimitsConverted: (currencyCountryCode: string): string =>
    `${GLOBAL_LIMITS}/converted/?currencyCountryCode=${currencyCountryCode}`,
  updateGlobalLimits: (): string => `${GLOBAL_LIMITS}`,
  getLimitRules: (): string => `${LIMIT_RULES}`,
  getLimitRule: (id: string): string => `${LIMIT_RULES}/${id}`,
  deleteLimitRule: (id: string): string => `${LIMIT_RULES}/${id}`,
  postLimitRule: (): string => `${LIMIT_RULES}`,
  updateLimitRule: (id: string): string => `${LIMIT_RULES}/${id}`,
  getCountries: (brandCode: string): string => `${BRANDS}/${brandCode}/employeeCountries`,
  getProductCategoriesByBrand: (brandCode: string): string => `${BRANDS}/${brandCode}/product-categories`,
  getProductSkusByBrand: (brandCode: string): string => `${BRANDS}/${brandCode}/product-skus`,
  getProductStyleLinesByBrand: (brandCode: string): string => `${BRANDS}/${brandCode}/style-lines`,
  getNonEligibleConstraintsByBrand: (brandCode: string): string => `${BRANDS}/${brandCode}/non-eligible-constraints`,
  downloadNonEligibleProductsBulkUploadTemplate: (nonEligibleConstraint: NonEligibleConstraint): string =>
    `${LIMIT_RULES}/non-eligible-products-bulk-upload-template/?nonEligibleConstraint=${nonEligibleConstraint}`,
};

export default paths;
