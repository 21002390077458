/** @jsxImportSource @emotion/react */

import React, { useCallback, useMemo, useState } from 'react';
import { Button, Dropdown, DropdownItemProps, DropdownProps, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as EmailValidator from 'email-validator';
import _ from 'lodash';
import style from './userCard.style';
import { User } from '../models/user';
import { useEmailSelector } from '../stores/usersManagementSlice';
import { FormField } from '../../../../shared/components/FormField';
import { Role, roles } from '../../../auth/models/principal';
import { Brand } from '../../../../shared/models/brand';
import { useAppSelector } from '../../../../core/hooks';
import { selectSortedBrands } from '../../../../shared/store/brandsSlice';
import AdUserDropdown from '../../../../shared/components/AdUserDropDown';

export interface CardToSave {
  id: string;
  firstName: string;
  familyName: string;
  email: string;
  roles: Role[];
  brands: Brand[];
}

interface UserCardWritableProps {
  user: User;
  onCancel: () => void;
  onSave: (cardToSave: CardToSave) => void;
}

export const UserCardWritable = ({ user, onCancel, onSave }: UserCardWritableProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(user.email);
  const [selectedFirstName, setFirstName] = useState(user.firstName);
  const [selectedFamilyName, setFamilyName] = useState(user.familyName);
  const [selectedRoles, setRoles] = useState(user.roles);
  const [selectedBrands, setBrands] = useState(user.brands);

  const fullName = useMemo(() => {
    const name = `${selectedFirstName} ${selectedFamilyName}`;
    if (name.trim() === '') {
      return '-';
    }
    return name;
  }, [selectedFirstName, selectedFamilyName]);

  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const rolesAreValid = useMemo(() => selectedRoles.length > 0, [selectedRoles.length]);
  const brandsAreValid = useMemo(() => selectedBrands.length > 0, [selectedBrands.length]);

  const isEmailValid = useMemo(() => email.trim() !== '' && EmailValidator.validate(email), [email]);
  const isEmailNotUsed = useEmailSelector(email, user.id);

  const getEmailValidationMessages = useCallback(() => {
    const messages = [];
    if (showValidationMessage && !isEmailValid) {
      messages.push(t('users.emailValidationMessage'));
    }
    if (showValidationMessage && !isEmailNotUsed) {
      messages.push(t('users.emailUsed'));
    }
    return messages;
  }, [isEmailNotUsed, isEmailValid, showValidationMessage, t]);

  const isFirstNameValid = useMemo(() => selectedFirstName.trim() !== '', [selectedFirstName]);

  const isFamilyNameValid = useMemo(() => selectedFamilyName.trim() !== '', [selectedFamilyName]);

  const updateOrCreation = useCallback(() => {
    setShowValidationMessage(true);
    if (rolesAreValid && isEmailValid && isEmailNotUsed && isFirstNameValid && isFamilyNameValid && brandsAreValid) {
      onSave({
        id: user.id,
        email,
        familyName: selectedFamilyName,
        firstName: selectedFirstName,
        roles: selectedRoles,
        brands: selectedBrands,
      });
    }
  }, [
    brandsAreValid,
    email,
    isEmailNotUsed,
    isEmailValid,
    isFamilyNameValid,
    isFirstNameValid,
    onSave,
    rolesAreValid,
    selectedBrands,
    selectedFamilyName,
    selectedFirstName,
    selectedRoles,
    user.id,
  ]);

  return (
    <div css={style.container}>
      <div css={style.iconContainer}>
        <Icon css={style.userIcon} name='user' />
      </div>
      <div css={style.contentContainer}>
        <div>
          <div css={style.emailDropdownFieldContainer}>
            {user.id !== '' ? (
              <FormField label={t('users.email')} viewComponent={<span css={style.field}>{email}</span>} />
            ) : (
              <AdUserDropdown
                onSelection={(userEmail: string, userFirstName: string, userFamilyName: string) => {
                  setEmail(userEmail);
                  setFirstName(userFirstName);
                  setFamilyName(userFamilyName);
                }}
                validationMessages={getEmailValidationMessages()}
              />
            )}
          </div>
        </div>
        <div>
          <div css={style.fieldContainer}>
            <FormField label={t('users.name')} viewComponent={<span css={style.field}>{fullName}</span>} />
          </div>
        </div>
        <div>
          <div css={style.fieldContainer}>
            <FormField
              label={t('users.roles')}
              editMode
              mandatory
              validationMessages={showValidationMessage && !rolesAreValid ? [t('users.rolesRequiredMessage')] : []}
              editComponent={
                <Dropdown
                  css={style.field}
                  placeholder={t('users.roles')}
                  value={_.sortBy(selectedRoles, [role => role])}
                  selection
                  multiple
                  options={roles.map(r => ({ key: r, text: t(`users.role.${r}`), value: r }))}
                  onChange={(event, data: DropdownProps) => {
                    const v = data.value as Role[];
                    setRoles(v);
                  }}
                />
              }
            />
          </div>
        </div>
        <div>
          <div css={style.fieldContainer}>
            <FormField
              label={t('users.brands')}
              editMode
              mandatory
              validationMessages={showValidationMessage && !brandsAreValid ? [t('users.brandsRequiredMessage')] : []}
              editComponent={
                <BrandsDropdown
                  selectedBrands={selectedBrands}
                  onChange={brands => {
                    setBrands(brands);
                  }}
                  error={showValidationMessage && !brandsAreValid}
                />
              }
            />
          </div>
        </div>
        <div css={style.buttonContainer}>
          <div css={style.legendSection}>{t('mandatoryFields')}</div>
          <Button css={style.button} onClick={onCancel} content={t('cancel')} icon='cancel' labelPosition='left' />
          <Button css={style.button} onClick={updateOrCreation} content={t('save')} icon='save' labelPosition='left' />
        </div>
      </div>
    </div>
  );
};

interface BrandsDropdownProps {
  selectedBrands: Brand[];
  onChange: (brands: Brand[]) => void;
  error: boolean;
}

const BrandsDropdown = ({ error, selectedBrands, onChange }: BrandsDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const allBrands = useAppSelector(selectSortedBrands);
  const keringCode = 'KERING';
  const keringDescription = t('users.allBrands');

  const options = useMemo(() => {
    const allBrandsItem: DropdownItemProps = {
      key: keringCode,
      text: keringDescription,
      value: keringCode,
    };
    const brandOptions = allBrands.map(brand => {
      return { key: brand.code, text: brand.description, value: brand.code };
    });

    if (selectedBrands.length === allBrands.length) {
      return brandOptions;
    }
    return [allBrandsItem, ...brandOptions];
  }, [allBrands, keringDescription, selectedBrands.length]);
  return (
    <Dropdown
      css={style.field}
      placeholder={t('users.brands')}
      value={selectedBrands.map(b => b.code).sort((a, b) => (a > b ? 1 : -1))}
      error={error}
      selection
      multiple
      clearable
      noResultsMessage={t('noResultsFound')}
      options={options}
      onChange={(event, data: DropdownProps) => {
        const values = data.value as string[];
        if (values.includes(keringCode)) {
          const codes = allBrands.filter(b => b.code !== keringCode);
          onChange(codes);
        } else {
          const codes = allBrands.filter(b => values.includes(b.code));
          onChange(codes);
        }
      }}
    />
  );
};
