/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import style from './blockedEmployeeDetail.style';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';

import { AdminSectionHeader } from '../../../../shared/components/AdminSectionHeader';
import { BlockedEmployeeForm } from '../components/BlockedEmployeeForm';
import {
  createBlockedEmployee,
  fetchBlockedEmployee,
  resetBlockedEmployee,
  selectBlockedEmployeeDetail,
  selectIsFetchingBlockedEmployeeDetail,
  updateBlockedEmployee,
} from '../stores/blockedEmployeeDetailSlice';
import { BlockedEmployee, BlockedEmployeeCreationRequest, getEmptyBlockedEmployee } from '../models/blockedEmployee';

export default function BlockedEmployeeDetail(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const employee = useAppSelector(selectBlockedEmployeeDetail);
  const isFetching = useAppSelector(selectIsFetchingBlockedEmployeeDetail);
  useEffect(() => {
    if (id != null) {
      dispatch(fetchBlockedEmployee(id));
    }

    return () => {
      dispatch(resetBlockedEmployee());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSave = useCallback(
    async (employeeId: string, request: BlockedEmployeeCreationRequest) => {
      let saveResult: BlockedEmployee | null;

      if (employeeId === '') {
        saveResult = await dispatch(
          createBlockedEmployee({
            email: request.email,
            firstName: request.firstName,
            familyName: request.familyName,
            startDate: request.startDate,
            endDate: request.endDate,
          })
        );
      } else {
        saveResult = await dispatch(
          updateBlockedEmployee(employeeId, {
            startDate: request.startDate,
            endDate: request.endDate,
          })
        );
      }

      if (saveResult != null) {
        navigate('/privilege-card/admin/blocked-employees');
      }
    },
    [dispatch, navigate]
  );

  return (
    <div css={style.pageContainer}>
      <AdminSectionHeader
        title={t('blockedEmployees.blockedDetail')}
        redirectPath='/privilege-card/admin/blocked-employees'
        arrowBack
      />
      <div css={style.formContainer}>
        {isFetching && <Loader size='big' active />}
        {id != null && employee != null && (
          <BlockedEmployeeForm
            employee={employee}
            onSave={handleOnSave}
            onCancel={() => {
              navigate('/privilege-card/admin/blocked-employees');
            }}
          />
        )}
        {id == null && (
          <BlockedEmployeeForm
            employee={getEmptyBlockedEmployee()}
            onSave={handleOnSave}
            onCancel={() => {
              navigate('/privilege-card/admin/blocked-employees');
            }}
          />
        )}
      </div>
    </div>
  );
}
