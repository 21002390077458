/** @jsxImportSource @emotion/react */
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import style from './searchTrafficLightsBox.style';

interface SearchTrafficLightsBoxProps {
  onChange: (value: string) => void;
  autoFocus?: boolean;
  loading?: boolean;
}

export const SearchTrafficLightsBox = ({
  onChange,
  autoFocus = false,
  loading,
}: SearchTrafficLightsBoxProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Input
      icon='search'
      iconPosition='left'
      placeholder={t('salesAssistant.trafficLights.searchTrafficLights')}
      css={style.search}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      autoFocus={autoFocus}
      loading={loading}
    />
  );
};
