import { BusinessCardUserPreference, DiscountCardUserPreference } from './userPreference';
import { Brand, toBrand } from '../../../shared/models/brand';

export interface Principal {
  email: string;
  familyName: string;
  firstName: string;
  roles: Role[];
  discountCardPreferences: DiscountCardUserPreference;
  businessCardPreferences: BusinessCardUserPreference;
  brands: Brand[];
  mobilePhone: string;
  workdayID: string;
  countryDescription: string;
  countryCode: string;
  brand: Brand;
  salesAssistantStores: SalesAssistantStore[];
}

interface PrincipalResponse {
  email: string;
  familyName: string;
  firstName: string;
  roles: Role[];
  discountCardPreferences: DiscountCardUserPreference;
  businessCardPreferences: BusinessCardUserPreference;
  brands: Brand[];
  mobilePhone: string;
  workdayID: string;
  countryDescription: string;
  countryCode: string;
  brand: Brand;
  salesAssistantStores: SalesAssistantStore[];
}

export interface SalesAssistantStoreResponse {
  storeCode: string;
  brand: Brand;
  countryCode: string;
  countryDescription: string;
  name: string;
}
export interface SalesAssistantStore {
  storeCode: string;
  brand: Brand;
  countryCode: string;
  countryDescription: string;
  name: string;
}

export const toSalesAssistantStore = (response: SalesAssistantStoreResponse): SalesAssistantStore => {
  return {
    storeCode: response.storeCode,
    brand: toBrand(response.brand),
    countryCode: response.countryCode,
    countryDescription: response.countryDescription,
    name: response.name,
  };
};
export const toPrincipal = (response: PrincipalResponse): Principal => ({
  email: response.email,
  familyName: response.familyName,
  firstName: response.firstName,
  roles: response.roles,
  discountCardPreferences: response.discountCardPreferences,
  businessCardPreferences: response.businessCardPreferences,
  brands: response.brands.map(toBrand),
  workdayID: response.workdayID,
  mobilePhone: response.mobilePhone,
  countryCode: response.countryCode,
  countryDescription: response.countryDescription,
  brand: toBrand(response.brand),
  salesAssistantStores: response.salesAssistantStores.map(toSalesAssistantStore),
});

export enum Role {
  ADMIN = 'ADMIN',
  DC_EMPLOYEE = 'DC_EMPLOYEE',
  BC_EMPLOYEE = 'BC_EMPLOYEE',
  GROUP_ADMIN = 'GROUP_ADMIN',
  SALES_ASSISTANT = 'SALES_ASSISTANT',
  GLOBAL_STORES_VIEWER = 'GLOBAL_STORES_VIEWER',
}

export const roles = Object.values(Role);

export const checkRole = (principal: Principal | null, role: Role): boolean =>
  principal != null ? principal.roles.includes(role) : false;
export const checkRoles = (principal: Principal, roleList: Role[]): boolean =>
  principal != null ? principal.roles.some(r => roleList.includes(r)) : false;
