import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addProductsToBasket, changeProductQuantity } from '../store/basketSlice';
import { SalesAssistantStore } from '../../../auth/models/principal';
import { BasketProduct } from '../models/basket';
import { EmployeeCard } from '../../../../shared/models/employeeCard';
import { useAppDispatch } from '../../../../core/hooks';
import { Product } from '../models/product';

export const useBasket = (
  employeeCard: EmployeeCard,
  selectedStore: SalesAssistantStore,
  basketProduct: BasketProduct,
  product: Product,
  initialProductCounter: number,
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [productCounter, setProductCounter] = useState(initialProductCounter > 0 ? initialProductCounter : 1);

  useEffect(() => {
    setProductCounter(initialProductCounter > 0 ? initialProductCounter : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const decrementCounter = () => {
    if (productCounter > 0) {
      setProductCounter(counter => counter - 1);
    }
  };
  const incrementCounter = () => setProductCounter(counter => counter + 1);

  const handleBasketProductAction = async () => {
    if (basketProduct == null) {
      await dispatch(
        addProductsToBasket(
          employeeCard.mail,
          employeeCard.brand.code,
          product,
          productCounter,
          selectedStore!.storeCode
        )
      );
    } else {
      await dispatch(
        changeProductQuantity(
          employeeCard.mail,
          employeeCard.brand.code,
          basketProduct,
          productCounter,
          selectedStore!.storeCode
        )
      );
    }
  };

  const basketActionLabel = useMemo(() => {
    if (productCounter > 0) {
      return basketProduct == null
        ? t('salesAssistant.productDetail.addToBasket')
        : t('salesAssistant.productDetail.updateBasketQuantity');
    }

    return basketProduct == null
      ? t('salesAssistant.productDetail.cancel')
      : t('salesAssistant.productDetail.removeFromBasket');
  }, [basketProduct, productCounter, t]);

  return {
    decrementCounter,
    incrementCounter,
    basketActionLabel,
    handleBasketProductAction,
    productCounter,
    
  };
};



