/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useState } from 'react';
import { Button, Input, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import style from './specialCasesCard.style';
import { selectPrincipalRoles } from '../../../auth/store/principalSlice';
import { Role } from '../../../auth/models/principal';
import { FormField } from '../../../../shared/components/FormField';
import { selectIsFetching } from '../store/specialCasesSlice';
import {
  SpecialCaseType,
  SpecialCasesGoldCards,
  SpecialCasesGoldCardsUpdateRequest,
  SpecialCasesSilverCards,
  SpecialCasesSilverCardsUpdateRequest,
  SpecialUser,
} from '../models/specialCases';
import { AppThunk } from '../../../../core/store';
import SpecialCasesUserDropdown from './SpecialCasesUserDropdown';

interface SpecialCasesCardProps {
  typeOfSpecialCase: SpecialCaseType;
  specialCases: SpecialCasesGoldCards | SpecialCasesSilverCards;
  updateSpecialCases:
    | ((goldCards: SpecialCasesGoldCardsUpdateRequest) => AppThunk)
    | ((silverCards: SpecialCasesSilverCardsUpdateRequest) => AppThunk);
}

export default function SpecialCasesCard({
  typeOfSpecialCase,
  specialCases,
  updateSpecialCases,
}: SpecialCasesCardProps): JSX.Element {
  const dispatch = useAppDispatch();
  const isFetchingSpecialCases = useAppSelector(selectIsFetching);
  const principalRoles = useAppSelector(selectPrincipalRoles);
  const [isEditable, setIsEditable] = useState(false);
  const updatedSpecialCases = async (
    updatedCases: SpecialCasesGoldCardsUpdateRequest | SpecialCasesSilverCardsUpdateRequest
  ) => {
    await dispatch(
      updateSpecialCases(
        (updatedCases as SpecialCasesGoldCardsUpdateRequest) || (updatedCases as SpecialCasesSilverCardsUpdateRequest)
      )
    );
    setIsEditable(false);
  };

  if (isEditable) {
    return (
      <WritableSpecialCasesCard
        onSave={updatedSpecialCases}
        onCancel={() => {
          setIsEditable(false);
        }}
        specialCases={specialCases}
        typeOfSpecialCase={typeOfSpecialCase}
      />
    );
  }
  return (
    <ReadonlySpecialCasesCard
      typeOfSpecialCase={typeOfSpecialCase}
      specialCases={specialCases}
      onEdit={() => setIsEditable(true)}
      canEdit={
        typeOfSpecialCase === SpecialCaseType.GOLD_CARD
          ? principalRoles.includes(Role.GROUP_ADMIN)
          : principalRoles.includes(Role.GROUP_ADMIN) || principalRoles.includes(Role.ADMIN)
      }
      isFetching={isFetchingSpecialCases}
    />
  );
}

const ReadonlySpecialCasesCard = ({
  typeOfSpecialCase,
  specialCases,
  onEdit,
  canEdit,
  isFetching,
}: {
  typeOfSpecialCase: SpecialCaseType;
  specialCases: SpecialCasesGoldCards | SpecialCasesSilverCards;
  onEdit: () => void;
  canEdit: boolean;
  isFetching: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.outerContainer}>
      <div css={style.title}>
        {typeOfSpecialCase === SpecialCaseType.GOLD_CARD
          ? t('discounts.specialCases.goldCardsTitle')
          : t('discounts.specialCases.silverCardsTitle')}
      </div>
      <div css={style.card}>
        {typeOfSpecialCase === SpecialCaseType.GOLD_CARD && (
          <FormField
            css={style.percentage}
            label={t('discounts.specialCases.percentage')}
            viewComponent={`${(specialCases as SpecialCasesGoldCards).percentage} %`}
          />
        )}
        <FormField
          css={style.usersList}
          label={t('discounts.specialCases.usersList')}
          viewComponent={
            <div css={style.usersListContent}>
              {specialCases.usersList.length === 0 && !isFetching ? t('discounts.specialCases.emptyList') : <Loader />}
              {specialCases.usersList.length > 0 &&
                specialCases.usersList.map(u => `${u.firstName} ${u.familyName}`).join(', ')}
            </div>
          }
        />
      </div>
      {canEdit && (
        <div css={style.groupButton}>
          <Button css={style.buttons} onClick={() => onEdit()} icon='edit' />
        </div>
      )}
    </div>
  );
};

interface WritableSpecialCasesCardProps {
  onSave: (specialCases: SpecialCasesGoldCardsUpdateRequest | SpecialCasesSilverCardsUpdateRequest) => void;
  onCancel: () => void;
  specialCases: SpecialCasesGoldCards | SpecialCasesSilverCards;
  typeOfSpecialCase: SpecialCaseType;
}
const WritableSpecialCasesCard = ({
  onSave,
  onCancel,
  specialCases,
  typeOfSpecialCase,
}: WritableSpecialCasesCardProps) => {
  const { t } = useTranslation();
  const [currentPercentage, setCurrentPercentage] = useState(
    (specialCases as SpecialCasesGoldCards).percentage?.toString()
  );
  const [currentUsersList, setCurrentUsersList] = useState(specialCases.usersList);
  const [showValidation, setShowValidation] = useState(false);

  const onPercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowValidation(false);
    const { value } = event.target;
    if (value === '') {
      setCurrentPercentage('');
      return;
    }
    const regex = /^(([1-9]\d?)|0|100)$/;
    if (value !== '' && !regex.test(value)) {
      return;
    }
    setCurrentPercentage(value);
  };

  const saveSpecialCases = () => {
    if (typeOfSpecialCase === SpecialCaseType.GOLD_CARD) {
      if (currentPercentage === '0') {
        setShowValidation(true);
        return;
      }
      onSave({
        percentage: +currentPercentage,
        usersList: currentUsersList.map(u => u.email),
      });
    } else {
      onSave({
        usersList: currentUsersList.map(u => u.email),
      });
    }
  };

  return (
    <div css={style.outerContainer}>
      <div css={style.title}>
        {typeOfSpecialCase === SpecialCaseType.GOLD_CARD
          ? t('discounts.specialCases.goldCardsTitle')
          : t('discounts.specialCases.silverCardsTitle')}
      </div>
      <div css={style.card}>
        {typeOfSpecialCase === SpecialCaseType.GOLD_CARD && (
          <FormField
            label={t('discounts.specialCases.percentage')}
            editMode
            validationMessages={showValidation ? [t('discountRuleset.modalFormValidationMessage')] : []}
            editComponent={
              <Input
                label={{ content: '%', style: { paddingTop: '0.9rem' } }}
                labelPosition='right'
                value={currentPercentage}
                onChange={onPercentageChange}
                css={style.percentageInput}
                size='mini'
              />
            }
          />
        )}

        <FormField
          label={t('discounts.specialCases.usersList')}
          editMode
          editComponent={
            <SpecialCasesUserDropdown
              onSelection={(users: SpecialUser[]) => setCurrentUsersList(users)}
              specialCasesList={specialCases.usersList}
            />
          }
        />
        <div css={style.groupButton}>
          <Button
            css={style.buttons}
            onClick={() => {
              onCancel();
            }}
            icon='cancel'
          />
          <Button css={style.buttons} onClick={saveSpecialCases} icon='save' />
        </div>
      </div>
    </div>
  );
};
