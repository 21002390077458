import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import i18n from '../../../../i18n';
import type { AppThunk, RootState } from '../../../../core/store';
import { Country, getEmptyCountry } from '../../../../shared/models/country';

export const getOtherCountries = (language: string): Country => {
  return { ...getEmptyCountry(), description: t('purchaseLimits.otherCountries') };
};

interface EmployeePurchaseLimitFilterSliceState {
  searchBoxFilter: string;
  countryFilter: Country;
}

const initialState: EmployeePurchaseLimitFilterSliceState = {
  searchBoxFilter: '',
  countryFilter: getOtherCountries(i18n.language),
};

export const employeePurchaseLimitFiltersSlice = createSlice({
  name: 'employeePurchaseLimitFilters',
  initialState,
  reducers: {
    setSearchBoxFilter: (
      state: Draft<EmployeePurchaseLimitFilterSliceState>,
      payloadAction: PayloadAction<string>
    ): EmployeePurchaseLimitFilterSliceState => {
      return {
        ...state,
        searchBoxFilter: payloadAction.payload,
      };
    },
    setCountryFilter: (
      state: Draft<EmployeePurchaseLimitFilterSliceState>,
      payloadAction: PayloadAction<Country>
    ): EmployeePurchaseLimitFilterSliceState => {
      return {
        ...state,
        countryFilter: payloadAction.payload,
      };
    },
    setDefaultCountryFilter: (
      state: Draft<EmployeePurchaseLimitFilterSliceState>
    ): EmployeePurchaseLimitFilterSliceState => {
      return {
        ...state,
        countryFilter: getOtherCountries(i18n.language),
      };
    },
  },
});
export const { setSearchBoxFilter, setCountryFilter, setDefaultCountryFilter } =
  employeePurchaseLimitFiltersSlice.actions;

export default employeePurchaseLimitFiltersSlice.reducer;
export const changeSearchBoxFilter =
  (value: string): AppThunk =>
  async dispatch => {
    dispatch(setSearchBoxFilter(value));
  };
export const changeCountryFilter =
  (filter: Country): AppThunk =>
  async dispatch => {
    dispatch(setCountryFilter(filter));
  };

export const selectSearchBoxFilter = (state: RootState): string => state.employeePurchaseLimitFilters.searchBoxFilter;
export const selectCountryFilter = (state: RootState): Country => state.employeePurchaseLimitFilters.countryFilter;
