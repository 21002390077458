import { Brand } from './brand';

export interface EmployeeCard {
  givenName: string;
  surname: string;
  mail: string;
  mobilePhone: string;
  brand: Brand;
  workdayId: string;
  jobTitle: string;
  photo: string;
  country: string;
  countryCode: string;
  qrCode: string;
  isBlocked?: boolean;
  isSpecialCaseGoldCard?: boolean;
  isSpecialCaseSilverCard?: boolean;
  isEligibleUser?: boolean;
}

export interface EmployeeCardResponse {
  givenName: string;
  surname: string;
  mail: string;
  mobilePhone: string;
  brand: Brand;
  workdayId: string;
  jobTitle: string;
  photo: string;
  country: string;
  countryCode: string;
  qrCode: string;
  isBlocked?: boolean;
  isSpecialCaseGoldCard?: boolean;
  isSpecialCaseSilverCard?: boolean;
  isEligibleUser?: boolean;
}

export const toEmployeeCard = (response: EmployeeCardResponse): EmployeeCard => {
  return {
    givenName: response.givenName,
    surname: response.surname,
    mail: response.mail,
    mobilePhone: response.mobilePhone,
    brand: response.brand,
    workdayId: response.workdayId,
    jobTitle: response.jobTitle,
    photo: response.photo,
    country: response.country,
    countryCode: response.countryCode,
    qrCode: response.qrCode,
    isBlocked: response?.isBlocked,
    isSpecialCaseGoldCard: response?.isSpecialCaseGoldCard,
    isSpecialCaseSilverCard: response?.isSpecialCaseSilverCard,
    isEligibleUser: response?.isEligibleUser,
  };
};

export const getEmptyEmployeeCard = (): EmployeeCard => ({
  givenName: '',
  surname: '',
  mail: '',
  mobilePhone: '',
  brand: { code: '', description: '' },
  workdayId: '',
  jobTitle: '',
  photo: '',
  country: '',
  countryCode: '',
  qrCode: '',
  isBlocked: false,
  isSpecialCaseGoldCard: false,
  isSpecialCaseSilverCard: false,
  isEligibleUser: true,
});

export const getBusinessCardBackgroundColor = (code: string): string => {
  switch (code) {
    case 'BALENCIAGA':
      return 'var(--dcd-balenciaga-card-background-color)';
    case 'BOUCHERON':
      return 'var(--dcd-boucheron-card-background-color)';
    default:
      return 'var(--dcd-default-card-background-color)';
  }
};

export const getBackgroundColor = (code: string, isBlocked: boolean | undefined, isEligible?: boolean): string => {
  if (isBlocked || isEligible === false) {
    return 'var(--dcd-disabled-card-background-color)';
  }

  switch (code) {
    case 'BALENCIAGA':
      return 'var(--dcd-balenciaga-card-background-color)';
    case 'BOUCHERON':
      return 'var(--dcd-boucheron-card-background-color)';
    case 'POMELLATO':
      return 'var(--dcd-pomellato-card-background-color)';
    case 'DODO':
      return 'var(--dcd-dodo-card-background-color)';
    default:
      return 'var(--dcd-default-card-background-color)';
  }
};

export const getBusinessCardFontColor = (code: string): string => {
  switch (code) {
    case 'BALENCIAGA':
      return 'var(--dcd-black-color)';
    default:
      return 'var(--dcd-white-color)';
  }
};

export const getFontColor = (
  code: string,
  isSpecialCaseGoldCard: boolean | undefined,
  isSpecialCaseSilverCard: boolean | undefined
): string => {
  if (isSpecialCaseGoldCard) {
    return 'var(--dcd-black-color)';
  }

  if (isSpecialCaseSilverCard) {
    return 'var(--dcd-black-color)';
  }
  switch (code) {
    case 'BALENCIAGA':
      return 'var(--dcd-black-color)';
    case 'DODO':
      return 'var(--dcd-black-color)';
    default:
      return 'var(--dcd-white-color)';
  }
};
