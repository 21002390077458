/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { Button, Image, Modal, Progress } from 'semantic-ui-react';
import style from './onboardingScreens.style';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import {
  selectIsDiscountCardFirstAccess,
  updateDiscountCardFirstAccess,
} from '../../features/auth/store/principalSlice';
import employeeCardScreen from '../../assets/onboardingScreens/employee-card-screen.svg';
import historyScreen from '../../assets/onboardingScreens/history-screen.svg';
import limitsScreen from '../../assets/onboardingScreens/limits-screen.svg';

const OnboardingScreens = (): JSX.Element => {
  const totalSteps = 3;
  const initialStep = 0;
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(initialStep);
  const handleNext = () => {
    if (step === totalSteps - 1) {
      dispatch(updateDiscountCardFirstAccess(false));
    } else {
      setStep(prev => prev + 1);
    }
  };
  const resetStep = () => {
    setStep(initialStep);
  };
  const { image, title } = useMemo(() => getStepConfiguration(step), [step]);

  return (
    <ScreenStep
      onNext={handleNext}
      image={image}
      title={title}
      progress={((step + 1) / totalSteps) * 100}
      onClose={resetStep}
    />
  );
};

interface ScreenStepProps {
  onNext: () => void;
  image: string;
  title: string;
  progress: number;
  onClose: () => void;
}
const ScreenStep = ({ onNext, image, title, progress, onClose }: ScreenStepProps): JSX.Element => {
  const isFirstAccess = useAppSelector(selectIsDiscountCardFirstAccess);
  return (
    <Modal open={isFirstAccess} css={style.modal} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Content css={style.content}>
        <Image size='medium' src={image} circular wrapped bordered centered css={style.image} />
        <Modal.Description>
          <div css={style.description}>
            <div css={style.title}>{title}</div>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions css={style.actions}>
        <Button css={style.button} icon='arrow right' size='big' onClick={onNext} />
      </Modal.Actions>
      <Progress percent={progress} attached='bottom' css={style.progress} />
    </Modal>
  );
};

const getStepConfiguration = (step: number): { image: string; title: string } => {
  const conf = [
    {
      image: employeeCardScreen,
      title: 'Show your discount digital card easily',
    },
    {
      image: limitsScreen,
      title: 'Get easy access to your purchase limits',
    },
    {
      image: historyScreen,
      title: 'Consult your purchase history',
    },
  ];
  return conf[step];
};
export default OnboardingScreens;
