import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const headerContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 230px;
  width: 100%;
  padding-bottom: 10px;
`;

const headerTitle = css`
  ${globalStyle.bodyNormal};
  color: var(--dcd-gray-color-70);
  /* position: relative; */
  text-align: center;
  width: 100%;
  min-height: 22px;

  /* span {
    position: relative;
    z-index: 1;
    background-color: white;
    padding: 0 10px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 50px);
    border-top: 1px solid #000;
    transform: translateY(-50%);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  } */
`;

export default {
  headerContainer,
  headerTitle,
};