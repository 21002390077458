/** @jsxImportSource @emotion/react */

import { Button } from 'semantic-ui-react';
import React, { useCallback, useMemo } from 'react';
import style from './storeSelection.style';
import { SalesAssistantStore } from '../../features/auth/models/principal';

interface StoreSelectionProps {
  onSelection: (store: SalesAssistantStore) => void;
  stores: SalesAssistantStore[];
  selectedStores?: SalesAssistantStore[];
}
export const StoreSelection = ({ onSelection, stores, selectedStores = [] }: StoreSelectionProps): JSX.Element => {
  const compareStores = useCallback(
    (a: SalesAssistantStore, b: SalesAssistantStore) => {
      const aIsSelected = selectedStores.some(selectedStore => selectedStore.storeCode === a.storeCode);
      const bIsSelected = selectedStores.some(selectedStore => selectedStore.storeCode === b.storeCode);
      if ((aIsSelected && bIsSelected) || (!aIsSelected && !bIsSelected)) {
        return a.brand.description.localeCompare(b.brand.description) || a.name.localeCompare(b.name);
      }
      if (aIsSelected && !bIsSelected) return -1;
      if (!aIsSelected && bIsSelected) return 1;
      return 0;
    },
    [selectedStores]
  );

  const sts = useMemo(() => {
    return [...stores].sort(compareStores);
  }, [stores, compareStores]);

  const isSelected = useCallback(
    (store: SalesAssistantStore): boolean => {
      return selectedStores.some(selectedStore => selectedStore.storeCode === store.storeCode);
    },
    [selectedStores]
  );

  return (
    <div css={style.storeSelectionButtonsContainer}>
      {sts.map(saStore => (
        <Button
          key={saStore.storeCode}
          css={style.storeButton(isSelected(saStore))}
          onClick={() => onSelection(saStore)}>
          <div>
            <span>{saStore.brand.description}</span> - <span>{saStore.storeCode}</span>
          </div>
          <div css={style.storeName}>{saStore.name}</div>
        </Button>
      ))}
    </div>
  );
};
