/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button, Checkbox, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../core/hooks';
import { SearchBox } from '../../../../shared/components/SearchBox';
import style from './blockedEmployees.style';
import {
  fetchBlockedEmployees,
  selectFilter,
  selectFilteredAndSortedBlockedEmployees,
  selectIsFetching,
  selectShowOnlyBlockedFilter,
  setFilter,
  toggleOnlyBlockedFilter,
} from '../stores/blockedEmployeesSlice';
import { BlockedEmployee } from '../models/blockedEmployee';
import { BlockedEmployeeCard } from '../components/BlockedEmployeeCard';

export const BlockedEmployeesPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const blockedEmployees = useSelector(selectFilteredAndSortedBlockedEmployees);
  const isFetchingBlockedEmployees = useSelector(selectIsFetching);
  const showOnlyBlockedFilter = useSelector(selectShowOnlyBlockedFilter);

  const blockedEmployeesFilterValue = useSelector(selectFilter);
  const setFilterDebounced = useDebouncedCallback((filterValue: string) => {
    dispatch(setFilter(filterValue));
  }, 300);

  useEffect(() => {
    dispatch(fetchBlockedEmployees());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <div css={style.header}>
        <Button
          css={style.addButton(t('blockedEmployees.addBlockedEmployee'))}
          icon='plus'
          onClick={() => {
            navigate('/privilege-card/admin/blocked-employees/detail');
          }}
        />
        <div css={style.headerRight}>
          <div css={style.toggle}>
            <div>{t('blockedEmployees.blockedToggle')}</div>
            <Checkbox toggle checked={showOnlyBlockedFilter} onChange={() => dispatch(toggleOnlyBlockedFilter())} />
          </div>
          <SearchBox
            defaultValue={blockedEmployeesFilterValue}
            placeholder={t('blockedEmployees.search')}
            onChange={value => {
              setFilterDebounced(value);
            }}
          />
        </div>
      </div>
      {blockedEmployees.length <= 0 ? (
        <div css={style.noItemsLabel}>{t('blockedEmployees.noItemsLabel')}</div>
      ) : (
        <div css={style.cardsContainer}>
          {isFetchingBlockedEmployees ? (
            <Loader active />
          ) : (
            blockedEmployees.map((e: BlockedEmployee) => <BlockedEmployeeCard employee={e} key={e.id} />)
          )}
        </div>
      )}
    </div>
  );
};
