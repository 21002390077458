import axios from 'axios';
import paths from './paths';
import { Purchase, toPurchase } from '../models/purchase';
import { Brand, toBrand } from '../../../../shared/models/brand';

const api = {
  getMyPurchasesByYear: async (purchasesYear: number): Promise<Array<Purchase>> => {
    const response = await axios.get(paths.getMyPurchasesByYear(purchasesYear));
    return response.data.map(toPurchase);
  },
  getMyCurrentYearPurchases: async (): Promise<Array<Purchase>> => {
    const response = await axios.get(paths.getMyCurrentYearPurchases());
    return response.data.map(toPurchase);
  },
  getMyCurrentYearPurchasesInEuros: async (): Promise<Array<Purchase>> => {
    const response = await axios.get(paths.getMyCurrentYearPurchasesInEuros());
    return response.data.map(toPurchase);
  },
  getMyPurchasesBrands: async (): Promise<Array<Brand>> => {
    const response = await axios.get(paths.getMyPurchasesBrands());
    return response.data.map(toBrand);
  },
  getMyPurchasesStores: async (): Promise<Array<string>> => {
    const response = await axios.get(paths.getMyPurchasesStores());
    return response.data;
  },
  getMyPurchasesCategories: async (): Promise<Array<string>> => {
    const response = await axios.get(paths.getMyPurchasesCategories());
    return response.data;
  },
};
export default api;
