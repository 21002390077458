import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const searchBoxContainer = css`
  display: flex;
  gap: 15px;
  height: max-content;
`;

const storeSelectionContainer = css`
  flex: 1;
  overflow: auto;
  padding: 5px;
`;
const loaderContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`;

const buttonsFooter = css`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const button = css`
  ${globalStyle.primaryButton}
  flex: 1
`;

const storeBadge = css`
  ${globalStyle.bodySmall};
  && {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dcd-secondary-color-40);
    border-radius: 20px;
    margin-right: 5px;
    padding: 4px 8px;
  }
`;

const badgesWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const badgesContainer = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const toggle = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: auto;
  column-gap: 6px;
  white-space: nowrap;
  &&& {
    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: var(--dcd-pink-color-80) !important;
    }
  }
`;

const globalViewMessage = css`
  ${globalStyle.bodySmall};
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  &&& {
    font-style: italic;
  }
`;

const warningIcon = css`
  color: var(--dcd-secondary-color-90) !important;
`;

export default {
  container,
  searchBoxContainer,
  storeSelectionContainer,
  loaderContainer,
  buttonsFooter,
  button,
  storeBadge,
  badgesWrapper,
  badgesContainer,
  toggle,
  globalViewMessage,
  warningIcon,
};
