/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { SerializedStyles } from '@emotion/react';
import { Label, Popup } from 'semantic-ui-react';
import style from './formField.style';

type AlignmentType = 'left' | 'center' | 'right';

interface FormFieldProps {
  css?: SerializedStyles;
  className?: string;
  label: string;
  align?: AlignmentType;
  inline?: boolean;
  viewComponent?: JSX.Element | string;
  editMode?: boolean;
  mandatory?: boolean;
  editComponent?: JSX.Element;
  validationMessages?: string[];
}

export const FormField = ({
  css,
  className = 'form-field',
  label,
  align = 'left',
  inline = false,
  editMode = false,
  mandatory = false,
  viewComponent,
  editComponent,
  validationMessages = [],
}: FormFieldProps): JSX.Element => {
  const notValid = useMemo(() => validationMessages?.length > 0, [validationMessages?.length]);
  const contextRef = React.useRef<HTMLDivElement>(null);
  const [reRender, setReRender] = useState(false);
  useEffect(() => {
    setReRender(!reRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextRef]);

  return (
    <div css={[style.container(inline), css]} className={className}>
      <Label css={style.label(inline, align)}>
        {label}
        {mandatory && editMode && <span css={style.star}>*</span>}
      </Label>
      <div css={style.valueContainer(align)} className='children-component-container'>
        {editMode && editComponent ? (
          <div className='edit-component-container' ref={contextRef}>
            {editComponent}
          </div>
        ) : (
          <div className='view-component-container' css={style.viewModeValue}>
            {viewComponent}
          </div>
        )}
      </div>
      <Popup
        css={style.validationPopup}
        mountNode={contextRef && contextRef.current ? contextRef.current : undefined}
        pinned
        context={contextRef}
        open={notValid}
        position='top center'
        offset={[0, -4]}>
        <div>
          {validationMessages?.map(m => (
            <div key={m}>{m}</div>
          ))}
        </div>
      </Popup>
    </div>
  );
};
