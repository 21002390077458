/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { RequireAuth } from '../core/components/RequireAuth';
import { SalesAssistantPage } from './SalesAssistantPage';
import { updatePWAManifest } from '../core/utils';

interface PageProps {
  children: JSX.Element;
}

export const SalesAssistantPageUnderSecurity = (props: PageProps) => {
  const { children } = props;
  useEffect(() => {
    updatePWAManifest('dc-manifest');
  });
  return (
    <RequireAuth>
      <SalesAssistantPage>{children}</SalesAssistantPage>
    </RequireAuth>
  );
};
