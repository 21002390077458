import { CreationInfo } from '../../../../shared/models/creation-info';

export interface SpecialUser {
  email: string;
  firstName: string;
  familyName: string;
}

export interface SpecialUserWithImage extends SpecialUser {
  image?: string;
}

export interface SpecialCasesGoldCards {
  percentage: number;
  usersList: SpecialUser[];
  updateInfo: CreationInfo | null;
}

export interface SpecialCasesSilverCards {
  usersList: SpecialUser[];
  updateInfo: CreationInfo | null;
}

export interface SpecialCasesGoldCardsUpdateRequest {
  percentage: number;
  usersList: string[];
}

export interface SpecialCasesGoldCardsResponse {
  percentage: number;
  usersList: SpecialUser[];
  updateInfo: CreationInfo | null;
}

export const toSpecialCasesGoldCards = (response: SpecialCasesGoldCardsResponse): SpecialCasesGoldCards => {
  return {
    percentage: response.percentage,
    usersList: response.usersList,
    updateInfo: response.updateInfo,
  };
};

export interface SpecialCasesSilverCardsUpdateRequest {
  usersList: string[];
}

export interface SpecialCasesSilverCardsResponse {
  usersList: SpecialUser[];
  updateInfo: CreationInfo | null;
}

export const toSpecialCasesSilverCards = (response: SpecialCasesSilverCardsResponse): SpecialCasesSilverCards => {
  return {
    usersList: response.usersList,
    updateInfo: response.updateInfo,
  };
};

export enum SpecialCaseType {
  GOLD_CARD = 'Gold card',
  SILVER_CARD = 'Silver card',
}
