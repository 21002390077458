import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import { ProductCategory } from '../../../../shared/models/productCategory';
import api from '../utils/api';

interface ProductCategoriesSliceState {
  data: ProductCategory[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: ProductCategoriesSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const productCategoriesSlice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {
    startFetch: (state: Draft<ProductCategoriesSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<ProductCategoriesSliceState>, action: PayloadAction<ProductCategory[]>) => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    httpError: (state: Draft<ProductCategoriesSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError } = productCategoriesSlice.actions;

export const fetchProductCategories =
  (brandCode: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const productCategories = await api.getProductCategoriesByBrand(brandCode);
      dispatch(finishFetch(productCategories));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

const selectProductCategories = (state: RootState): string[] =>
  state.productCategories.data?.map(cat => cat.description) || [];

export const selectSortedProductCategories = createSelector(
  selectProductCategories,
  (categories: string[]): string[] => {
    return _.sortBy(categories);
  }
);
export const selectIsFetchingProductCategories = (state: RootState): boolean => state.productCategories.isFetching;

export default productCategoriesSlice.reducer;
