import { useEffect, useState } from 'react';

export const useBarcodeScannerDevice = () => {
  const scanCharInterval = 20;
  const [deviceBarcode, setDeviceBarcode] = useState('');

  useEffect(() => {
    let scanTimeout: NodeJS.Timer;
    let barcode = '';
    const handleBarcodeInput = (event: KeyboardEvent) => {
      if (scanTimeout != null) {
        clearTimeout(scanTimeout);
      }

      if (event.code === 'Enter' && barcode) {
        setDeviceBarcode(barcode);
        barcode = '';
      } else {
        barcode += event.key;
      }

      scanTimeout = setTimeout(() => {
        barcode = '';
        setDeviceBarcode('');
      }, scanCharInterval);
    };

    window.addEventListener('keydown', handleBarcodeInput);

    return () => {
      window.removeEventListener('keydown', handleBarcodeInput);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { deviceBarcode };
};
