/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Icon, Label, Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import style from './myStoresSelection.style';
import { SearchBox } from '../../../../shared/components/SearchBox';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { StoreSelection } from '../../../../shared/components/StoreSelection';
import { Role, SalesAssistantStore } from '../../../auth/models/principal';
import {
  fetchStores,
  selectFilteredStores,
  selectIsFetching,
  selectSearchBoxFilter,
  setStoresSearchBoxFilter,
} from '../stores/storesSlice';
import { selectPrincipal, selectPrincipalSalesAssistantStores, updateStores } from '../../../auth/store/principalSlice';
import api from '../utils/api';
import { toastService } from '../../../../core/services/toastService';

export const MyStoresSelection = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const principal = useAppSelector(selectPrincipal);
  const isGlobalStoresViewer = principal?.roles.includes(Role.GLOBAL_STORES_VIEWER);
  const [isGlobalStoresView, setIsGlobalStoresView] = useState<boolean>(false);

  useEffect(() => {
    if (!isGlobalStoresView) {
      dispatch(fetchStores());
    }
  }, [dispatch, isGlobalStoresView]);

  const mySavedStores = useAppSelector(selectPrincipalSalesAssistantStores);
  const [myStores, setMyStores] = React.useState<SalesAssistantStore[]>([]);
  useEffect(() => {
    setMyStores(mySavedStores || []);
  }, [mySavedStores]);

  const fetchedStores = useAppSelector(selectFilteredStores);
  const stores: SalesAssistantStore[] = _.uniqBy(
    fetchedStores.filter(fetchedStore => !myStores.map(myStore => myStore.storeCode).includes(fetchedStore.storeCode)),
    'storeCode'
  );
  const isFetchingStores = useAppSelector(selectIsFetching);

  const [worldStores, setWorldStores] = useState<SalesAssistantStore[]>([]);
  const globalStores: SalesAssistantStore[] = _.uniqBy(
    worldStores.filter(
      fetchedGlobalStore => !myStores.map(myStore => myStore.storeCode).includes(fetchedGlobalStore.storeCode)
    ),
    'storeCode'
  );
  const [isFetchingGlobalStores, setIsFetchingGlobalStores] = useState<boolean>(false);
  const searchGlobalStores = useDebouncedCallback(async (filter: string) => {
    setWorldStores([]);
    setIsFetchingGlobalStores(false);
    if (filter.trim().length < 3 || filter.trim() === '') {
      return;
    }
    setIsFetchingGlobalStores(true);
    try {
      const result = await api.getGlobalStores(filter);
      setWorldStores(result);
    } catch {
      setWorldStores([]);
    } finally {
      setIsFetchingGlobalStores(false);
    }
  }, 300);

  const searchBoxFilter = useAppSelector(selectSearchBoxFilter);
  const setSearchBoxFilter = useCallback(
    (value: string) => {
      dispatch(setStoresSearchBoxFilter(value));
    },
    [dispatch]
  );

  const onToggleGlobalStoresView = () => {
    setIsGlobalStoresView(!isGlobalStoresView);
    if (isGlobalStoresView) {
      setWorldStores([]);
    }
    if (!isGlobalStoresView) {
      toastService.default(t('storeSelection.globalViewMessage'), { autoClose: 5000 });
    }
  };

  const addToMyStores = useCallback(
    (store: SalesAssistantStore) => {
      setMyStores([...myStores, store]);
      stores.filter(st => st.storeCode !== store.storeCode);
    },
    [myStores, stores]
  );

  const removeFromMyStores = useCallback(
    (store: SalesAssistantStore) => {
      setMyStores(myStores.filter(selectedStore => selectedStore.storeCode !== store.storeCode));
      stores.concat(store);
    },
    [myStores, stores]
  );

  const cancel = useCallback(() => {
    setMyStores(mySavedStores || []);
  }, [mySavedStores]);

  const save = useCallback(() => {
    dispatch(updateStores(myStores.map(store => store.storeCode)));
  }, [dispatch, myStores]);

  return (
    <div css={style.container}>
      <div css={style.searchBoxContainer}>
        {isGlobalStoresView ? (
          <SearchBox
            key='globalStoresSearch'
            defaultValue=''
            onChange={value => {
              searchGlobalStores(value);
            }}
            placeholder={t('storeSelection.searchPlaceHolder')}
          />
        ) : (
          <SearchBox
            key='storesSearch'
            defaultValue={searchBoxFilter}
            onChange={value => {
              setSearchBoxFilter(value);
            }}
            placeholder={t('storeSelection.searchPlaceHolder')}
          />
        )}
        {isGlobalStoresViewer && (
          <div css={style.toggle}>
            <div>{t('storeSelection.activateGlobalStoresView')}</div>
            <Checkbox toggle checked={isGlobalStoresView} onChange={onToggleGlobalStoresView} />
          </div>
        )}
      </div>
      <MyStoresBadges myStores={myStores} removeFromMyStores={store => removeFromMyStores(store)} />
      <div css={style.storeSelectionContainer}>
        <StoreSelection
          onSelection={store => addToMyStores(store)}
          stores={isGlobalStoresView ? globalStores : stores}
        />
        {((isFetchingStores && fetchedStores.length === 0) || isFetchingGlobalStores) && (
          <div css={style.loaderContainer}>
            <Loader active />
          </div>
        )}
      </div>
      <div css={style.buttonsFooter}>
        <Button css={style.button} onClick={() => cancel()}>
          {t('storeSelection.cancel')}
        </Button>
        <Button css={style.button} onClick={() => save()}>
          {t('storeSelection.save')}
        </Button>
      </div>
    </div>
  );
};

const MyStoresBadges = ({
  myStores,
  removeFromMyStores,
}: {
  myStores: SalesAssistantStore[];
  removeFromMyStores: (store: SalesAssistantStore) => void;
}): JSX.Element => {
  return (
    <div css={style.badgesWrapper}>
      <div css={style.badgesContainer}>
        {myStores.map(store => (
          <Label css={style.storeBadge}>
            <span title={`${store.brand.code} - ${store.name}`}>{store.storeCode}</span>
            <Icon name='delete' onClick={() => removeFromMyStores(store)} />
          </Label>
        ))}
      </div>
    </div>
  );
};
