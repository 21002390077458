const jwtTokenKey = 'dcd-token';

export const getJwtToken = (): string | null => localStorage.getItem(jwtTokenKey);

export const setJwtToken = (token: string): void => localStorage.setItem(jwtTokenKey, token);

export const deleteJwtToken = (): void => localStorage.removeItem(jwtTokenKey);

const redirectPagePathKey = 'redirect-page-path';

export const getRedirectPagePath = (defaultPath = '/'): string =>
  sessionStorage.getItem(redirectPagePathKey) || defaultPath;

export const removeRedirectPagePath = (): void => sessionStorage.clear();

export const setRedirectPagePath = (pagePath: string): void => {
  if (getJwtToken() == null) {
    sessionStorage.setItem(redirectPagePathKey, pagePath);
  }
};
export const setBusinessCardRedirectPagePath = (pagePath: string): void => {
  sessionStorage.setItem(redirectPagePathKey, pagePath);
};
