import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import { Brand } from '../../../../shared/models/brand';
import { ActivitySection, activitySections } from '../models/userActivity';
import api from '../utils/api';

interface FilterData {
  brands: Brand[];
  activitySections: ActivitySection[];
  dateRange: Array<Date | null>;
}

export interface Filters {
  brand: string;
  activitySection: string;
  activityType: string;
  dateCode: string;
  dateRange: Array<Date | null>;
}
interface UserActivitiesFiltersSliceState {
  data: FilterData;
  isFetching: boolean;
  error: string;
  searchBoxFilter: string;
  filters: Filters;
}

const initialState: UserActivitiesFiltersSliceState = {
  data: {
    brands: [],
    activitySections: [],
    dateRange: [null, null],
  },
  isFetching: false,
  error: '',
  searchBoxFilter: '',
  filters: {
    brand: '',
    activitySection: '',
    activityType: '',
    dateCode: '',
    dateRange: [null, null],
  },
};

export const userActivitiesFiltersSlice = createSlice({
  name: 'userActivitiesFilters',
  initialState,
  reducers: {
    startBrandsFetch: (state: Draft<UserActivitiesFiltersSliceState>) => ({ ...state, isFetching: true }),
    finishBrandsFetch: (state: Draft<UserActivitiesFiltersSliceState>, action: PayloadAction<Brand[]>) => {
      return {
        ...state,
        data: { ...state.data, brands: action.payload },
        isFetching: false,
      };
    },
    httpError: (state: Draft<UserActivitiesFiltersSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
    makeFilters: (
      state: Draft<UserActivitiesFiltersSliceState>,
      payloadAction: PayloadAction<FilterData>
    ): UserActivitiesFiltersSliceState => {
      return {
        ...state,
        data: payloadAction.payload,
      };
    },
    setSearchBoxFilter: (
      state: Draft<UserActivitiesFiltersSliceState>,
      action: PayloadAction<string>
    ): UserActivitiesFiltersSliceState => ({
      ...state,
      searchBoxFilter: action.payload,
    }),
    setFilters: (
      state: Draft<UserActivitiesFiltersSliceState>,
      action: PayloadAction<Filters>
    ): UserActivitiesFiltersSliceState => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),
    resetFilters: (state: Draft<UserActivitiesFiltersSliceState>): UserActivitiesFiltersSliceState => {
      return { ...state, filters: { ...initialState.filters } };
    },
  },
});

export const {
  startBrandsFetch,
  finishBrandsFetch,
  httpError,
  makeFilters,
  setSearchBoxFilter,
  setFilters,
  resetFilters,
} = userActivitiesFiltersSlice.actions;

export const changeSearchBoxFilter =
  (filterValue: string): AppThunk =>
  async dispatch => {
    dispatch(setSearchBoxFilter(filterValue));
  };

export const changeFilters =
  (filters: Filters): AppThunk =>
  async dispatch => {
    dispatch(setFilters(filters));
  };

export const fetchBrandsFilters = (): AppThunk => async dispatch => {
  dispatch(startBrandsFetch());
  try {
    const brands = await api.getUserActivitiesBrands();
    dispatch(finishBrandsFetch(brands));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const createFilters = (): AppThunk => async (dispatch, state) => {
  try {
    const { brands } = state().userActivitiesFilters.data;

    const filters: FilterData = {
      brands,
      activitySections,
      dateRange: [null, null],
    };
    dispatch(makeFilters(filters));
  } catch (e) {
    console.log(JSON.stringify(e));
  }
};

export const selectBrands = (state: RootState): Brand[] => state.userActivitiesFilters.data.brands;
export const selectSortedBrands = createSelector(selectBrands, (brands: Brand[]): Brand[] =>
  _.sortBy(brands, ['description'])
);

export const selectActivitySections = (state: RootState): ActivitySection[] =>
  state.userActivitiesFilters.data.activitySections;
export const selectSearchBoxFilter = (state: RootState): string => state.userActivitiesFilters.searchBoxFilter;
export const selectFilters = (state: RootState): Filters => state.userActivitiesFilters.filters;

export default userActivitiesFiltersSlice.reducer;
