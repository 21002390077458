import { Brand } from '../../../../shared/models/brand';

export interface BusinessCard {
  givenName: string;
  surname: string;
  mail: string;
  jobTitle: string;
  mobilePhone?: string;
  brand: Brand;
  qrCode: string;
}

export interface BusinessCardResponse {
  givenName: string;
  surname: string;
  mail: string;
  jobTitle: string;
  mobilePhone?: string;
  brand: Brand;
  qrCode: string;
}

export const toBusinessCard = (response: BusinessCardResponse): BusinessCard => {
  return {
    givenName: response.givenName,
    surname: response.surname,
    mail: response.mail,
    mobilePhone: response.mobilePhone,
    jobTitle: response.jobTitle,
    brand: response.brand,
    qrCode: response.qrCode,
  };
};

export const getEmptyBusinessCard = (): BusinessCard => ({
  givenName: '',
  surname: '',
  mail: '',
  jobTitle: '',
  mobilePhone: '',
  brand: { code: '', description: '' },
  qrCode: '',
});
