/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { RequireAuth } from '../core/components/RequireAuth';
import { AdminPage } from './AdminPage';
import { updatePWAManifest } from '../core/utils';

interface AdminPageProps {
  children: JSX.Element;
}

export const AdminPageUnderSecurity = (props: AdminPageProps) => {
  useEffect(() => {
    updatePWAManifest('dc-manifest');
  });
  const { children } = props;
  return (
    <RequireAuth>
      <AdminPage>{children}</AdminPage>
    </RequireAuth>
  );
};
