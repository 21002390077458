import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
`;

const title = css`
  margin-bottom: 14px;
  ${globalStyle.bodyMedium};
`;

const filtersContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
`;

const searchBoxContainer = css`
  flex: 1;
  margin-right: 15px;
`;

const filterBadge = css`
  ${globalStyle.bodySmall};
  && {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dcd-secondary-color-40);
    border-radius: 20px;
    margin-right: 5px;
    padding: 4px 8px;
  }
`;

const badgesWrapper = css`
  padding-top: 20px;
  padding-left: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const badgesContainer = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const filterBadgesContainer = css`
  padding-bottom: 5px;
`;

const userActivitiesContainer = css`
  flex-grow: 1;
  padding-top: 10px;
  overflow: auto;
`;

const loader = css`
  &&&& {
    &:after {
      border-color: #767676 #ddd #ddd;
    }
  }
`;

export default {
  container,
  title,
  filtersContainer,
  searchBoxContainer,
  filterBadge,
  badgesWrapper,
  badgesContainer,
  filterBadgesContainer,
  userActivitiesContainer,
  loader,
};
