/** @jsxImportSource @emotion/react */
import React, { ChangeEvent } from 'react';
import { Dropdown, DropdownProps, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './searchBox.style';
import { fetchMyPurchasesByYear } from '../../features/employee/purchaseHistory/store/purchaseHistorySlice';
import { useAppDispatch } from '../../core/hooks';

interface SearchBoxProps {
  defaultValue: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  loading?: boolean;
  placeholder?: string;
  isSearchBarWithYearSelection?: boolean;
  selectedYear?: string;
  setSelectedYear?: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBox = ({
  defaultValue,
  onChange,
  autoFocus = false,
  placeholder,
  loading,
  isSearchBarWithYearSelection,
  selectedYear,
  setSelectedYear,
}: SearchBoxProps): JSX.Element => {
  return (
    <Input
      icon='search'
      iconPosition='left'
      labelPosition={selectedYear && setSelectedYear ? 'right' : undefined}
      label={
        selectedYear &&
        setSelectedYear && <PurchasesYearDropdown purchaseYear={selectedYear} setPurchaseYear={setSelectedYear} />
      }
      placeholder={placeholder}
      css={isSearchBarWithYearSelection ? style.searchWithYearSelection : style.search}
      defaultValue={defaultValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      autoFocus={autoFocus}
      loading={loading}
    />
  );
};

interface YearOption {
  key: string;
  value: string;
  text: string;
}

interface PurchaseYearDropdownProps {
  purchaseYear: string;
  setPurchaseYear: React.Dispatch<React.SetStateAction<string>>;
}

const PurchasesYearDropdown = ({ purchaseYear, setPurchaseYear }: PurchaseYearDropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;
  const twoYearsAgo = currentYear - 2;

  const yearOptions: YearOption[] = [
    { key: currentYear.toString(), value: currentYear.toString(), text: currentYear.toString() },
    { key: lastYear.toString(), value: lastYear.toString(), text: lastYear.toString() },
    { key: twoYearsAgo.toString(), value: twoYearsAgo.toString(), text: twoYearsAgo.toString() },
  ].filter(y => y.value !== '2022');

  const handleChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data;
    setPurchaseYear(value as string);
    dispatch(fetchMyPurchasesByYear(value as number));
  };

  return (
    <Dropdown
      css={style.yearSelectionDropdown}
      placeholder={t('purchaseHistory.selectYear')}
      selection
      options={yearOptions}
      value={purchaseYear}
      onChange={handleChange}
    />
  );
};
