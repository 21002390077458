/** @jsxImportSource @emotion/react */
import React from 'react';
import { AdminMainMenu } from './AdminMainMenu';

interface AdminPageProps {
  children: JSX.Element;
}

export const AdminPage = ({ children }: AdminPageProps): JSX.Element => {
  return <AdminMainMenu>{children}</AdminMainMenu>;
};
