import { css } from '@emotion/react';
import globalStyle from './global.style';
import discountCardBackground from '../assets/discountCardBackground.svg';

const container = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: url(${discountCardBackground}) no-repeat;
  background-size: cover;
  overflow-x: hidden;

  @media (min-width: 600px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
const content = css`
  max-width: 600px;
  width: 100%;
  height: 100%;
  background-color: var(--dcd-white-color);
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    box-shadow: 0px 0px 4px var(--dcd-gray-color-60);
    border-radius: 5px;
  }
  padding-bottom: 62px;
`;

const titleContainer = css`
  background-color: var(--dcd-secondary-color-40);
  text-align: center;
  padding: 20px 20px;
  @media (min-width: 600px) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

const title = css`
  ${globalStyle.bodyNormal}
  && {
    margin-bottom: 0;
  }
`;

const bodyContainer = css`
  margin: 60px auto 0;
  width: 100%;
  padding: 0 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
`;

const roleBtn = css`
  ${globalStyle.primaryButton};
  width: 100%;
`;

export default { titleContainer, container, content, bodyContainer, roleBtn, title };
