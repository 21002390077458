import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const limitsContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const title = css`
  ${globalStyle.bodyMedium};

  @media (max-width: 600px) {
    > span {
      display: none;
    }
    > div {
      margin-left: 0;
    }
  }

  &&& {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const limitRuleSetsSectionContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const filtersContainer = css`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin-left: auto;
`;

const filterBadge = css`
  ${globalStyle.bodySmall};
  && {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dcd-secondary-color-40);
    border-radius: 20px;
    margin-right: 5px;
    padding: 4px 8px;
  }
`;

const badgesWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const badgesContainer = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const addLimitRuleSetButton = (label: string): SerializedStyles => css`
  ${globalStyle.labeledButton(label)};
`;

const limitTypeSelector = css`
  display: flex;
  flex-wrap: wrap;
  background-color: var(--dcd-secondary-color-70);
  justify-content: space-between;
  flex-basis: 100%;
  margin-left: 102px;
  margin-right: 3.5px;
  border-radius: 20px;
  padding: 10px 20px;
`;

const limitTypeSelectorItem = css`
  border-radius: 2px;
  padding: 10px;
  &&&& {
    background-color: var(--dcd-secondary-color-70);
    &.active {
      background-color: var(--dcd-white-color);
      color: var(--dcd-gray-color-80);
      border-radius: 20px;
    }
  }
`;

const rulesHeader = css`
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 10px;
`;
const ruleList = css`
  padding-bottom: 10px;
  padding-right: 15px;

  @media (min-width: 600px) {
    flex: 1;
    overflow: auto;
  }
`;
const noItemsLabel = css`
  ${globalStyle.bodySmall}
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export default {
  container,
  limitsContainer,
  limitRuleSetsSectionContainer,
  title,
  filtersContainer,
  filterBadge,
  badgesWrapper,
  badgesContainer,
  addLimitRuleSetButton,
  rulesHeader,
  ruleList,
  limitTypeSelector,
  limitTypeSelectorItem,
  noItemsLabel,
};
