import axios from 'axios';
import paths from './paths';
import {
  QuotaLimitByCategory,
  QuotaLimitPerSku,
  toQuotaLimitByCategory,
  toQuotaLimitPerSku,
} from '../models/QuotaLimit';
import { MyDiscountRule, toMyDiscountRule } from '../models/MyDiscountRule';

const api = {
  getMyQuotaLimitsByCategories: async (brandCode: string): Promise<QuotaLimitByCategory[]> => {
    const response = await axios.get(paths.getMyQuotaLimitsByCategories(brandCode));
    return response.data.map(toQuotaLimitByCategory);
  },
  getMyQuotaLimitsPerSku: async (brandCode: string): Promise<QuotaLimitPerSku[]> => {
    const response = await axios.get(paths.getMyQuotaLimitsBySkus(brandCode));
    return response.data.map(toQuotaLimitPerSku);
  },
  getMyDiscountRules: async (brandCode: string): Promise<MyDiscountRule[]> => {
    const response = await axios.get(paths.getMyDiscountRules(brandCode));
    return response.data.map(toMyDiscountRule);
  },
};
export default api;
