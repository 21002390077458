import { css } from '@emotion/react';

const pageContainer = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const formContainer = css`
  height: calc(100% - 40px);
`;

// const addRuleForm = css`
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   width: 100%;
//   margin-bottom: 14px;
//   padding: 0 10px;
// `;
//
// const mainConstraintsContainer = css`
//   width: 100%;
// `;
// const mainConstraints = css`
//   display: flex;
//   justify-content: flex-start;
//   width: 100%;
//   margin-bottom: 14px;
//   gap: 20px;
// `;
// const brandField = css`
//   min-width: 200px;
// `;
//
// const ruleFormContainer = css`
//   padding: 0 10px;
//   margin: 10px 0 -10px 0;
// `;
//
// const scrollableContent = css`
//   overflow: auto;
//   height: calc(100% - 286px);
// `;
// const ruleCardContainer = css`
//   overflow: auto;
//   padding: 10px;
// `;
//
// const reusableButton = (label: string): SerializedStyles => css`
//   &&&& {
//     position: absolute;
//     z-index: 10;
//     bottom: 20px;
//     right: 20px;
//     width: 55px;
//     height: 55px;
//     border-radius: 8px;
//     background-color: var(--dcd-pink-color-80);
//     color: var(--dcd-white-color);
//   }
//
//   @media (min-width: 768px) {
//     &&&& {
//       position: unset;
//       z-index: unset;
//       bottom: unset;
//       right: unset;
//       width: unset;
//       height: unset;
//       border-radius: 8px;
//       background-color: var(--dcd-pink-color-80);
//       color: var(--dcd-white-color);
//
//       :after {
//         content: '${label}';
//         padding-left: 5px;
//       }
//     }
//   }
// `;
// const saveRuleSetButtonContainer = css`
//   padding: 40px 15px 25px 25px;
//   display: flex;
//   justify-content: flex-end;
// `;
// const saveRuleSetButton = css`
//   ${globalStyle.primaryButton};
// `;
//
// const modalContent = css`
//   &&&& {
//     border-radius: 10px;
//     @media (max-width: 760px) {
//       padding: 18px 14px 8px !important;
//     }
//     @media (min-width: 760px) {
//       padding: 30px 21px 8px !important;
//     }
//   }
// `;
//
// const modalActions = css`
//   &&& {
//     display: flex;
//     justify-content: center;
//     border: none;
//     background-color: transparent;
//     @media (max-width: 760px) {
//       padding-top: 0 !important;
//     }
//   }
// `;
//
// const refuteBtn = css`
//   ${globalStyle.secondaryButton};
//   flex: 1;
// `;
//
// const confirmBtn = css`
//   ${globalStyle.primaryButton};
//   flex: 1;
// `;

export default {
  pageContainer,
  formContainer,
  // addRuleForm,
  // mainConstraintsContainer,
  // mainConstraints,
  // brandField,
  // ruleFormContainer,
  // ruleCardContainer,
  // scrollableContent,
  // reusableButton,
  // saveRuleSetButtonContainer,
  // saveRuleSetButton,
  // modalContent,
  // modalActions,
  // confirmBtn,
  // refuteBtn,
};
