import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import { checkValueInclude } from '../../../../shared/utils/utils';
import { TrafficLightsByCategoryAndStoreTypology, TrafficLightsReadingRequest } from '../models/trafficLights';
import api from '../utils/api';
import { selectSearchBoxFilter } from './trafficLightsFilterSlice';

interface TrafficLightsSliceState {
  data: TrafficLightsByCategoryAndStoreTypology[];
  isFetching: boolean;
  error: string;
}

const initialState: TrafficLightsSliceState = {
  data: [],
  isFetching: false,
  error: '',
};

export const trafficLightsState = createSlice({
  name: 'trafficLights',
  initialState,
  reducers: {
    startFetch: (state: Draft<TrafficLightsSliceState>): TrafficLightsSliceState => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetch: (
      state: Draft<TrafficLightsSliceState>,
      payloadAction: PayloadAction<Array<TrafficLightsByCategoryAndStoreTypology>>
    ): TrafficLightsSliceState => {
      return {
        ...state,
        data: payloadAction.payload,
        isFetching: false,
        error: '',
      };
    },
    httpError: (
      state: Draft<TrafficLightsSliceState>,
      payloadAction: PayloadAction<string>
    ): TrafficLightsSliceState => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    reset: () => initialState,
  },
});

export const { startFetch, finishFetch, httpError, reset } = trafficLightsState.actions;

export const resetTrafficLights = (): AppThunk => async dispatch => {
  dispatch(reset());
};

export const fetchTrafficLights =
  (request: TrafficLightsReadingRequest): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const trafficLights = await api.getTrafficLights(request);
      dispatch(finishFetch(trafficLights));
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };

export const selectTrafficLights = (state: RootState): TrafficLightsByCategoryAndStoreTypology[] => state.trafficLights.data;

export const selectIsFetchingTrafficLights = (state: RootState): boolean => state.trafficLights.isFetching;

export const selectOrderedTrafficLights = createSelector(
  selectTrafficLights,
  (trafficLights): TrafficLightsByCategoryAndStoreTypology[] => _.sortBy(trafficLights, 'category')
);

export const selectFilteredTrafficLights = createSelector(
  selectOrderedTrafficLights,
  selectSearchBoxFilter,
  (trafficLights, filter): TrafficLightsByCategoryAndStoreTypology[] =>
    trafficLights.filter(tl => checkValueInclude(tl.category, filter))
);

export default trafficLightsState.reducer;
