import { StoreTypologyEnum } from '../../../../shared/models/storeTypology';
import { LimitRule, MoneyAmount } from '../../../admin/limits/models/limitRule';

interface Quota {
  amount: MoneyAmount;
  totalItemsCount: number | null;
}

export interface QuotaLimitByCategory {
  quota: Quota;
  limitRule: LimitRule;
}

interface QuotaLimitByCategoryResponse {
  quota: Quota;
  limitRule: LimitRule;
}
export const toQuotaLimitByCategory = (response: QuotaLimitByCategoryResponse): QuotaLimitByCategory => {
  return {
    quota: response.quota,
    limitRule: response.limitRule,
  };
};

export interface QuotaLimitPerSku {
  categoryLabel: string | null;
  storeTypology: StoreTypologyEnum  | null;
  productCategories: string[] | null;
  quantityLimit: number | null;
}

interface QuotaLimitPerSkuResponse {
  categoryLabel: string | null;
  storeTypology: StoreTypologyEnum  | null;
  productCategories: string[] | null;
  quantityLimit: number | null;
}

export const toQuotaLimitPerSku = (response: QuotaLimitPerSkuResponse): QuotaLimitPerSku => {
  return {
    categoryLabel: response.categoryLabel,
    storeTypology: response.storeTypology,
    productCategories: response.productCategories,
    quantityLimit: response.quantityLimit,
  };
};
