/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { Product } from '../models/product';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { onChangeSearchBoxFilter, selectIsFetching, selectProducts } from '../store/productSearchSlice';
import style from './productsDropdown.style';
import { SalesAssistantStore } from '../../../auth/models/principal';

interface ProductsDropdownProps {
  onSelection: (product: Product) => void;
  store: SalesAssistantStore;
}

export const ProductsDropdown = ({ onSelection, store }: ProductsDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectProducts);
  const isFetching = useAppSelector(selectIsFetching);
  const [selectedValue, setSelectedValue] = useState('');

  const getOptions = useCallback(() => {
    const generateItem = (product: Product): DropdownItemProps => ({
      key: product.sku,
      value: product.sku,
      text: `${product.name}`,
      content: (
        <div css={style.dropdownItemContainer}>
          <div>
            <div css={style.dropdownItemName}>
              {product.name} - {product.sku}
            </div>
            <div css={style.dropdownItemInfo}>
              <div>{product.description}</div>
              <div>{product.category}</div>
            </div>
          </div>
          {store.brand.code === 'GUCCI' && !product.isEligible && <div css={style.nonEligibleIconContainer}><Icon name='dont' size='big' color='red'/></div>}
        </div>
        
      ),
    });
    return products.map(generateItem);
  }, [products, store.brand.code]);

  const searchProducts = useDebouncedCallback(async (productTerm: string) => {
    if (store != null) {
      dispatch(onChangeSearchBoxFilter(store.storeCode, productTerm));
    }
  }, 300);

  return (
    <Dropdown
      css={style.roundedDropdown}
      fluid
      selectOnBlur={false}
      selection
      icon='search'
      noResultsMessage={t('salesAssistant.productsSearch.noProductsFound')}
      search={() => getOptions()}
      options={getOptions()}
      placeholder={
        store?.brand.code === 'POMELLATO'
          ? t('salesAssistant.productsSearch.searchPomellato')
          : t('salesAssistant.productsSearch.search')
      }
      onSearchChange={(e, { searchQuery }) => {
        searchProducts(searchQuery);
      }}
      selectOnNavigation={false}
      onChange={(e, { value }) => {
        onSelection(products.find(product => product.sku === (value as string)) as Product);
        setSelectedValue('');
      }}
      value={selectedValue}
      disabled={isFetching}
      loading={isFetching}
    />
  );
};
