/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form, Radio } from 'semantic-ui-react';
import style from './radioGroup.style';

export interface RadioItem {
  code: string;
  description: string;
}

interface RadioGroupProps {
  label: string;
  items: RadioItem[];
  value: string;
  onClick: (v: string) => void;
}

export const RadioGroup = ({ label, items, value, onClick }: RadioGroupProps): JSX.Element => {
  return (
    <section css={style.filterSection}>
      <div css={style.radioGroupLabel}>{label}</div>
      <Form css={style.radioGroup}>
        {items.map(item => (
          <Form.Field key={item.code}>
            <Radio
              css={style.radioButton}
              name='radioGroup'
              value={item.code}
              label={item.description}
              checked={item.code === value}
              onClick={() => {
                onClick(item.code);
              }}
            />
          </Form.Field>
        ))}
      </Form>
    </section>
  );
};
