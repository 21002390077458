import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css``;

const headerContainer = css`
  position: relative;
  margin-bottom: 14px;
  ${globalStyle.stickyHeader(`var(--dcd-white-color)`)})};

`;

const employeeBadge = css`
  position: absolute;
  top: -7px;
  right: 0;
  ${globalStyle.bodyNormal};

  &&& {
    color: var(--dcd-secondary-color-30);
    background-color: var(--dcd-pink-color-90);
    font-weight: 500;
  }

  padding: 7px;
  border-radius: 30px;
`;

const contentContainer = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const filtersContainer = css`
  width: 100%;
  &&{
    padding-left: 0;
    padding-right: 0;
  }
  padding-top: 7px;
  padding-bottom: 7px;
  ${globalStyle.stickyHeader(`var(--dcd-white-color)`, '20px')})};

`;

const filterIcon = css`
  && {
    margin-left: 15px;
  }
`;

const trafficLightsListContainer = css`
  padding-top: 7px;
  padding-left: 5px;
  padding-right: 5px;

  margin-bottom: 10px;
  overflow-y: auto;
  height: calc(100% - 170px);
  width: 100%;
`;

const noItemsMessage = css`
  ${globalStyle.bodySmall};
  text-align: center;
`;

export default {
  container,
  headerContainer,
  employeeBadge,
  contentContainer,
  filtersContainer,
  filterIcon,
  noItemsMessage,
  trafficLightsListContainer,
};
