import { css } from '@emotion/react';

const headerContainer = css`
  display: flex;
  justify-content: space-between;
  min-width: 230px;
  width: 100%;
  padding-top: 20px;
`;

const headerLeftElement = css`
  flex: 1;
`;

const headerCenterElement = css`
  flex: 4;
  text-align: center;
`;

const headerTitle = css`
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
`;

const headerArrow = css`
  color: var(--dcd-gray-color);
  :hover {
    color: var(--dcd-gray-color-70);
  }
  padding: 0 10px;
  cursor: pointer;
`;

const headerRightElement = css`
  flex: 1;
`;

export default {
  headerContainer,
  headerLeftElement,
  headerCenterElement,
  headerTitle,
  headerArrow,
  headerRightElement,
};
