/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Loader, Modal } from 'semantic-ui-react';
import ExcelJS from 'exceljs';
import style from './nonEligibleBulkUploadModal.style';
import { AppDispatch } from '../../../../../core/store';
import { downloadNonEligibleProductsBulkUploadTemplate } from '../../stores/limitRuleDetailSlice';
import { NonEligibleConstraint } from '../../models/nonEligibleConstraint';
import { toastService } from '../../../../../core/services/toastService';

export const NonEligibleBulkUploadModal = ({
  open,
  onClose,
  nonEligibleConstraint,
  onConfirm,
  fileName,
  setFileName,
}: {
  open: boolean;
  onClose: () => void;
  nonEligibleConstraint: NonEligibleConstraint | null;
  onConfirm: (skusFromFile: string[]) => void;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [fileWithErrors, setFileWithErrors] = useState<ExcelJS.Buffer | null>(null);
  const [isFileValid, setIsFileValid] = useState(true);
  const [skusFromFile, setSkusFromFile] = useState<string[]>([]);

  const isExcelSheetFormatValid = (
    fileSheet: ExcelJS.Worksheet | undefined,
    columnHeader: ExcelJS.CellValue
  ): boolean => {
    return (
      fileSheet != null &&
      (columnHeader === ('SKUs' as NonEligibleConstraint) ||
        columnHeader === ('Style lines' as NonEligibleConstraint) ||
        columnHeader === ('Style colors' as NonEligibleConstraint)) &&
      fileSheet.columnCount === 1
    );
  };

  const isCellFormatValid = (cellValue: ExcelJS.CellValue): boolean => {
    const alphanumericRegex = /^[a-zA-Z0-9. &/-]+$/;
    return (
      (cellValue != null && typeof cellValue === 'string' && alphanumericRegex.test(cellValue)) ||
      (cellValue != null && typeof cellValue === 'number')
    );
  };

  const addInvalidFormatText = (row: ExcelJS.Row) => {
    const errorCell = row.getCell('B');
    errorCell.value = 'invalid format';
  };

  const addNonEligibleConstraintColumnHeader = (fileSheet: ExcelJS.Worksheet) => {
    const columnTwo = fileSheet.getColumn(2);
    columnTwo.header = 'Errors';
    const headerCell = fileSheet.getCell('B1');
    headerCell.font = { bold: true };
    return columnTwo;
  };

  const validateFile = async (selectedFile: File) => {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.read(selectedFile.stream());

    const nonEligibleListSheet = workbook?.getWorksheet('NonEligibleProducts');
    const columnHeader = nonEligibleListSheet?.getCell('A1').value;
    if (!isExcelSheetFormatValid(nonEligibleListSheet, columnHeader)) {
      setFileName('');
      toastService.error(t('limitRuleset.nonEligibleBulkUpload.wrongFormat'));
      return;
    }

    if (nonEligibleListSheet != null) {
      const nonEligibleProducts = [];
      let hasErrors;
      let i = 2;

      while (nonEligibleListSheet.getRow(i).hasValues) {
        const row = nonEligibleListSheet.getRow(i);
        const cellValue = row.getCell('A').value;

        if (isCellFormatValid(cellValue)) {
          if (cellValue?.toString() != null) {
            nonEligibleProducts.push(cellValue?.toString());
          }
        } else {
          if (!hasErrors) {
            hasErrors = true;
          }
          addInvalidFormatText(row);
        }
        // eslint-disable-next-line no-plusplus
        i++;
      }

      if (!hasErrors) {
        setIsFileValid(true);
        setFileName(selectedFile.name);
        setSkusFromFile(nonEligibleProducts);
      } else {
        addNonEligibleConstraintColumnHeader(nonEligibleListSheet);
        const bufferFileWithErrors = await workbook.xlsx.writeBuffer();
        setFileWithErrors(bufferFileWithErrors);
        setIsFileValid(false);
        setFileName(selectedFile.name);
      }
    }
  };

  const downloadTemplate = async () => {
    setIsDownloading(true);
    if (nonEligibleConstraint != null) {
      await dispatch(downloadNonEligibleProductsBulkUploadTemplate(nonEligibleConstraint));
    }
    setIsDownloading(false);
  };

  const downloadFileWithErrors = () => {
    if (fileWithErrors != null) {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
      const blob = new Blob([fileWithErrors], {
        type: fileType,
      });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'non-eligible-products-upload-errors-file.xlsx');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const creationStep = useMemo((): BulkUploadStep => {
    switch (true) {
      case fileName !== '' && isFileValid:
        return 'selected';
      case fileName !== '' && !isFileValid:
        return 'selectedWithErrors';
      case fileName === '':
      default:
        return 'initial';
    }
  }, [fileName, isFileValid]);

  return (
    <Modal css={style.modalContainer} open={open} onClose={onClose} closeIcon>
      <Modal.Header css={style.header}>{t('limitRuleset.nonEligibleBulkUpload.title')}</Modal.Header>
      <Modal.Actions css={style.buttonsAndFileContainer}>
        <div css={style.buttonsContainer}>
          <Button
            css={style.downloadTemplateButton}
            content={t('limitRuleset.nonEligibleBulkUpload.downloadTemplate')}
            labelPosition='left'
            loading={isDownloading}
            icon='cloud download'
            onClick={downloadTemplate}
          />
          <ChooseFileButton onChange={validateFile} />
        </div>
      </Modal.Actions>
      <Modal.Content css={style.content}>
        <SelectionStep
          step={creationStep}
          skusFromFile={skusFromFile}
          confirmAndClose={onConfirm}
          fileName={fileName}
          isFileValid={isFileValid}
          getErrorsFile={downloadFileWithErrors}
        />
      </Modal.Content>
    </Modal>
  );
};

type BulkUploadStep = 'initial' | 'selected' | 'selectedWithErrors';

const SelectionStep = ({
  step,
  skusFromFile,
  confirmAndClose,
  fileName,
  isFileValid,
  getErrorsFile,
}: {
  step: BulkUploadStep;
  skusFromFile: string[];
  confirmAndClose: (skusFromFile: string[]) => void;
  fileName: string;
  isFileValid: boolean;
  getErrorsFile: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  let stepComponent: JSX.Element;

  switch (step) {
    case 'initial':
      stepComponent = (
        <div css={style.initialStep}>
          <ul>
            <li>{t('limitRuleset.nonEligibleBulkUpload.downloadTemplate')}</li>
            <li>{t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.fillTemplate')}</li>
            <li>{t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.noEmptyRows')}</li>
            <li>{t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.fillInFirstColumn')}</li>
            <li>{t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.chooseFileToUpload')}</li>
          </ul>
        </div>
      );
      break;
    case 'selected':
      stepComponent = (
        <div css={style.selectedStep}>
          {fileName !== '' && isFileValid && (
            <ul>
              <li>
                {t('limitRuleset.nonEligibleBulkUpload.file')} <i>{fileName}</i>{' '}
                {t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.selectedSuccessfully')}
              </li>
              <li>{t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.confirmToSave')}</li>
            </ul>
          )}
          <div css={style.footerButtonsContainer}>
            <Button
              onClick={() => confirmAndClose(skusFromFile)}
              css={style.confirmButton}
              labelPosition='left'
              icon='folder'
              content={t('confirm')}
            />
          </div>
        </div>
      );
      break;
    case 'selectedWithErrors':
      stepComponent = (
        <div css={style.selectedStep}>
          {fileName !== '' && !isFileValid && (
            <ul>
              <li>
                {t('limitRuleset.nonEligibleBulkUpload.file')} <i>{fileName}</i>{' '}
                {t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.selectedWithErrors')}
              </li>
              <li>{t('limitRuleset.nonEligibleBulkUpload.uploadStepInfo.downloadErrorsFile')}</li>
            </ul>
          )}
          <div css={style.footerButtonsContainer}>
            <Button
              onClick={getErrorsFile}
              css={style.confirmButton}
              labelPosition='left'
              icon='folder'
              content={t('limitRuleset.nonEligibleBulkUpload.downloadErrorsFile')}
            />
          </div>
        </div>
      );
      break;
    default:
      stepComponent = (
        <div css={style.loaderContainer}>
          <Loader size='large' active css={style.loader} />
        </div>
      );
      break;
  }
  return stepComponent;
};

const ChooseFileButton = ({ onChange }: { onChange: (file: File) => void }): JSX.Element => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const openUploadWindow = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };
  return (
    <div>
      <Button
        css={style.chooseFileButton}
        content={t('limitRuleset.nonEligibleBulkUpload.chooseFile')}
        labelPosition='left'
        icon='folder'
        onClick={openUploadWindow}
      />
      <input
        ref={fileInputRef}
        type='file'
        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        hidden
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files != null && e.target.files.length > 0) {
            onChange(e.target.files[0]);
          }
        }}
      />
    </div>
  );
};
