import { DiscountRule, DiscountRuleResponse } from '../../../admin/discounts/models/discountRuleSet';
import { Brand, BrandResponse, toBrand } from '../../../../shared/models/brand';
import { toCountry } from '../../../../shared/models/country';

export interface MyDiscountRule extends DiscountRule {
  brand: Brand;
}
export interface MyDiscountRuleResponse extends DiscountRuleResponse {
  brand: BrandResponse;
}

export const toMyDiscountRule = (response: MyDiscountRuleResponse): MyDiscountRule => {
  return {
    brand: toBrand(response.brand),
    constraints: {
      countries: response.constraints.countries?.map(toCountry) ?? null,
      productCategories: response.constraints.productCategories,
    },
    discountPercentage: response.discountPercentage,
  };
};
