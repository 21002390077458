import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const headerContainer = css`
  position: relative;
  margin-bottom: 14px;
`;

const employeeBadge = css`
  position: absolute;
  top: -7px;
  right: 0;
  ${globalStyle.bodyNormal};

  &&& {
    color: var(--dcd-secondary-color-30);
    background-color: var(--dcd-pink-color-90);
    font-weight: 500;
  }

  padding: 7px;
  border-radius: 30px;
`;

const generalContainer = css`
  height: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
`;

const container = css`
  width: 100%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const topSearchBox = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-bottom: 20px;
`;
const centeredSearchBox = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const searchProduct = css`
  ${globalStyle.bodySmall};
`;
const searchOrScanProduct = css`
  ${globalStyle.bodySmall};
  margin: 0;
`;

const productScanner = css`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1;
`;
const productScannerDropdownContainer = css`
  display: flex;
  width: 100%;
  gap: 15px;
`;

const basketButtonContainer = css`
  display: flex;
  align-items: center;
  position: absolute;
  top: -50px;
  right: 2px;
`;
const basketButton = css`
  &&&& {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    background-color: transparent;
  }
`;

const basketButtonCounter = css`
  &&&&& {
    background-color: transparent;
    color: var(--dcd-black-color);
    & :hover {
      background-color: transparent;
      color: var(--dcd-black-color);
    }
  }
`;

const corner = css`
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: var(--dcd-gray-color-70);
  border-style: solid;
  content: ' ';
`;

const corners = css`
  :before,
  :after {
    ${corner};
    bottom: 0;
  }
  :before {
    right: 0;
    border-width: 0 2px 2px 0;
  }

  :after {
    left: 0;
    border-width: 0 0 2px 2px;
  }
`;

const scanBarcodeButton = css`
  position: relative;
  &&&& {
    background-color: transparent;
    font-size: 10px;
    line-height: 10px;
    padding: 0 12px;
    height: 36px;
  }

  :before,
  :after {
    ${corner};
    top: 0;
  }

  :before {
    left: 0;
    border-width: 2px 0 0 2px;
  }

  :after {
    right: 0;
    border-width: 2px 2px 0 0;
  }
`;
export default {
  generalContainer,
  container,
  headerContainer,
  employeeBadge,
  centeredSearchBox,
  topSearchBox,
  searchProduct,
  productScanner,
  searchOrScanProduct,
  basketButtonContainer,
  basketButton,
  basketButtonCounter,
  productScannerDropdownContainer,
  corners,
  scanBarcodeButton,
};
