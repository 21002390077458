/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { fetchAllLimits } from '../../../admin/limits/stores/limitRulesSlice';
import { fetchFilters } from '../../../admin/limits/stores/limitRulesFiltersSlice';
import { PurchaseLimitWizard } from '../components/PurchaseLimitWizard';
import style from './salesAssistantPurchaseLimit.style';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';

export const SalesAssistantPurchaseLimit = () => {
  const dispatch = useAppDispatch();
  const selectedStore = useAppSelector(selectSelectedStore);
  const handleInitialFetch = async () => {
    await dispatch(fetchAllLimits(selectedStore?.countryCode));
    dispatch(fetchFilters());
  };

  useEffect(() => {
    handleInitialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={style.container}>
      <PurchaseLimitWizard />
    </div>
  );
};
