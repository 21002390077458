import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import globalStyle from '../../../../shared/global.style';

const tabsContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 2px 7px;
  width: 100%;
`;

const tab = css`
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 0;
  width: 50%;
  text-align: center;
  ${globalStyle.bodyNormal};
`;
const selectedTab = css`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  background-color: var(--dcd-secondary-color-40);
`;

const tabButton = (selected: boolean): SerializedStyles => {
  return css`
    ${tab}
    ${selected ? selectedTab : ''};
  `;
};

const smallTabButton = (selected: boolean): SerializedStyles => {
  return css`
    ${tabButton(selected)};
    &&& {
      font-size: 14px;
    }
  `;
};

const quotaCardsContainer = css`
  overflow: auto;
  padding-top: 7px;
  flex: 1;
`;

const quotaCard = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin: 3px 3px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const quotaInfoContainer = css`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 18px;
`;
const quotaMiddleSection = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
`;

const quotaProductCategory = css`
  display: flex;
  flex-direction: column;
  ${globalStyle.bodySmall};
  margin-bottom: 0;
  && {
    font-weight: 500;
  }
`;

const quotaCountLimit = css`
  width: fit-content;
  flex-shrink: 0;
`;
const noItemsMessage = css`
  ${globalStyle.bodySmall};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const quotaLimitsContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: auto;
`;

export default {
  tabsContainer,
  smallTabButton,
  quotaCardsContainer,
  quotaCard,
  quotaInfoContainer,
  quotaMiddleSection,
  quotaProductCategory,
  quotaCountLimit,
  noItemsMessage,
  quotaLimitsContainer,
};
