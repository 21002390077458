import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { SalesAssistantStore } from '../../../auth/models/principal';
import type { RootState } from '../../../../core/store';

interface SalesAssistantSlice {
  selectedStore: SalesAssistantStore | null;
}

const initialState: SalesAssistantSlice = {
  selectedStore: null,
};

export const salesAssistantSlice = createSlice({
  name: 'salesAssistantSlice',
  initialState,
  reducers: {
    setSelectedStore: (
      state: Draft<SalesAssistantSlice>,
      action: PayloadAction<SalesAssistantStore>
    ): SalesAssistantSlice => ({
      selectedStore: action.payload,
    }),
    resetSelectedStore: (): SalesAssistantSlice => ({
      selectedStore: null,
    }),
  },
});
export const { setSelectedStore, resetSelectedStore } = salesAssistantSlice.actions;

export default salesAssistantSlice.reducer;
export const selectSelectedStore = (state: RootState): SalesAssistantStore | null => state.salesAssistant.selectedStore;
