/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Loader, Icon, Button, Image } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  fetchBusinessCard,
  getAppleWalletBusinessCard,
  getGoogleWalletBusinessCard,
  selectIsFetchingMyBusinessCard,
  selectMyBusinessCard,
} from '../store/businessCardSlice';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import style from './myBusinessCard.style';
import { EmployeeCardActionsModal, DetailAction } from '../../../../shared/components/EmployeeCardActionsModal';
import QrCodeModal from '../../../../shared/components/QrCodeModal';
import Header from '../../../../shared/components/Header';
import { getBusinessCardLogo } from '../../../../shared/models/business-card.brand';
import { getQrcodeLogo } from '../../../../shared/models/brand';

export const MyBusinessCard = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const myBusinessCard = useAppSelector(selectMyBusinessCard);
  const isFetching = useAppSelector(selectIsFetchingMyBusinessCard);
  const [shareModal, setShareModal] = useState(false);
  const [qrCodeModalOpen, setqrCodeModalOpen] = useState(false);
  const actions: DetailAction[] = ['shareContact'];
  const isBusinessCardPath = window.location.pathname === '/business-card/my-card';

  const downloadVcard = (blob: Blob) => {
    const downloadURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'my-business-card.vcf';
    link.href = downloadURL;
    link.click();
  };

  const share = async () => {
    const vCardContent = `BEGIN:VCARD\nVERSION:3.0\nN:${myBusinessCard.surname};${myBusinessCard.givenName};;;\nFN:${
      myBusinessCard.givenName
    } ${myBusinessCard.surname}\nTEL;TYPE=CELL:${myBusinessCard.mobilePhone || ''}\nEMAIL:${
      myBusinessCard.mail
    }\nEND:VCARD`;

    const blob = new Blob([vCardContent], { type: 'text/vcard' });
    const file = new File([blob], 'my-business-card.vcf', { type: 'text/vcard' });
    const filesArray = [file];

    try {
      if (navigator.canShare && navigator.canShare({ files: filesArray })) {
        await navigator.share({
          files: filesArray,
        });
      } else {
        downloadVcard(blob);
      }
    } catch (error) {
      if (isIOS) {
        console.error('message:', error);
      } else {
        downloadVcard(blob);
      }
    }
  };

  const shareToWallet = () => {
    if (isIOS) {
      dispatch(getAppleWalletBusinessCard());
    }
    if (isAndroid) {
      dispatch(getGoogleWalletBusinessCard());
    }
  };

  useEffect(() => {
    dispatch(fetchBusinessCard());
  }, [dispatch]);

  return (
    <div style={{ height: '100%' }}>
      <Header arrowBackEnable={false} title={t('myBusinessCard.title')} />
      <div css={style.container}>
        {isFetching ? (
          <Loader size='big' active />
        ) : (
          <div css={style.contentContainer}>
            <div css={style.card}>
              <div css={style.content}>
                <Image src={getBusinessCardLogo(myBusinessCard.brand.code)} size='small' />
                <div css={style.nameSection}>
                  <div css={style.name}>{`${myBusinessCard.givenName} ${myBusinessCard.surname}`}</div>
                </div>
                <div css={style.emailSection}>
                  {myBusinessCard.jobTitle != null && (
                    <div css={style.info}>
                      <Icon name='briefcase' css={style.infoIcon} />
                      <div css={style.infoText}>{myBusinessCard.jobTitle}</div>
                    </div>
                  )}
                  <div css={style.info}>
                    <Icon name='mail' css={style.infoIcon} />
                    <div css={style.infoText}>{myBusinessCard.mail}</div>
                  </div>
                  {myBusinessCard.mobilePhone != null && (
                    <div css={style.info}>
                      <Icon name='phone' css={style.infoIcon} />
                      <div css={style.infoText}>{myBusinessCard.mobilePhone}</div>
                    </div>
                  )}
                </div>
                <div css={style.qrCode} onClick={() => setqrCodeModalOpen(true)}>
                  <Image src={myBusinessCard.qrCode} />
                  <div css={style.logoContainer}>
                    <Image src={getQrcodeLogo(myBusinessCard.brand.code)} />
                  </div>
                </div>
              </div>
            </div>
            <div css={style.policiesLinkContainer}>
              <a
                href='https://keringconnect.service-now.com/kb?id=kb_article_view&sysparm_article=KB0036797'
                target='_blank'
                rel='noreferrer'
                css={style.policiesLink}>
                <Icon name='linkify' />
                {t('seeMore.privacyPolicy')}
              </a>
            </div>
            <div css={style.buttonContainer}>
              <Button css={style.button} onClick={share}>
                <Icon name='share square' css={style.buttonIcon} />
                {t('myBusinessCard.shareContact')}
              </Button>
              {isIOS && (
                // add isAndroid in the conditions of this component when ready
                <Button css={style.walletButton} onClick={shareToWallet}>
                  <FontAwesomeIcon icon={faWallet} size='xl' css={style.walletIcon} />
                  {t('myBusinessCard.addToWallet')}
                </Button>
              )}
            </div>
          </div>
        )}
        <EmployeeCardActionsModal
          shareContact={share}
          onCancel={() => setShareModal(false)}
          open={shareModal}
          actions={actions}
          businessCard={myBusinessCard}
        />
        <QrCodeModal
          onClose={() => setqrCodeModalOpen(false)}
          image={myBusinessCard.qrCode}
          open={qrCodeModalOpen}
          isBusinessCardPath={isBusinessCardPath}
          myBrandCode={myBusinessCard.brand.code}
        />
      </div>
    </div>
  );
};
