import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const modalContainer = css`
  && {
    max-width: 800px;
  }
  &.visible.transition {
    display: flex !important;
  }
  flex-direction: column;
  height: 85%;
`;

const header = css`
  text-align: center;
  &&& {
    background-color: var(--dcd-secondary-color-70);
    color: var(--dcd-gray-color);
    border: none;
  }
`;

const content = css`
  &&& {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex-grow: 1;
    @media (max-width: 768px) {
      padding: 1.5rem !important;
    }
  }
`;

const section = css`
  display: flex;
  column-gap: 20px;
`;

const sectionLabel = css`
  ${globalStyle.bodySmall};
  text-decoration: underline;
  padding: 8px 0;
  flex: 0 0 80px;
`;

const formField = css`
  .edit-component-container {
    width: 100%;
  }
`;

const sectionContent = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 10px;
`;

const longerSectionContent = css`
  ${sectionContent};
  flex-basis: 100%;
`;

const dropdown = css`
  width: 100%;
  &.ui.selection.dropdown {
    min-width: auto;
  }
`;

const discountField = css`
  max-width: 140px;
`;

const actions = css`
  display: flex;
  align-items: center;
  margin-top: auto;
  &&& {
    background-color: transparent;
  }
`;

const legendSection = css`
  ${globalStyle.bodySmall}
  flex-basis: 100%;
  text-align: left;
  && {
    font-size: 10px;
  }
`;

const confirmButton = css`
  ${globalStyle.secondaryButton};
  &.ui.button {
    margin-right: 0;
  }
`;

export default {
  modalContainer,
  header,
  content,
  section,
  sectionContent,
  longerSectionContent,
  sectionLabel,
  dropdown,
  actions,
  confirmButton,
  legendSection,
  formField,
  discountField,
};
