/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon, SemanticCOLORS } from 'semantic-ui-react';
import style from './trafficLightsCard.style';
import { StoreTypologyEnum } from '../../../../shared/models/storeTypology';

interface TrafficLightsCardProps {
  productCategory: string;
  storeTypology: StoreTypologyEnum | null;
  iconColor: string;
}

export function TrafficLightsCard({ storeTypology,iconColor, productCategory }: TrafficLightsCardProps): JSX.Element {
  return (
    <div css={style.card}>
      <div css={style.infoContainer}>
        <Icon name='shopping bag' size='big' />
        <div css={style.categoryAndStoreTypologyContainer}>
          <p css={style.productCategory}>{productCategory}</p>
          {storeTypology != null && <p css={style.productCategory}>{storeTypology}</p>}
        </div>
      </div>
      <div css={style.iconContainer}>
        <Icon
          name={iconColor === 'red' ? 'dont' : 'check circle outline'}
          size='large'
          color={iconColor as SemanticCOLORS}
        />
      </div>
    </div>
  );
}
