import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const bodyContainer = css`
  padding: 40px 30px 0 30px;
  align-self: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const labelAndInputContainer = css`
  &&&&& {
    margin: 0 0 8px 0;
    word-wrap: break-word;
  }
`;

const labelItem = css`
  &&&&& {
    ${globalStyle.bodySmall};
    background-color: transparent;
    padding: 8px 0;
    color: var(--dcd-gray-color);
  }
`;

const inputField = css`
  ${globalStyle.bodySmall};
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 10px;
`;

export default {
  bodyContainer,
  labelAndInputContainer,
  labelItem,
  inputField,
};
