import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import {
  SpecialCasesGoldCards,
  SpecialCasesGoldCardsUpdateRequest,
  SpecialCasesSilverCards,
  SpecialCasesSilverCardsUpdateRequest,
} from '../models/specialCases';
import { toastService } from '../../../../core/services/toastService';
import userApi from '../../usersManagement/utils/api';

interface Image {
  email: string;
  image: string;
}

interface SpecialCasesPayload {
  goldCards: SpecialCasesGoldCards;
  silverCards: SpecialCasesSilverCards;
}

interface SpecialCasesSliceState {
  data: {
    goldCards: SpecialCasesGoldCards;
    silverCards: SpecialCasesSilverCards;
    goldCardsImages: Image[];
    silverCardsImages: Image[];
  };
  isFetching: boolean;
  error: string;
  filter: string;
}

const initialState: SpecialCasesSliceState = {
  data: {
    goldCards: { percentage: 0, usersList: [], updateInfo: null },
    silverCards: { usersList: [], updateInfo: null },
    goldCardsImages: [],
    silverCardsImages: [],
  },
  isFetching: false,
  error: '',
  filter: '',
};

export const specialCasesSlice = createSlice({
  name: 'specialCasesSlice',
  initialState,
  reducers: {
    startFetch: (state: Draft<SpecialCasesSliceState>): SpecialCasesSliceState => ({
      ...state,
      isFetching: true,
    }),
    finishFetch: (
      state: Draft<SpecialCasesSliceState>,
      payloadAction: PayloadAction<SpecialCasesPayload>
    ): SpecialCasesSliceState => {
      return {
        ...state,
        isFetching: false,
        error: '',
        data: {
          ...state.data,
          goldCards: payloadAction.payload.goldCards,
          silverCards: payloadAction.payload.silverCards,
        },
      };
    },
    finishGoldCardsImageFetch: (
      state: Draft<SpecialCasesSliceState>,
      payloadAction: PayloadAction<Image[]>
    ): SpecialCasesSliceState => {
      return {
        ...state,
        data: { ...state.data, goldCardsImages: payloadAction.payload },
      };
    },
    finishSilverCardsImageFetch: (
      state: Draft<SpecialCasesSliceState>,
      payloadAction: PayloadAction<Image[]>
    ): SpecialCasesSliceState => {
      return {
        ...state,
        data: { ...state.data, silverCardsImages: payloadAction.payload },
      };
    },
    setSpecialCasesGoldCards: (
      state: Draft<SpecialCasesSliceState>,
      payloadAction: PayloadAction<SpecialCasesGoldCards>
    ): SpecialCasesSliceState => {
      return {
        ...state,
        data: { ...state.data, goldCards: payloadAction.payload },
      };
    },
    setSpecialCasesSilverCards: (
      state: Draft<SpecialCasesSliceState>,
      payloadAction: PayloadAction<SpecialCasesSilverCards>
    ): SpecialCasesSliceState => {
      return {
        ...state,
        data: { ...state.data, silverCards: payloadAction.payload },
      };
    },
    setFilter: (state: Draft<SpecialCasesSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      filter: payloadAction.payload,
    }),
    httpError: (
      state: Draft<SpecialCasesSliceState>,
      payloadAction: PayloadAction<string>
    ): SpecialCasesSliceState => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    reset: () => initialState,
  },
});

export const {
  startFetch,
  finishFetch,
  finishGoldCardsImageFetch,
  finishSilverCardsImageFetch,
  setSpecialCasesGoldCards,
  setSpecialCasesSilverCards,
  setFilter,
  httpError,
  reset,
} = specialCasesSlice.actions;

export const fetchSpecialCases = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const goldCards = await api.getSpecialCasesGoldCards();
    const silverCards = await api.getSpecialCasesSilverCards();
    dispatch(finishFetch({ goldCards, silverCards }));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const fetchGoldCardImageIfIsNotPresent =
  (email: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (getState().discountSpecialCases.data.goldCardsImages.some(i => i.email === email)) {
        return;
      }
      const image = await userApi.getUserImage(email);
      const images = [...getState().discountSpecialCases.data.goldCardsImages, { email, image }];

      dispatch(finishGoldCardsImageFetch(images));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const fetchSilverCardImageIfIsNotPresent =
  (email: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      if (getState().discountSpecialCases.data.silverCardsImages.some(i => i.email === email)) {
        return;
      }
      const image = await userApi.getUserImage(email);
      const images = [...getState().discountSpecialCases.data.silverCardsImages, { email, image }];

      dispatch(finishSilverCardsImageFetch(images));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateSpecialCasesGoldCards =
  (goldCards: SpecialCasesGoldCardsUpdateRequest): AppThunk =>
  async dispatch => {
    try {
      const result = await api.updateSpecialCasesGoldCards(goldCards);
      dispatch(setSpecialCasesGoldCards(result));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateSpecialCasesSilverCards =
  (silverCards: SpecialCasesSilverCardsUpdateRequest): AppThunk =>
  async dispatch => {
    try {
      const result = await api.updateSpecialCasesSilverCards(silverCards);
      dispatch(setSpecialCasesSilverCards(result));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectSpecialCasesGoldCards = (state: RootState): SpecialCasesGoldCards =>
  state.discountSpecialCases.data.goldCards;
export const selectSpecialCasesSilverCards = (state: RootState): SpecialCasesSilverCards =>
  state.discountSpecialCases.data.silverCards;

export const selectFilter = (state: RootState) => state.discountSpecialCases.filter;

export const selectIsFetching = (state: RootState): boolean => state.discountSpecialCases.isFetching;

export const selectSpecialCasesGoldCardsWithSortedUsers = createSelector(selectSpecialCasesGoldCards, specialCases => {
  return {
    percentage: specialCases.percentage,
    usersList: _.sortBy(specialCases.usersList, 'firstName'),
    updateInfo: specialCases.updateInfo,
  };
});

export const selectSpecialCasesSilverCardsWithSortedUsers = createSelector(
  selectSpecialCasesSilverCards,
  specialCases => {
    return {
      usersList: _.sortBy(specialCases.usersList, 'firstName'),
      updateInfo: specialCases.updateInfo,
    };
  }
);

export const selectSortedSpecialCasesGoldCards = createSelector(selectSpecialCasesGoldCards, specialCases =>
  _.sortBy(specialCases.usersList, 'firstName')
);

export const selectSortedSpecialCasesSilverCards = createSelector(selectSpecialCasesSilverCards, specialCases =>
  _.sortBy(specialCases.usersList, 'firstName')
);

export const selectFilteredAndSortedSpecialCasesGoldCards = createSelector(
  selectSortedSpecialCasesGoldCards,
  selectFilter,
  (list, filter) => {
    return list.filter(user => {
      return (
        user.email.toLowerCase().includes(filter.toLowerCase()) ||
        user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
        user.familyName.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }
);

export const selectFilteredAndSortedSpecialCasesSilverCards = createSelector(
  selectSortedSpecialCasesSilverCards,
  selectFilter,
  (list, filter) => {
    return list.filter(user => {
      return (
        user.email.toLowerCase().includes(filter.toLowerCase()) ||
        user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
        user.familyName.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }
);

export const selectSpecialCasesGoldCardsPercentage = createSelector(
  selectSpecialCasesGoldCards,
  specialCases => specialCases.percentage
);

export const selectSpecialCaseGoldCardImage = (email: string) => (state: RootState) => {
  return state.discountSpecialCases.data.goldCardsImages.find(i => i.email === email)?.image;
};

export const selectSpecialCaseSilverCardImage = (email: string) => (state: RootState) => {
  return state.discountSpecialCases.data.silverCardsImages.find(i => i.email === email)?.image;
};

export default specialCasesSlice.reducer;
