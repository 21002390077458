import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import { Brand } from '../../../../shared/models/brand';

interface FilterData {
  brands: Brand[] | undefined;
  employeeTypes: string[];
}
export interface Filters {
  brand: string;
  employeeType: string;
}
interface DiscountRuleSetsFiltersSliceState {
  data: FilterData;
  isFetching: boolean;
  error: string;
  filters: Filters;
}

const initialState: DiscountRuleSetsFiltersSliceState = {
  data: {
    brands: [],
    employeeTypes: [],
  },
  isFetching: false,
  error: '',
  filters: {
    brand: '',
    employeeType: '',
  },
};

export const discountRuleSetsFiltersSlice = createSlice({
  name: 'discountRuleSetsFilters',
  initialState,
  reducers: {
    startFetch: (state: Draft<DiscountRuleSetsFiltersSliceState>): DiscountRuleSetsFiltersSliceState => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchFilters: (
      state: Draft<DiscountRuleSetsFiltersSliceState>,
      payloadAction: PayloadAction<FilterData>
    ): DiscountRuleSetsFiltersSliceState => {
      return {
        ...state,
        data: payloadAction.payload,
      };
    },
    httpError: (
      state: Draft<DiscountRuleSetsFiltersSliceState>,
      payloadAction: PayloadAction<string>
    ): DiscountRuleSetsFiltersSliceState => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    setFilters: (
      state: Draft<DiscountRuleSetsFiltersSliceState>,
      action: PayloadAction<Filters>
    ): DiscountRuleSetsFiltersSliceState => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),

    resetFilters: (state: Draft<DiscountRuleSetsFiltersSliceState>): DiscountRuleSetsFiltersSliceState => {
      return { ...state, filters: { ...initialState.filters } };
    },
  },
});

export const { startFetch, httpError, setFilters, finishFetchFilters, resetFilters } =
  discountRuleSetsFiltersSlice.actions;

export const changeFilters =
  (filters: Filters): AppThunk =>
  async dispatch => {
    dispatch(setFilters(filters));
  };

export const clearFilters = (): AppThunk => async dispatch => {
  dispatch(resetFilters());
};

export const fetchFilters = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const brands: Brand[] | undefined = state().principal.data?.brands;
    const employeeTypes: string[] = state().employeeTypes.data as string[];
    const filters: FilterData = {
      brands,
      employeeTypes,
    };
    dispatch(finishFetchFilters(filters));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
  }
};

export const selectBrands = (state: RootState): Brand[] => state.discountRuleSetsFilters.data?.brands || [];
export const selectSortedBrands = createSelector(selectBrands, (brands: Brand[]): Brand[] =>
  _.sortBy(brands, ['description'])
);
export const selectEmployeeTypes = (state: RootState): string[] => state.discountRuleSetsFilters.data.employeeTypes;
export const selectSortedEmployeeTypes = createSelector(selectEmployeeTypes, (employeeTypes: string[]): string[] =>
  _.sortBy(employeeTypes)
);
export const selectFilters = (state: RootState): Filters => state.discountRuleSetsFilters.filters;

export default discountRuleSetsFiltersSlice.reducer;
