import { API_URL } from '../../../../constants';

const USER_ACTIVITIES = `${API_URL}/user-activities`;

const paths = {
  getUserActivities: (
    page: number,
    searchBoxFilter: string,
    brandFilter: string,
    activitySectionFilter: string,
    activityTypeFilter: string,
    dateRangeFilter: Array<Date | null>
  ): string =>
    `${USER_ACTIVITIES}?page=${page}&searchBoxFilter=${searchBoxFilter}&brandFilter=${brandFilter}&activitySectionFilter=${activitySectionFilter}&activityTypeFilter=${activityTypeFilter}&dateRangeFilter=${dateRangeFilter}`,
  getBrands: () => `${USER_ACTIVITIES}/brands`,
};

export default paths;
