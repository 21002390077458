/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { Button, Confirm, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import style from './userCard.style';
import { User } from '../models/user';
import { FormField } from '../../../../shared/components/FormField';

interface ReadonlyUserCardProps {
  user: User;
  onEdit: () => void;
  onDelete: (userId: string) => void;
}

export const ReadonlyUserCard = ({ user, onEdit, onDelete }: ReadonlyUserCardProps) => {
  const { t } = useTranslation();
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
  return (
    <div css={style.container}>
      <div css={style.iconContainer}>
        <Icon css={style.userIcon} name='user' />
      </div>

      <div css={style.contentContainer}>
        <div>
          <div css={style.emailFieldContainer}>
            <FormField label={t('users.email')} viewComponent={<span css={style.field}>{user.email}</span>} />
          </div>
        </div>
        <div>
          <div css={style.fieldContainer}>
            <FormField
              label={t('users.name')}
              viewComponent={
                <span css={style.field}>
                  {user.firstName} {user.familyName}
                </span>
              }
            />
          </div>
        </div>
        <div>
          <div css={style.fieldContainer}>
            <FormField
              label={t('users.roles')}
              viewComponent={
                <div css={style.multiFieldViewComponent}>
                  {user.roles
                    .map(r => t(`users.role.${r}`))
                    .sort((a, b) => (a > b ? 1 : -1))
                    .join(', ')}
                </div>
              }
            />
          </div>
        </div>
        <div>
          <div css={style.fieldContainer}>
            <FormField
              label={t('users.brands')}
              viewComponent={
                <div css={style.multiFieldViewComponent}>
                  {user.brands
                    .map(b => b.description)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .join(', ')}
                </div>
              }
            />
          </div>
        </div>
        <div css={style.buttonContainer}>
          <Button css={style.button} onClick={onEdit} content={t('edit')} icon='edit' labelPosition='left' />
          <Button
            css={style.button}
            onClick={() => setDeleteConfirmIsOpen(true)}
            content={t('delete')}
            icon='trash'
            labelPosition='left'
          />
          <Confirm
            css={style.confirm}
            open={deleteConfirmIsOpen}
            onCancel={() => setDeleteConfirmIsOpen(false)}
            onConfirm={() => onDelete(user.id)}
            size='mini'
          />
        </div>
      </div>
    </div>
  );
};
