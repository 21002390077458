/** @jsxImportSource @emotion/react */
import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import style from './pageNotFound.style';
import keringLogo from '../../../assets/keringLogo.png';

export const PageNotFound = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.container}>
      <Card fluid css={style.card}>
        <Card.Content css={style.cardContent}>
          <div css={style.contentContainer}>
            <Image src={keringLogo} wrapped />
            <h2>404</h2>
            <h3 css={style.title}>{t('pageNotFound')}</h3>
            <div>{t('pageNotFoundMessage')}</div>
            <div>
              {t('goBackTo')} <Link to='/'>{t('dcdHome')}</Link>
            </div>
            <div>
              {t('orGoBackTo')} <Link to='/business-card'>{t('bcHome')}</Link>
            </div>
          </div>
        </Card.Content>
      </Card>
    </div>
  );
};
