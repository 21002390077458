/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { fetchDiscountRuleSets, fetchQuotaLimitsByCategories } from '../store/salesAssistantPurchaseLimitSlice';
import { selectSAPurchaseLimitFilters } from '../store/salesAssistantPurchaseLimitFilterSlice';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';
import Header from '../../../../shared/components/Header';
import QuotaLimits, { GlobalLimitsCard } from './QuotaLimits';
import StoreDiscountRules from './StoreDiscountRules';
import PurchaseLimitFilters from './PurchaseLimitFilters';
import style from './purchaseLimitResult.style';
import { selectGlobalLimits } from '../../../admin/limits/stores/limitRulesSlice';

enum TABS {
  'QUOTA',
  'DISCOUNT',
}
interface PurchaseLimitResultProps {
  onBack: () => void;
}

export const PurchaseLimitResult = ({ onBack }: PurchaseLimitResultProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const salesAssistantSelectedStore = useAppSelector(selectSelectedStore);
  const globalLimits = useAppSelector(selectGlobalLimits);
  const filter = useAppSelector(selectSAPurchaseLimitFilters);
  const [activeTab, setActiveTab] = useState<TABS>(TABS.QUOTA);

  useEffect(() => {
    dispatch(
      fetchQuotaLimitsByCategories(
        salesAssistantSelectedStore?.brand.code || '',
        filter.country.code,
        filter.employeeType,
        salesAssistantSelectedStore?.countryCode || ''
      )
    );

    dispatch(
      fetchDiscountRuleSets(
        salesAssistantSelectedStore?.brand.code || '',
        salesAssistantSelectedStore?.countryCode || '',
        filter.employeeType
      )
    );
  }, [
    dispatch,
    filter,
    salesAssistantSelectedStore?.brand.code,
    salesAssistantSelectedStore?.countryCode,
    salesAssistantSelectedStore,
  ]);

  return (
    <div css={style.container}>
      <div css={style.headerContainer}>
        <Header title={t('salesAssistant.purchaseLimits.purchaseLimits')} onBackCustom={onBack} />
      </div>
      {salesAssistantSelectedStore != null && (
        <GlobalLimitsCard selectedStore={salesAssistantSelectedStore} globalLimits={globalLimits} />
      )}
      <div css={style.tabsContainer}>
        <div css={style.tabButton(activeTab === TABS.QUOTA)} onClick={() => setActiveTab(TABS.QUOTA)}>
          {t('salesAssistant.purchaseLimits.quota')}
        </div>
        <div css={style.tabButton(activeTab === TABS.DISCOUNT)} onClick={() => setActiveTab(TABS.DISCOUNT)}>
          {t('salesAssistant.purchaseLimits.discount')}
        </div>
      </div>
      <PurchaseLimitFilters hideCountry={activeTab === TABS.DISCOUNT} />
      {activeTab === TABS.QUOTA ? <QuotaLimits /> : <StoreDiscountRules />}
      <div />
    </div>
  );
};
