import { createSlice, Draft, PayloadAction, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { ProductStyleLine } from '../models/productStyleLine';

interface ProductStyleLinesSliceState {
  data: ProductStyleLine[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: ProductStyleLinesSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const productStyleLinesSlice = createSlice({
  name: 'productStyleLines',
  initialState,
  reducers: {
    startFetch: (state: Draft<ProductStyleLinesSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<ProductStyleLinesSliceState>, action: PayloadAction<ProductStyleLine[]>) => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    httpError: (state: Draft<ProductStyleLinesSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError } = productStyleLinesSlice.actions;

export const fetchProductStyleLines =
  (brandCode: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const productStyleLines = await api.getProductStyleLinesByBrand(brandCode);
      dispatch(finishFetch(productStyleLines));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectProductStyleLines = (state: RootState): string[] =>
  state.productStyleLines.data?.map(style => style.styleLine) || [];

export const selectSortedProductStyleLines = createSelector(
  selectProductStyleLines,
  (styleLines: string[]): string[] => {
    return _.sortBy(styleLines);
  }
);
export const selectIsFetchingStyleLines = (state: RootState): boolean => state.productStyleLines.isFetching;

export default productStyleLinesSlice.reducer;
