/** @jsxImportSource @emotion/react */
import React from 'react';
import { useZxing } from 'react-zxing';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './codeScanner.style';
import { APP_URL } from '../../../../constants';

export const CodeScanner = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const employeeCardPath = `/privilege-card/sales-assistant/card-search/`;
  const searchString = `${APP_URL}${employeeCardPath}`;

  const { ref } = useZxing({
    onResult(r) {
      const link = r.getText();
      const isValidQrCode = link.startsWith(searchString);

      if (isValidQrCode) {
        const employeeLink = link.substring(searchString.length);
        navigate(`${employeeCardPath}${employeeLink}`);
      }
    },
  });

  return (
    <div css={style.cameraContainer}>
      <div css={style.targetSquareMessage}>{t('codeScanner.scanCard')}</div>
      <video css={style.video} ref={ref} muted />
      <TargetSquare />
    </div>
  );
};

const TargetSquare = (): JSX.Element => {
  return (
    <div css={style.targetSquareContainer}>
      <div css={style.targetSquare} />
    </div>
  );
};
