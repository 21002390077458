import { css } from '@emotion/react';

const qrCodeModal = css`
  text-align: center;
`;

const logoContainer = css`
  position: absolute;
  background-color: var(--dcd-white-color);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 75px;
  width: 75px;
`;

export default {
  qrCodeModal,
  logoContainer,
};
