/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Button, Icon, Label, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  fetchQuotaLimitsAndDiscountRuleSets,
  PurchaseLimitStep,
  selectCurrentStep,
  selectMyDiscountRules,
  selectSelectedBrand,
  selectSortedAvailableCountries,
  setBrand,
  setStep,
} from '../store/employeePurchaseLimitSlice';
import { selectSortedBrands } from '../../../../shared/store/brandsSlice';
import { SearchBox } from '../../../../shared/components/SearchBox';
import Header from '../../../../shared/components/Header';
import { MoneyThresholdCard } from '../components/MoneyThresholdCard';
import { fetchAllLimits, selectGlobalLimits } from '../../../admin/limits/stores/limitRulesSlice';
import {
  fetchAllCurrentYearPurchases,
  selectIsFetchingMyCurrentYearPurchaseHistory,
  selectIsFetchingMyCurrentYearPurchaseHistoryInEuros,
  selectMyCurrentYearPurchaseHistory,
  selectMyCurrentYearPurchaseHistoryInEuros,
} from '../../purchaseHistory/store/purchaseHistorySlice';
import QuotaLimitList from '../components/QuotaLimitList';
import DiscountRuleList from '../components/DiscountRuleList';
import DiscountFilters from '../components/DiscountFilters';
import {
  changeCountryFilter,
  changeSearchBoxFilter,
  selectCountryFilter,
  selectSearchBoxFilter,
  setDefaultCountryFilter,
} from '../store/employeePurchaseLimitFiltersSlice';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import style from './purchaseLimit.style';

export const PurchaseLimit = () => {
  const { t } = useTranslation();

  const step = useAppSelector(selectCurrentStep);

  return (
    <>
      {step === PurchaseLimitStep.BrandSelection && (
        <div>
          <div css={style.headerContainer}>
            <Header title={t('purchaseLimits.purchaseLimit')} arrowBackEnable={false} />
          </div>
          <BrandSelection />
        </div>
      )}
      {step === PurchaseLimitStep.PurchaseLimitsConsulting && <PurchaseLimits />}
    </>
  );
};

const BrandSelection = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const brands = useAppSelector(selectSortedBrands);
  return (
    <div>
      <h3 css={style.brandSelectionTitle}>{t('purchaseLimits.selectHouse')}</h3>
      <div css={style.brandSelectionContainer}>
        {brands.map(brand => (
          <Button
            key={brand.code}
            css={style.brandButton}
            onClick={() => {
              dispatch(setBrand(brand));
              dispatch(setStep(PurchaseLimitStep.PurchaseLimitsConsulting));
            }}
            content={brand.description}
          />
        ))}
      </div>
    </div>
  );
};

const PurchaseLimits = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const globalLimits = useAppSelector(selectGlobalLimits);
  const purchaseHistoryInEmployeeCurrency = useAppSelector(selectMyCurrentYearPurchaseHistory);
  const purchaseHistoryInEuros = useAppSelector(selectMyCurrentYearPurchaseHistoryInEuros);
  const brand = useAppSelector(selectSelectedBrand);
  const myDiscountRules = useAppSelector(selectMyDiscountRules);
  const principal = useAppSelector(selectPrincipal);
  const availableCountries = useAppSelector(selectSortedAvailableCountries);
  const searchBoxFilter = useAppSelector(selectSearchBoxFilter);
  const isFetchingPurchaseHistoryInEuros = useAppSelector(selectIsFetchingMyCurrentYearPurchaseHistoryInEuros);
  const isFetchingPurchaseHistoryInEmployeeCurrency = useAppSelector(selectIsFetchingMyCurrentYearPurchaseHistory);

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(changeSearchBoxFilter(value));
  }, 500);
  const [showDiscountFilters, setShowDiscountFilters] = useState(false);

  enum Tab {
    Quota,
    Discount,
  }
  const [activeTab, setActiveTab] = useState(Tab.Quota);

  useEffect(() => {
    if (brand != null) {
      dispatch(fetchQuotaLimitsAndDiscountRuleSets(brand.code));
    }
  }, [brand, dispatch]);

  useEffect(() => {
    dispatch(fetchAllLimits(principal?.countryCode));
    dispatch(fetchAllCurrentYearPurchases());
  }, [dispatch, principal?.countryCode]);

  useEffect(() => {
    if (principal != null && myDiscountRules != null) {
      const principalCountry = availableCountries.find(c => c.code === principal.countryCode);
      if (principalCountry != null) {
        dispatch(changeCountryFilter(principalCountry));
      } else {
        dispatch(setDefaultCountryFilter());
      }
    }
  }, [availableCountries, dispatch, myDiscountRules, principal]);

  return (
    <div css={style.container}>
      <div css={style.headerContainer}>
        <Header
          title={t('purchaseLimits.purchaseLimit')}
          onBackCustom={() => {
            dispatch(setStep(PurchaseLimitStep.BrandSelection));
          }}
        />
      </div>

      <div css={style.moneyThresholdCardContainer}>
        <MoneyThresholdCard
          globalLimits={globalLimits}
          purchaseHistoryInEmployeeCurrency={purchaseHistoryInEmployeeCurrency}
          purchaseHistoryInEuros={purchaseHistoryInEuros}
        />
      </div>
      <div css={style.tabsContainer}>
        <div css={style.tabButton(activeTab === Tab.Quota)} onClick={() => setActiveTab(Tab.Quota)}>
          {t('purchaseLimits.remainingQuota')}
        </div>
        <div css={style.tabButton(activeTab === Tab.Discount)} onClick={() => setActiveTab(Tab.Discount)}>
          {t('purchaseLimits.purchaseDiscount')}
        </div>
      </div>
      <div css={style.filtersContainer}>
        <SearchBox
          key='limitSearch'
          defaultValue={searchBoxFilter}
          onChange={value => debounced(value)}
          placeholder={t('purchaseLimits.search')}
        />
        {activeTab === Tab.Discount && (
          <Icon
            name='filter'
            size='large'
            link
            onClick={() => {
              setShowDiscountFilters(true);
            }}
          />
        )}
      </div>
      {showDiscountFilters && (
        <DiscountFilters
          onBack={() => {
            setShowDiscountFilters(false);
          }}
        />
      )}
      {activeTab === Tab.Discount && <DiscountFiltersBadges />}

      {isFetchingPurchaseHistoryInEmployeeCurrency || isFetchingPurchaseHistoryInEuros ? (
        <div css={style.loaderContainer}>
          <Loader size='medium' active />
        </div>
      ) : activeTab === Tab.Quota ? (
        <QuotaLimitList />
      ) : (
        <DiscountRuleList />
      )}
    </div>
  );
};

const DiscountFiltersBadges = () => {
  const countryFilter = useAppSelector(selectCountryFilter);
  return (
    <div css={style.badgesContainer}>
      {countryFilter != null && <Label css={style.filterBadge}>{countryFilter.description}</Label>}
    </div>
  );
};
