/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Brand } from '../../../../shared/models/brand';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  changeFilters,
  Filters,
  selectEmployeeTypes,
  selectFilters,
  selectSortedBrands,
  fetchFilters,
} from '../store/discountRuleSetsFiltersSlice';
import { RadioGroup, RadioItem } from '../../../../shared/components/RadioGroup';
import { ModalPage } from '../../../../shared/components/ModalPage';
import style from './discountRuleSetsFilters.style';

export const DiscountRuleSetsFilters = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);
  const [brandFilter, setBrandFilter] = useState(filters.brand);
  const [employeeTypeFilter, setEmployeeTypeFilter] = useState(filters.employeeType);
  const [open, setOpen] = React.useState(false);
  dispatch(fetchFilters());
  useEffect(() => {
    setEmployeeTypeFilter(filters.employeeType);
    setBrandFilter(filters.brand);
  }, [filters]);

  const applyFilters = () => {
    const filtersToApply: Filters = {
      brand: brandFilter,
      employeeType: employeeTypeFilter,
    };
    dispatch(changeFilters(filtersToApply));
    setOpen(false);
  };

  return (
    <>
      <Icon
        name='filter'
        size='large'
        link
        onClick={() => {
          setOpen(true);
        }}
      />
      {open && (
        <ModalPage
          onClose={() => {
            setOpen(false);
          }}
          title={t('discounts.ruleset.filters')}>
          <div css={style.container}>
            <div css={style.filters}>
              <BrandRadioGroup value={brandFilter} onClick={setBrandFilter} />
              <EmployeeTypeRadioGroup value={employeeTypeFilter} onClick={setEmployeeTypeFilter} />
            </div>
            <Button css={style.applyButton} onClick={applyFilters}>
              {t('discounts.ruleset.applyFilters')}
            </Button>
          </div>
        </ModalPage>
      )}
    </>
  );
};

interface BrandRadioGroupProps {
  onClick: (code: string) => void;
  value: string;
}

const BrandRadioGroup = ({ value, onClick }: BrandRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const principalBrands: Brand[] = useAppSelector(selectSortedBrands);

  const brands: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('discounts.ruleset.allBrands') },
      ...principalBrands.map(b => ({ code: b.description, description: b.description })),
    ],
    [principalBrands, t]
  );

  return <RadioGroup label={t('discounts.ruleset.filterByBrand')} items={brands} value={value} onClick={onClick} />;
};

interface EmployeeTypeRadioGroupProps {
  onClick: (code: string) => void;
  value: string;
}

const EmployeeTypeRadioGroup = ({ value, onClick }: EmployeeTypeRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableEmployeeTypes = useAppSelector(selectEmployeeTypes);

  const employeeTypes: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('discounts.ruleset.allEmployeeTypes') },
      ...availableEmployeeTypes.map(e => ({ code: e, description: t(`discounts.ruleset.employeeTypes.${e}`) })),
    ],
    [availableEmployeeTypes, t]
  );

  return (
    <RadioGroup
      label={t('discounts.ruleset.filterByEmployeeType')}
      items={employeeTypes}
      value={value}
      onClick={onClick}
    />
  );
};
