import { css } from '@emotion/react';
import globalStyle from '../global.style';

const filterSection = css`
  padding-bottom: 28px;
`;
const radioGroupLabel = css`
  ${globalStyle.bodyNormal};
  padding-bottom: 28px;
`;
const radioGroup = css`
  padding-left: 10px;
`;

const radioButton = css`
  &.ui.radio.checkbox input:checked ~ .box:before,
  &.ui.radio.checkbox input:checked ~ label:before {
    background-color: var(--dcd-secondary-color-100);
    border-color: var(--dcd-secondary-color-100);
  }
  &.ui.radio.checkbox input:checked ~ .box:after,
  &.ui.radio.checkbox input:checked ~ label:after {
    background-color: var(--dcd-white-color);
  }
`;

export default {
  filterSection,
  radioGroupLabel,
  radioGroup,
  radioButton,
};
