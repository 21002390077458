import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const card = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 18px;
  margin: 3px;
`;

const content = css`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const globalLimitTitle = css`
  ${globalStyle.bodySmall};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  && {
    font-weight: 500;
  }
`;

const threshold = css`
  padding-left: 10px;
  text-align: right;
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 32px;
`;

const thresholdAmount = css`
  width: 100%;
  margin: 0;
`;

const thresholdAmountsContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;
const spentAmount = css`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
`;

const currency = css`
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  align-self: center;
`;

const spendableAmount = css`
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  align-self: center;
`;

const subtitle = css`
  ${globalStyle.bodySmall};
  && {
    line-height: 14px;
  }
`;

export default {
  card,
  threshold,
  content,
  thresholdAmount,
  globalLimitTitle,
  thresholdAmountsContainer,
  subtitle,
  spentAmount,
  spendableAmount,
  currency,
};
