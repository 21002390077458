import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import i18n from '../../../../i18n';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { Brand } from '../../../../shared/models/brand';

export type MonthCode =
  | ''
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | 'CUSTOM';

interface Month {
  code: MonthCode;
  description: string;
}

export const generateMonths = (language: string): Month[] => {
  return [
    { code: '', description: t('purchaseHistory.months.wholeYear') },
    { code: '01', description: t('purchaseHistory.months.january') },
    { code: '02', description: t('purchaseHistory.months.february') },
    { code: '03', description: t('purchaseHistory.months.march') },
    { code: '04', description: t('purchaseHistory.months.april') },
    { code: '05', description: t('purchaseHistory.months.may') },
    { code: '06', description: t('purchaseHistory.months.june') },
    { code: '07', description: t('purchaseHistory.months.july') },
    { code: '08', description: t('purchaseHistory.months.august') },
    { code: '09', description: t('purchaseHistory.months.september') },
    { code: '10', description: t('purchaseHistory.months.october') },
    { code: '11', description: t('purchaseHistory.months.november') },
    { code: '12', description: t('purchaseHistory.months.december') },
    { code: 'CUSTOM', description: '' },
  ];
};
export const getMonthDescription = (code: string): string =>
  generateMonths(i18n.language).find(m => m.code === code)?.description || '';

interface FilterData {
  refundedStatus: string[];
  brands: Brand[];
  stores: string[];
  categories: string[];
  months: Month[];
  dateRange: Array<Date | null>;
}
export interface Filters {
  refundedStatusName: string;
  brand: Brand;
  storeName: string;
  category: string;
  monthCode: MonthCode;
  dateRange: Array<Date | null>;
}
interface PurchaseHistoryFiltersSliceState {
  data: FilterData;
  isFetching: boolean;
  error: string;
  searchBoxFilter: string;
  filters: Filters;
}

const initialState: PurchaseHistoryFiltersSliceState = {
  data: {
    refundedStatus: [],
    brands: [],
    stores: [],
    categories: [],
    months: [],
    dateRange: [null, null],
  },
  isFetching: false,
  error: '',
  searchBoxFilter: '',
  filters: {
    refundedStatusName: '',
    brand: {
      code: '',
      description: '',
    },
    category: '',
    storeName: '',
    monthCode: '',
    dateRange: [null, null],
  },
};

export const purchaseHistoryFiltersSlice = createSlice({
  name: 'purchaseHistoryFilters',
  initialState,
  reducers: {
    startFetch: (state: Draft<PurchaseHistoryFiltersSliceState>): PurchaseHistoryFiltersSliceState => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchFilters: (
      state: Draft<PurchaseHistoryFiltersSliceState>,
      payloadAction: PayloadAction<FilterData>
    ): PurchaseHistoryFiltersSliceState => {
      return {
        ...state,
        data: payloadAction.payload,
      };
    },
    httpError: (
      state: Draft<PurchaseHistoryFiltersSliceState>,
      payloadAction: PayloadAction<string>
    ): PurchaseHistoryFiltersSliceState => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    setSearchBoxFilter: (
      state: Draft<PurchaseHistoryFiltersSliceState>,
      action: PayloadAction<string>
    ): PurchaseHistoryFiltersSliceState => ({
      ...state,
      searchBoxFilter: action.payload,
    }),
    setFilters: (
      state: Draft<PurchaseHistoryFiltersSliceState>,
      action: PayloadAction<Filters>
    ): PurchaseHistoryFiltersSliceState => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),

    resetFilters: (state: Draft<PurchaseHistoryFiltersSliceState>): PurchaseHistoryFiltersSliceState => {
      return { ...state, filters: { ...initialState.filters } };
    },
  },
});

export const { startFetch, httpError, setSearchBoxFilter, setFilters, finishFetchFilters, resetFilters } =
  purchaseHistoryFiltersSlice.actions;

export const changeSearchBoxFilter =
  (filterValue: string): AppThunk =>
  async dispatch => {
    dispatch(setSearchBoxFilter(filterValue));
  };

export const changeFilters =
  (filters: Filters): AppThunk =>
  async dispatch => {
    dispatch(setFilters(filters));
  };

export const clearFilters = (): AppThunk => async dispatch => {
  dispatch(resetFilters());
};

export const fetchPurchasesFilters = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const refundedStatus: string[] = ['Refunded'];
    const brands: Brand[] = await api.getMyPurchasesBrands();
    const stores: string[] = await api.getMyPurchasesStores();
    const categories: string[] = await api.getMyPurchasesCategories();
    const filters: FilterData = {
      refundedStatus,
      brands,
      stores,
      categories,
      months: generateMonths(i18n.language),
      dateRange: [null, null],
    };
    dispatch(finishFetchFilters(filters));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
  }
};

export const selectPurchasesRefundedStatus = (state: RootState): string[] =>
  state.purchaseHistoryFilters.data.refundedStatus;
export const selectPurchasesBrands = (state: RootState): Brand[] => state.purchaseHistoryFilters.data.brands;
export const selectPurchasesStores = (state: RootState): string[] => state.purchaseHistoryFilters.data.stores;
export const selectPurchasesCategories = (state: RootState): string[] => state.purchaseHistoryFilters.data.categories;
export const selectPurchasesMonths = (state: RootState): Month[] => state.purchaseHistoryFilters.data.months;

export const selectSearchBoxFilter = (state: RootState): string => state.purchaseHistoryFilters.searchBoxFilter;

export const selectGeneralFilters = (state: RootState): Filters => state.purchaseHistoryFilters.filters;

export default purchaseHistoryFiltersSlice.reducer;
