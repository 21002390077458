import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import { Country } from '../../../../shared/models/country';
import { Brand } from '../../../../shared/models/brand';
import { storeTypologiesArray } from '../../../../shared/models/storeTypology';

interface FilterData {
  countries: Country[];
  employeeTypes: string[];
  brands: Brand[] | undefined;
  storeTypologies: string[];
}
export interface Filters {
  country: string;
  employeeType: string;
  brand: string;
  storeTypology: string;
}
interface LimitRulesFiltersSliceState {
  data: FilterData;
  isFetching: boolean;
  error: string;
  filters: Filters;
}

const initialState: LimitRulesFiltersSliceState = {
  data: {
    countries: [],
    employeeTypes: [],
    brands: [],
    storeTypologies: [],
  },
  isFetching: false,
  error: '',
  filters: {
    country: '',
    employeeType: '',
    brand: '',
    storeTypology: '',
  },
};

export const limitRulesFiltersSlice = createSlice({
  name: 'limitRulesFilters',
  initialState,
  reducers: {
    startFetch: (state: Draft<LimitRulesFiltersSliceState>): LimitRulesFiltersSliceState => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchFilters: (
      state: Draft<LimitRulesFiltersSliceState>,
      payloadAction: PayloadAction<FilterData>
    ): LimitRulesFiltersSliceState => {
      return {
        ...state,
        data: payloadAction.payload,
      };
    },
    httpError: (
      state: Draft<LimitRulesFiltersSliceState>,
      payloadAction: PayloadAction<string>
    ): LimitRulesFiltersSliceState => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    setFilters: (
      state: Draft<LimitRulesFiltersSliceState>,
      action: PayloadAction<Filters>
    ): LimitRulesFiltersSliceState => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),

    resetFilters: (state: Draft<LimitRulesFiltersSliceState>): LimitRulesFiltersSliceState => {
      return { ...state, filters: { ...initialState.filters } };
    },
  },
});

export const { startFetch, httpError, setFilters, finishFetchFilters, resetFilters } = limitRulesFiltersSlice.actions;

export const changeFilters =
  (filters: Filters): AppThunk =>
  async dispatch => {
    dispatch(setFilters(filters));
  };

export const fetchFilters = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const countriesArray: Country[] = [];
    state().limitRules.limitRules.forEach(l => {
      if (l.constraints.countries != null) {
        countriesArray.push(...l.constraints.countries);
      }
    });

    const countries: Country[] = _.uniqBy(countriesArray, c => c.code);
    const employeeTypes: string[] = state().employeeTypes.data as string[];
    const brands: Brand[] | undefined = state().principal.data?.brands;
    const storeTypologies: string[] = storeTypologiesArray
    const filters: FilterData = {
      countries,
      employeeTypes,
      brands,
      storeTypologies
    };
    dispatch(finishFetchFilters(filters));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
  }
};

export const selectCountries = (state: RootState): Country[] => state.limitRulesFilters.data.countries;
export const selectSortedCountries = createSelector(selectCountries, (countries): Country[] =>
  _.sortBy(countries, ['description'])
);
export const selectEmployeeTypes = (state: RootState): string[] => state.limitRulesFilters.data.employeeTypes;
export const selectBrands = (state: RootState): Brand[] => state.limitRulesFilters.data?.brands || [];
export const selectSortedBrands = createSelector(selectBrands, (brands: Brand[]): Brand[] =>
  _.sortBy(brands, ['description'])
);
export const selectStoreTypologies = (state: RootState): string[] => state.limitRulesFilters.data.storeTypologies;
export const selectFilters = (state: RootState): Filters => state.limitRulesFilters.filters;

export default limitRulesFiltersSlice.reducer;
