import { css } from '@emotion/react';
import globalStyle from '../global.style';

const modalContainer = css`
  ${globalStyle.bodyNormal};
  height: 90%;
  &.visible.transition {
    display: flex !important;
  }
  &.ui.modal .scrolling.content {
    max-height: none;
  }
  &.ui.modal .actions > .button {
    margin-left: 0;
  }
  flex-direction: column;
  @media (max-width: 800px) {
    &&&&& {
      width: 100%;
      height: 100%;
      border-radius: 0;
      top: 0;
      margin: 0;
    }
  }
`;

const modalHeader = css`
  &&& {
    background-color: var(--dcd-secondary-color-40);
    padding: 20px !important;
    border: none;
  }
`;

const modalTitle = css`
  ${globalStyle.bodyNormal};
  text-align: center;
  margin-left: 45px;
  margin-right: 45px;
  &&& {
    line-height: 20px;
  }
`;

const bodyTitle = css`
  ${globalStyle.bodyMedium};
  text-align: center;
  &&& {
    line-height: 20px;
    font-weight: 600;
  }
  @media (max-width: 800px) {
    &&&&& {
      text-align: start;
    }
  }
`;

const listSubtitle = css`
  margin-left: 15px;
  margin-bottom: 10px;
  padding-left: 15px;
  ${globalStyle.bodySmall};
  &&& {
    line-height: 20px;
    font-weight: 600;
  }
`;

const subTitle = css`
  ${globalStyle.bodySmall};
  &&& {
    line-height: 20px;
    font-weight: 600;
  }
`;

const list = css`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const listElement = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const modalDescription = css`
  padding: 0 !important;
`;

const bodyContainer = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const sectionContainer = css`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;
    flex-basis: 100%;
  }
`;

const pageParagraphs = css`
  ${globalStyle.bodyNormal}
`;

const modalContent = css`
  &&&&& {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex-grow: 1;
    @media (max-width: 768px) {
      padding: 1.5rem !important;
    }
  }
`;

const modalActions = css`
  &&& {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 25px 20px;
    margin-top: auto;
  }
`;

const declinePoliciesBtn = css`
  ${globalStyle.secondaryButton};
  &&&& {
    margin-left: 3.5px;
  }
  flex: 1;
`;

const acceptPoliciesBtn = css`
  ${globalStyle.primaryButton};
  flex: 1;
`;

const downloadPdfText = css`
  background: none !important;
  padding: 0 !important;
  text-align: start !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #4183c4 !important;
  &&&& {
    :hover {
      text-decoration: underline;
    }
  }
`;

const disclaimer = css`
  ${globalStyle.bodyNormal}
  &&& {
    font-weight: 600;
  }
`;

export default {
  modalContainer,
  modalHeader,
  modalTitle,
  bodyTitle,
  listSubtitle,
  subTitle,
  modalDescription,
  modalContent,
  bodyContainer,
  sectionContainer,
  pageParagraphs,
  modalActions,
  acceptPoliciesBtn,
  declinePoliciesBtn,
  downloadPdfText,
  list,
  listElement,
  disclaimer,
};
