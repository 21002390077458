import KeringLogoBC from '../../assets/brands/businessCardBrands/kering/blackKeringLogo-cropped.svg';
import BalenciagaLogoBC from '../../assets/brands/businessCardBrands/balenciaga/blackBalenciagaLogo.svg';
import AMQLogoBC from '../../assets/brands/businessCardBrands/alexander-mcqueen/blackAlexanderMcQueenLogo.svg';
import BrioniLogoBC from '../../assets/brands/businessCardBrands/brioni/blackBrioniLogo.svg';
import BVLogoBC from '../../assets/brands/businessCardBrands/bottega-veneta/BV Single-line logo-01 2 black letters no background.svg';
import DodoLogoBC from '../../assets/brands/businessCardBrands/dodo/Logo DoDo black - tagline.svg';
import PomellatoLogoBC from '../../assets/brands/businessCardBrands/pomellato/Logo Pomellato black - tagline.svg';
import BoucheronLogoBC from '../../assets/brands/businessCardBrands/boucheron/Boucheron - Logo black.svg';
import QeelinLogoBC from '../../assets/brands/businessCardBrands/qeelin/Qeelin Logo black letters transparent bgd.svg';
import SaintLaurentLogoBC from '../../assets/brands/businessCardBrands/saint-laurent/blackSaintLaurentLogo.svg';
import KeringEyewearLogoBC from '../../assets/brands/businessCardBrands/kering-eyewear/Kering_Eyewear_black logo on transparent (1).svg';

export const getBusinessCardLogo = (code: string): string => {
  switch (code) {
    case 'KERING':
    case 'CORPORATE':
      return KeringLogoBC;
    case 'KERING_EYEWEAR':
      return KeringEyewearLogoBC; // specific black logo missing
    case 'ALEXANDER_MCQUEEN':
      return AMQLogoBC; // waiting for Business to confirm Logo
    case 'BALENCIAGA':
      return BalenciagaLogoBC; // logo used for Privilege Card
    case 'BOTTEGA_VENETA':
      return BVLogoBC;
    case 'BRIONI':
      return BrioniLogoBC;
    case 'BOUCHERON':
      return BoucheronLogoBC;
    case 'YVES_SAINT_LAURENT':
      return SaintLaurentLogoBC;
    case 'DODO':
      return DodoLogoBC;
    case 'POMELLATO':
      return PomellatoLogoBC;
    case 'QEELIN':
      return QeelinLogoBC;
    default:
      return KeringLogoBC;
  }
};

export const businessCardLogos = [
  KeringLogoBC,
  KeringEyewearLogoBC,
  AMQLogoBC,
  BalenciagaLogoBC,
  BVLogoBC,
  BrioniLogoBC,
  BoucheronLogoBC,
  SaintLaurentLogoBC,
  DodoLogoBC,
  PomellatoLogoBC,
  QeelinLogoBC,
];
