/** @jsxImportSource @emotion/react */

import React from 'react';
import { Button, Icon, IconGroup, List, ListContent, ListDescription, ListIcon, ListItem } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { BlockedEmployee, isEmployeeBlocked, isEmployeeUnblocked } from '../models/blockedEmployee';
import style from './blockedEmployeeCard.style';
import { FormField } from '../../../../shared/components/FormField';
import { CreationData } from '../../../../shared/components/CreationData';

interface BlockedEmployeeCardProps {
  employee: BlockedEmployee;
}

export const BlockedEmployeeCard = ({ employee }: BlockedEmployeeCardProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isBlocked = isEmployeeBlocked(employee);
  const currentInterval = isEmployeeUnblocked(employee)
    ? null
    : employee.blockedIntervals[employee.blockedIntervals.length - 1];
  const history = isEmployeeUnblocked(employee)
    ? [...employee.blockedIntervals].reverse()
    : employee.blockedIntervals.slice(0, -1).reverse();

  return (
    <div css={style.container}>
      <div css={style.iconContainer}>
        <IconGroup css={style.userIcon}>
          <Icon name='user' />
          <Icon corner='bottom right' name={isBlocked ? 'lock' : 'unlock'} />
        </IconGroup>
      </div>

      <div css={style.contentContainer}>
        <div css={style.rowFieldsContainer}>
          <div css={style.emailFieldContainer}>
            <FormField
              label={t('blockedEmployees.email')}
              viewComponent={<span css={style.field}>{employee.email}</span>}
            />
          </div>
          <div css={style.fieldContainer}>
            <FormField
              label={t('blockedEmployees.name')}
              viewComponent={
                <div css={style.field}>
                  {employee.firstName} {employee.familyName}
                </div>
              }
            />
          </div>
        </div>
        <div>
          {currentInterval != null && (
            <div css={style.fieldContainer}>
              <FormField
                label={t('blockedEmployees.blockedInterval')}
                viewComponent={
                  <div>
                    <div key={currentInterval.startDate.toString()}>
                      {`${moment(currentInterval.startDate).format('YYYY-MM-DD')}, ${
                        currentInterval.endDate != null
                          ? moment(currentInterval.endDate).format('YYYY-MM-DD')
                          : t('blockedEmployees.ongoing')
                      }`}
                    </div>
                  </div>
                }
              />
            </div>
          )}

          {history.length > 0 && (
            <div css={style.fieldContainer}>
              <FormField
                label={t('blockedEmployees.blockedIntervals')}
                viewComponent={
                  <List horizontal css={style.historyList}>
                    {history.map(interval => (
                      <ListItem key={interval.startDate.toString()} css={style.historyItem}>
                        <ListIcon name='clock' />
                        <ListContent>
                          <ListDescription>
                            {`${moment(interval.startDate).format('YYYY-MM-DD')}, ${
                              interval.endDate != null
                                ? moment(interval.endDate).format('YYYY-MM-DD')
                                : t('blockedEmployees.ongoing')
                            }`}
                          </ListDescription>
                        </ListContent>
                      </ListItem>
                    ))}
                  </List>
                }
              />
            </div>
          )}
        </div>
        <div css={style.buttonContainer}>
          <Button
            css={style.button}
            onClick={() => navigate(`/privilege-card/admin/blocked-employees/detail/${employee.id}`)}
            content={t('edit')}
            icon='pencil'
            labelPosition='left'
          />
        </div>
        <div css={style.creationInfoContainer}>
          <CreationData creationInfo={employee.creationInfo} updateInfo={employee.updateInfo} />
        </div>
      </div>
    </div>
  );
};
