import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../core/store';
import api from '../utils/api';

interface EmployeeTypesSliceState {
  data: string[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: EmployeeTypesSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const employeeTypesSlice = createSlice({
  name: 'employeeTypes',
  initialState,
  reducers: {
    startFetch: (state: Draft<EmployeeTypesSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<EmployeeTypesSliceState>, action: PayloadAction<string[]>): EmployeeTypesSliceState => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    httpError: (state: Draft<EmployeeTypesSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError } = employeeTypesSlice.actions;

export const fetchEmployeeTypes = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const employeeTypes = await api.getEmployeeTypes();
    employeeTypes.sort();
    dispatch(finishFetch(employeeTypes));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const selectEmployeeTypes = (state: RootState): string[] | null => state.employeeTypes.data;

export default employeeTypesSlice.reducer;
