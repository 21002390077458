import axios from 'axios';
import paths from './paths';
import {
  LimitRuleCreationRequest,
  LimitRuleUpdateRequest,
  toLimitRule,
  GlobalLimits,
  toGlobalLimits,
  GlobalLimitsUpdateRequest,
  LimitRule,
} from '../models/limitRule';
import { Country, toCountry } from '../../../../shared/models/country';
import { ProductCategory } from '../../../../shared/models/productCategory';
import { ProductSku } from '../models/productSku';
import { ProductStyleLine } from '../models/productStyleLine';
import { NonEligibleConstraint } from '../models/nonEligibleConstraint';

const api = {
  getGlobalLimits: async (): Promise<GlobalLimits> => {
    const response = await axios.get(paths.getGlobalLimits());
    return toGlobalLimits(response.data);
  },
  getGlobalLimitsConverted: async (currencyCountryCode: string): Promise<GlobalLimits> => {
    const response = await axios.get(paths.getGlobalLimitsConverted(currencyCountryCode));
    return toGlobalLimits(response.data);
  },
  updateGlobalLimits: async (request: GlobalLimitsUpdateRequest): Promise<GlobalLimits> => {
    const response = await axios.put(paths.updateGlobalLimits(), request);
    return toGlobalLimits(response.data);
  },
  getLimitRules: async (): Promise<LimitRule[]> => {
    const response = await axios.get(paths.getLimitRules());
    return response.data.map(toLimitRule);
  },
  getLimitRule: async (id: string): Promise<LimitRule> => {
    const response = await axios.get(paths.getLimitRule(id));
    return toLimitRule(response.data);
  },
  deleteLimitRule: async (id: string) => {
    const response = await axios.delete(paths.deleteLimitRule(id));
    return response.data;
  },
  createRule: async (request: LimitRuleCreationRequest): Promise<LimitRule> => {
    const response = await axios.post(paths.postLimitRule(), request);
    return toLimitRule(response.data);
  },
  updateRule: async (id: string, request: LimitRuleUpdateRequest): Promise<LimitRule> => {
    const response = await axios.put(paths.updateLimitRule(id), request);
    return toLimitRule(response.data);
  },
  getCountriesByBrand: async (brandCode: string): Promise<Country[]> => {
    const response = await axios.get(paths.getCountries(brandCode));
    return response.data.map(toCountry);
  },
  getProductCategoriesByBrand: async (brandCode: string): Promise<ProductCategory[]> => {
    const response = await axios.get(paths.getProductCategoriesByBrand(brandCode));
    return response.data;
  },
  getProductSkusByBrand: async (brandCode: string): Promise<ProductSku[]> => {
    const response = await axios.get(paths.getProductSkusByBrand(brandCode));
    return response.data;
  },
  getProductStyleLinesByBrand: async (brandCode: string): Promise<ProductStyleLine[]> => {
    const response = await axios.get(paths.getProductStyleLinesByBrand(brandCode));
    return response.data;
  },
  getNonEligibleConstraintsByBrand: async (brandCode: string): Promise<NonEligibleConstraint[]> => {
    const response = await axios.get(paths.getNonEligibleConstraintsByBrand(brandCode));
    return response.data;
  },
  downloadNonEligibleProductsBulkUploadTemplate: async (
    nonEligibleConstraint: NonEligibleConstraint
  ): Promise<Blob> => {
    const response = await axios.get(paths.downloadNonEligibleProductsBulkUploadTemplate(nonEligibleConstraint), {
      responseType: 'blob',
    });
    return response.data;
  },
};

export default api;
