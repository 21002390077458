import axios from 'axios';
import paths from './paths';
import {
  DiscountRuleSet,
  DiscountRuleSetCreationRequest,
  DiscountRuleSetUpdateRequest,
  toDiscountRuleSet,
} from '../models/discountRuleSet';
import { Country, toCountry } from '../../../../shared/models/country';
import { ProductCategory } from '../../../../shared/models/productCategory';
import {
  SpecialCasesGoldCards,
  SpecialCasesGoldCardsUpdateRequest,
  SpecialCasesSilverCards,
  SpecialCasesSilverCardsUpdateRequest,
  toSpecialCasesGoldCards,
  toSpecialCasesSilverCards,
} from '../models/specialCases';

const api = {
  getDiscountRuleSets: async (): Promise<DiscountRuleSet[]> => {
    const response = await axios.get(paths.getDiscountRuleSets());
    return response.data.map(toDiscountRuleSet);
  },
  getDiscountRuleSet: async (id: string): Promise<DiscountRuleSet> => {
    const response = await axios.get(paths.getDiscountRuleSet(id));
    return toDiscountRuleSet(response.data);
  },
  createDiscountRuleSet: async (request: DiscountRuleSetCreationRequest): Promise<DiscountRuleSet> => {
    const response = await axios.post(paths.createDiscountRuleSet(), request);
    return toDiscountRuleSet(response.data);
  },
  updateDiscountRuleSet: async (id: string, request: DiscountRuleSetUpdateRequest): Promise<DiscountRuleSet> => {
    const response = await axios.put(paths.updateDiscountRuleSet(id), request);
    return toDiscountRuleSet(response.data);
  },
  deleteDiscountRuleSet: async (id: string): Promise<string> => {
    const response = await axios.delete(paths.deleteDiscountRuleSet(id));
    return response.data;
  },
  getCountriesByBrand: async (brandCode: string): Promise<Country[]> => {
    const response = await axios.get(paths.getCountries(brandCode));
    return response.data.map(toCountry);
  },
  getProductCategoriesByBrand: async (brandCode: string): Promise<ProductCategory[]> => {
    const response = await axios.get(paths.getProductCategoriesByBrand(brandCode));
    return response.data;
  },
  getSpecialCasesGoldCards: async (): Promise<SpecialCasesGoldCards> => {
    const response = await axios.get(paths.getSpecialCasesGoldCards());
    return toSpecialCasesGoldCards(response.data);
  },
  updateSpecialCasesGoldCards: async (request: SpecialCasesGoldCardsUpdateRequest): Promise<SpecialCasesGoldCards> => {
    const response = await axios.put(paths.updateSpecialCasesGoldCards(), request);
    return toSpecialCasesGoldCards(response.data);
  },
  getSpecialCasesSilverCards: async (): Promise<SpecialCasesSilverCards> => {
    const response = await axios.get(paths.getSpecialCasesSilverCards());
    return toSpecialCasesSilverCards(response.data);
  },
  updateSpecialCasesSilverCards: async (
    request: SpecialCasesSilverCardsUpdateRequest
  ): Promise<SpecialCasesSilverCards> => {
    const response = await axios.put(paths.updateSpecialCasesSilverCards(), request);
    return toSpecialCasesSilverCards(response.data);
  },
};

export default api;
