import { css } from '@emotion/react';

const menuContainer = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`;

const menu = css`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  && {
    border: none;
    box-shadow: none;
  }
  && {
    .item:before {
      width: 0;
    }
    &.ui.menu .active.item,
    &.ui.menu .item:hover {
      background: rgba(250, 233, 219, 0.65);
      span {
        font-weight: 600;
      }
    }
  }
`;

const menuIcon = css`
  && {
    font-size: 20px;
    color: #f9e6d7 !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const menuItem = css`
  color: var(--dcd-kering-primary-color);
  display: flex;
  align-items: center;
  height: 100%;
`;

const sidebar = css`
  width: 266px;
  padding: 0 30px;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.05);
  left: -2px;
`;

const sidebarContent = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
`;

const logoContainer = css`
  border-radius: 4px;
  width: 160px;
  height: 90px;
  margin: 20px auto;
`;

const logo = css`
  top: 10px;
`;

const header = css`
  background-color: var(--dcd-secondary-color-70);
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 8px 15px;
`;

const languageDropdown = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const content = css`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const pageContent = css`
  padding: 20px;
  flex: 1;
  overflow: auto;
`;

const userMenu = css``;
const profileText = css`
  cursor: pointer;
`;
const profileImage = css`
  background-color: var(--dcd-white-color);
  width: 64px;
  cursor: pointer;
`;

const personalInfo = css`
  overflow: auto;
  flex: 1;
`;
const personalInfoContainer = css`
  display: flex;
  width: 100%;
  max-height: calc(100% - 85px);
  max-width: 400px;
  z-index: 11;
  position: absolute;
  right: 20px;
  top: 73px;
  background-color: var(--dcd-white-color);
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.07);
  border-top: none;
  .infoContainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  flex-direction: column;
  @media (max-width: 600px) {
    right: 0;
    border: none;
    height: calc(100% - 72px);
    max-height: calc(100% - 72px);
  }
`;

const personalInfoButtonsContainer = css`
  border-top: 1px solid var(--dcd-pink-color-80);
  display: flex;
  padding: 16px;
  column-gap: 16px;
  &&&&&&& {
    .logoutButton {
      background-color: var(--dcd-pink-color-80);
      color: var(--dcd-white-color);
      border: none !important;
      display: block;
      text-align: center;
      width: 50%;
      padding: 10px 10px;
      margin: 0 auto;
      font-weight: 500 !important;
      &:hover {
        color: var(--dcd-white-color) !important;
      }
    }
  }
`;
const switchRoleButton = css`
  &&&&&&& {
    background-color: var(--dcd-pink-color-80);
    color: var(--dcd-white-color);
    border: none !important;
    line-height: 20px;
    display: block;
    width: 50%;
    padding: 10px 10px;
    margin: 0 auto;
    font-weight: 500 !important;
    :hover {
      text-decoration: underline;
    }
  }
`;

export default {
  menuContainer,
  menu,
  menuIcon,
  sidebar,
  sidebarContent,
  header,
  languageDropdown,
  content,
  pageContent,
  logoContainer,
  logo,
  userMenu,
  profileImage,
  profileText,
  personalInfoContainer,
  personalInfo,
  personalInfoButtonsContainer,
  switchRoleButton,
  menuItem,
};
