/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import {
  addEmptyUser,
  fetchUsers,
  selectFilter,
  selectFilteredAndSortedUsers,
  selectIsFetching,
  setFilter,
} from '../stores/usersManagementSlice';
import style from './usersManagement.style';
import { UserCard } from '../components/UserCard';
import { useAppDispatch } from '../../../../core/hooks';
import { SearchBox } from '../../../../shared/components/SearchBox';
import { scrollToTop } from '../../../../core/utils';
import { User } from '../models/user';
import { fetchBrands } from '../../../../shared/store/brandsSlice';

export const UsersManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cardsContainerRef = useRef<HTMLDivElement>(null);

  const users = useSelector(selectFilteredAndSortedUsers);
  const isFetchingUsers = useSelector(selectIsFetching);

  const usersFilterValue = useSelector(selectFilter);
  const setFilterDebounced = useDebouncedCallback((filterValue: string) => {
    dispatch(setFilter(filterValue));
  }, 300);

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <div css={style.configurationMessage}>
        <Icon name='warning sign' size='large' css={style.warningIcon} /> {t('users.configurationMessage')}
      </div>
      <div css={style.header}>
        <Button
          css={style.addButton(t('users.addUser'))}
          icon='plus'
          onClick={() => {
            dispatch(addEmptyUser());
            scrollToTop(cardsContainerRef.current);
          }}
        />
        <div>
          <SearchBox
            defaultValue={usersFilterValue}
            placeholder={t('users.search')}
            onChange={value => {
              setFilterDebounced(value);
            }}
          />
        </div>
      </div>
      {users.length <= 0 ? (
        <div css={style.noItemsLabel}>{t('users.noItemsLabel')}</div>
      ) : (
        <div ref={cardsContainerRef} css={style.cardsContainer}>
          {isFetchingUsers ? <Loader active /> : users.map((user: User) => <UserCard user={user} key={user.id} />)}
        </div>
      )}
    </div>
  );
};
