/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Icon, Label, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import _ from 'lodash';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { Purchase } from '../models/purchase';
import {
  addPurchasesYear,
  fetchMyPurchasesByYear,
  selectFilteredPurchaseHistoryGroupedByMonth,
  selectIsFetchingPurchaseHistoryByYear,
  selectPurchasesYear,
} from '../store/purchaseHistorySlice';
import {
  changeFilters,
  changeSearchBoxFilter,
  fetchPurchasesFilters,
  getMonthDescription,
  selectGeneralFilters,
  selectSearchBoxFilter,
} from '../store/purchaseHistoryFiltersSlice';
import { fetchAllLimits } from '../../../admin/limits/stores/limitRulesSlice';
import Header from '../../../../shared/components/Header';
import { SearchBox } from '../../../../shared/components/SearchBox';
import { PurchaseCard } from '../components/PurchaseCard';
import { PurchaseFilters } from '../components/PurchaseFilters';
import { PurchaseDetailModal } from '../components/PurchaseDetailModal';
import style from './purchaseHistory.style';

export const PurchaseHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(selectIsFetchingPurchaseHistoryByYear);
  const purchaseHistoryGroupedByMonth = useAppSelector(selectFilteredPurchaseHistoryGroupedByMonth);
  const searchBoxFilter = useAppSelector(selectSearchBoxFilter);
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(changeSearchBoxFilter(value));
  }, 500);
  const [showFilters, setShowFilters] = useState(false);
  const [purchaseDetail, setPurchaseDetail] = useState<Purchase | null>(null);
  const [detailIsOpen, setDetailIsOpen] = useState(false);
  const purchasesYear = useAppSelector(selectPurchasesYear);
  const [selectedYear, setSelectedYear] = useState<string>(purchasesYear.toString());

  useEffect(() => {
    dispatch(fetchAllLimits());
    dispatch(fetchMyPurchasesByYear(+selectedYear));
    dispatch(addPurchasesYear(+selectedYear));
    dispatch(fetchPurchasesFilters());
  }, [dispatch, selectedYear]);

  return (
    <div css={style.purchaseContainer}>
      <Header title={t('purchaseHistory.title')} arrowBackEnable={false} />
      <div css={style.filtersContainer}>
        <div css={style.searchBoxContainer}>
          <SearchBox
            key='historySearch'
            defaultValue={searchBoxFilter}
            onChange={value => debounced(value)}
            placeholder={t('purchaseHistory.search')}
            isSearchBarWithYearSelection
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
        </div>
        <div css={style.filterIconContainer}>
          <Icon
            css={style.filterIcon}
            name='filter'
            size='large'
            link
            onClick={() => {
              setShowFilters(true);
            }}
          />
        </div>
      </div>
      {showFilters && (
        <PurchaseFilters
          onBack={() => {
            setShowFilters(false);
          }}
        />
      )}
      {isFetching ? (
        <Loader size='big' active />
      ) : Object.entries(purchaseHistoryGroupedByMonth).length <= 0 ? (
        <>
          <FiltersBadges />
          <div css={style.noItemsLabel}>{t('purchaseHistory.noItemsLabel')}</div>
        </>
      ) : (
        <>
          <FiltersBadges />
          <div css={style.purchaseHistoryCardsContainer}>
            {Object.entries(purchaseHistoryGroupedByMonth).map(([key, monthPurchaseList]) => (
              <div key={`${key}${Math.random()}`}>
                <div css={style.historyLabel}>{key.charAt(0).toUpperCase() + key.slice(1)}</div>
                {monthPurchaseList.map(purchase => (
                  <PurchaseCard
                    key={`${purchase.sku}${purchase.date.toString()}`}
                    quantity={purchase.quantity}
                    amountValue={
                      purchase.discountedPriceConverted.value != null
                        ? purchase.discountedPriceConverted.value
                        : purchase.discountedPrice
                    }
                    amountCurrency={
                      purchase.discountedPriceConverted.value != null
                        ? purchase.discountedPriceConverted.currency
                        : purchase.store.currency
                    }
                    brand={purchase.store.brand}
                    product={purchase.productName}
                    productCategory={purchase.productCategory}
                    store={purchase.store}
                    date={purchase.date}
                    onClick={() => {
                      setPurchaseDetail(purchase);
                      setDetailIsOpen(true);
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        </>
      )}

      {purchaseDetail && (
        <PurchaseDetailModal purchase={purchaseDetail} open={detailIsOpen} onClose={() => setDetailIsOpen(false)} />
      )}
    </div>
  );
};

const FiltersBadges = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectGeneralFilters);
  const { t } = useTranslation();

  return (
    <div css={style.badgesWrapper}>
      <div css={style.badgesContainer}>
        {!_.isEmpty(filters.refundedStatusName) && (
          <Label css={style.filterBadge}>
            {t('purchaseHistory.refundedStatus')}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, refundedStatusName: '' }))} />
          </Label>
        )}
        {!_.isEmpty(filters.brand.code) && (
          <Label css={style.filterBadge}>
            {filters.brand.description}
            <Icon
              name='delete'
              onClick={() => dispatch(changeFilters({ ...filters, brand: { code: '', description: '' } }))}
            />
          </Label>
        )}
        {!_.isEmpty(filters.storeName) && (
          <Label css={style.filterBadge}>
            {filters.storeName}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, storeName: '' }))} />
          </Label>
        )}
        {!_.isEmpty(filters.category) && (
          <Label css={style.filterBadge}>
            {filters.category}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, category: '' }))} />
          </Label>
        )}
        {!_.isEmpty(filters.monthCode) && filters.monthCode !== 'CUSTOM' && (
          <Label css={style.filterBadge}>
            {getMonthDescription(filters.monthCode)}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, monthCode: '' }))} />
          </Label>
        )}
        {!_.isEqual(filters.dateRange, [null, null]) && (
          <Label css={style.filterBadge}>
            {moment(filters.dateRange[0]).format('DD/MM/YY')}
            {`${filters.dateRange[1] != null ? moment(filters.dateRange[1]).format(' - DD/MM/YY') : ''}`}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, dateRange: [null, null] }))} />
          </Label>
        )}
      </div>
    </div>
  );
};
