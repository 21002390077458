import { css } from '@emotion/react';
import globalStyle from '../../../../../shared/global.style';

const newRuleButtonContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: max-content;
  z-index: 1;
`;

const legendSection = css`
  ${globalStyle.bodySmall}
  flex-basis: 100%;
  && {
    font-size: 10px;
  }
`;

const saveRuleButton = css`
  ${globalStyle.secondaryButton};
`;

const cancelRuleButton = css`
  ${globalStyle.secondaryButton};
`;

const notEligibleLabel = css`
  ${globalStyle.bodySmall};
  &&& {
    font-weight: 500;
  }
`;

const sectionContainer = css`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
`;

const sectionLabel = css`
  ${globalStyle.bodySmall};
  min-width: 120px;
  padding: 8px 0;
  align-self: flex-start;
  text-decoration: underline;
`;

const ruleCardContainer = css`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  flex: 1;
  overflow: auto;
  ${globalStyle.bodySmall};
`;

const constraintsSection = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 14px;
  gap: 20px;
`;
const brandField = css`
  min-width: 200px;
`;

const outerContainer = css`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const datePicker = css`
  ${globalStyle.bodySmall};
  border: 1px solid var(--dcd-kering-primary-color-20);
  border-radius: 2px;
  padding: 8px;
  ::placeholder {
    opacity: 0.5;
  }
`;

const iconDropdownField = css`
  &&& {
    min-width: auto;
  }
`;
const iconDropdown = css`
  &&& {
    min-width: auto;
    width: 80px;
    height: 38px;
  }
`;
const categoryIcon = css`
  &&& {
    font-size: 1.5rem;
  }
`;
const selectedCategoryIcon = css`
  ${categoryIcon};
  &&& {
    position: relative;
    left: 2px;
  }
`;
const groupedConstraints = css`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const productCategoryLabel = css`
  width: 196px;
`;

const bulkActionsBtn = css`
  ${globalStyle.secondaryButton}
  height: 39px;
  &&&& {
    margin-bottom: 1px;
  }
`;

const bulkActionsBtnContainer = (fileName: string) => {
  if (fileName === '') {
    return css`
      padding-top: 38px;
      display: flex;
      align-items: flex-end;
      flex-grow: 0;
    `;
  }
  return css`
    padding-top: 14px;
    display: flex;
    align-items: flex-end;
    flex-grow: 0;
  `;
};

const resetFilePathButton = css`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const filepathLabel = css`
  ${globalStyle.bodySmall};
  padding-top: 20px;
`;

const modalContainer = css`
  ${globalStyle.bodyNormal}
  &&& {
    max-width: 360px;
    border-radius: 10px;
    padding: 14px 0 0;
  }
`;

const modalContent = css`
  &&&& {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 14px 14px 14px;
    gap: 14px;
  }
`;

const modalActions = css`
  &&& {
    padding-top: 14px !important;
    border: none;
    background-color: transparent;
    @media (max-width: 768px) {
      padding: 1rem 1rem !important;
    }
  }
`;

const modalButton = css`
  ${globalStyle.primaryButton};
  @media (max-width: 768px) {
    margin: 0 !important;
    margin-left: 0.75rem !important;
    margin-right: 0.25rem !important;
  }
`;

const nonEligibleConstraintDropdown = css`
  z-index: 2;
`;

export default {
  ruleCardContainer,
  legendSection,
  sectionContainer,
  newRuleButtonContainer,
  notEligibleLabel,
  sectionLabel,
  saveRuleButton,
  cancelRuleButton,
  constraintsSection,
  brandField,
  outerContainer,
  datePicker,
  iconDropdownField,
  iconDropdown,
  categoryIcon,
  selectedCategoryIcon,
  groupedConstraints,
  productCategoryLabel,
  bulkActionsBtn,
  bulkActionsBtnContainer,
  resetFilePathButton,
  filepathLabel,
  modalContainer,
  modalContent,
  modalActions,
  modalButton,
  nonEligibleConstraintDropdown,
};
