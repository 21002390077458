import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';
import qrTarget from '../../../../assets/qrTarget.svg';

const cameraContainer = css`
  height: 100%;
  min-height: 300px;
  min-width: 320px;
  background-color: var(--dcd-black-color);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const video = css`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
`;

const targetSquareContainer = css`
  position: absolute;
  width: 100%;
  text-align: center;
`;
const targetSquare = css`
  background: url(${qrTarget}) no-repeat;
  background-size: cover;
  width: 200px;
  height: 200px;
  margin: 50px auto;
`;

const targetSquareMessage = css`
  ${globalStyle.bodyNormal};
  color: var(--dcd-white-color);
  width: 100%;
  position: absolute;
  top: 20px;
  text-align: center;
`;

export default {
  cameraContainer,
  video,
  targetSquareContainer,
  targetSquare,
  targetSquareMessage,
};
