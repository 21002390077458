import _ from 'lodash';

interface Currency {
  code: string;
  description: string;
}

const currencies: Currency[] = [
  { code: 'USD', description: 'United States Dollar' },
  { code: 'AUD', description: 'Australian Dollar' },
  { code: 'EUR', description: 'Euro' },
  { code: 'BHD', description: 'Bahraini Dinar' },
  { code: 'BRL', description: 'Brazilian Real' },
  { code: 'CAD', description: 'Canadian Dollar' },
  { code: 'CLP', description: 'Chilean Peso' },
  { code: 'CZK', description: 'Czech Koruna' },
  { code: 'DKK', description: 'Danish Krone' },
  { code: 'DOP', description: 'Dominican Peso'}, 
  { code: 'HUF', description: 'Hungarian Forint' },
  { code: 'INR', description: 'Indian Rupee' },
  { code: 'JPY', description: 'Japanese Yen' },
  { code: 'KRW', description: 'South Korean Won' },
  { code: 'KWD', description: 'Kuwaiti Dinar' },
  { code: 'MOP', description: 'Macanese Pataca' },
  { code: 'CNY', description: 'Yuan Renminbi' },
  { code: 'MYR', description: 'Malaysian Ringgit' },
  { code: 'MXN', description: 'Mexican Peso' },
  { code: 'NZD', description: 'New Zealand Dollar' },
  { code: 'NOK', description: 'Norwegian Krone' },
  { code: 'PHP', description: 'Philippine Peso' },
  { code: 'QAR', description: 'Qatari Riyal' },
  { code: 'RON', description: 'Romanian Leu' },
  { code: 'RUB', description: 'Russian Ruble' },
  { code: 'SAR', description: 'Saudi Riyal' },
  { code: 'RSD', description: 'Serbian Dinar' },
  { code: 'SGD', description: 'Singapore Dollar' },
  { code: 'ZAR', description: 'South African Rand' },
  { code: 'SEK', description: 'Swedish Krona' },
  { code: 'CHF', description: 'Swiss Franc' },
  { code: 'TWD', description: 'New Taiwan Dollar' },
  { code: 'THB', description: 'Thai Baht' },
  { code: 'TRY', description: 'Turkish Lira' },
  { code: 'AED', description: 'United Arab Emirates Dirham' },
  { code: 'GBP', description: 'Pound Sterling' },
  { code: 'VND', description: 'Vietnamese Dong' },
  { code: 'PAB', description: 'Panamanian Balboa' },
  { code: 'HKD', description: 'Hong Kong Dollar' },
];

export const orderedCurrencies: Currency[] = _.sortBy(currencies, 'code');
