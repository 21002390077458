/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { DiscountCardPageUnderSecurity } from './shared/DiscountCardPageUnderSecurity';
import { Login } from './features/auth/pages/Login';
import { OauthReceiver } from './features/auth/components/OauthReceiver';
import { LoginError } from './features/auth/pages/LoginError';
import { PageNotFound } from './features/auth/pages/PageNotFound';
import { useOauthToken } from './features/auth/hooks/useOauthToken';
import { MyDigitalCard } from './features/employee/discountCard/pages/MyDigitalCard';
import { PurchaseHistory } from './features/employee/purchaseHistory/pages/PurchaseHistory';
import { SeeMore } from './features/employee/seeMore/pages/SeeMore';
import { MyPersonalInformation } from './features/employee/seeMore/pages/MyPersonalInformation';
import { PurchaseLimit } from './features/employee/purchaseLimit/pages/PurchaseLimit';
import { AdminDashboard } from './features/admin/AdminDashboard';
import { AdminPageUnderSecurity } from './shared/AdminPageUnderSecurity';
import { UsersManagement } from './features/admin/usersManagement/pages/UsersManagement';
import { LogoutPage } from './shared/LogoutPage';
import Limits from './features/admin/limits/pages/Limits';
import { SalesAssistantPageUnderSecurity } from './shared/SalesAssistantPageUnderSecurity';
import { CardSearch } from './features/salesAssistant/cardSearch/pages/CardSearch';
import { SpecialCases } from './features/salesAssistant/specialCases/pages/SpecialCases';
import { EmployeeDiscountCard } from './features/salesAssistant/cardSearch/pages/EmployeeDiscountCard';
import ProductSearch from './features/salesAssistant/products/pages/ProductSearch';
import { SalesAssistantPurchaseLimit } from './features/salesAssistant/limit/pages/SalesAssistantPurchaseLimit';
import { SalesAssistantSeeMore } from './features/salesAssistant/seeMore/pages/SalesAssistantSeeMore';
import { RoleSelectionMenu } from './shared/RoleSelectionMenu';
import LimitRuleDetail from './features/admin/limits/pages/LimitRuleDetail';
import { TrafficLights } from './features/salesAssistant/trafficLights/pages/TrafficLights';
import Discounts from './features/admin/discounts/pages/Discounts';
import DiscountRuleSetDetail from './features/admin/discounts/pages/DiscountRuleSetDetail';
import { BlockedEmployeesPage } from './features/admin/blockedEmployees/pages/BlockedEmployeesPage';
import { MyBusinessCard } from './features/employee/businessCard/pages/MyBusinessCard';
import { BusinessCardPageUnderSecurity } from './shared/BusinessCardPageUnderSecurity';
import { BusinessCardLogin } from './features/auth/pages/BusinessPageLogin';
import { MyStoresSelectionPage } from './features/salesAssistant/storeSelection/pages/MyStoresSelectionPage';
import { preloadImages } from './core/utils';
import goldCardBackground from './assets/gold-card-background.jpg';
import silverCardBackground from './assets/silver-card-background.jpg';
import discountBackgroundCard from './assets/discountCardBackground.svg';
import qrTarget from './assets/qrTarget.svg';
import { roundedLogos, themedLogos } from './shared/models/brand';
import { businessCardLogos } from './shared/models/business-card.brand';
import BlockedEmployeeDetail from './features/admin/blockedEmployees/pages/BlockedEmployeeDetail';
import { UserActivities } from './features/admin/userActivities/pages/UserActivities';

function App() {
  const { handleTokenRefresh, setTokenAndTimeout } = useOauthToken();
  useEffect(() => {
    handleTokenRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  preloadImages([
    goldCardBackground,
    silverCardBackground,
    discountBackgroundCard,
    qrTarget,
    ...roundedLogos,
    ...themedLogos,
    ...businessCardLogos,
  ]);

  return (
    <Routes>
      <Route path='/business-card' element={<Navigate to='/business-card/my-card' />} />
      <Route path='/business-card'>
        <Route
          path='my-card'
          element={
            <BusinessCardPageUnderSecurity>
              <MyBusinessCard />
            </BusinessCardPageUnderSecurity>
          }
        />
        <Route path='login' element={<BusinessCardLogin />} />
      </Route>
      <Route path='/' element={<Navigate to='/privilege-card/home' />} />
      <Route path='/privilege-card'>
        <Route path='' element={<Navigate to='/privilege-card/home' />} />
        <Route path='home' element={<RoleSelectionMenu />} />
        <Route path='employee' element={<Navigate to='/privilege-card/employee/my-card' />} />
        <Route path='employee'>
          <Route
            path='my-card'
            element={
              <DiscountCardPageUnderSecurity>
                <MyDigitalCard />
              </DiscountCardPageUnderSecurity>
            }
          />
          <Route
            path='purchase-limit'
            element={
              <DiscountCardPageUnderSecurity>
                <PurchaseLimit />
              </DiscountCardPageUnderSecurity>
            }
          />
          <Route
            path='purchase-history'
            element={
              <DiscountCardPageUnderSecurity>
                <PurchaseHistory />
              </DiscountCardPageUnderSecurity>
            }
          />
          <Route
            path='see-more'
            element={
              <DiscountCardPageUnderSecurity>
                <SeeMore />
              </DiscountCardPageUnderSecurity>
            }
          />
          <Route
            path='my-personal-information'
            element={
              <DiscountCardPageUnderSecurity>
                <MyPersonalInformation />
              </DiscountCardPageUnderSecurity>
            }
          />
        </Route>

        <Route path='admin' element={<Navigate to='privilege-card/admin/home' />} />
        <Route path='admin'>
          <Route
            path='home'
            element={
              <AdminPageUnderSecurity>
                <AdminDashboard />
              </AdminPageUnderSecurity>
            }
          />
          <Route
            path='users-management'
            element={
              <AdminPageUnderSecurity>
                <UsersManagement />
              </AdminPageUnderSecurity>
            }
          />
          <Route path='blocked-employees'>
            <Route
              path='detail'
              element={
                <AdminPageUnderSecurity>
                  <BlockedEmployeeDetail />
                </AdminPageUnderSecurity>
              }
            />
            <Route
              path='detail/:id'
              element={
                <AdminPageUnderSecurity>
                  <BlockedEmployeeDetail />
                </AdminPageUnderSecurity>
              }
            />
            <Route
              index
              element={
                <AdminPageUnderSecurity>
                  <BlockedEmployeesPage />
                </AdminPageUnderSecurity>
              }
            />
          </Route>
          <Route
            path='limits'
            element={
              <AdminPageUnderSecurity>
                <Limits />
              </AdminPageUnderSecurity>
            }
          />
          <Route path='rules'>
            <Route
              index
              element={
                <AdminPageUnderSecurity>
                  <LimitRuleDetail />
                </AdminPageUnderSecurity>
              }
            />
            <Route
              path=':id'
              element={
                <AdminPageUnderSecurity>
                  <LimitRuleDetail />
                </AdminPageUnderSecurity>
              }
            />
          </Route>
          <Route
            path='discounts'
            element={
              <AdminPageUnderSecurity>
                <Discounts />
              </AdminPageUnderSecurity>
            }
          />
          <Route path='discount-rule-set'>
            <Route
              index
              element={
                <AdminPageUnderSecurity>
                  <DiscountRuleSetDetail />
                </AdminPageUnderSecurity>
              }
            />
            <Route
              path=':id'
              element={
                <AdminPageUnderSecurity>
                  <DiscountRuleSetDetail />
                </AdminPageUnderSecurity>
              }
            />
          </Route>
          <Route
            path='user-activities'
            element={
              <AdminPageUnderSecurity>
                <UserActivities />
              </AdminPageUnderSecurity>
            }
          />
        </Route>

        <Route path='sales-assistant' element={<Navigate to='/privilege-card/sales-assistant/card-search' />} />
        <Route path='sales-assistant'>
          <Route
            path='card-search'
            element={
              <SalesAssistantPageUnderSecurity>
                <CardSearch />
              </SalesAssistantPageUnderSecurity>
            }
          />
          <Route
            path='card-search/:id'
            element={
              <SalesAssistantPageUnderSecurity>
                <EmployeeDiscountCard />
              </SalesAssistantPageUnderSecurity>
            }
          />
          <Route
            path='product-search/'
            element={
              <SalesAssistantPageUnderSecurity>
                <ProductSearch />
              </SalesAssistantPageUnderSecurity>
            }
          />
          <Route
            path='check-quota/'
            element={
              <SalesAssistantPageUnderSecurity>
                <TrafficLights />
              </SalesAssistantPageUnderSecurity>
            }
          />
          <Route
            path='purchase-limit'
            element={
              <SalesAssistantPageUnderSecurity>
                <SalesAssistantPurchaseLimit />
              </SalesAssistantPageUnderSecurity>
            }
          />
          <Route
            path='special-cases'
            element={
              <SalesAssistantPageUnderSecurity>
                <SpecialCases />
              </SalesAssistantPageUnderSecurity>
            }
          />
          <Route
            path='see-more'
            element={
              <SalesAssistantPageUnderSecurity>
                <SalesAssistantSeeMore />
              </SalesAssistantPageUnderSecurity>
            }
          />
          <Route
            path='store-selection'
            element={
              <SalesAssistantPageUnderSecurity>
                <MyStoresSelectionPage />
              </SalesAssistantPageUnderSecurity>
            }
          />
        </Route>
        <Route path='login' element={<Login />} />
        <Route path='logout' element={<LogoutPage />} />
      </Route>
      <Route path='login-callback' element={<OauthReceiver onAuthSuccess={setTokenAndTimeout} />} />
      <Route path='login-error' element={<LoginError />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
