import { API_URL } from '../../../../constants';

const PRODUCTS_URL = `${API_URL}/stores`;
const BASKET_URL = `${API_URL}/basket`;
const PRODUCT_IMAGE_URL = `${API_URL}/product-image`

const paths = {
  getProduct: (storeCode: string, sku: string): string => `${PRODUCTS_URL}/${storeCode}/products/${sku}`,
  getProducts: (storeCode: string, filter: string): string =>
    `${PRODUCTS_URL}/${storeCode}/products?filter=${filter}`,
  getProductCategories: (storeCode: string): string => `${PRODUCTS_URL}/${storeCode}/product-categories`,
  updateBasket: (): string => `${BASKET_URL}`,
  getProductImage: (imageUrl: string): string => `${PRODUCT_IMAGE_URL}?imageUrl=${imageUrl}`
};

export default paths;
