/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './page.style';
import { BusinessCardPoliciesModal } from './components/BusinessCardPoliciesModal';

interface PageProps {
  children: JSX.Element;
}

export const BusinessCardPage = (props: PageProps): JSX.Element => {
  const { children } = props;
  return (
    <div css={style.page}>
      <div css={style.appContainer}>
        <BusinessCardPoliciesModal />
        <div css={style.container} style={{ backgroundColor: 'var(--dcd-gray-color-30)' }}>
          {children}
        </div>
      </div>
    </div>
  );
};
