/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import { acceptBusinessCardPolicies, selectBusinessCardPolicies } from '../../features/auth/store/principalSlice';
import style from './policiesModal.style';

export const BusinessCardPoliciesModal = (): JSX.Element => {
  const policiesAccepted = useAppSelector(selectBusinessCardPolicies);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handlePoliciesAcceptance = () => {
    dispatch(acceptBusinessCardPolicies());
  };

  return (
    <Modal css={style.modalContainer} open={!policiesAccepted}>
      <Modal.Header css={style.modalHeader}>
        <div css={style.modalTitle}>
          <p>{t('termsAndConditions.termsAndConditions')}</p>
        </div>
      </Modal.Header>
      <Modal.Content scrolling css={style.modalContent}>
        <Modal.Description css={style.modalDescription}>
          <div css={style.bodyContainer}>
            <div css={style.sectionContainer}>
              <div css={style.bodyTitle}>Business Card Application Terms of Use</div>
              <div css={style.pageParagraphs}>
                As part of this Business Card, your employer, as identified in your employment contract, processes your
                personal data as data controller for the following purposes: access management to the Business Card and
                security management; (ii) implementation of the Business Card; and (iii) maintenance and technical
                support. You have various rights regarding the processing of your personal data, in particular the right
                to request access to your data or to rectify it. To find out more about the processing and in particular
                how to exercise your rights, please consult the{' '}
                <a
                  href='https://keringconnect.service-now.com/kb?id=kb_article_view&sysparm_article=KB0036797'
                  target='_blank'
                  rel='noreferrer'>
                  Employee Privacy Policy
                </a>
                .
              </div>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions css={style.modalActions}>
        <Button css={style.acceptPoliciesBtn} onClick={handlePoliciesAcceptance}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
