/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../core/store';
import style from './logoutModal.style';

interface LogoutModalProps {
  onClickHandler?: (value: boolean) => void;
}

export const LogoutModal = ({ onClickHandler }: LogoutModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/privilege-card/logout');
    dispatch(logoutAction());
    setOpen(false);
  };

  return (
    <>
      <Button
        className='logoutButton'
        css={style.logoutBtn}
        onClick={() => {
          setOpen(true);
          if (onClickHandler != null) {
            onClickHandler(true);
          }
        }}>
        {t('logout.logOutButton')}
      </Button>
      <Modal css={style.modalContainer} size='mini' open={open}>
        <Modal.Content css={style.modalContent}>
          <Modal.Description>
            <p>{t('logout.warningMessage')}</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions css={style.modalActions}>
          <Button
            css={style.cancelBtn}
            content={t('logout.cancel')}
            onClick={() => {
              setOpen(false);
              if (onClickHandler != null) {
                onClickHandler(false);
              }
            }}
          />
          <Button css={style.confirmLogoutBtn} content={t('logout.logout')} onClick={() => handleLogout()} />
        </Modal.Actions>
      </Modal>
    </>
  );
};
