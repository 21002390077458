import KeringWhite from '../../assets/brands/whiteKeringLogo.svg';
import KeringEyewearWhite from '../../assets/brands/whiteKeringEyewearLogo.svg';
import KeringBeauteWhite from '../../assets/brands/whiteKeringBeauteLogo.svg';
import AlexanderMcQueenWhite from '../../assets/brands/whiteAlexanderMcQueenLogo.svg';
import BalenciagaBlack from '../../assets/brands/blackBalenciagaLogo.svg';
import BottegaVenetaWhite from '../../assets/brands/whiteBottegaVenetaLogo.png';
import BrioniWhite from '../../assets/brands/whiteBrioniLogo.svg';
import SaintLaurentWhite from '../../assets/brands/whiteSaintLaurentLogo.svg';
import BoucheronWhite from '../../assets/brands/whiteBoucheronLogo.svg';
import PomellatoWhite from '../../assets/brands/whitePomellatoLogo.svg';
import QeelinWhite from '../../assets/brands/whiteQeelinLogo.png';
import GucciWhite from '../../assets/brands/whiteOnTransparentGucciLogo.svg';
import DodoBlack from '../../assets/brands/blackDodoLogo.png';
import KeringBlack from '../../assets/brands/blackKeringLogo.svg';
import KeringBeauteBlack from '../../assets/brands/blackKeringBeauteLogo.png';
import AlexanderMcQueenRound from '../../assets/brands/whiteCircularAlexanderMcQueenLogo.svg';
import BrioniRound from '../../assets/brands/whiteCircularBrioniLogo.svg';
import SaintLaurentRound from '../../assets/brands/whiteCircularSaintLaurentLogo.svg';
import GucciRound from '../../assets/brands/whiteCircularGucciLogo.svg';
import PomellatoRound from '../../assets/brands/whiteCircularPomellatoLogo.svg';
import BoucheronRound from '../../assets/brands/whiteCircularBoucheronLogo.svg';
import DodoRound from '../../assets/brands/whiteCircularDodoLogo.svg';
import KeringRound from '../../assets/brands/whiteCircularKeringLogo.svg';
import KeringEyewearRound from '../../assets/brands/whiteCircularKeringEyewearLogo.svg';
import BalenciagaRound from '../../assets/brands/whiteCircularBalenciagaLogo.svg';
import BottegaVenetaRound from '../../assets/brands/whiteCircularBottegaVenetaLogo.svg';
import QeelinRound from '../../assets/brands/whiteCircularQeelinLogo.svg';
import KeringQrcodeLogo from '../../assets/keringQrcodeLogo.svg';
import AmqQrcodeLogo from '../../assets/amqQrcodeLogo.svg';
import BrioniQrcodeLogo from '../../assets/brioniQrcodeLogo.svg';

export interface Brand {
  code: string;
  description: string;
}
export interface BrandResponse {
  code: string;
  description: string;
}

export const toBrand = (response: BrandResponse): Brand => ({
  code: response.code,
  description: response.description,
});

const gucciBrandCode = 'GUCCI';
export const isGucciBrandCode = (code: string | undefined) => code === gucciBrandCode;
export const getThemedLogo = (
  code: string,
  isSpecialCaseGoldCard: boolean | undefined,
  isSpecialCaseSilverCard: boolean | undefined
): string => {
  if (isSpecialCaseGoldCard) {
    return KeringBlack;
  }

  if (isSpecialCaseSilverCard) {
    return KeringBlack;
  }

  switch (code) {
    case 'KERING':
    case 'CORPORATE':
    case 'GINORI':
      return KeringWhite;
    case 'KERING_BEAUTE':
      return KeringBeauteWhite;
    case 'KERING_EYEWEAR':
      return KeringEyewearWhite;
    case 'ALEXANDER_MCQUEEN':
      return AlexanderMcQueenWhite;
    case 'BALENCIAGA':
      return BalenciagaBlack;
    case 'BOTTEGA_VENETA':
      return BottegaVenetaWhite;
    case 'BRIONI':
      return BrioniWhite;
    case 'BOUCHERON':
      return BoucheronWhite;
    case 'YVES_SAINT_LAURENT':
      return SaintLaurentWhite;
    case 'DODO':
      return DodoBlack;
    case 'POMELLATO':
      return PomellatoWhite;
    case 'QEELIN':
      return QeelinWhite;
    case gucciBrandCode:
      return GucciWhite;
    default:
      return '';
  }
};

export const getLogo = (code: string): string => {
  switch (code) {
    case 'ALEXANDER_MCQUEEN':
      return AlexanderMcQueenRound;
    case 'BRIONI':
      return BrioniRound;
    case 'YVES_SAINT_LAURENT':
      return SaintLaurentRound;
    case gucciBrandCode:
      return GucciRound;
    case 'POMELLATO':
      return PomellatoRound;
    case 'BOUCHERON':
      return BoucheronRound;
    case 'DODO':
      return DodoRound;
    case 'KERING':
    case 'CORPORATE':
    case 'GINORI':
      return KeringRound;
    case 'KERING_EYEWEAR':
      return KeringEyewearRound;
    case 'BOTTEGA_VENETA':
      return BottegaVenetaRound;
    case 'BALENCIAGA':
      return BalenciagaRound;
    case 'QEELIN':
      return QeelinRound;
    default:
      return KeringRound;
  }
};

export const isAvailableLogo = (brandCode: string): boolean => {
  const availableLogosBrandCodes = [
    'ALEXANDER_MCQUEEN',
    'BRIONI',
    'YVES_SAINT_LAURENT',
    gucciBrandCode,
    'POMELLATO',
    'BOUCHERON',
    'DODO',
    'KERING',
    'CORPORATE',
    'KERING_EYEWEAR',
    'QEELIN',
    'BOTTEGA_VENETA',
    'BALENCIAGA',
  ];
  return availableLogosBrandCodes.includes(brandCode);
};

export const isFashionBrand = (brandCode: string): boolean => {
  const fashionBrandCodes: string[] = [
    'YVES_SAINT_LAURENT',
    'ALEXANDER_MCQUEEN',
    'BALENCIAGA',
    'BOTTEGA_VENETA',
    'BRIONI',
    gucciBrandCode,
    'KERING_EYEWEAR',
    'GINORI',
  ];
  return fashionBrandCodes.includes(brandCode);
};

export const isJewelryBrand = (brandCode: string): boolean => {
  const jewelryBrandCodes: string[] = ['QEELIN', 'BOUCHERON', 'POMELLATO', 'DODO'];
  return jewelryBrandCodes.includes(brandCode);
};

export const getQrcodeLogo = (brandCode: string): string => {
  switch (brandCode) {
    case 'ALEXANDER_MCQUEEN':
      return AmqQrcodeLogo;
    case 'BRIONI':
      return BrioniQrcodeLogo;
    default:
      return KeringQrcodeLogo;
  }
};

export const roundedLogos = [
  AlexanderMcQueenRound,
  BrioniRound,
  SaintLaurentRound,
  GucciRound,
  PomellatoRound,
  BoucheronRound,
  DodoRound,
  KeringRound,
  KeringEyewearRound,
  BottegaVenetaRound,
  BalenciagaRound,
  QeelinRound,
  KeringQrcodeLogo,
  AmqQrcodeLogo,
  BrioniQrcodeLogo,
];

export const themedLogos = [
  KeringBlack,
  KeringBeauteBlack,
  AlexanderMcQueenWhite,
  BrioniWhite,
  SaintLaurentWhite,
  GucciWhite,
  PomellatoWhite,
  BoucheronWhite,
  DodoBlack,
  KeringBeauteWhite,
  KeringWhite,
  KeringEyewearWhite,
  BottegaVenetaWhite,
  BalenciagaBlack,
  QeelinWhite,
];
