import { css } from "@emotion/react";

const dropdownField = css`
  min-width: 150px;
  margin-right: 10px;
`;

const dropdownItemName = css`
  font-weight: 500;
  margin-bottom: 2px;
`;

const usersList = css`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 85%;
  @media (max-width: 1400px) {
    max-width: 100%;
  }
`;

const usersListContent = css`
  min-width: 180px;
  min-height: 19px;
`;

export default {
  dropdownField,
  dropdownItemName,

  usersList,
  usersListContent,
};
