import { css, SerializedStyles } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const buttonsContainer = css`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    margin-top: -36px;
  }
`;

const addDiscountRuleSetButton = (label: string): SerializedStyles => css`
  ${globalStyle.labeledButton(label)};
`;

const filtersContainer = css`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin-left: auto;
`;

const filterBadge = css`
  ${globalStyle.bodySmall};
  && {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dcd-secondary-color-40);
    border-radius: 20px;
    margin-right: 5px;
    padding: 4px 8px;
  }
`;

const badgesWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const badgesContainer = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const scrollableRuleSetsContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* overflow: auto; */
  margin-top: 20px;
`;

const noItemsLabel = css`
  ${globalStyle.bodySmall};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
export default {
  container,
  buttonsContainer,
  addDiscountRuleSetButton,
  noItemsLabel,
  scrollableRuleSetsContainer,
  filtersContainer,
  filterBadge,
  badgesContainer,
  badgesWrapper,
};
