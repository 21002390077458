/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import style from './limitRuleDetail.style';
import { Limits } from '../models/limitRule';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { LimitType, selectLimitTypeSelected } from '../stores/limitRulesSlice';
import { fetchRule, resetRule, selectLimitRule } from '../stores/limitRuleDetailSlice';
import { resetCountries } from '../stores/countriesSlice';
import LimitRuleForm from '../components/ruleForm/LimitRuleForm';
import { AdminSectionHeader } from '../../../../shared/components/AdminSectionHeader';

export default function LimitRuleDetail(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const selectedRuleType = useAppSelector(selectLimitTypeSelected);
  const limitRule = useAppSelector(selectLimitRule);

  useEffect(() => {
    if (id != null) {
      dispatch(fetchRule(id));
    }

    return () => {
      dispatch(resetRule());
      dispatch(resetCountries());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detectRuleType = (limits: Limits): LimitType => {
    const { amount, itemsCountPerSku, totalItemsCount } = limits;
    if (amount == null && itemsCountPerSku === 0 && totalItemsCount === 0) {
      return 'notEligible';
    }
    if (itemsCountPerSku != null) {
      return 'maxItemsBySku';
    }
    if (totalItemsCount != null) {
      return 'maxItems';
    }
    return 'maxAmount';
  };

  const limitRuleType = useMemo(() => {
    return limitRule != null ? detectRuleType(limitRule.limits) : selectedRuleType;
  }, [limitRule, selectedRuleType]);

  return (
    <div css={style.pageContainer}>
      <AdminSectionHeader title={t('limitRuleset.limitsPage')} redirectPath='/privilege-card/admin/limits' arrowBack />
      <div css={style.limitRuleFormContainer}>
        <LimitRuleForm limitRule={limitRule} ruleType={limitRuleType} />
      </div>
    </div>
  );
}
