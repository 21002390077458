/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { QuotaLimitByCategory, QuotaLimitPerSku } from '../models/QuotaLimit';
import { useAppSelector } from '../../../../core/hooks';
import {
  selectFilteredQuotaLimitsByCategories,
  selectFilteredQuotaLimitsPerSku,
  selectIsFetching,
} from '../store/employeePurchaseLimitSlice';
import { toLocaleAmount } from '../../../../shared/utils/utils';
import style from './quotaLimitList.style';

export default function QuotaLimitList() {
  const { t } = useTranslation();
  const isFetching = useAppSelector(selectIsFetching);
  enum PurchaseTab {
    Category,
    Sku,
  }

  const [activeTab, setActiveTab] = useState(PurchaseTab.Category);
  const limitsByCategory: QuotaLimitByCategory[] = useAppSelector(selectFilteredQuotaLimitsByCategories) || [];
  const limitsBySku: QuotaLimitPerSku[] = useAppSelector(selectFilteredQuotaLimitsPerSku) || [];
  return (
    <div css={style.quotaLimitsContainer}>
      <div css={style.tabsContainer}>
        <div
          css={style.smallTabButton(activeTab === PurchaseTab.Category)}
          onClick={() => setActiveTab(PurchaseTab.Category)}>
          {t('purchaseLimits.categoryTab')}
        </div>
        <div css={style.smallTabButton(activeTab === PurchaseTab.Sku)} onClick={() => setActiveTab(PurchaseTab.Sku)}>
          {t('purchaseLimits.skuTab')}
        </div>
      </div>
      {isFetching ? (
        <Loader size='big' active />
      ) : activeTab === PurchaseTab.Category ? (
        <div css={style.quotaCardsContainer}>
          {limitsByCategory.length === 0 && <div css={style.noItemsMessage}>{t('purchaseLimits.noItems')}</div>}
          {limitsByCategory.map(l => (
            <QuotaLimitCardByCategory key={`${l.limitRule.constraints.brand.code}-${Math.random()}`} quotaLimit={l} />
          ))}
        </div>
      ) : (
        <div css={style.quotaCardsContainer}>
          {limitsBySku.length === 0 && <div css={style.noItemsMessage}>{t('purchaseLimits.noItems')}</div>}
          {limitsBySku.map(l => (
            <QuotaLimitCardPerSku key={`${l.quantityLimit}-${Math.random()}`} quotaLimit={l} />
          ))}
        </div>
      )}
    </div>
  );
}

interface QuotaLimitCardByCategoryProps {
  quotaLimit: QuotaLimitByCategory;
}

function QuotaLimitCardByCategory({ quotaLimit }: QuotaLimitCardByCategoryProps): JSX.Element {
  const { t } = useTranslation();
  const { quota, limitRule } = quotaLimit;
  const isAllCategories = limitRule.constraints.productCategories == null;
  const categoryLabel =
    limitRule.constraints.categoryLabel === '' || limitRule.constraints.categoryLabel == null
      ? limitRule.constraints.productCategories?.join(', ')
      : limitRule.constraints.categoryLabel;
  return (
    <div css={style.quotaCard}>
      <div css={style.quotaInfoContainer}>
        {!isAllCategories && <Icon name='shopping bag' size='big' fitted />}
        <div css={style.quotaMiddleSection}>
          <div css={style.quotaProductCategory}>
            <div>{isAllCategories ? t('purchaseLimits.ALL_CATEGORIES') : categoryLabel}</div>
            {limitRule.constraints.storeTypology != null && <div>{limitRule.constraints.storeTypology}</div>}
          </div>
          {limitRule.limits.amount != null && limitRule.limits.amount.value != null && (
            <div>
              {toLocaleAmount(Math.round(quota.amount.value))} /{' '}
              {toLocaleAmount(Math.round(limitRule.limits.amount.value))} {limitRule.limits.amount.currency}
            </div>
          )}
        </div>

        {limitRule.limits.totalItemsCount != null && (
          <div css={style.quotaCountLimit}>
            {quota.totalItemsCount} / {limitRule.limits.totalItemsCount}
          </div>
        )}
      </div>
    </div>
  );
}

interface QuotaLimitCardPerSkuProps {
  quotaLimit: QuotaLimitPerSku;
}

function QuotaLimitCardPerSku({ quotaLimit }: QuotaLimitCardPerSkuProps): JSX.Element {
  const { t } = useTranslation();
  const isAllCategories = quotaLimit.productCategories == null;
  const categoryLabel =
    quotaLimit.categoryLabel === '' || quotaLimit.categoryLabel == null
      ? quotaLimit.productCategories?.join(', ')
      : quotaLimit.categoryLabel;
  return (
    <div css={style.quotaCard}>
      <div css={style.quotaInfoContainer}>
        {!isAllCategories && <Icon name='shopping bag' size='big' fitted />}
        <div css={style.quotaMiddleSection}>
          <div css={style.quotaProductCategory}>
            {isAllCategories ? t('purchaseLimits.ALL_CATEGORIES') : categoryLabel}
            {quotaLimit.storeTypology != null && <div>{quotaLimit.storeTypology}</div>}
          </div>
        </div>
        <div css={style.quotaCountLimit}>
          {quotaLimit.quantityLimit != null && quotaLimit.quantityLimit}{' '}
          {quotaLimit.quantityLimit != null && quotaLimit.quantityLimit > 1
            ? t('purchaseLimits.itemsPerSKU')
            : t('purchaseLimits.itemPerSKU')}
        </div>
      </div>
    </div>
  );
}
