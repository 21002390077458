import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { t } from 'i18next';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { checkValueInclude } from '../../../../shared/utils/utils';
import { QuotaLimitsByCategories } from '../models/QuotaLimit';
import { DiscountRule } from '../../../admin/discounts/models/discountRuleSet';
import { selectSearchBoxFilter } from './salesAssistantPurchaseLimitFilterSlice';
import { LimitRule } from '../../../admin/limits/models/limitRule';

interface SaleAssistantPurchaseLimitSliceState {
  quotaByCategories: QuotaLimitsByCategories;
  discountRules: DiscountRule[] | null;
  isFetchingQuotas: boolean;
  isFetchingRules: boolean;
  error: string;
}

const initialState: SaleAssistantPurchaseLimitSliceState = {
  quotaByCategories: {
    quotaLimits: [],
  },
  discountRules: null,
  isFetchingQuotas: false,
  isFetchingRules: false,
  error: '',
};

export const salesAssistantPurchaseLimitSlice = createSlice({
  name: 'salesAssistantPurchaseLimit',
  initialState,
  reducers: {
    startFetchQuotaByCategories: (
      state: Draft<SaleAssistantPurchaseLimitSliceState>
    ): SaleAssistantPurchaseLimitSliceState => {
      return {
        ...state,
        isFetchingQuotas: true,
      };
    },
    startFetchDiscountRules: (
      state: Draft<SaleAssistantPurchaseLimitSliceState>
    ): SaleAssistantPurchaseLimitSliceState => {
      return {
        ...state,
        isFetchingRules: true,
      };
    },
    finishQuotaByCategoriesFetch: (
      state: Draft<SaleAssistantPurchaseLimitSliceState>,
      payloadAction: PayloadAction<QuotaLimitsByCategories>
    ): SaleAssistantPurchaseLimitSliceState => {
      return {
        ...state,
        quotaByCategories: payloadAction.payload,
        isFetchingQuotas: false,
        error: '',
      };
    },
    finishDiscountRulesFetch: (
      state: Draft<SaleAssistantPurchaseLimitSliceState>,
      payloadAction: PayloadAction<DiscountRule[]>
    ): SaleAssistantPurchaseLimitSliceState => {
      return {
        ...state,
        discountRules: payloadAction.payload,
        isFetchingRules: false,
        error: '',
      };
    },
    httpError: (
      state: Draft<SaleAssistantPurchaseLimitSliceState>,
      payloadAction: PayloadAction<string>
    ): SaleAssistantPurchaseLimitSliceState => {
      return {
        ...state,
        isFetchingRules: false,
        isFetchingQuotas: false,
        error: payloadAction.payload,
      };
    },
  },
});

export const {
  startFetchQuotaByCategories,
  startFetchDiscountRules,
  finishDiscountRulesFetch,
  finishQuotaByCategoriesFetch,
  httpError,
} = salesAssistantPurchaseLimitSlice.actions;
export default salesAssistantPurchaseLimitSlice.reducer;

export const fetchQuotaLimitsByCategories =
  (brandCode: string, employeeCountryCode: string, employeeType: string, storeCountryCode: string): AppThunk =>
  async dispatch => {
    dispatch(startFetchQuotaByCategories());
    try {
      const quotaByCategories = await api.getQuotaLimitsByCategories(
        brandCode,
        employeeCountryCode,
        employeeType,
        storeCountryCode
      );
      dispatch(finishQuotaByCategoriesFetch(quotaByCategories));
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };

export const fetchDiscountRuleSets =
  (brandCode: string, countryCode: string, employeeType: string): AppThunk =>
  async dispatch => {
    dispatch(startFetchDiscountRules());
    try {
      const discountRules = await api.getSalesAssistantDiscountRules(brandCode, countryCode, employeeType);
      dispatch(finishDiscountRulesFetch(discountRules));
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };
const selectQuotaLimitsByCategories = (state: RootState): LimitRule[] =>
  _.sortBy(state.salesAssistantPurchaseLimit.quotaByCategories.quotaLimits, obj =>
    obj.constraints.productCategories == null ? 0 : 1
  );

export const selectFilteredQuotaLimitsByCategories = createSelector(
  selectSearchBoxFilter,
  selectQuotaLimitsByCategories,
  (filter, quotaLimits) => {
    if (filter === '') {
      return quotaLimits;
    }
    return quotaLimits.filter(
      quota =>
        checkValueInclude(
          quota.constraints.productCategories?.join(' ') || t('purchaseLimits.ALL_CATEGORIES'),
          filter
        ) || checkValueInclude(quota.constraints?.categoryLabel || '', filter)
    );
  }
);

export const selectIsFetchingQuotaByCategories = (state: RootState): boolean =>
  state.salesAssistantPurchaseLimit.isFetchingQuotas;
export const selectIsFetchingDiscountRules = (state: RootState): boolean =>
  state.salesAssistantPurchaseLimit.isFetchingRules;
const selectDiscountRules = (state: RootState): DiscountRule[] | null =>
  state.salesAssistantPurchaseLimit.discountRules;

export const selectFilteredDiscountRules = createSelector(
  selectDiscountRules,
  selectSearchBoxFilter,
  (rules, filter) => {
    if (filter === '') {
      return rules;
    }
    return rules?.filter(
      rule =>
        checkValueInclude(rule.discountPercentage.toString(), filter) ||
        checkValueInclude(rule.constraints.countries?.map(c => c.description).join(' ') || 'all countries', filter) ||
        checkValueInclude(rule.constraints.productCategories?.join(' ') || 'all categories', filter)
    );
  }
);
