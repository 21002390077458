import { css } from '@emotion/react';
import globalStyle from '../global.style';

const modalContainer = css`
  ${globalStyle.bodyNormal}
  &&& {
    width: 90%;
    max-width: 360px;
    height: 150px;
    text-align: center;
    border-radius: 10px;
  }
`;

const modalContent = css`
  &&&& {
    border-radius: 10px;
    @media (max-width: 760px) {
      padding: 18px 14px 8px !important;
    }
    @media (min-width: 760px) {
      padding: 30px 21px 8px !important;
    }
  }
`;

const modalActions = css`
  &&& {
    display: flex;
    justify-content: center;
    border: none;
    background-color: transparent;
    @media (max-width: 760px) {
      padding-top: 0 !important;
    }
  }
`;

const cancelBtn = css`
  ${globalStyle.secondaryButton};
  flex: 1;
`;

const confirmLogoutBtn = css`
  ${globalStyle.primaryButton};
  flex: 1;
`;

const logoutBtn = css`
  &&&&& {
    ${globalStyle.bodySmall};
    color: #000000;
    line-height: 24px;
    border-bottom: 1px solid #c2c2c2;
    padding: 18px 0;
    :hover {
      text-decoration: underline;
      color: #000000 !important;
    }
    background-color: transparent;
    text-align: left;
  }
`;

export default { modalContainer, modalContent, modalActions, cancelBtn, confirmLogoutBtn, logoutBtn };
