import { css } from '@emotion/react';

const roundedDropdown = css`
  &&&& {
    border-radius: 20px;
    &.ui.active.selection.dropdown {
      border-radius: 20px !important;
    }
    &.ui.search.dropdown > .text {
      left: 25px;
    }
    &.ui.search.dropdown > input.search {
      padding: 0.67857143em 1em 0.67857143em 2.8em;
    }
    &.ui.selection.dropdown > .search.icon {
      left: 1em;
      right: auto;
      padding: 8px 12px;
    }
    &.ui.search.dropdown .menu {
      top: 39px;
      max-height: 520px;
    }
  }
`;

const dropdownItemContainer = css`
  display: flex;
`
const dropdownItemName = css`
  font-weight: 500;
  margin-bottom: 2px;
`;
const dropdownItemInfo = css`
  font-size: 12px;
`;

const nonEligibleIconContainer = css`
  margin-left: auto;
  display: flex;
  align-items: center;
`


export default {
  roundedDropdown,
  dropdownItemContainer,
  dropdownItemName,
  dropdownItemInfo,
  nonEligibleIconContainer
};
