import { API_BASE_URL, API_URL } from '../../../constants';

const AUTH_URL = `${API_BASE_URL}/auth`;

const paths = {
  getToken: (): string => `${AUTH_URL}/get-token`,
  getPrincipal: (): string => `${AUTH_URL}/me`,
  refreshToken: (): string => `${AUTH_URL}/refresh-token`,
  updatediscountCardLanguage: () => `${API_URL}/user-preferences/dc-language`,
  acceptDiscountCardPolicies: () => `${API_URL}/user-preferences/accept-dc-policies`,
  updateDiscountCardFirstAccess: () => `${API_URL}/user-preferences/is-dc-first-access`,
  acceptBusinessCardPolicies: () => `${API_URL}/user-preferences/accept-bc-policies`,
  updateStores: () => `${API_URL}/user-preferences/stores`,
};

export default paths;
