import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  height: 100%;
  padding-top: 10px;
  padding-right: 10px;
`;

const headerContainer = css`
  position: relative;
  margin-bottom: 14px;
`;

const contentContainer = css`
  margin: 0 auto;
  height: 100%;
  text-align: center;
`;

const employeeCategoryButton = css`
  ${globalStyle.primaryButton};
  width: calc(50% - 10px);
  && {
    padding: 12px;
    height: 50px;
    min-width: 0;
    margin: 0;
  }
`;

const selectEmployeeCategoryTitle = css`
  text-align: center;
  && {
    margin: 40px 0;
  }
`;
const selectEmployeeCategoryContainer = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  margin: 40px auto;
`;

export default {
  container,
  headerContainer,
  selectEmployeeCategoryTitle,
  selectEmployeeCategoryContainer,
  contentContainer,
  employeeCategoryButton,
};
