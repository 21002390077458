/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dropdown, DropdownProps, Flag } from 'semantic-ui-react';
import i18next from 'i18next';
import style from './selectLanguageDropdown.style';
import { Language } from '../../features/auth/models/userPreference';
import { useAppDispatch } from '../../core/hooks';
import { updateDiscountCardLanguage } from '../../features/auth/store/principalSlice';

const languageOptions = [
  {
    key: 'en',
    text: (
      <span>
        <Flag name='us' />
        English
      </span>
    ),
    value: Language.en,
  },
  {
    key: 'fr',
    text: (
      <span>
        <Flag name='fr' />
        French
      </span>
    ),
    value: Language.fr,
  },
  {
    key: 'it',
    text: (
      <span>
        <Flag name='it' />
        Italian
      </span>
    ),
    value: Language.it,
  },
  // {
  //   key: 'zht',
  //   text: (
  //     <span>
  //       <Flag name='cn' />
  //       Chinese (traditional)
  //     </span>
  //   ),
  //   value: Language.zht,
  // },
  // {
  //   key: 'zhs',
  //   text: (
  //     <span>
  //       <Flag name='cn' />
  //       Chinese (simplified)
  //     </span>
  //   ),
  //   value: Language.zhs,
  // },
  // {
  //   key: 'ko',
  //   text: (
  //     <span>
  //       <Flag name='kr' />
  //       Korean
  //     </span>
  //   ),
  //   value: Language.ko,
  // },
  // {
  //   key: 'ja',
  //   text: (
  //     <span>
  //       <Flag name='jp' />
  //       Japanese
  //     </span>
  //   ),
  //   value: Language.ja,
  // },
];

export const SelectLanguageDropdown = () => {
  const dispatch = useAppDispatch();

  return (
    <Dropdown
      defaultValue={i18next.language}
      className='icon'
      floating
      direction='left'
      options={languageOptions}
      css={style.dropDown}
      onChange={(event, data: DropdownProps) => {
        const language = data.value as Language;
        dispatch(updateDiscountCardLanguage(language));
      }}
    />
  );
};
