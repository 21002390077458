import axios from 'axios';
import paths from './paths';
import { Brand, toBrand } from '../models/brand';

const api = {
  getBrands: async (): Promise<Brand[]> => {
    const response = await axios.get(paths.getBrands());
    return response.data.map(toBrand);
  },
  getEmployeeTypes: async (): Promise<string[]> => {
    const response = await axios.get(paths.getEmployeeTypes());
    return response.data;
  },
};
export default api;
