import { css } from '@emotion/react';

const container = css`
  height: calc(100% - 40px);
`;

const header = css`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
`;

const title = css`
  && {
    margin: 0;
    flex-grow: 1;
  }
`;

const icon = css`
  && {
    font-size: 16px;
    cursor: pointer;
    position: absolute;
  }
`;

const dimmer = css`
  && {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.1;
    width: 100%;
    height: 100%;
    padding: 0;
    .content {
      background-color: var(--dcd-white-color);
      padding: 20px;
      width: 100%;
      height: 100%;
      max-width: 700px;
      max-height: 1000px;
      color: #000;

      @media (min-width: 600px) {
        border-radius: 10px;
      }
    }
  }
`;

export default {
  container,
  header,
  dimmer,
  icon,
  title,
};
