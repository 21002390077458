import { css } from '@emotion/react';

const card = css`
  && {
    height: 100%;
    background-color: var(--dcd-kering-primary-color-10);
    margin: 0;
  }
`;

const cardContent = css`
  &&& {
    display: flex;
    padding: 0;
  }
`;

const contentContainer = css`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 20px;
  max-width: 1200px;
`;

const title = css`
  padding: 15px 0px 25px;
  margin: 0;
  text-align: center;
`;

export default {
  contentContainer,
  title,
  cardContent,
  card,
};
