import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const loaderContainer = css`
  position: relative;
  flex: 1;
`;

const cardsContainer = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  gap: 10px;
`;

const cardWrapper = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin: 3px 3px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const constraintsSection = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const percentageSection = css`
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const constraint = css`
  ${globalStyle.bodySmall};
`;

const percentage = css`
  width: max-content;
  text-align: right;
  ${globalStyle.bodyNormal};
  && {
    font-weight: 500;
  }
`;
const noItemsMessage = css`
  ${globalStyle.bodySmall};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
`;

export default {
  loaderContainer,
  noItemsMessage,
  cardsContainer,
  cardWrapper,
  constraintsSection,
  percentageSection,
  constraint,
  percentage,
};
