/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import style from './purchaseFilters.style';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  changeFilters,
  Filters,
  MonthCode,
  selectGeneralFilters,
  selectPurchasesBrands,
  selectPurchasesCategories,
  selectPurchasesMonths,
  selectPurchasesRefundedStatus,
  selectPurchasesStores,
} from '../store/purchaseHistoryFiltersSlice';
import { Brand } from '../../../../shared/models/brand';
import { RadioGroup, RadioItem } from '../../../../shared/components/RadioGroup';
import { ModalPage } from '../../../../shared/components/ModalPage';
import { selectPurchasesYear } from '../store/purchaseHistorySlice';

interface PurchaseFiltersProps {
  onBack: () => void;
}

export function PurchaseFilters({ onBack }: PurchaseFiltersProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectGeneralFilters);
  const [refundedStatusFilter, setRefundedStatusFilter] = useState(filters.refundedStatusName);
  const [brandFilter, setBrandFilter] = useState(filters.brand);
  const [storeFilter, setStoreFilter] = useState(filters.storeName);
  const [categoryFilter, setCategoryFilter] = useState(filters.category);
  const [monthFilter, setMonthFilter] = useState(filters.monthCode);
  const [dateRangeFilter, setDateRangeFilter] = useState<Array<Date | null>>(filters.dateRange);

  const applyFilters = () => {
    const filtersToApply: Filters = {
      refundedStatusName: refundedStatusFilter,
      brand: brandFilter,
      category: categoryFilter,
      storeName: storeFilter,
      monthCode: monthFilter,
      dateRange: dateRangeFilter,
    };
    dispatch(changeFilters(filtersToApply));
    onBack();
  };

  return (
    <ModalPage onClose={onBack} title={t('purchaseHistory.filters')}>
      <div css={style.container}>
        <div css={style.filters}>
          <TypeRadioGroup value={refundedStatusFilter.toString()} onClick={setRefundedStatusFilter} />
          <BrandRadioGroup value={brandFilter.code} onClick={setBrandFilter} />
          <StoreRadioGroup value={storeFilter} onClick={setStoreFilter} />
          <CategoryRadioGroup value={categoryFilter} onClick={setCategoryFilter} />
          <DateRadioGroup
            value={monthFilter}
            onMonthClick={setMonthFilter}
            dateRange={dateRangeFilter}
            onRangeSelection={setDateRangeFilter}
          />
        </div>
        <Button css={style.applyButton} onClick={applyFilters}>
          {t('purchaseHistory.applyFilters')}
        </Button>
      </div>
    </ModalPage>
  );
}

interface TypeRadioGroupProps {
  onClick: (code: string) => void;
  value: string;
}

const TypeRadioGroup = ({ value, onClick }: TypeRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableRefundedStatus = useAppSelector(selectPurchasesRefundedStatus);

  const refundedStatuses: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('purchaseHistory.allRefundedStatus') },
      ...availableRefundedStatus.map(s => ({ code: s, description: t('purchaseHistory.refundedStatus') })),
    ],
    [availableRefundedStatus, t]
  );

  return (
    <RadioGroup
      label={t('purchaseHistory.filterByRefundedStatus')}
      items={refundedStatuses}
      value={value}
      onClick={onClick}
    />
  );
};

interface BrandRadioGroupProps {
  onClick: (code: Brand) => void;
  value: string;
}

const BrandRadioGroup = ({ value, onClick }: BrandRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableBrands = useAppSelector(selectPurchasesBrands);
  const brands: RadioItem[] = useMemo(
    () => [{ code: '', description: t('purchaseHistory.allBrands') }, ...availableBrands],
    [availableBrands, t]
  );
  return (
    <RadioGroup
      label={t('purchaseHistory.filterByBrand')}
      items={brands}
      value={value}
      onClick={v => {
        if (v === '') {
          onClick({ code: '', description: '' });
        } else {
          const selectedBrand = brands.find(b => b.code === v) as Brand;
          onClick(selectedBrand);
        }
      }}
    />
  );
};

interface StoreRadioGroupProps {
  onClick: (code: string) => void;
  value: string;
}

const StoreRadioGroup = ({ value, onClick }: StoreRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableStores = useAppSelector(selectPurchasesStores);
  const stores: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('purchaseHistory.allStores') },
      ...availableStores.map(s => ({ code: s, description: s })),
    ],
    [availableStores, t]
  );

  return <RadioGroup label={t('purchaseHistory.filterByStore')} items={stores} value={value} onClick={onClick} />;
};

interface CategoryRadioGroupProps {
  onClick: (code: string) => void;
  value: string;
}

const CategoryRadioGroup = ({ value, onClick }: CategoryRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const availableCategories = useAppSelector(selectPurchasesCategories);
  const categories: RadioItem[] = useMemo(
    () => [
      { code: '', description: t('purchaseHistory.allCategories') },
      ...availableCategories.map(c => ({ code: c, description: c })),
    ],
    [availableCategories, t]
  );
  return (
    <RadioGroup label={t('purchaseHistory.filterByCategory')} items={categories} value={value} onClick={onClick} />
  );
};

interface MonthRadioGroupProps {
  onMonthClick: (code: MonthCode) => void;
  value: string;
  dateRange: Array<Date | null>;
  onRangeSelection: (range: Array<Date | null>) => void;
}

const DateRadioGroup = ({ value, onMonthClick, dateRange, onRangeSelection }: MonthRadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const months = useAppSelector(selectPurchasesMonths);
  const purchasesYear = useAppSelector(selectPurchasesYear);
  const [startDate, endDate] = dateRange;
  const monthItems: RadioItem[] = useMemo(
    () => months.filter(m => m.code !== 'CUSTOM').map(m => ({ code: m.code, description: m.description })),
    [months]
  );
  const customItem: RadioItem = useMemo(
    () =>
      months
        .filter(m => m.code === 'CUSTOM')
        .map(m => ({ code: m.code, description: m.description }))
        .pop() as RadioItem,
    [months]
  );

  return (
    <section css={style.filterSection}>
      <div css={style.radioGroupLabel}>{t('purchaseHistory.filterByDate')}</div>
      <Form css={style.radioGroup}>
        {monthItems.map(item => (
          <Form.Field key={item.code}>
            <Radio
              css={style.radioButton}
              name='radioGroup'
              value={item.code}
              label={item.description}
              checked={item.code === value}
              onClick={() => {
                onMonthClick(item.code as MonthCode);
                onRangeSelection([null, null]);
              }}
            />
          </Form.Field>
        ))}
        <Form.Field key={customItem.code} style={{ display: 'flex', alignItems: 'center' }}>
          <Radio
            css={style.radioButton}
            name='radioGroup'
            value={customItem.code}
            checked={customItem.code === value}
            onClick={() => {
              onMonthClick(customItem.code as MonthCode);
            }}
          />
          {customItem.code === 'CUSTOM' && (
            <div css={style.datePickerContainer}>
              <DatePicker
                minDate={new Date(purchasesYear, 0, 1)}
                maxDate={purchasesYear === new Date().getFullYear() ? new Date() : new Date(purchasesYear, 11, 31)}
                disabled={value !== customItem.code}
                css={style.datePicker}
                placeholderText={t('purchaseHistory.selectRange')}
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(update: Array<Date | null>) => {
                  onRangeSelection(update);
                }}
                withPortal
              />
            </div>
          )}
        </Form.Field>
      </Form>
    </section>
  );
};
