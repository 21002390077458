import axios from 'axios';
import paths from './paths';
import { EmployeeCard, toEmployeeCard } from '../../../../shared/models/employeeCard';

const api = {
  getMyCard: async (): Promise<EmployeeCard> => {
    const response = await axios.get(paths.getMyCard());
    return toEmployeeCard(response.data);
  },
  getMyAppleWalletEmployeeCard: async (): Promise<Blob> => {
    const response = await axios.get(paths.getMyAppleWalletEmployeeCard(), { responseType: 'blob' });
    return response.data;
  },
  addToGoogleWallet: async (): Promise<string> => {
    const response = await axios.get(paths.addToGoogleWallet());
    return response.data;
  },
};
export default api;
