import { css } from '@emotion/react';

const page = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 760px;
  margin: auto;
`;

export default {
  page,
};
