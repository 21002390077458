/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import GlobalLimitsCard from './GlobalLimitsCard';
import style from './globalLimits.style';
import { useAppSelector } from '../../../../core/hooks';
import { selectGlobalLimits } from '../stores/limitRulesSlice';
import { CreationData } from '../../../../shared/components/CreationData';

export default function GlobalLimits(): JSX.Element {
  const { t } = useTranslation();
  const rules = useAppSelector(selectGlobalLimits);
  return (
    <div css={style.wrapper}>
      <div css={style.globalLimitsContainer}>
        <div css={style.title}>{t('limits.globalLimits')}</div>
        <div css={style.rulesWrapper}>
          <GlobalLimitsCard globalLimits={rules} />
        </div>
      </div>
      <CreationData creationInfo={null} updateInfo={rules.updateInfo} />
    </div>
  );
}
