import { css } from '@emotion/react';

const search = css`
  &&&& {
    width: 100%;
    height: 35px;
    display: flex;
    background-color: var(--dcd-white-color);

    i {
      color: var(--dcd-gray-color-50);
      opacity: 1;
    }

    input {
      width: calc(100% - 70px);
      background-color: var(--dcd-white-color);
      border-radius: 20px;
      border-color: var(--dcd-gray-color-50);
      color: var(--dcd-gray-color-50);
      &:focus {
        color: var(--dcd-gray-color-80);

        + i {
          color: var(--dcd-gray-color-80);
        }
      }
    }
  }
`;

const searchWithYearSelection = css`
  ${search.styles}

  &&&& input {
    border-radius: 20px 0 0 20px;
    &:focus {
      border-color: var(--dcd-gray-color-50);
      border-right-color: transparent !important;
    }
  }
`;

const yearSelectionDropdown = css`
  &&& {
    min-width: 70px;
    min-height: 35px;
    width: 70px;
    height: 35px;
    font-size: 13px;
    border-radius: 0 20px 20px 0;
    background-color: var(--dcd-secondary-color-30);
    border-color: var(--dcd-gray-color-50);
    padding: 10px 15px 11px 8px;
    i.dropdown.icon {
      padding: 10px 6px 11px 0;
      color: var(--dcd-kering-primary-color);
    }
  }
  &.ui.dropdown.selection.active .menu,
  &.ui.selection.dropdown:focus {
    border-color: var(--dcd-gray-color-50);
  }
  &.ui.dropdown .menu > .item {
    background-color: transparent;
    font-weight: 500;
    padding: 10px 11px !important;
  }
  && .menu > .item:hover {
    background-color: var(--dcd-gray-color-30);
  }
  &&.ui.dropdown .menu .active.item {
    font-weight: 600;
  }
  &&.ui.dropdown .menu {
    min-width: 0;
    min-height: 0;
  }
`;

export default {
  search,
  searchWithYearSelection,
  yearSelectionDropdown,
};
