const getEnvConst = (path: string, env: string | undefined = process.env.NODE_ENV): string => {
  const w = window as never;
  return w['_env_'] != null ? (w['_env_'][path] as string) : (process.env[path] as string);
};

export const APP_URL = getEnvConst('REACT_APP_BASE_URL');
export const API_BASE_URL = getEnvConst('REACT_APP_API_BASE_URL');
export const API_URL = getEnvConst('REACT_APP_API_URL');
export const REDIRECT_URI = getEnvConst('REACT_APP_REDIRECT_URI');
export const OAUTH_AUTHORIZE_URL = getEnvConst('REACT_APP_OAUTH_AUTHORIZE_URL');
export const OAUTH_TOKEN_URL = getEnvConst('REACT_APP_OAUTH_TOKEN_URL');
export const OAUTH_CLIENT_ID = getEnvConst('REACT_APP_OAUTH_CLIENT_ID');
export const OAUTH_TOKEN_EXPIRATION = getEnvConst('REACT_APP_OAUTH_TOKEN_EXPIRATION') as unknown as number;
export const DEFAULT_CACHE_TTL = 0;
export const APP_ENV = getEnvConst('REACT_APP_ENV');
