import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';

interface ProductImageSlice {
  image: string;
  isFetching: boolean;
  error: string;
}

const initialState: ProductImageSlice = {
  image: 'no-image',
  isFetching: false,
  error: '',
};

export const productImageSlice = createSlice({
  name: 'productImageSlice',
  initialState,
  reducers: {
    startFetch: (state: Draft<ProductImageSlice>): ProductImageSlice => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchImage: (
      state: Draft<ProductImageSlice>,
      payloadAction: PayloadAction<string>
    ): ProductImageSlice => {
      return {
        ...state,
        image: payloadAction.payload,
        isFetching: false,
      };
    },
    httpError: (state: Draft<ProductImageSlice>, payloadAction: PayloadAction<string>): ProductImageSlice => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
  },
});

export const { startFetch, finishFetchImage, httpError } =
  productImageSlice.actions;

export const fetchImage =
  (imageUrl: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
     const image = await api.getProductImage(imageUrl)
     dispatch(finishFetchImage(image))
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };


export const selectProductImage = (state: RootState): string => state.productImage.image;
export const selectIsFetchingProductImage = (state: RootState): boolean => state.productImage.isFetching;

export default productImageSlice.reducer;