import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../core/store';
import { Brand } from '../models/brand';
import api from '../utils/api';

interface BrandsSliceState {
  data: Brand[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: BrandsSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    startFetch: (state: Draft<BrandsSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<BrandsSliceState>, action: PayloadAction<Brand[]>): BrandsSliceState => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    httpError: (state: Draft<BrandsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError } = brandsSlice.actions;

export const fetchBrands = (): AppThunk => async dispatch => {
  dispatch(startFetch());
  try {
    const brands = await api.getBrands();
    dispatch(finishFetch(brands));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const selectBrands = (state: RootState): Brand[] => state.brands.data || [];

export const selectSortedBrands = createSelector(selectBrands, (brands: Brand[]): Brand[] => {
  const brandsWithoutDodo = brands.filter(b => b.code !== 'DODO');
  return _.sortBy(brandsWithoutDodo, ['description']);
});

export default brandsSlice.reducer;
