import axios from 'axios';
import paths from './paths';

const api = {
  getEmployeePrivacyPolicyPdf: async (): Promise<Blob> => {
    const response = await axios.get(paths.getEmployeePrivacyPolicyPdf(), { responseType: 'blob' });
    return response.data;
  },
  getEmployeeConditionsOfUsePdf: async (language: string): Promise<Blob> => {
    const response = await axios.get(paths.getEmployeeConditionsOfUsePdf(language), { responseType: 'blob' });
    return response.data;
  },
};

export default api;
