import { css } from '@emotion/react';

const dropDown = css`
  line-height: 24px;

  &.ui.dropdown > .dropdown.icon {
    display: none;
  }
`;

export default {
  dropDown,
};
