import moment from 'moment';

export const scrollToTop = (element: HTMLElement | null, behavior: 'smooth' | 'auto' = 'smooth') => {
  if (element != null)
    element.scrollTo({
      top: 0,
      behavior,
    });
};

export type AppKey = 'dc-manifest' | 'bc-manifest';

const manifestMap: { [key in AppKey]: string } = {
  'dc-manifest': `${window.location.origin}/dc-manifest.json`,
  'bc-manifest': `${window.location.origin}/bc-manifest.json`,
};

const titleMap: { [key in AppKey]: string } = {
  'dc-manifest': `Privilege Card`,
  'bc-manifest': `Business Card`,
};

const titleBarColor: { [key in AppKey]: string } = {
  'dc-manifest': `#ffffff`,
  'bc-manifest': `#f3f3f4`,
};

const setManifest = (key: 'dc-manifest' | 'bc-manifest') => {
  const stringManifest = manifestMap[key];
  const element = document.querySelector('#manifest-placeholder');
  if (element != null) {
    element.setAttribute('href', stringManifest);
  }
};

const setTitleBarColor = (key: 'dc-manifest' | 'bc-manifest') => {
  const stringManifest = titleBarColor[key];
  const tileColorElement = document.querySelector('#tile-color');
  if (tileColorElement != null) {
    tileColorElement.setAttribute('content', stringManifest);
  }
  const themeColorElement = document.querySelector('#theme-color');
  if (themeColorElement != null) {
    themeColorElement.setAttribute('content', stringManifest);
  }
};

const setTitle = (key: 'dc-manifest' | 'bc-manifest') => {
  document.title = titleMap[key];
};

export const updatePWAManifest = (key: AppKey) => {
  setManifest(key);
  setTitle(key);
  setTitleBarColor(key);
};

const images = [];
const preloadImage = async (src: string) => {
  const image = new Image();
  image.onload = loadedImg => {
    images.push(loadedImg);
  };
  image.src = src;
  await image.decode();
};

export const preloadImages = (srcs: string[]) => {
  return Promise.all(srcs.map(src => preloadImage(src)));
};

export const formatDate = (stringDate: string) => moment(stringDate).format('YYYY-MM-DD');
export const formatDateAndTime = (stringDate: string) => moment(stringDate).format('YYYY-MM-DD HH:mm:ss');
