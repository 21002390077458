import axios from 'axios';
import { toTrafficLights, TrafficLightsByCategoryAndStoreTypology, TrafficLightsReadingRequest } from '../models/trafficLights';
import paths from './paths';

const api = {
  getTrafficLights: async (request: TrafficLightsReadingRequest): Promise<TrafficLightsByCategoryAndStoreTypology[]> => {
    const response = await axios.post(paths.getTrafficLights(), request);
    return response.data.map(toTrafficLights);
  },
};

export default api;
