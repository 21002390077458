import { API_URL } from '../../../../constants';

const STORES_URL = `${API_URL}/stores`;
const GLOBAL_STORES_URL = `${API_URL}/global-stores`;

const paths = {
  getStores: (): string => `${STORES_URL}`,
  getGlobalStores: (filter: string): string => `${GLOBAL_STORES_URL}/?filter=${filter}`,
};

export default paths;
