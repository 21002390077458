import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import { Filters, UserActivity } from '../models/userActivity';
import api from '../utils/api';

interface UserActivitiesSliceState {
  userActivities: UserActivity[];
  totalCount: number;
  isFetching: boolean;
  currentPage: number;
  pageSize: number;
  error: string;
}

const initialState: UserActivitiesSliceState = {
  userActivities: [],
  totalCount: 0,
  isFetching: false,
  currentPage: 1,
  pageSize: 30,
  error: '',
};

export const userActivitiesSlice = createSlice({
  name: 'userActivities',
  initialState,
  reducers: {
    startFetch: (state: Draft<UserActivitiesSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (
      state: Draft<UserActivitiesSliceState>,
      action: PayloadAction<{ data: UserActivity[]; totalCount: number }>
    ) => {
      return {
        ...state,
        userActivities: action.payload.data,
        totalCount: action.payload.totalCount,
        isFetching: false,
      };
    },
    setPage(state, action: PayloadAction<number>) {
      return { ...state, currentPage: action.payload };
    },
    httpError: (state: Draft<UserActivitiesSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, setPage, httpError } = userActivitiesSlice.actions;

export const fetchUserActivities =
  (page: number, searchBoxFilter: string, filters: Filters): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const response = await api.getUserActivities(page, searchBoxFilter, filters);
      dispatch(finishFetch(response));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectUserActivities = (state: RootState): UserActivity[] => state.userActivities.userActivities;
export const selectIsFetchingUserActivities = (state: RootState): boolean => state.userActivities.isFetching;
export const selectCurrentPage = (state: RootState): number => state.userActivities.currentPage;
export const selectTotalCount = (state: RootState): number => state.userActivities.totalCount;
export const selectPageSize = (state: RootState): number => state.userActivities.pageSize;

export default userActivitiesSlice.reducer;
