/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import style from './seeMoreHeader.style';

interface HeaderProps {
  title: string;
  rightElement: string;
}

export const SeeMoreHeader: React.FC<HeaderProps> = ({ title, rightElement }) => {
  const navigate = useNavigate();
  const goBack = useCallback(
    () => (navigate.length > 1 ? navigate(-1) : navigate('/privilege-card/employee/see-more')),
    [navigate]
  );

  return (
    <div css={style.headerContainer}>
      <div css={style.headerLeftElement}>
        <Icon name='arrow left' size='large' css={style.headerArrow} onClick={goBack} />
      </div>
      <div css={style.headerCenterElement}>
        <h2 css={style.headerTitle}>{title}</h2>
      </div>
      <div css={style.headerRightElement}>{rightElement}</div>
    </div>
  );
};
