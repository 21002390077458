import axios from 'axios';
import paths from './paths';
import { EmployeeCard, toEmployeeCard } from '../../../../shared/models/employeeCard';

const api = {
  getEmployeeCards: async (filter: string): Promise<EmployeeCard[]> => {
    const response = await axios.get(paths.getEmployeeCards(filter));
    return response.data.map(toEmployeeCard);
  },
  getEmployeeCard: async (id: string): Promise<EmployeeCard> => {
    const response = await axios.get(paths.getEmployeeCard(id));
    return toEmployeeCard(response.data);
  },
};

export default api;
