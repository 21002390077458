import axios from 'axios';
import paths from './paths';
import { SalesAssistantStore, toSalesAssistantStore } from '../../../auth/models/principal';

const api = {
  getStores: async (): Promise<SalesAssistantStore[]> => {
    const response = await axios.get(paths.getStores());
    return response.data.map(toSalesAssistantStore);
  },
  getGlobalStores: async (filter: string): Promise<SalesAssistantStore[]> => {
    const response = await axios.get(paths.getGlobalStores(filter));
    return response.data.map(toSalesAssistantStore);
  },
};

export default api;
