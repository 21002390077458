/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { RequireAuth } from '../core/components/RequireAuth';
import { DiscountCardPage } from './DiscountCardPage';
import { updatePWAManifest } from '../core/utils';

interface PageProps {
  children: JSX.Element;
}

export const DiscountCardPageUnderSecurity = (props: PageProps) => {
  const { children } = props;
  useEffect(() => {
    updatePWAManifest('dc-manifest');
  });

  return (
    <RequireAuth>
      <DiscountCardPage>{children}</DiscountCardPage>
    </RequireAuth>
  );
};
