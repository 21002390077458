import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { NonEligibleConstraint } from '../models/nonEligibleConstraint';

interface NonEligibleConstraintsSliceState {
  data: NonEligibleConstraint[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: NonEligibleConstraintsSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const nonEligibleConstraintsSlice = createSlice({
  name: 'nonEligibleConstraints',
  initialState,
  reducers: {
    startFetch: (state: Draft<NonEligibleConstraintsSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<NonEligibleConstraintsSliceState>, action: PayloadAction<NonEligibleConstraint[]>) => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    httpError: (state: Draft<NonEligibleConstraintsSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError } = nonEligibleConstraintsSlice.actions;

export const fetchNonEligibleConstraints =
  (brandCode: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const nonEligibleConstraints = await api.getNonEligibleConstraintsByBrand(brandCode);
      dispatch(finishFetch(nonEligibleConstraints));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectNonEligibleConstraints = (state: RootState): NonEligibleConstraint[] | null =>
  state.nonEligibleConstraints.data;
export const selectIsFetchingNonEligibleConstraints = (state: RootState): boolean =>
  state.nonEligibleConstraints.isFetching;

export default nonEligibleConstraintsSlice.reducer;
