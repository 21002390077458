import { css } from '@emotion/react';
import globalStyle from './global.style';

const menu = css`
  ${globalStyle.bodySmall};
  && {
    display: flex;
    background-color: var(--dcd-secondary-color-40);
    max-width: 500px;
    border: none;
    i {
      height: 24px;
      margin-bottom: auto;
    }
  }
`;

const menuContainer = css`
  background-color: var(--dcd-secondary-color-40);
  display: flex;
  justify-content: center;
  height: max-content;
  width: 100%;

  @media (min-width: 821px) {
    max-width: 600px;
  }
`;

const menuText = css`
  font-size: 12px;
  white-space: pre-line;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-right: 5px;
  padding-left: 5px;
`;

export default {
  menu,
  menuContainer,
  menuText,
};
