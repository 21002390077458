import { LimitRule, MoneyAmount } from '../../../admin/limits/models/limitRule';

export interface MoneyAmountWithValueConverted extends MoneyAmount {
  valueConverted?: number;
}

export interface QuotaLimitByCategory {
  category: string;
  itemsCountLimit: number | null;
  amountLimit: MoneyAmountWithValueConverted;
  itemsCountPerSKULimit: number | null;
}

interface QuotaLimitByCategoryResponse {
  category: string;
  itemsCountLimit: number | null;
  amountLimit: MoneyAmountWithValueConverted;
  itemsCountPerSKULimit: number | null;
}
export const toQuotaLimitByCategory = (response: QuotaLimitByCategoryResponse): QuotaLimitByCategory => {
  return {
    category: response.category,
    amountLimit: response.amountLimit,
    itemsCountLimit: response.itemsCountLimit,
    itemsCountPerSKULimit: response.itemsCountPerSKULimit,
  };
};

export interface QuotaLimitsByCategories {
  quotaLimits: LimitRule[];
}

interface QuotaLimitsByCategoriesResponse {
  quotaLimits: LimitRule[];
}

export const toQuotaLimitsByCategories = (response: QuotaLimitsByCategoriesResponse): QuotaLimitsByCategories => {
  return {
    quotaLimits: response.quotaLimits,
  };
};
