/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Label,
  Loader,
  Pagination,
  PaginationProps,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';
import _ from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import style from './userActivities.style';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  fetchUserActivities,
  selectCurrentPage,
  selectUserActivities,
  selectIsFetchingUserActivities,
  selectPageSize,
  selectTotalCount,
  setPage,
} from '../stores/userActivitiesSlice';
import { UserActivitiesFilters } from '../components/UserActivitiesFilters';
import {
  Filters,
  changeFilters,
  changeSearchBoxFilter,
  fetchBrandsFilters,
  selectFilters,
  selectSearchBoxFilter,
} from '../stores/userActivitiesFiltersSlice';
import { SearchBox } from '../../../../shared/components/SearchBox';
import { formatDateAndTime } from '../../../../core/utils';
import { UserActivity } from '../models/userActivity';

export const UserActivities = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userActivities = useAppSelector(selectUserActivities);
  const isFetchingUserActivities = useAppSelector(selectIsFetchingUserActivities);
  const currentPage = useAppSelector(selectCurrentPage);
  const totalCount = useAppSelector(selectTotalCount);
  const pageSize = useAppSelector(selectPageSize);
  const searchBoxFilter = useAppSelector(selectSearchBoxFilter);
  const filters = useAppSelector(selectFilters);
  const debounced = useDebouncedCallback(async (filter: string) => {
    if (filter.trim() !== '' && filter.trim().length < 3) {
      return;
    }
    if (filter.trim() === '') {
      dispatch(setPage(1));
    }
    dispatch(changeSearchBoxFilter(filter));
    dispatch(fetchUserActivities(currentPage, filter, filters));
  }, 300);

  useEffect(() => {
    dispatch(fetchBrandsFilters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserActivities(currentPage, searchBoxFilter, filters));
  }, [currentPage, dispatch, filters, searchBoxFilter]);

  const handlePageChange = (e: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
    dispatch(setPage(data.activePage as number));
  };

  return (
    <div css={style.container}>
      <div css={style.title}>
        <span>{t('userActivities.userActivities')}</span>
      </div>
      <div css={style.filtersContainer}>
        <div css={style.searchBoxContainer}>
          <SearchBox
            key='userActivitiesSearch'
            defaultValue={searchBoxFilter}
            onChange={value => debounced(value)}
            placeholder={t('userActivities.search')}
          />
        </div>
        <UserActivitiesFilters />
      </div>
      <div css={style.filterBadgesContainer}>
        <FiltersBadges filters={filters} />
      </div>
      <div css={style.userActivitiesContainer}>
        <UserActivitiesTable userActivities={userActivities} isFetching={isFetchingUserActivities} />
      </div>
      <Pagination
        activePage={currentPage}
        pointing
        secondary
        onPageChange={handlePageChange}
        totalPages={Math.ceil(totalCount / pageSize)}
      />
    </div>
  );
};

const FiltersBadges = ({ filters }: { filters: Filters }) => {
  const dispatch = useAppDispatch();

  return (
    <div css={style.badgesWrapper}>
      <div css={style.badgesContainer}>
        {!_.isEmpty(filters.brand) && (
          <Label css={style.filterBadge}>
            {filters.brand}
            <Icon
              name='delete'
              onClick={() => {
                dispatch(setPage(1));
                dispatch(changeFilters({ ...filters, brand: '' }));
              }}
            />
          </Label>
        )}
        {!_.isEmpty(filters.activitySection) && (
          <Label css={style.filterBadge}>
            {filters.activitySection}
            <Icon
              name='delete'
              onClick={() => {
                dispatch(setPage(1));
                dispatch(changeFilters({ ...filters, activitySection: '' }));
              }}
            />
          </Label>
        )}
        {!_.isEmpty(filters.activityType) && (
          <Label css={style.filterBadge}>
            {filters.activityType}
            <Icon
              name='delete'
              onClick={() => {
                dispatch(setPage(1));
                dispatch(changeFilters({ ...filters, activityType: '' }));
              }}
            />
          </Label>
        )}
        {!_.isEqual(filters.dateRange, [null, null]) && (
          <Label css={style.filterBadge}>
            {moment(filters.dateRange[0]).format('DD/MM/YY')}
            {`${filters.dateRange[1] != null ? moment(filters.dateRange[1]).format(' - DD/MM/YY') : ''}`}
            <Icon
              name='delete'
              onClick={() => {
                dispatch(setPage(1));
                dispatch(changeFilters({ ...filters, dateCode: '', dateRange: [null, null] }));
              }}
            />
          </Label>
        )}
      </div>
    </div>
  );
};

export const UserActivitiesTable = ({
  userActivities,
  isFetching,
}: {
  userActivities: UserActivity[];
  isFetching: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Table singleLine>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>{t('userActivities.name')}</TableHeaderCell>
          <TableHeaderCell>{t('userActivities.surname')}</TableHeaderCell>
          <TableHeaderCell>{t('userActivities.email')}</TableHeaderCell>
          <TableHeaderCell>{t('userActivities.brand')}</TableHeaderCell>
          <TableHeaderCell>{t('userActivities.section')}</TableHeaderCell>
          <TableHeaderCell>{t('userActivities.type')}</TableHeaderCell>
          <TableHeaderCell>{t('userActivities.date')}</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {userActivities.length <= 0 ? (
          <TableRow>
            <TableCell>{t('userActivities.noActivities')}</TableCell>
          </TableRow>
        ) : isFetching ? (
          <TableRow>
            <TableCell>
              <Loader active size='tiny' css={style.loader} inline />
            </TableCell>
          </TableRow>
        ) : (
          userActivities.map(a => (
            <TableRow>
              <TableCell>{a.userName}</TableCell>
              <TableCell>{a.userSurname}</TableCell>
              <TableCell>{a.userEmail}</TableCell>
              <TableCell>{a.userBrand.description}</TableCell>
              <TableCell>{a.activitySection}</TableCell>
              <TableCell>{a.activityType}</TableCell>
              <TableCell>{formatDateAndTime(a.date.toString())}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};
