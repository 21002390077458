export interface Basket {
  basketItems: BasketProduct[];
}

export interface BasketProduct {
  sku: string;
  name: string;
  description: string;
  color: string;
  category: string;
  price: number;
  priceCurrency: string;
  discount: number;
  discountedPrice: number;
  errors: string[];
  warnings: string[];
}
export interface BasketUpdateRequest {
  storeCode: string;
  employeeEmail: string;
  employeeBrandCode: string;
  productsSkus: string[];
}
export interface BasketResponse {
  basketItems: BasketItemResponse[];
}

interface BasketItemResponse {
  sku: string;
  category: string;
  price: number;
  discount: number;
  discountedPrice: number;
  priceCurrency: string;
  name: string;
  description: string;
  color: string;
  errors: string[];
  warnings: string[];
}

export interface GroupedBasketProduct extends BasketProduct {
  counter: number;
}

export const toBasketItem = (response: BasketItemResponse): BasketProduct => {
  return {
    sku: response.sku,
    category: response.category,
    discount: response.discount,
    discountedPrice: response.discountedPrice,
    price: response.price,
    priceCurrency: response.priceCurrency,
    name: response.name,
    description: response.description,
    color: response.color,
    errors: response.errors,
    warnings: response.warnings,
  };
};
export const toBasket = (response: BasketResponse): Basket => {
  return { basketItems: response.basketItems.map(i => toBasketItem(i)) };
};
