import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const loaderContainer = css`
  position: relative;
  flex: 1;
`;

const quotaCardsContainer = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const quotaCard = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin: 3px 3px 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const quotaInfoContainer = css`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
`;
const quotaMiddleSection = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  padding-left: 18px;
`;
const quotaLimitSection = css`
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const quotaProductCategory = css`
  display: flex;
  flex-direction: column;
  ${globalStyle.bodySmall};
  margin-bottom: 0;
  && {
    font-weight: 500;
  }
`;

const quotaCountLimit = css`
  width: max-content;
  text-align: right;
`;
const noItemsMessage = css`
  ${globalStyle.bodySmall};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex: 1;
`;

const globalLimitDoubleCurrency = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export default {
  loaderContainer,
  noItemsMessage,
  quotaCardsContainer,
  quotaCard,
  quotaInfoContainer,
  quotaMiddleSection,
  quotaLimitSection,
  quotaProductCategory,
  quotaCountLimit,
  globalLimitDoubleCurrency,
};
