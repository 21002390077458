export enum Language {
  en = 'en',
  fr = 'fr',
  it = 'it',
  zht = 'zh-Hant',
  zhs = 'zh-Hans',
  ko = 'ko',
  ja = 'ja',
}

export interface DiscountCardUserPreference {
  policiesAccepted: boolean;
  language: Language;
  isFirstAccess: boolean;
}

export interface DiscountCardUserPreferenceResponse {
  policiesAccepted: boolean;
  language: Language;
  isFirstAccess: boolean;
}

export const toDiscountCardUserPreference = (
  response: DiscountCardUserPreferenceResponse
): DiscountCardUserPreference => ({
  policiesAccepted: response.policiesAccepted,
  language: response.language,
  isFirstAccess: response.isFirstAccess,
});

export interface BusinessCardUserPreference {
  policiesAccepted: boolean;
}

export interface BusinessCardUserPreferenceResponse {
  policiesAccepted: boolean;
}

export const toBusinessCardUserPreference = (
  response: BusinessCardUserPreferenceResponse
): BusinessCardUserPreference => ({
  policiesAccepted: response.policiesAccepted,
});
