/** @jsxImportSource @emotion/react */
import React from 'react';
import { Dimmer, Icon } from 'semantic-ui-react';
import style from './modalPage.style';

interface ModalPageProps {
  onClose: () => void;
  title?: string;
  children: JSX.Element | JSX.Element[] | string;
}

export function ModalPage({ onClose, title, children }: ModalPageProps): JSX.Element {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dimmer active css={style.dimmer}>
      <Header onClose={handleClose} title={title} />
      <div css={style.container}>{children}</div>
    </Dimmer>
  );
}

interface HeaderProps {
  onClose: () => void;
  title?: string;
}

const Header = ({ onClose, title }: HeaderProps): JSX.Element => {
  return (
    <div css={style.header}>
      <Icon size='large' name='arrow left' onClick={onClose} css={style.icon} />
      <h4 css={style.title}>{title}</h4>
    </div>
  );
};
