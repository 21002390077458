import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function useParamSelector(selector, ...params) {
  return useSelector(state => selector(state, ...params));
}
