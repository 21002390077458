/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Loader } from 'semantic-ui-react';
import { useAppSelector } from '../../../../core/hooks';
import {
  selectFilteredQuotaLimitsByCategories,
  selectIsFetchingQuotaByCategories,
} from '../store/salesAssistantPurchaseLimitSlice';
import { toLocaleAmount } from '../../../../shared/utils/utils';
import style from './quotaLimits.style';
import { GlobalLimits, LimitRule } from '../../../admin/limits/models/limitRule';
import { SalesAssistantStore } from '../../../auth/models/principal';
import { isFashionBrand } from '../../../../shared/models/brand';
import { MoneyAmountWithValueConverted } from '../models/QuotaLimit';

export default function QuotaLimits(): JSX.Element {
  const quotaLimitsByCategories: LimitRule[] = useAppSelector(selectFilteredQuotaLimitsByCategories);
  const isFetching = useAppSelector(selectIsFetchingQuotaByCategories);
  const { t } = useTranslation();
  return (
    <div css={style.quotaCardsContainer}>
      {isFetching ? (
        <div css={style.loaderContainer}>
          <Loader size='big' active />
        </div>
      ) : (
        <>
          {quotaLimitsByCategories.length === 0 && <div css={style.noItemsMessage}>{t('purchaseLimits.noItems')}</div>}
          {quotaLimitsByCategories.map(limit => (
            <QuotaLimitCard quotaLimit={limit} />
          ))}
        </>
      )}
    </div>
  );
}

interface GlobalLimitsCardProps {
  selectedStore: SalesAssistantStore;
  globalLimits: GlobalLimits;
}

export const GlobalLimitsCard = ({ globalLimits, selectedStore }: GlobalLimitsCardProps): JSX.Element => {
  const { t } = useTranslation();
  const relevantGlobalLimits: MoneyAmountWithValueConverted = isFashionBrand(selectedStore.brand.code)
    ? {
        value: globalLimits.fashionAmount,
        currency: globalLimits.converted?.currency != null ? globalLimits.converted?.currency : 'EUR',
        valueConverted: globalLimits.converted?.fashionAmount,
      }
    : {
        value: globalLimits.jewelryAmount,
        currency: globalLimits.converted?.currency != null ? globalLimits.converted?.currency : 'EUR',
        valueConverted: globalLimits.converted?.jewelryAmount,
      };
  return (
    <div css={style.quotaCard}>
      <div css={style.quotaInfoContainer}>
        <div css={style.quotaMiddleSection}>
          <div css={style.quotaProductCategory}>{t(`purchaseLimits.GLOBAL`)}</div>
        </div>
        <div css={style.quotaLimitSection}>
          {relevantGlobalLimits.valueConverted != null && relevantGlobalLimits.currency !== 'EUR' ? (
            <div css={style.globalLimitDoubleCurrency}>
              {relevantGlobalLimits.value != null && (
                <div css={style.quotaCountLimit}>{toLocaleAmount(Math.round(relevantGlobalLimits.value))} EUR</div>
              )}
              <div css={style.quotaCountLimit}>
                {toLocaleAmount(Math.round(relevantGlobalLimits.valueConverted))} {relevantGlobalLimits.currency}
              </div>
            </div>
          ) : (
            <div css={style.quotaCountLimit}>{toLocaleAmount(Math.round(relevantGlobalLimits.value))} EUR</div>
          )}
        </div>
      </div>
    </div>
  );
};
interface QuotaLimitCardProps {
  quotaLimit: LimitRule;
}

function QuotaLimitCard({ quotaLimit }: QuotaLimitCardProps): JSX.Element {
  const { t } = useTranslation();
  const isAllCategories = quotaLimit.constraints.productCategories == null;
  const categoryLabel =
    quotaLimit.constraints.categoryLabel === '' || quotaLimit.constraints.categoryLabel == null
      ? quotaLimit.constraints.productCategories?.join(', ')
      : quotaLimit.constraints.categoryLabel;
  return (
    <div css={style.quotaCard}>
      <div css={style.quotaInfoContainer}>
        {!isAllCategories && <Icon name='shopping bag' size='big' />}
        <div css={style.quotaMiddleSection}>
          <div css={style.quotaProductCategory}>
            <div>{isAllCategories ? t('purchaseLimits.ALL_CATEGORIES') : categoryLabel}</div>
            {quotaLimit.constraints.storeTypology != null && <div>{quotaLimit.constraints.storeTypology}</div>}
          </div>
        </div>
        <div css={style.quotaLimitSection}>
          {quotaLimit.limits.amount?.value != null && (
            <div css={style.quotaCountLimit}>
              {toLocaleAmount(Math.round(quotaLimit.limits.amount?.value))} {quotaLimit.limits.amount?.currency}
            </div>
          )}
          {quotaLimit.limits.totalItemsCount != null && (
            <div css={style.quotaCountLimit}>
              {quotaLimit.limits.totalItemsCount}{' '}
              {quotaLimit.limits.totalItemsCount > 1 ? t('purchaseLimits.items') : t('purchaseLimits.item')}
            </div>
          )}
          {quotaLimit.limits.itemsCountPerSku != null && (
            <div css={style.quotaCountLimit}>
              {quotaLimit.limits.itemsCountPerSku}{' '}
              {quotaLimit.limits.itemsCountPerSku > 1
                ? t('purchaseLimits.itemsPerSKU')
                : t('purchaseLimits.itemPerSKU')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
