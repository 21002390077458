import { css } from '@emotion/react';

const currenciesDropdown = css`
  width: 81px;
  &.ui.dropdown {
    text-align: end;
  }
  &.ui.dropdown .menu {
    max-height: 16rem;
    overflow-y: auto;
  }
  &.ui.search.dropdown > .text {
    position: absolute;
    left: 13px;
  }
  &.ui.dropdown .menu > .item {
    background-color: transparent;
    font-weight: 500;
  }
  &.ui.dropdown .menu > :nth-child(even) {
    background-color: #fafafb;
  }
  && .menu > .item:hover {
    background-color: var(--dcd-kering-primary-color-20);
  }
  &&.ui.dropdown .menu .active.item {
    font-weight: 700;
  }
  &.ui.dropdown.active {
    width: 252px;
  }
  &.ui.dropdown.active > input.search {
    width: 252px;
  }
`;

const limitInput = css`
  width: 196px;
`;

const dropdownItemCode = css`
  margin-bottom: 2px;
`;

const dropdownItemDescription = css`
  font-weight: 400;
`;

export default {
  currenciesDropdown,
  limitInput,
  dropdownItemCode,
  dropdownItemDescription,
};
