import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { LimitRule, LimitRuleCreationRequest, LimitRuleUpdateRequest } from '../models/limitRule';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { toastService } from '../../../../core/services/toastService';
import { NonEligibleConstraint } from '../models/nonEligibleConstraint';

interface LimitRuleDetailSliceState {
  limitRule: LimitRule | null;
  isFetching: boolean;
  error: string;
}

const initialState: LimitRuleDetailSliceState = {
  limitRule: null,
  isFetching: false,
  error: '',
};

export interface UpdateLimitRulesPayload {
  ruleIndex: number | undefined;
  rule: LimitRule;
}
export interface DeleteLimitRulesPayload {
  ruleIndex: number | undefined;
}
export const limitRuleDetailSlice = createSlice({
  name: 'limitRuleDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<LimitRuleDetailSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (
      state: Draft<LimitRuleDetailSliceState>,
      payloadAction: PayloadAction<LimitRule>
    ): LimitRuleDetailSliceState => {
      return {
        limitRule: payloadAction.payload,
        isFetching: false,
        error: '',
      };
    },
    httpError: (state: Draft<LimitRuleDetailSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    reset: () => initialState,
  },
});

export const { startFetch, finishFetch, httpError, reset } = limitRuleDetailSlice.actions;

export const resetRule = (): AppThunk => async dispatch => {
  dispatch(reset());
};

export const fetchRule =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const rule: LimitRule = await api.getLimitRule(id);
      dispatch(finishFetch(rule));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateRule =
  (id: string, limitRule: LimitRuleUpdateRequest): AppThunk<Promise<LimitRule | null>> =>
  async (dispatch): Promise<LimitRule | null> => {
    try {
      const request: LimitRuleUpdateRequest = {
        limits: limitRule.limits,
        constraints: limitRule.constraints,
      };
      const result = await api.updateRule(id, request);

      dispatch(finishFetch(result));

      toastService.success();

      return result;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const deleteLimitRule =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      await api.deleteLimitRule(id);
      toastService.success();
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };

export const createRule =
  (rule: LimitRuleCreationRequest): AppThunk<Promise<LimitRule | null>> =>
  async (dispatch): Promise<LimitRule | null> => {
    try {
      const request: LimitRuleCreationRequest = {
        limits: rule.limits,
        constraints: rule.constraints,
      };
      const result = await api.createRule(request);
      dispatch(finishFetch(result));
      toastService.success();
      return result;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

// export const getNonEligibleBulkUploadFailErrorsFile = async (file: NonEligibleBulkUploadErrorsFileJson | null) => {
//   if (file != null) {
//     const workbook = new ExcelJS.Workbook();
//     const json = JSON.parse(file.errorsFileJson);
//     json.modified = new Date();
//     json.created = new Date();
//     workbook.model = json;
//     const buffer = await workbook.xlsx.writeBuffer();
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
//     const blob = new Blob([buffer], {
//       type: fileType,
//     });

//     const link = document.createElement('a');
//     const url = window.URL.createObjectURL(blob);
//     link.setAttribute('href', url);
//     link.setAttribute('download', 'non-eligible-products-upload-errors-file.xlsx');
//     link.style.visibility = 'hidden';
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   }
// };

export const downloadNonEligibleProductsBulkUploadTemplate =
  (nonEligibleConstraint: NonEligibleConstraint): AppThunk =>
  async dispatch => {
    try {
      const templateBlob = await api.downloadNonEligibleProductsBulkUploadTemplate(nonEligibleConstraint);
      const url = window.URL.createObjectURL(new Blob([templateBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'non-eligible-products-upload-template.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectLimitRule = (state: RootState): LimitRule | null => state.limitRuleDetail.limitRule;
export const selectLimitRuleCurrency = (state: RootState): string | null =>
  state.limitRuleDetail.limitRule?.limits.amount?.currency || null;
export const selectIsFetchingRule = (state: RootState): boolean => state.limitRuleDetail.isFetching;
// export const selectNonEligibleBulkUploadErrorsFileJson = (
//   state: RootState
// ): NonEligibleBulkUploadErrorsFileJson | null => state.limitRuleDetail.nonEligibleBulkUploadErrorsFileJson;

export default limitRuleDetailSlice.reducer;
