import { css } from '@emotion/react';

const button = css`
  && {
    color: var(--dcd-kering-primary-color);
    background-color: var(--dcd-secondary-color-70);
    padding-top: 5px;
    padding-bottom: 5px;
    height: 72px;
    position: absolute;
    border-radius: 0;

    &&:hover,
    &&:focus {
      color: var(--dcd-kering-primary-color-80);
      background-color: var(--dcd-secondary-color-40);
    }
  }
`;

const buttonIcon = css`
  && {
    font-size: 1.5rem;
  }
`;

const sidebar = css`
  &&&& {
    top: 72px;
    height: calc(100% - 72px) !important;
    display: flex;
    background-color: var(--dcd-white-color);
    width: 100%;
    max-width: 450px;

    .menu {
      padding-top: 10px;
    }
    .menu .item {
      height: 50px;
      border-bottom: 1px solid var(--dcd-gray-color-30);
      font-size: 1.2rem;
    }
  }
`;

const logoContainer = css`
  background-color: var(--dcd-secondary-color-70);
  width: 100%;
  height: 90px;
  margin-top: auto;
  align-self: center;
`;

export default {
  button,
  buttonIcon,
  sidebar,
  logoContainer,
};
