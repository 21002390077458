/** @jsxImportSource @emotion/react */
import React from 'react';
import { Modal, Image } from 'semantic-ui-react';
import style from './qrCodeModal.style';
import { getQrcodeLogo } from '../models/brand';

interface QrCodeModalProps {
  open: boolean;
  onClose: () => void;
  image: string;
  myBrandCode?: string;
  isBusinessCardPath?: boolean;
}

function QrCodeModal({ open, image, onClose, myBrandCode, isBusinessCardPath }: QrCodeModalProps): JSX.Element {
  const isBusinessCard = isBusinessCardPath != null && isBusinessCardPath;
  const brandCodeIsGiven = myBrandCode != null;
  return (
    <Modal onClose={onClose} open={open} basic>
      <Modal.Content css={style.qrCodeModal} image>
        <Image size='medium' src={image} wrapped centered rounded inline />
        {isBusinessCard && brandCodeIsGiven && (
          <div css={style.logoContainer}>
            <Image src={getQrcodeLogo(myBrandCode)} />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default QrCodeModal;
