/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './specialCases.style';
import SpecialCasesCard from './SpecialCasesCard';
import {
  selectSpecialCasesGoldCardsWithSortedUsers,
  selectSpecialCasesSilverCardsWithSortedUsers,
  updateSpecialCasesGoldCards,
  updateSpecialCasesSilverCards,
} from '../store/specialCasesSlice';
import { useAppSelector } from '../../../../core/hooks';
import { SpecialCaseType } from '../models/specialCases';
import { CreationData } from '../../../../shared/components/CreationData';

export default function SpecialCases(): JSX.Element {
  const specialCasesGoldCards = useAppSelector(selectSpecialCasesGoldCardsWithSortedUsers);
  const specialCasesSilverCards = useAppSelector(selectSpecialCasesSilverCardsWithSortedUsers);

  return (
    <div css={style.specialCasesContainer}>
      <div css={style.specialCasesCardsContainer}>
        <div css={style.specialCasesWrapper}>
          <SpecialCasesCard
            typeOfSpecialCase={SpecialCaseType.GOLD_CARD}
            specialCases={specialCasesGoldCards}
            updateSpecialCases={updateSpecialCasesGoldCards}
          />
        </div>
        <div css={style.creationDataContainer}>
          <CreationData creationInfo={null} updateInfo={specialCasesGoldCards.updateInfo} />
        </div>
        <div css={style.specialCasesWrapper}>
          <SpecialCasesCard
            typeOfSpecialCase={SpecialCaseType.SILVER_CARD}
            specialCases={specialCasesSilverCards}
            updateSpecialCases={updateSpecialCasesSilverCards}
          />
        </div>
        <div css={style.creationDataContainer}>
          <CreationData creationInfo={null} updateInfo={specialCasesSilverCards.updateInfo} />
        </div>
      </div>
    </div>
  );
}
