import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const wrapper = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ruleIcon = css`
  && {
    font-size: 14px;
    color: #f9e6d7 !important;
    display: flex;
    align-items: center;
  }
`;
const ruleCard = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0;
  :first-of-type {
    padding-top: 0;
  }
  margin-bottom: 15px;
`;
const ruleCardContent = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-right: 20px;
`;

const ruleField = css`
  display: flex;
  flex-direction: column;
  ${globalStyle.bodySmall};
  width: 160px;
`;
const ruleFieldLabel = css`
  ${globalStyle.bodyNormal};

  &&&&& {
    ${globalStyle.bodySmall};
    background-color: transparent;
    padding: 8px 0;
    color: var(--dcd-gray-color);
    text-align: center;
    margin-bottom: 4px;
  }
`;
const ruleFieldValue = css`
  ${globalStyle.bodySmall};
  border: 1px solid #d9d9d9;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const limitColumn = css`
  margin-left: auto;
`;
const buttonContainer = css`
  margin-left: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 52px;
  width: 100px;
`;

const discountRuleDetailContainer = css`
  display: flex;
  width: 100%;
  column-gap: 20px;
  margin: 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--dcd-gray-color-60);
  align-items: center;
`;
const discountRuleDetail = css`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const limitRuleSetsButtons = css`
  ${globalStyle.secondaryButton};
`;

const creationDataContainer = css`
  margin-left: auto;
  padding: 0 10px 10px 0;
`;

export default {
  ruleIcon,
  limitColumn,
  buttonContainer,
  limitRuleSetsButtons,
  discountRuleDetailContainer,
  discountRuleDetail,
  wrapper,
  ruleCard,
  ruleCardContent,
  ruleField,
  ruleFieldLabel,
  ruleFieldValue,
  creationDataContainer,
};
