import { Brand } from '../../../../shared/models/brand';
import { Country } from '../../../../shared/models/country';
import { CreationInfo } from '../../../../shared/models/creation-info';
import { NonEligibleConstraint } from './nonEligibleConstraint';

export interface GlobalLimits {
  fashionAmount: number;
  jewelryAmount: number;
  converted?: {
    fashionAmount: number;
    jewelryAmount: number;
    currency: string;
  };
  updateInfo: CreationInfo | null;
}

export interface GlobalLimitsResponse {
  fashionAmount: number;
  jewelryAmount: number;
  converted?: {
    fashionAmount: number;
    jewelryAmount: number;
    currency: string;
  };
  updateInfo: CreationInfo | null;
}

export interface GlobalLimitsUpdateRequest {
  fashionAmount: number;
  jewelryAmount: number;
}

export const toGlobalLimits = (limits: GlobalLimitsResponse): GlobalLimits => {
  return {
    fashionAmount: limits.fashionAmount,
    jewelryAmount: limits.jewelryAmount,
    converted: limits?.converted,
    updateInfo: limits.updateInfo,
  };
};

export interface MoneyAmount {
  value: number;
  currency: string;
}

export interface Limits {
  amount: MoneyAmount | null;
  totalItemsCount: number | null;
  itemsCountPerSku: number | null;
}

export interface LimitRule {
  id: string;
  constraints: Constraints;
  limits: Limits;
  creationInfo: CreationInfo | null;
  updateInfo: CreationInfo | null;
}

export interface Constraints {
  brand: Brand;
  employeeTypes: string[];
  countries: Country[] | null;
  storeTypology: string | null;
  productCategories: string[] | null;
  skus: string[] | null;
  nonEligibleConstraint: NonEligibleConstraint | null;
  validityStartDate: Date | null;
  validityEndDate: Date | null;
  categoryLabel: string;
  categoryIcon?: string;
}

export interface LimitRuleResponse {
  id: string;
  constraints: Constraints;
  limits: Limits;
  creationInfo: CreationInfo | null;
  updateInfo: CreationInfo | null;
}

export interface LimitRuleCreationRequest {
  constraints: Constraints;
  limits: Limits;
}

export interface LimitRuleUpdateRequest {
  constraints: Constraints;
  limits: Limits;
}

export const toLimitRule = (rule: LimitRuleResponse): LimitRule => {
  return {
    id: rule.id,
    constraints: rule.constraints,
    limits: rule.limits,
    creationInfo: rule?.creationInfo,
    updateInfo: rule?.updateInfo,
  };
};
