import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const card = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  column-gap: 16px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const brandContainer = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;
const brand = css`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
const infoContainer = css`
  min-width: 0;
  flex: 1;
`;
const product = css`
  ${globalStyle.bodyNormal};
  && {
    line-height: 20px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const detail = css`
  ${globalStyle.bodyNormal};
  && {
    font-size: 10px;
    line-height: 12px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const priceContainer = css`
  ${globalStyle.bodySmall};
  width: max-content;
  text-align: right;
`;

const refundedPrice = css`
  margin-top: 12px;
`;

const fullPrice = css`
  margin-top: 18px;
`;

const refundedText = css`
  ${globalStyle.bodyNormal};
  && {
    font-size: 12px;
    line-height: 12px;
  }
`;

const productNameText = css`
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const temporaryLogo = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: black;
  color: white;
  width: 50px;
  height: 50px;
  font-size: 8px;
  text-align: center;
  line-height: 1.5em;
`;

export default {
  card,
  brandContainer,
  brand,
  product,
  infoContainer,
  detail,
  priceContainer,
  refundedText,
  refundedPrice,
  fullPrice,
  productNameText,
  temporaryLogo,
};
