/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SeeMoreHeader } from '../components/SeeMoreHeader';
import style from './myPersonalInformation.style';
import { PersonalInformationContent } from '../components/PersonalInformationContent';

export const MyPersonalInformation = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.page}>
      <SeeMoreHeader title={t('myPersonalInformation.myInformation')} rightElement='' />
      <PersonalInformationContent />
    </div>
  );
};
