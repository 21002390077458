import axios, { AxiosResponse } from 'axios';
import paths from './paths';
import { Principal, toPrincipal } from '../models/principal';
import {
  Language,
  toDiscountCardUserPreference,
  DiscountCardUserPreference,
  toBusinessCardUserPreference,
  BusinessCardUserPreference,
} from '../models/userPreference';

const api = {
  getToken: async (
    tokenUrl: string,
    code: string | (string | null)[] | null,
    redirectUri: string,
    clientId: string,
    abortController?: AbortController
  ): Promise<AxiosResponse> => {
    return axios({
      method: 'post',
      url: paths.getToken(),
      data: {
        tokenUrl,
        code,
        redirectUri,
        clientId,
        grant_type: 'authorization_code',
      },
      signal: abortController?.signal,
    });
  },
  getPrincipal: async (): Promise<Principal> => {
    const response = await axios.get(paths.getPrincipal());
    return toPrincipal(response.data);
  },
  refreshToken: async (): Promise<AxiosResponse> => {
    return axios.get(paths.refreshToken());
  },
  updateDiscountCardLanguage: async (language: Language): Promise<DiscountCardUserPreference> => {
    const response = await axios.put(paths.updatediscountCardLanguage(), { language });
    return toDiscountCardUserPreference(response.data);
  },
  updateDiscountCardFirstAccess: async (isFirstAccess: boolean): Promise<DiscountCardUserPreference> => {
    const response = await axios.put(paths.updateDiscountCardFirstAccess(), { isFirstAccess });
    return toDiscountCardUserPreference(response.data);
  },
  acceptDiscountCardPolicies: async (): Promise<DiscountCardUserPreference> => {
    const response = await axios.post(paths.acceptDiscountCardPolicies());
    return toDiscountCardUserPreference(response.data);
  },
  acceptBusinessCardPolicies: async (): Promise<BusinessCardUserPreference> => {
    const response = await axios.post(paths.acceptBusinessCardPolicies());
    return toBusinessCardUserPreference(response.data);
  },
  updateStores: async (jdaCodes: string[]): Promise<DiscountCardUserPreference> => {
    const response = await axios.put(paths.updateStores(), jdaCodes);
    return toDiscountCardUserPreference(response.data);
  },
};

export default api;
