import { css } from '@emotion/react';
import globalStyle from '../global.style';

const modal = css`
  @media (max-width: 800px) {
    top: 0;

    &&&&& {
      margin: 0;
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }
`;
const content = css`
  text-align: center;
  @media (max-width: 800px) {
    height: calc(100% - 77px);
  }
`;

const image = css`
  &&&& {
    padding: 15px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
`;

const description = css`
  &&& {
    padding: 0 40px;
  }
`;
const title = css`
  ${globalStyle.h2};
  text-align: center;
`;

const subtitle = css`
  ${globalStyle.bodyNormal};
  text-align: left;
`;
const actions = css`
  &&& {
    border: none;
    background: none;
  }
`;
const button = css`
  ${globalStyle.primaryButton};
  && {
    margin-right: 20px;
  }
`;
const progress = css`
  &.ui.progress .bar {
    background-color: var(--dcd-pink-color);
  }
`;

export default {
  modal,
  content,
  image,
  description,
  title,
  subtitle,
  actions,
  button,
  progress,
};
