import { SerializedStyles, css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const noSpecialCasesLabel = css`
  ${globalStyle.bodySmall}
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const headerContainer = css`
  ${globalStyle.bodyNormal}
  display: flex;
  justify-content: center;
  padding: 14px 0;
  && {
    font-size: 24px;
  }
`;

const stickyHeader = css`
  ${globalStyle.stickyHeader(`var(--dcd-white-color)`, '-20px')};
  padding-bottom: 14px;
`;

const specialCasesCardsContainer = css`
  display: flex;
  padding: 0 2px;
  flex: 1;
  overflow: auto;
`;

const tabsContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const selectedTab = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--dcd-secondary-color-40);
`;

const tabButton = (selected: boolean): SerializedStyles => {
  return css`
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 0;
    width: 50%;
    text-align: center;
    ${globalStyle.bodyNormal};
    ${selected ? selectedTab : ''};
  `;
};
export default {
  noSpecialCasesLabel,
  container,
  headerContainer,
  stickyHeader,
  specialCasesCardsContainer,
  tabsContainer,
  tabButton
};
