/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './specialCasesDiscountPercentage.style';

interface percentageProp {
  label: string;
  percentage: number;
}
export const SpecialCasesDiscountPercentage = ({ label, percentage }: percentageProp): JSX.Element => {
  return (
    <div css={style.card}>
      <div css={style.content}>
        <div css={style.title}>{label}</div>
        <div css={style.threshold}>{percentage}%</div>
      </div>
    </div>
  );
};
