/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreationInfo } from '../models/creation-info';
import style from './creationData.style';
import { formatDateAndTime } from '../../core/utils';

export const CreationData = ({
  creationInfo,
  updateInfo,
}: {
  creationInfo: CreationInfo | null;
  updateInfo: CreationInfo | null;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.textContainer}>
      {updateInfo != null ? (
        <p css={style.text}>
          {t('lastModified')} {updateInfo.author.name} {updateInfo.author.surname} {t('on')}{' '}
          {formatDateAndTime(updateInfo.date.toString())}
        </p>
      ) : creationInfo != null ? (
        <p css={style.text}>
          {t('lastModified')} {creationInfo.author.name} {creationInfo.author.surname} {t('on')}{' '}
          {formatDateAndTime(creationInfo.date.toString())}
        </p>
      ) : null}
    </div>
  );
};
