import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const ruleCard = css`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--dcd-gray-color-90);
  }
`;
const ruleCardWrapper = css`
  display: flex;
  align-items: center;
  width: 100%;
`;
const ruleCardContent = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-right: 20px;
  padding-bottom: 10px;
`;

const ruleField = css`
  &&& {
    max-width: 165px;
    min-width: 145px;
    .children-component-container {
      margin: 0;
    }
    .view-component-container {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }
`;

const limitColumn = css`
  margin-left: auto;
`;
const buttonContainer = css`
  margin-left: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 37px;
  width: 100px;
`;

const limitRuleSetsButtons = css`
  ${globalStyle.secondaryButton}
`;

const textContainer = css`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: auto;
`;

const text = css`
  ${globalStyle.bodySmall};
  &&& {
    line-height: 10px;
    font-style: italic;
    font-size: 13px;
  }
  display: block;
  margin-block: 0px;
`;

export default {
  limitColumn,
  buttonContainer,
  limitRuleSetsButtons,
  ruleCard,
  ruleCardWrapper,
  ruleCardContent,
  ruleField,
  textContainer,
  text,
};
