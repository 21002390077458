import { css, SerializedStyles } from '@emotion/react';
import style from '../../../../shared/global.style';

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const searchBox = css`
  ${style.bodySmall};
  &&&& {
    width: 100%;
    max-width: 400px;
    display: flex;

    i {
      color: var(--dcd-gray-color-50);
      opacity: 1;
    }

    input {
      border-radius: 20px;
      border-color: var(--dcd-gray-color-50);
      color: var(--dcd-gray-color-50);
      &:focus {
        border-color: var(--dcd-gray-color-80);
        color: var(--dcd-gray-color-80);

        + i {
          color: var(--dcd-gray-color-80);
        }
      }
    }
  }
`;

const addButton = (label: string): SerializedStyles => css`
  ${style.labeledButton(label)};
`;

const header = css`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`;
const headerRight = css`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const toggle = css`
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  column-gap: 10px;
  &&& {
    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: var(--dcd-pink-color-80) !important;
    }
  }
`;

const cardsContainer = css`
  overflow: auto;
  flex-grow: 1;
  padding-top: 20px;
`;

const noItemsLabel = css`
  ${style.bodySmall}
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export default {
  headerRight,
  toggle,
  container,
  searchBox,
  addButton,
  header,
  cardsContainer,
  noItemsLabel,
};
