/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, SemanticCOLORS } from 'semantic-ui-react';
import style from './allCategoriesCard.style';
import { StoreTypologyEnum } from '../../../../shared/models/storeTypology';

interface TrafficLightsCardProps {
  storeTypology: StoreTypologyEnum | null
  iconColor: string;
  trafficLightsLength: number;
}

export function AllCategoriesCard({ storeTypology, iconColor, trafficLightsLength }: TrafficLightsCardProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div css={style.card}>
      <div css={style.infoContainer}>
        <div css={style.allCategories}>
          <p css={style.productCategory}>{trafficLightsLength === 1 && t('salesAssistant.trafficLights.allCategories')}</p>
          <p css={style.productCategory}>{trafficLightsLength > 1 && t('salesAssistant.trafficLights.otherCategories')}</p>
              {storeTypology != null && <p css={style.productCategory}>{storeTypology}</p>}
        </div>
      </div>
      <div css={style.iconContainer}>
        <Icon
          name={iconColor === 'red' ? 'dont' : 'check circle outline'}
          size='large'
          color={iconColor as SemanticCOLORS}
        />
      </div>
    </div>
  );
}
