import { Brand } from '../../../../shared/models/brand';

export interface UserActivity {
  id: string;
  userName: string;
  userSurname: string;
  userEmail: string;
  userBrand: Brand;
  activitySection: ActivitySection;
  activityType: ActivityType;
  date: Date;
}

export interface UserActivitiesWithCount {
  data: UserActivity[];
  totalCount: number;
}

export type ActivitySection = 'Login' | 'Limits' | 'Discounts' | 'Suspended employees';

export type ActivityType =
  | 'Access'
  | 'Limit rule creation'
  | 'Limit rule update'
  | 'Limit rule deletion'
  | 'Global limits update'
  | 'Discount rule set creation'
  | 'Discount rule set update'
  | 'Discount rule set deletion'
  | 'Special cases gold cards update'
  | 'Special cases silver cards update'
  | 'Suspended employee creation'
  | 'Suspended employee update';

export const activitySections: ActivitySection[] = ['Login', 'Limits', 'Discounts', 'Suspended employees'];

export const allActivityTypes: ActivityType[] = [
  'Access',
  'Limit rule creation',
  'Limit rule update',
  'Limit rule deletion',
  'Global limits update',
  'Discount rule set creation',
  'Discount rule set update',
  'Discount rule set deletion',
  'Special cases gold cards update',
  'Special cases silver cards update',
  'Suspended employee creation',
  'Suspended employee update',
];

export const getActivityTypesBySection = (section?: ActivitySection): ActivityType[] => {
  switch (section) {
    case 'Login':
      return ['Access'];
    case 'Limits':
      return ['Limit rule creation', 'Limit rule update', 'Limit rule deletion', 'Global limits update'];
    case 'Discounts':
      return [
        'Discount rule set creation',
        'Discount rule set update',
        'Discount rule set deletion',
        'Special cases gold cards update',
        'Special cases silver cards update',
      ];
    case 'Suspended employees':
      return ['Suspended employee creation', 'Suspended employee update'];
    default:
      return allActivityTypes;
  }
};

export interface UserActivityResponse {
  id: string;
  userName: string;
  userSurname: string;
  userEmail: string;
  userBrand: Brand;
  activitySection: ActivitySection;
  activityType: ActivityType;
  date: Date;
}

export const toUserActivity = (response: UserActivityResponse): UserActivity => {
  return {
    id: response.id,
    userName: response.userName,
    userSurname: response.userSurname,
    userEmail: response.userEmail,
    userBrand: response.userBrand,
    activitySection: response.activitySection,
    activityType: response.activityType,
    date: response.date,
  };
};

export interface UserActivityWithCountResponse {
  data: UserActivityResponse[];
  totalCount: number;
}

export const toUserActivitiesWithCount = (response: UserActivityWithCountResponse): UserActivitiesWithCount => {
  return {
    data: response.data.map(toUserActivity),
    totalCount: response.totalCount,
  };
};

export interface Filters {
  brand: string;
  activitySection: string;
  activityType: string;
  dateCode: string;
  dateRange: Array<Date | null>;
}
