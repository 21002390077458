import axios from 'axios';
import paths from './paths';
import { AdUser, toAdUser, toUser, User, UserCreationRequest, UserUpdateRequest } from '../models/user';

const api = {
  getUsers: async (): Promise<User[]> => {
    const response = await axios.get(paths.getUsers());
    return response.data.map(toUser);
  },
  createUser: async (userCreationRequest: UserCreationRequest): Promise<User> => {
    const response = await axios.post(paths.postUser(), userCreationRequest);
    return toUser(response.data);
  },
  updateUser: async (id: string, userUpdateRequest: UserUpdateRequest): Promise<User> => {
    const response = await axios.put(paths.putUser(id), userUpdateRequest);
    return toUser(response.data);
  },
  deleteUser: async (id: string): Promise<void> => {
    const response = await axios.delete<void>(paths.deleteUser(id));
    return response.data;
  },
  getAdUsers: async (filter: string): Promise<AdUser[]> => {
    const response = await axios.get(`${paths.getAdUsers()}?filter=${filter}`);
    return response.data.map(toAdUser);
  },
  getUserImage: async (email: string): Promise<string> => {
    const response = await axios.get(`${paths.getUserImage(email)}`);
    return response.data;
  },
};

export default api;
