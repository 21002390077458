/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'semantic-ui-react';
import style from './salesAssistantSeeMore.style';
import { LogoutModal } from '../../../../shared/components/LogoutModal';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { selectPrincipalRoles } from '../../../auth/store/principalSlice';
import {
  getEmployeeConditionsOfUsePdf,
  getEmployeePrivacyPolicyPdf,
} from '../../../employee/seeMore/store/SeeMoreSlice';
import { SelectLanguageDropdown } from '../../../../shared/components/SelectLanguageDropdown';

export const SalesAssistantSeeMore = (): JSX.Element => {
  const principalRoles = useAppSelector(selectPrincipalRoles);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const languageSelectionEnabled = true;

  const downloadEmmployeePrivacyPolicyPdf = async () => {
    await dispatch(getEmployeePrivacyPolicyPdf());
  };

  const downloadEmployeeConditionsOfUsePdf = async () => {
    await dispatch(getEmployeeConditionsOfUsePdf());
  };

  return (
    <div css={style.page}>
      <div css={style.languageDropdwon}>{languageSelectionEnabled && <SelectLanguageDropdown />}</div>
      <div css={style.title}>{t('salesAssistant.seeMore.title')}</div>
      <div css={style.bodyContainer}>
        <a
          css={style.link}
          target='_blank'
          href='https://keringconnect.service-now.com/kb_view.do?sysparm_article=KB0036477'
          rel='noreferrer'>
          {t('salesAssistant.seeMore.purchaseLimit')}
        </a>
        <Button
          css={style.btn}
          content={t('salesAssistant.seeMore.employeePrivacyPolicy')}
          onClick={downloadEmmployeePrivacyPolicyPdf}
        />
        <Button
          css={style.btn}
          content={t('salesAssistant.seeMore.conditionsOfUse')}
          onClick={downloadEmployeeConditionsOfUsePdf}
        />
        <Link css={style.link} to='/privilege-card/sales-assistant/store-selection'>
          {t('seeMore.storeSelection')}
        </Link>

        {principalRoles.length > 1 && (
          <Link css={style.link} to='/privilege-card/home'>
            {t('seeMore.switchRole')}
          </Link>
        )}
        <LogoutModal />
      </div>
    </div>
  );
};
