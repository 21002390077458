/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, Image, Menu, Sidebar } from 'semantic-ui-react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import style from './adminMenu.style';
import mobileStyle from './adminMobileMenu.style';
import privilegeCardLogo from '../assets/privilege-card-logo.svg';
import { PersonalInformationContent } from '../features/employee/seeMore/components/PersonalInformationContent';
import { LogoutModal } from './components/LogoutModal';
import { useAppSelector } from '../core/hooks';
import { selectPrincipalRoles } from '../features/auth/store/principalSlice';
import { Role } from '../features/auth/models/principal';
import { SelectLanguageDropdown } from './components/SelectLanguageDropdown';

interface AdminMainMenuProps {
  children?: JSX.Element | string;
}

export const AdminMainMenu = ({ children }: AdminMainMenuProps): JSX.Element => {
  const mediaQuery = '(min-width: 821px)';
  const [isDesktop, setIsDesktop] = useState(window.matchMedia(mediaQuery).matches);
  const languageSelectionEnabled = true;

  useEffect(() => {
    window.matchMedia(mediaQuery).addEventListener('change', e => setIsDesktop(e.matches));
  }, []);

  return (
    <div css={style.menuContainer}>
      {isDesktop ? <AdminMainMenuDesktop /> : <AdminMainMenuMobile />}
      <div css={style.content}>
        <header css={style.header}>
          <div css={style.languageDropdown}>{languageSelectionEnabled && <SelectLanguageDropdown />}</div>
          <UserMenu />
        </header>
        <div css={style.pageContent}>{children}</div>
      </div>
    </div>
  );
};

interface MainMenuProps {
  onSelectMenuItem?: () => void;
}

const MainMenu = ({ onSelectMenuItem }: MainMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const principalRoles = useAppSelector(selectPrincipalRoles);
  const isActive = (currentPath: string, pathContains = false) => {
    if (pathContains) {
      return location.pathname.includes(currentPath);
    }
    return location.pathname === currentPath;
  };

  return (
    <Menu css={style.menu}>
      <Menu.Item
        name='admin'
        active={isActive('/privilege-card/admin/home')}
        onClick={() => {
          if (onSelectMenuItem) {
            onSelectMenuItem();
          }
          navigate('/privilege-card/admin/home');
        }}>
        <Icon name='square' css={style.menuIcon} />
        <span css={style.menuItem}>{t('mainMenu.admin')}</span>
      </Menu.Item>
      {principalRoles.includes(Role.GROUP_ADMIN) && (
        <Menu.Item
          name='Employees'
          active={isActive('/privilege-card/admin/users-management')}
          onClick={() => {
            if (onSelectMenuItem) {
              onSelectMenuItem();
            }
            navigate('/privilege-card/admin/users-management');
          }}>
          <Icon name='square' css={style.menuIcon} />
          <span css={style.menuItem}>{t('mainMenu.usersManagement')}</span>
        </Menu.Item>
      )}
      {principalRoles.includes(Role.GROUP_ADMIN) && (
        <Menu.Item
          name='blocked-employees'
          active={isActive('/privilege-card/admin/blocked-employees')}
          onClick={() => {
            if (onSelectMenuItem) {
              onSelectMenuItem();
            }
            navigate('/privilege-card/admin/blocked-employees');
          }}>
          <Icon name='square' css={style.menuIcon} />
          <span css={style.menuItem}>{t('mainMenu.blockedEmployees')}</span>
        </Menu.Item>
      )}
      <Menu.Item
        name='limits'
        active={isActive('/privilege-card/admin/limits') || isActive('/privilege-card/admin/rules', true)}
        onClick={() => {
          if (onSelectMenuItem) {
            onSelectMenuItem();
          }
          navigate('/privilege-card/admin/limits');
        }}>
        <Icon name='square' css={style.menuIcon} />
        <span css={style.menuItem}>{t('mainMenu.limits')}</span>
      </Menu.Item>
      <Menu.Item
        name='discounts'
        active={
          isActive('/privilege-card/admin/discounts') || isActive('/privilege-card/admin/discount-rule-set', true)
        }
        onClick={() => {
          if (onSelectMenuItem) {
            onSelectMenuItem();
          }
          navigate('/privilege-card/admin/discounts');
        }}>
        <Icon name='square' css={style.menuIcon} />
        <span css={style.menuItem}>{t('mainMenu.discounts')}</span>
      </Menu.Item>
      {(principalRoles.includes(Role.GROUP_ADMIN) || principalRoles.includes(Role.ADMIN)) && (
        <Menu.Item
          name='user-activities'
          active={isActive('/privilege-card/admin/user-activities')}
          onClick={() => {
            if (onSelectMenuItem) {
              onSelectMenuItem();
            }
            navigate('/privilege-card/admin/user-activities');
          }}>
          <Icon name='square' css={style.menuIcon} />
          <span css={style.menuItem}>{t('mainMenu.userActivities')}</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

const AdminMainMenuDesktop = (): JSX.Element => {
  return (
    <aside css={style.sidebar}>
      <div css={style.sidebarContent}>
        <div css={style.logoContainer}>
          <Image src={privilegeCardLogo} size='small' centered />
        </div>
        <MainMenu />
      </div>
    </aside>
  );
};

const AdminMainMenuMobile = (): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Button icon css={mobileStyle.button} onClick={() => setVisible(true)}>
        <Icon name='bars' css={mobileStyle.buttonIcon} />
      </Button>
      <Sidebar
        as={Menu}
        animation='overlay'
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        css={mobileStyle.sidebar}>
        <MainMenu
          onSelectMenuItem={() => {
            setVisible(false);
          }}
        />
        <div css={mobileStyle.logoContainer}>
          <Image src={privilegeCardLogo} size='tiny' centered css={style.logo} />
        </div>
      </Sidebar>
    </>
  );
};

const UserMenu = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const principalRoles = useAppSelector(selectPrincipalRoles);
  const canSwitchRole =
    principalRoles.filter((role: Role) => ![Role.ADMIN, Role.GROUP_ADMIN].includes(role)).length > 0;

  const handleUserMenuOnClick = () => {
    setModalOpen(!modalOpen);
  };
  const ref = useRef<HTMLInputElement>(null);

  return (
    <OutsideClickHandler onOutsideClick={handleUserMenuOnClick} disabled={!modalOpen || dialogOpen}>
      <div ref={ref} css={style.userMenu}>
        <Icon css={style.profileImage} name='user' circular size='big' onClick={handleUserMenuOnClick} />
        {modalOpen &&
          ref.current != null &&
          createPortal(
            <div css={style.personalInfoContainer}>
              <div css={style.personalInfo}>
                <PersonalInformationContent />
              </div>
              <div css={style.personalInfoButtonsContainer}>
                {canSwitchRole && (
                  <Button css={style.switchRoleButton} onClick={() => navigate('/privilege-card/home')}>
                    {t('mainMenu.switchRole')}
                  </Button>
                )}
                <LogoutModal onClickHandler={(value: boolean) => setDialogOpen(value)} />
              </div>
            </div>,
            ref.current
          )}
      </div>
    </OutsideClickHandler>
  );
};
