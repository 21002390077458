import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import api from '../utils/api';
import type { AppThunk, RootState } from '../../../../core/store';
import { getEmptyUser, User, UserCreationRequest, UserUpdateRequest } from '../models/user';
import { toastService } from '../../../../core/services/toastService';

interface UsersManagementSliceState {
  data: User[];
  isFetching: boolean;
  error: string;
  isValidCache: boolean;
  filter: string;
}

const initialState: UsersManagementSliceState = {
  data: [],
  isFetching: false,
  error: '',
  isValidCache: false,
  filter: '',
};

export const usersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState,
  reducers: {
    startFetch: (state: Draft<UsersManagementSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<UsersManagementSliceState>, payloadAction: PayloadAction<User[]>) => {
      return {
        ...state,
        isFetching: false,
        error: '',
        data: [...payloadAction.payload],
      };
    },
    httpError: (state: Draft<UsersManagementSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    addUser: (state: Draft<UsersManagementSliceState>, payloadAction: PayloadAction<User>) => {
      return { ...state, data: [...state.data, payloadAction.payload] };
    },
    editUser: (state: Draft<UsersManagementSliceState>, payloadAction: PayloadAction<User>) => {
      const users = [...state.data];
      const existingItemIndex = users.findIndex((u: User) => u.id === payloadAction.payload.id);
      if (existingItemIndex > -1) {
        users[existingItemIndex] = payloadAction.payload;
      }
      return {
        ...state,
        data: users,
      };
    },
    removeUser: (state: Draft<UsersManagementSliceState>, payloadAction: PayloadAction<string>) => {
      return {
        ...state,
        data: state.data.filter((user: User) => user.id !== payloadAction.payload),
      };
    },
    removeEmptyUser: (state: Draft<UsersManagementSliceState>) => {
      return {
        ...state,
        data: state.data.filter((user: User) => user.id !== ''),
      };
    },
    addEmptyUser: (state: Draft<UsersManagementSliceState>) => {
      return {
        ...state,
        data: state.data.find(d => d.id === '') != null ? [...state.data] : [...state.data, getEmptyUser()],
      };
    },
    setFilter: (state: Draft<UsersManagementSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      filter: payloadAction.payload,
    }),
  },
});

export const {
  startFetch,
  finishFetch,
  httpError,
  addUser,
  editUser,
  removeUser,
  setFilter,
  addEmptyUser,
  removeEmptyUser,
} = usersManagementSlice.actions;

export const fetchUsers = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const users = await api.getUsers();
    dispatch(finishFetch(users));
  } catch (error) {
    dispatch(httpError(JSON.stringify(error)));
  }
};

export const saveUser =
  (userCreationRequest: UserCreationRequest): AppThunk =>
  async dispatch => {
    try {
      const newUser: User = await api.createUser(userCreationRequest);
      dispatch(addUser(newUser));
      dispatch(removeEmptyUser());
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateUser =
  (id: string, userUpdateRequest: UserUpdateRequest): AppThunk =>
  async dispatch => {
    try {
      const updatedUser = await api.updateUser(id, userUpdateRequest);
      dispatch(editUser(updatedUser));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const deleteUser =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      await api.deleteUser(id);
      dispatch(removeUser(id));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectUsers = (state: RootState): User[] => state.usersManagement.data;
export const selectIsFetching = (state: RootState): boolean => state.usersManagement.isFetching;
export const selectFilter = (state: RootState) => state.usersManagement.filter;

export const selectSortedUsers = createSelector(selectUsers, (users: User[]): User[] => {
  return _.sortBy(users, ['firstName']);
});

export const selectFilteredAndSortedUsers = createSelector(selectFilter, selectSortedUsers, (filter, users) => {
  return users.filter(
    (user: User) =>
      user.email.toLowerCase().includes(filter.toLowerCase()) ||
      user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
      user.familyName.toLowerCase().includes(filter.toLowerCase()) ||
      user.roles.some(role => role.toLowerCase().includes(filter.toLowerCase())) ||
      _.isEqual(user, getEmptyUser())
  );
});

export const selectIsEmailNotUsed = createSelector(
  [selectUsers, (state, email, id) => ({ email, id })],
  (users, value) => {
    return !users.some(user => user.email.toLowerCase() === value.email.toLowerCase() && user.id !== value.id);
  }
);

export function useEmailSelector(email: string, id: string) {
  return useSelector((state: RootState) => selectIsEmailNotUsed(state, email, id));
}

export default usersManagementSlice.reducer;
