/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import { Icon, Image, Modal, TransitionablePortal } from 'semantic-ui-react';
import React from 'react';
import style from './purchaseDetailModal.style';
import { Purchase } from '../models/purchase';
import { getLogo, isAvailableLogo } from '../../../../shared/models/brand';
import { toLocaleAmount } from '../../../../shared/utils/utils';

interface PurchaseDetailModalProps {
  purchase: Purchase;
  open: boolean;
  onClose: () => void;
}

export const PurchaseDetailModal = ({ purchase, open, onClose }: PurchaseDetailModalProps): JSX.Element => {
  const { t } = useTranslation();

  const priceTotal = `${toLocaleAmount(
    Math.round(
      Math.abs(
        purchase.discountedPriceConverted.value != null
          ? purchase.discountedPriceConverted.value
          : purchase.discountedPrice
      )
    )
  )} ${
    purchase.discountedPriceConverted.value != null
      ? purchase.discountedPriceConverted.currency
      : purchase.store.currency
  }`;

  return (
    <TransitionablePortal open={open} transition={{ animation: 'fade up', duration: 300 }}>
      <Modal css={style.modal} onClose={onClose} open centered={false}>
        <Modal.Content css={style.modalContent}>
          <div css={style.header}>
            <div css={style.headerTop}>
              <div css={style.headerTopLeft}>
                <div onClick={onClose}>
                  <Icon css={style.caretDownIcon} name='caret down' size='big' />
                </div>
              </div>
              {isAvailableLogo(purchase.store.brand.code) && (
                <Image css={style.brandLogo} src={getLogo(purchase.store.brand.code)} />
              )}
              {!isAvailableLogo(purchase.store.brand.code) && (
                <div css={style.temporaryLogo}>{purchase.store.brand.description}</div>
              )}
              <div css={style.headerTopRight} />
            </div>
            <div css={style.headerBottom}>
              <div css={style.product}>
                <div css={style.productNameText}>{purchase.productName}</div>
                <div css={style.productQuantity}>
                  {purchase.quantity != null && purchase.quantity > 0
                    ? `${t('purchaseDetail.quantity')}: ${purchase.quantity}`
                    : ''}
                  {purchase.quantity != null && purchase.quantity < 0
                    ? `${t('purchaseDetail.quantityRefunded')}: ${Math.abs(purchase.quantity)}`
                    : ''}
                </div>
                <div css={style.productCategory}>{purchase.productCategory} {purchase.store.brand.code === 'GUCCI' && purchase.productSubCategory != null && ` - ${purchase.productSubCategory}`}</div>
              </div>
              <div css={style.date}>{purchase.date}</div>
              <div css={style.discount}>
                {purchase.discountPercentage}% {t('purchaseDetail.discountApplied')}
              </div>
              <div css={style.amount}>
                {purchase.quantity && purchase.quantity < 0 ? (
                  <>
                    <div style={{ textDecoration: 'line-through', fontWeight: 400 }}>{priceTotal}</div>
                    <span style={{ paddingLeft: 5 }}>{`${t('purchaseDetail.refunded')}`}</span>
                  </>
                ) : (
                  <div>{priceTotal}</div>
                )}
              </div>
            </div>
          </div>
          <div css={style.body}>
            <div>
              <div css={style.detailTitle}>{t('purchaseDetail.storeLocationName')}</div>
              <div css={style.address}>{purchase.store.locationName}</div>
            </div>
            <div style={{ textTransform: 'uppercase' }}>
              <div css={style.detailTitle} style={{ textTransform: 'initial' }}>
                {t('purchaseDetail.storeCountry')}
              </div>
              {purchase.store.country}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};
