/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button, Icon, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import style from './limits.style';
import GlobalLimits from '../components/GlobalLimits';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  fetchAllLimits,
  selectLimitTypeSelected,
  selectSortedLimitRules,
  setLimitType,
} from '../stores/limitRulesSlice';
import { changeFilters, selectFilters } from '../stores/limitRulesFiltersSlice';
import { LimitRulesFilters } from '../components/LimitRulesFilters';
import { selectPrincipalRoles } from '../../../auth/store/principalSlice';
import { Role } from '../../../auth/models/principal';
import LimitRuleCard from '../components/LimitRuleCard';

export default function Limits(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const principalRoles = useAppSelector(selectPrincipalRoles);
  const limitRules = useAppSelector(selectSortedLimitRules);
  useEffect(() => {
    dispatch(fetchAllLimits());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <GlobalLimits />
      {principalRoles.includes(Role.ADMIN) && (
        <div css={style.limitsContainer}>
          <div css={style.title}>
            <span>{t('limits.limits')}</span>
            <LimitTypeSelector />
          </div>
          <div css={style.limitRuleSetsSectionContainer}>
            <div css={style.rulesHeader}>
              <div>
                <Button
                  css={style.addLimitRuleSetButton(t('limits.addRule'))}
                  icon='plus'
                  onClick={() => {
                    navigate(`/privilege-card/admin/rules`);
                  }}
                />
              </div>
              <div css={style.filtersContainer}>
                <div>
                  <FiltersBadges />
                </div>
                <LimitRulesFilters />
              </div>
            </div>
            {limitRules.length <= 0 && <div css={style.noItemsLabel}>{t('limits.noItemsLabel')}</div>}

            {limitRules.length > 0 && (
              <div css={style.ruleList}>
                {limitRules.map(r => (
                  <LimitRuleCard key={r.id} limitRule={r} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
const LimitTypeSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedType = useAppSelector(selectLimitTypeSelected);
  return (
    <div css={style.limitTypeSelector}>
      <Button
        css={style.limitTypeSelectorItem}
        active={selectedType === 'maxAmount'}
        onClick={() => dispatch(setLimitType('maxAmount'))}>
        {t('limits.limitRules.maxAmount')}
      </Button>
      <Button
        css={style.limitTypeSelectorItem}
        active={selectedType === 'maxItems'}
        onClick={() => dispatch(setLimitType('maxItems'))}>
        {t('limits.limitRules.maxQuantity')}
      </Button>
      <Button
        css={style.limitTypeSelectorItem}
        active={selectedType === 'maxItemsBySku'}
        onClick={() => dispatch(setLimitType('maxItemsBySku'))}>
        {t('limits.limitRules.maxQuantityPerSku')}
      </Button>
      <Button
        css={style.limitTypeSelectorItem}
        active={selectedType === 'notEligible'}
        onClick={() => dispatch(setLimitType('notEligible'))}>
        {t('limits.limitRules.notEligibleProducts')}
      </Button>
    </div>
  );
};

const FiltersBadges = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);

  return (
    <div css={style.badgesWrapper}>
      <div css={style.badgesContainer}>
        {!_.isEmpty(filters.country) && (
          <Label css={style.filterBadge}>
            {filters.country}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, country: '' }))} />
          </Label>
        )}
        {!_.isEmpty(filters.employeeType) && (
          <Label css={style.filterBadge}>
            {t(`limits.limitRules.employeeTypes.${filters.employeeType}`)}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, employeeType: '' }))} />
          </Label>
        )}
        {!_.isEmpty(filters.brand) && (
          <Label css={style.filterBadge}>
            {filters.brand}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, brand: '' }))} />
          </Label>
        )}
        {!_.isEmpty(filters.storeTypology) && (
          <Label css={style.filterBadge}>
            {filters.storeTypology}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, storeTypology: '' }))} />
          </Label>
        )}
      </div>
    </div>
  );
};
