import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';

interface TrafficLightsFilterSliceState {
  searchBoxFilter: string;
}

const initialState: TrafficLightsFilterSliceState = {
  searchBoxFilter: '',
};

export const trafficLightsFilterSlice = createSlice({
  name: 'trafficLightsFilter',
  initialState,
  reducers: {
    setSearchBoxFilter: (
      state: Draft<TrafficLightsFilterSliceState>,
      action: PayloadAction<string>
    ): TrafficLightsFilterSliceState => ({
      ...state,
      searchBoxFilter: action.payload,
    }),
  },
});

export const { setSearchBoxFilter } = trafficLightsFilterSlice.actions;

export const changeSearchBoxFilter =
  (filterValue: string): AppThunk =>
  async dispatch => {
    dispatch(setSearchBoxFilter(filterValue));
  };

export const selectSearchBoxFilter = (state: RootState): string => state.trafficLightsFilter.searchBoxFilter;

export default trafficLightsFilterSlice.reducer;
