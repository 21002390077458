/** @jsxImportSource @emotion/react */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import style from './adminSectionHeader.style';

interface AdminSectionHeaderProps {
  title: string;
  redirectPath?: string;
  arrowBack?: boolean;
  checkForUnsavedChanges?: () => void;
}
export function AdminSectionHeader({
  title,
  redirectPath,
  arrowBack,
  checkForUnsavedChanges,
}: AdminSectionHeaderProps) {
  const navigate = useNavigate();
  const goBack = () => {
    if (checkForUnsavedChanges != null) {
      checkForUnsavedChanges();
    } else if (redirectPath == null) {
      navigate(-1);
    } else {
      navigate(redirectPath);
    }
  };
  return (
    <div css={style.header}>
      {arrowBack && <Icon name='arrow left' link onClick={goBack} />}
      {title}
    </div>
  );
}
