import moment from 'moment/moment';
import { CreationInfo } from '../../../../shared/models/creation-info';

export interface BlockedEmployee {
  id: string;
  email: string;
  firstName: string;
  familyName: string;
  blockedIntervals: BlockedInterval[];
  creationInfo: CreationInfo | null;
  updateInfo: CreationInfo | null;
}
export interface BlockedInterval {
  startDate: Date;
  endDate: Date | null;
}
export interface BlockedEmployeeCreationRequest {
  email: string;
  firstName: string;
  familyName: string;
  startDate: string;
  endDate: string | null;
}
export interface BlockedEmployeeUpdateRequest {
  startDate: string;
  endDate: string | null;
}

export const getEmptyBlockedEmployee = (): BlockedEmployee => ({
  id: '',
  email: '',
  firstName: '',
  familyName: '',
  blockedIntervals: [
    {
      startDate: new Date(),
      endDate: null,
    },
  ],
  creationInfo: null,
  updateInfo: null,
});

interface BlockedEmployeeResponse {
  id: string;
  email: string;
  firstName: string;
  familyName: string;
  blockedIntervals: BlockedInterval[];
  creationInfo: CreationInfo | null;
  updateInfo: CreationInfo | null;
}

export const toBlockedEmployee = (employee: BlockedEmployeeResponse): BlockedEmployee => {
  return {
    id: employee.id,
    email: employee.email,
    firstName: employee.firstName,
    familyName: employee.familyName,
    blockedIntervals: employee.blockedIntervals.map(interval => ({
      startDate: interval.startDate,
      endDate: interval.endDate,
    })),
    creationInfo: employee?.creationInfo,
    updateInfo: employee?.updateInfo,
  };
};

export const getLastBlockedInterval = (emp: BlockedEmployee): BlockedInterval => {
  const lastItem = emp.blockedIntervals.length - 1;
  return emp.blockedIntervals[lastItem];
};

export const isEmployeeUnblocked = (emp: BlockedEmployee): boolean => {
  const today = moment().startOf('day').toDate();
  const lastBlockedInterval = getLastBlockedInterval(emp);
  return lastBlockedInterval.endDate != null && moment(lastBlockedInterval.endDate).isBefore(today, 'day');
};

export const willEmployeeBeBlocked = (emp: BlockedEmployee): boolean => {
  const today = moment().startOf('day').toDate();
  const lastBlockedInterval = getLastBlockedInterval(emp);
  return (
    moment(lastBlockedInterval.startDate).isAfter(today, 'day') &&
    (lastBlockedInterval.endDate == null || moment(lastBlockedInterval.endDate).isAfter(today, 'day'))
  );
};

export const isEmployeeBlocked = (emp: BlockedEmployee): boolean => {
  const today = moment().startOf('day').toDate();
  const lastBlockedInterval = getLastBlockedInterval(emp);
  return (
    moment(lastBlockedInterval.startDate).isSameOrBefore(today, 'day') &&
    (lastBlockedInterval.endDate == null || moment(lastBlockedInterval.endDate).isSameOrAfter(today, 'day'))
  );
};
