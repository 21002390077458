import { API_URL } from '../../../../constants';

const DISCOUNT_RULE_SETS = `${API_URL}/discount-rule-sets`;
const BRANDS = `${API_URL}/brands`;
const SPECIAL_CASES = `${API_URL}/special-cases`;

const paths = {
  getDiscountRuleSets: (): string => DISCOUNT_RULE_SETS,
  getDiscountRuleSet: (id: string): string => `${DISCOUNT_RULE_SETS}/${id}`,
  createDiscountRuleSet: (): string => DISCOUNT_RULE_SETS,
  updateDiscountRuleSet: (id: string): string => `${DISCOUNT_RULE_SETS}/${id}`,
  deleteDiscountRuleSet: (id: string): string => `${DISCOUNT_RULE_SETS}/${id}`,
  getCountries: (brandCode: string): string => `${BRANDS}/${brandCode}/countries`,
  getProductCategoriesByBrand: (brandCode: string): string => `${BRANDS}/${brandCode}/product-categories`,
  getSpecialCasesGoldCards: (): string => `${SPECIAL_CASES}/gold-cards`,
  updateSpecialCasesGoldCards: (): string => `${SPECIAL_CASES}/gold-cards`,
  getSpecialCasesSilverCards: (): string => `${SPECIAL_CASES}/silver-cards`,
  updateSpecialCasesSilverCards: (): string => `${SPECIAL_CASES}/silver-cards`,
};

export default paths;
