import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { t } from 'i18next';
import { toastService } from './services/toastService';
import { deleteJwtToken, getJwtToken, setBusinessCardRedirectPagePath } from './services/webStorageService';

export const setJwtInterceptor = (): void => {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = getJwtToken();
      if (token) {
        if (config.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export const setErrorInterceptor = (): void => {
  axios.interceptors.response.use(
    response => response,
    // eslint-disable-next-line consistent-return
    (error: AxiosError) => {
      const { status } = error.response != null ? error.response : { status: '' };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorMsg = error.response?.data?.message || error.response?.data?.error || t('errors.somethingWentWrong');
      const unauthorizedMsg = t('errors.unauthorized');
      const notFoundMsg = t('errors.notFound');
      const networkErrorMsg = t('errors.networkError');

      if (status === 401) {
        deleteJwtToken();
        const currentPath = window.location.pathname;
        setBusinessCardRedirectPagePath(currentPath);
        if (currentPath.includes('/privilege-card')) {
          window.location.assign('/privilege-card/login');
        } else if (currentPath.includes('/business-card')) {
          window.location.assign('/business-card/login');
        }
      } else if (status === 403) {
        toastService.error(unauthorizedMsg);
        return Promise.reject(error);
      } else if (status === 404) {
        toastService.error(notFoundMsg);
        return Promise.reject(error);
      } else if (error.code === AxiosError.ERR_CANCELED) {
        return Promise.reject(error);
      } else if (error.code === 'ECONNABORTED' || (error.isAxiosError && !error.response)) {
        toastService.error(networkErrorMsg);
        return Promise.reject(error);
      } else {
        toastService.error(Array.isArray(errorMsg) ? (errorMsg as string[]).join('\n') : errorMsg);
        return Promise.reject(error);
      }
    }
  );
};
