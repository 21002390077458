import { API_URL } from '../../../../constants';

const BLOCKED_DC_EMPLOYEES_URL = `${API_URL}/blocked-employees`;

const paths = {
  getBlockedEmployees: (): string => `${BLOCKED_DC_EMPLOYEES_URL}`,
  getBlockedEmployee: (id: string): string => `${BLOCKED_DC_EMPLOYEES_URL}/${id}`,
  createBlockedEmployee: (): string => `${BLOCKED_DC_EMPLOYEES_URL}`,
  updateBlockedEmployee: (id: string) => `${BLOCKED_DC_EMPLOYEES_URL}/${id}`,
  deleteBlockedEmployee: (id: string) => `${BLOCKED_DC_EMPLOYEES_URL}/${id}`,
};

export default paths;
