import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import { Product } from '../models/product';
import api from '../utils/api';
import { Basket, BasketProduct, BasketUpdateRequest, GroupedBasketProduct } from '../models/basket';

interface BasketSlice {
  data: BasketProduct[];
  currency: string;
}

const initialState: BasketSlice = {
  data: [],
  currency: '',
};

export const basketSlice = createSlice({
  name: 'basketSlice',
  initialState,
  reducers: {
    setBasket: (
      state: Draft<BasketSlice>,
      { payload: basketItemsToUpdate }: PayloadAction<BasketProduct[]>
    ): BasketSlice => {
      return {
        ...state,
        data: basketItemsToUpdate,
      };
    },
    setBasketCurrency: (state: Draft<BasketSlice>, { payload }: PayloadAction<string>): BasketSlice => {
      return {
        ...state,
        currency: payload,
      };
    },
    resetBasket: (): BasketSlice => initialState,
  },
});

export const { resetBasket, setBasket, setBasketCurrency } = basketSlice.actions;

export const addProductsToBasket =
  (
    employeeEmail: string,
    employeeBrandCode: string,
    product: Product | BasketProduct,
    count: number,
    salesAssistantStoreCode: string
  ): AppThunk =>
  async (dispatch, state) => {
    if (count > 0) {
      const basketSkus: string[] = state().basket.data.map(bp => bp.sku);
      const skusToAdd: string[] = new Array(count).fill(product.sku);
      await dispatch(
        updateBasket(employeeEmail, employeeBrandCode, [...basketSkus, ...skusToAdd], salesAssistantStoreCode)
      );
    }
  };

const removeProductsFromBasket =
  (
    employeeEmail: string,
    employeeType: string,
    product: Product | BasketProduct,
    itemsToKeep: number,
    salesAssistantStoreCode: string
  ): AppThunk =>
  async (dispatch, state) => {
    const basketSkus: string[] = state().basket.data.map(bp => bp.sku);

    let itemsKept = 0;
    const filteredSkus = basketSkus.filter(sku => {
      if (sku !== product.sku) {
        return true;
      }
      if (itemsKept < itemsToKeep) {
        itemsKept += 1;
        return true;
      }
      return false;
    });

    await dispatch(updateBasket(employeeEmail, employeeType, filteredSkus, salesAssistantStoreCode));
  };

export const updateBasket =
  (
    employeeEmail: string,
    employeeBrandCode: string,
    productsSkus: string[],
    salesAssistantStoreCode: string
  ): AppThunk =>
  async dispatch => {
    const request: BasketUpdateRequest = {
      storeCode: salesAssistantStoreCode,
      employeeEmail,
      employeeBrandCode,
      productsSkus,
    };
    const response: Basket = await api.updateBasket(request);
    dispatch(setBasket(response.basketItems));
    dispatch(setBasketCurrency(response.basketItems[0]?.priceCurrency || 'EUR'))
  };

export const changeProductQuantity =
  (
    employeeEmail: string,
    employeeBrandCode: string,
    product: Product | BasketProduct,
    updatedCounter: number,
    salesAssistantStoreCode: string
  ): AppThunk =>
  async (dispatch, state) => {
    const basketSkus: string[] = state().basket.data.map(bp => bp.sku);
    const currentProductCount = basketSkus.filter(sku => sku === product.sku).length;

    if (updatedCounter > currentProductCount) {
      const numberOfProductsToAdd = updatedCounter - currentProductCount;
      dispatch(
        addProductsToBasket(employeeEmail, employeeBrandCode, product, numberOfProductsToAdd, salesAssistantStoreCode)
      );
    } else if (updatedCounter < currentProductCount) {
      dispatch(
        removeProductsFromBasket(employeeEmail, employeeBrandCode, product, updatedCounter, salesAssistantStoreCode)
      );
    }
  };

export const emptyBasket = (): AppThunk => async dispatch => {
  dispatch(resetBasket());
};

export const selectBasket = (state: RootState): BasketProduct[] => state.basket.data;
export const selectBasketSize = (state: RootState): number => state.basket.data.length;

export const selectBasketGrouped = (state: RootState): GroupedBasketProduct[] => {
  const groupedProducts: GroupedBasketProduct[] = [];
  const grouped = _.groupBy(state.basket.data, basketProduct => basketProduct.sku);
  const uniqueKeys = _.uniq(state.basket.data.map(i => i.sku));
  uniqueKeys.forEach(groupedKey => {
    const product = grouped[groupedKey].at(0) as BasketProduct;
    const itemForSku = grouped[groupedKey].length;
    const productErrors = _.uniq(grouped[groupedKey].flatMap(bp => bp.errors));
    groupedProducts.push({
      ...product,
      errors: productErrors,
      counter: itemForSku,
    });
  });
  return groupedProducts;
};

export const selectBasketHasErrors = (state: RootState): boolean =>
  state.basket.data.some(item => item.errors.length > 0);

export const selectBasketHasWarnings = (state: RootState): boolean =>
  state.basket.data.some(item => item.warnings.length > 0);

export const selectBasketErrorMessages = (state: RootState): string[] => {
  const messages = state.basket.data.flatMap((item: BasketProduct): string[] => item.errors);
  return [...new Set(messages)];
};
export const selectBasketWarningMessages = (state: RootState): string[] => {
  const messages = state.basket.data.flatMap((item: BasketProduct): string[] => item.warnings);
  return [...new Set(messages)];
};

export const selectBasketProductBySku = createSelector([selectBasket, (state, sku) => sku], (basketProducts, sku) => {
  const basketProduct = basketProducts.find(bs => bs.sku === sku);
  if (basketProduct != null) {
    const productsBySku = basketProducts.filter(bp => bp.sku === sku);
    const productErrors = productsBySku.flatMap(bp => bp.errors);
    const errors = _.uniq(productErrors);
    return {
      ...basketProduct,
      errors,
    };
  }
  return basketProduct;
});
export const selectBasketProductCountBySku = createSelector(
  [selectBasket, (state, sku: string) => sku],
  (basketProducts, sku): number => basketProducts.filter(bs => bs.sku === sku).length || 0
);

export const selectBasketPrice = (state: RootState): string => {
  const amount = state.basket.data.map(p => p.discountedPrice).reduce((price, totalPrice) => price + totalPrice, 0);
  return amount % 1 === 0 ? amount.toString() : amount.toFixed(2);
};

export const selectBasketCurrency = (state: RootState): string => state.basket.currency;

export default basketSlice.reducer;
