import axios from 'axios';
import paths from './paths';
import {
  BlockedEmployee,
  BlockedEmployeeCreationRequest,
  BlockedEmployeeUpdateRequest,
  toBlockedEmployee,
} from '../models/blockedEmployee';

const api = {
  getBlockedEmployees: async (): Promise<BlockedEmployee[]> => {
    const response = await axios.get(paths.getBlockedEmployees());
    return response.data.map(toBlockedEmployee);
  },
  getBlockedEmployee: async (id: string): Promise<BlockedEmployee> => {
    const response = await axios.get(paths.getBlockedEmployee(id));
    return toBlockedEmployee(response.data);
  },
  createBlockedEmployee: async (
    blockedEmployeeCreationRequest: BlockedEmployeeCreationRequest
  ): Promise<BlockedEmployee> => {
    const response = await axios.post(paths.createBlockedEmployee(), blockedEmployeeCreationRequest);
    return toBlockedEmployee(response.data);
  },
  updateBlockedEmployee: async (
    id: string,
    blockedEmployeeCreationRequest: BlockedEmployeeUpdateRequest
  ): Promise<BlockedEmployee> => {
    const response = await axios.put(paths.updateBlockedEmployee(id), blockedEmployeeCreationRequest);
    return toBlockedEmployee(response.data);
  },
  deleteBlockedEmployee: async (id: string): Promise<void> => {
    const response = await axios.delete<void>(paths.deleteBlockedEmployee(id));
    return response.data;
  },
};

export default api;
