/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Dimmer } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  changeFilters,
  Filters,
  resetFilters,
  selectSAPurchaseLimitFilters,
  WIZARD_STEP,
} from '../store/salesAssistantPurchaseLimitFilterSlice';
import { SelectEmployeeCategory } from './SelectEmployeeCategory';
import { PurchaseLimitResult } from './PurchaseLimitResult';
import { selectSelectedStore, setSelectedStore } from '../../cardSearch/store/salesAssistantSlice';
import { selectPrincipal } from '../../../auth/store/principalSlice';

export const PurchaseLimitWizard = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectSAPurchaseLimitFilters);
  const salesAssistantStore = useAppSelector(selectSelectedStore);
  const principal = useAppSelector(selectPrincipal);

  const [wizardStep, setWizardStep] = useState(WIZARD_STEP.SELECT_DC_EMPLOYEE_CATEGORY);

  const returnToSelectEmployeeCategoryStep = () => {
    dispatch(resetFilters());
    setWizardStep(WIZARD_STEP.SELECT_DC_EMPLOYEE_CATEGORY);
  };
  const forceSalesAssistantStoreSelection = useCallback(() => {
    if (salesAssistantStore == null && principal?.salesAssistantStores != null) {
      const brandCodes = principal.salesAssistantStores.map(store => store.brand.code) || [];
      const brandCodesSet = new Set(brandCodes);
      if (brandCodesSet.size === 1) {
        dispatch(setSelectedStore(principal?.salesAssistantStores[0]));
      }
    }
  }, [dispatch, principal?.salesAssistantStores, salesAssistantStore]);

  useEffect(() => {
    dispatch(resetFilters());
    forceSalesAssistantStoreSelection();
  }, [dispatch, forceSalesAssistantStoreSelection]);

  const onChangeEmployeeCategoryFilter = useCallback(
    (value: string) => {
      const filtersToApply: Filters = {
        ...filters,
        employeeType: value,
        brand: salesAssistantStore?.brand.code || '',
        country: {
          id: salesAssistantStore?.countryCode || '',
          code: salesAssistantStore?.countryCode || '',
          description: salesAssistantStore?.countryDescription || '',
        },
      };
      dispatch(changeFilters(filtersToApply));
      setWizardStep(WIZARD_STEP.SEARCH_LIMITS);
    },
    [dispatch, filters, salesAssistantStore]
  );
  if (salesAssistantStore == null) {
    return (
      <Dimmer active>
        {t('salesAssistant.purchaseLimits.pleaseSelectStore')} {t('salesAssistant.purchaseLimits.click')}{' '}
        <Link to='/privilege-card/sales-assistant/card-search'>{t('salesAssistant.purchaseLimits.here')}</Link>{' '}
        {t('salesAssistant.purchaseLimits.toRedirect')}
      </Dimmer>
    );
  }

  switch (wizardStep) {
    case WIZARD_STEP.SEARCH_LIMITS:
      return <PurchaseLimitResult onBack={returnToSelectEmployeeCategoryStep} />;
    case WIZARD_STEP.SELECT_DC_EMPLOYEE_CATEGORY:
    default:
      return <SelectEmployeeCategory onChange={onChangeEmployeeCategoryFilter} />;
  }
};
