/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../../../../shared/components/FormField';
import { MoneyAmount } from '../../models/limitRule';
import { orderedCurrencies } from '../../../../../shared/models/currency';
import { useAppSelector } from '../../../../../core/hooks';
import { selectIsFetchingRule } from '../../stores/limitRuleDetailSlice';
import style from './amountField.style';

export default function AmountField({
  label,
  mandatory = false,
  onChange,
  defaultAmount,
  showValidation,
  validationMessage = '',
}: {
  label: string;
  mandatory?: boolean;
  defaultAmount: MoneyAmount | null;
  onChange: (value: MoneyAmount | null) => void;
  showValidation: boolean;
  validationMessage?: string;
}) {
  const [amount, setAmount] = useState(defaultAmount?.value.toString() || '');
  const [amountCurrency, setAmountCurrency] = useState(defaultAmount?.currency || 'EUR');

  const isValidAmountValue = (value: string): boolean => {
    const regex = /^(([1-9]\d*)|(0))(\.\d*)?$/;
    return regex.test(value);
  };

  const onAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replaceAll(',', '.');
    if (value === '') {
      setAmount('');
      onChange(null);
      return;
    }
    if (!isValidAmountValue(value)) {
      return;
    }
    setAmount(value);
    onChange({ value: +value, currency: amountCurrency });
  };

  const onCurrencyChange = (currentCurrency: string) => {
    setAmountCurrency(currentCurrency);
    if (amount !== '' && isValidAmountValue(amount)) {
      onChange({ value: +amount, currency: currentCurrency });
    }
  };

  useEffect(() => {
    setAmount(defaultAmount?.value?.toString() || '');
    setAmountCurrency(defaultAmount?.currency || 'EUR');
  }, [defaultAmount]);

  return (
    <FormField
      label={label}
      mandatory
      editMode
      validationMessages={showValidation && !isValidAmountValue(amount) ? [validationMessage] : []}
      editComponent={
        <InputWithCurrency
          currency={amountCurrency}
          value={amount || ''}
          onChange={onAmountChange}
          onCurrencyChange={onCurrencyChange}
        />
      }
    />
  );
}

interface InputWithCurrencyProps {
  currency: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCurrencyChange: (event: string) => void;
}

const InputWithCurrency = ({ currency, value, onChange, onCurrencyChange }: InputWithCurrencyProps) => {
  const { t } = useTranslation();
  const options: DropdownItemProps[] = orderedCurrencies.map(c => ({
    key: `${c.code} ${c.description}`,
    text: `${c.code}`,
    value: c.code,
    content: (
      <div>
        <div css={style.dropdownItemCode}>{c.code}</div>
        <div css={style.dropdownItemDescription}>{c.description}</div>
      </div>
    ),
  }));
  const isFetching = useAppSelector(selectIsFetchingRule);

  return (
    <Input
      css={style.limitInput}
      label={
        <Dropdown
          css={style.currenciesDropdown}
          search={(options1, value1) => {
            return options1.filter(o => o.key.toLowerCase().includes(value1.toLowerCase()));
          }}
          options={options}
          loading={isFetching}
          noResultsMessage={t('noResultsFound')}
          value={currency}
          onChange={(event, data: DropdownProps) => {
            const selectedCurrency = data.value as string;
            onCurrencyChange(selectedCurrency);
          }}
        />
      }
      labelPosition='right'
      placeholder={t('limitRuleset.enterAmount')}
      value={value}
      onChange={onChange}
    />
  );
};
