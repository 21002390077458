/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DropdownItemProps, Dropdown } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { SpecialUser } from '../models/specialCases';
import { AdUser } from '../../usersManagement/models/user';
import userApi from '../../usersManagement/utils/api';
import style from './specialCasesUserDropdown.style';
import { FormField } from '../../../../shared/components/FormField';

interface SpecialCasesUserDropdownProps {
  onSelection: (users: SpecialUser[]) => void;
  specialCasesList: SpecialUser[];
}

export default function SpecialCasesUserDropdown({
  onSelection,
  specialCasesList,
}: SpecialCasesUserDropdownProps): JSX.Element {
  const { t } = useTranslation();
  const [adUsers, setAdUsers] = useState<AdUser[]>([]);
  const [usersList, setUsersList] = useState(specialCasesList);
  const [isFetching, setIsFetching] = useState(false);
  const getOptions = useCallback(() => {
    const generateItem = (user: AdUser): DropdownItemProps => ({
      key: user.email,
      value: `${user.firstName} ${user.familyName}`,
      text: `${user.firstName} ${user.familyName}`,
      content: (
        <div>
          <div css={style.dropdownItemName}>
            {user.firstName} {user.familyName}
          </div>
          <div>{user.email}</div>
        </div>
      ),
    });
    const mergedList = _.uniqBy(
      adUsers.concat(
        usersList.map(u => ({ email: u.email.toLowerCase(), firstName: u.firstName, familyName: u.familyName }))
      ),
      'email'
    );

    return mergedList.map(generateItem);
  }, [adUsers, usersList]);

  const searchAdUsers = useDebouncedCallback(async (userToSearch: string) => {
    if (userToSearch.trim() === '') {
      setAdUsers([]);
      return;
    }
    if (userToSearch.trim().length < 3) {
      return;
    }
    setIsFetching(true);
    try {
      const result = await userApi.getAdUsers(userToSearch);
      if (result.length > 0) {
        setAdUsers(result);
      }
    } catch {
      setAdUsers([]);
    } finally {
      setIsFetching(false);
    }
  }, 300);

  return (
    <FormField
      label=''
      inline
      editMode
      editComponent={
        <Dropdown
          css={style.dropdownField}
          placeholder={t('users.search')}
          value={_.sortBy(usersList, 'firstName').map(u => `${u.firstName} ${u.familyName}`)}
          selection
          multiple
          clearable
          noResultsMessage={t('noResultsFound')}
          search={() => {
            const options = getOptions().filter(
              o => !usersList.map(u => `${u.firstName} ${u.familyName}`).includes(o.text as string)
            );
            return options;
          }}
          options={getOptions()}
          onChange={(e, { value }) => {
            const searchedUsers = adUsers.filter(u => (value as string[]).includes(`${u.firstName} ${u.familyName}`));
            const selectedUsers = usersList.filter(u => (value as string[]).includes(`${u.firstName} ${u.familyName}`));
            const mergedList = _.uniqBy(searchedUsers.concat(selectedUsers), 'email');
            onSelection(mergedList);
            setUsersList(mergedList);
          }}
          onSearchChange={(e, { searchQuery }) => {
            searchAdUsers(searchQuery);
            if (searchQuery.trim() === '') {
              onSelection([]);
            }
          }}
          selectOnBlur={false}
          disabled={isFetching}
          loading={isFetching}
        />
      }
    />
  );
}
