import { css } from '@emotion/react';

const ruleIcon = css`
  && {
    font-size: 14px;
    color: #f9e6d7 !important;
    display: flex;
    align-items: center;
  }
`;

const editableRuleIcon = css`
  && {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
`;

const discountRuleDetailContainer = css`
  display: flex;
  width: 100%;
  column-gap: 20px;
  margin: 0 20px;
  align-items: center;
  @media (min-width: 540px) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--dcd-gray-color-60);
  }
`;
const discountRuleDetail = css`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
`;
const iconAndConstraintsContainer = css`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const priorityContainer = css`
  display: flex;
  column-gap: 12px;
  padding: 8px 0;
  align-items: center;
`;

export default {
  ruleIcon,
  editableRuleIcon,
  discountRuleDetail,
  discountRuleDetailContainer,
  iconAndConstraintsContainer,
  priorityContainer,
};
