/** @jsxImportSource @emotion/react */
import { Icon } from 'semantic-ui-react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './header.style';

interface HeaderProps {
  arrowBackEnable?: boolean;
  redirectPath?: string;
  title?: string;
  onBackCustom?: () => void;
}

export default function Header({ arrowBackEnable = true, redirectPath, title, onBackCustom }: HeaderProps) {
  const navigate = useNavigate();
  const goBack = useCallback(
    () => (redirectPath == null ? navigate(-1) : navigate(redirectPath)),
    [navigate, redirectPath]
  );
  const onBackClick = useCallback(
    () => (onBackCustom !== undefined ? onBackCustom() : goBack()),
    [goBack, onBackCustom]
  );

  return (
    <div css={style.headerContainer}>
      {arrowBackEnable ? (
        <>
          <Icon name='arrow left' size='large' css={style.headerArrow} onClick={onBackClick} />
          <div css={style.headerTitle}>{title}</div>
        </>
      ) : (
        <h2 css={style.headerTitle}>{title}</h2>
      )}
    </div>
  );
}
