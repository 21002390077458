import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const filtersContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  ${globalStyle.stickyHeader(`var(--dcd-white-color)`, '20px')};
`;

const filterIcon = css`
  && {
    margin-left: 15px;
  }
`;

const searchSection = css`
  margin: 15px auto;
`;

const badgeText = css`
  @media (max-width: 350px) {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const badgeTextWrapper = css`
  @media (max-width: 350px) {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const filterBadge = css`
  ${globalStyle.bodySmall};
  && {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dcd-secondary-color-40);
    border-radius: 20px;
    margin-right: 0;
    margin-left: 0;
    padding: 4px 8px;
  }
  @media (max-width: 350px) {
    &.ui.label:last-child {
      max-width: calc(100% - 122px);
    }
  }
`;

const badgesWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 7px;
`;

const badgesContainer = css`
  display: flex;
  white-space: nowrap;
  column-gap: 10px;
  width: 100%;
`;
export default {
  filtersContainer,
  filterIcon,
  searchSection,
  badgesWrapper,
  filterBadge,
  badgesContainer,
  badgeText,
  badgeTextWrapper,
};
