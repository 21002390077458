import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const card = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const infoContainer = css`
  display: flex;
  align-items: center;
  gap: 5px;
  /* flex-shrink: 0; */
`;

const iconContainer = css`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const productCategory = css`
  ${globalStyle.bodySmall};
  margin-bottom: 0;
  && {
    font-weight: 500;
  }
`;

const brand = css`
  font-size: 12px;
`;

const categoryAndStoreTypologyContainer = css`
  display: flex;
  flex-direction: column;
`
export default { card, infoContainer, iconContainer, productCategory, brand, categoryAndStoreTypologyContainer };
