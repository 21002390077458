/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  changeSearchBoxFilter,
  selectSAPurchaseLimitFilters,
  selectSearchBoxFilter,
} from '../store/salesAssistantPurchaseLimitFilterSlice';
import { SearchBox } from '../../../../shared/components/SearchBox';
import { PurchaseFiltersModal } from './PurchaseFiltersModal';
import style from './purchaseLimitFilters.style';

export default function PurchaseLimitFilters({ hideCountry }: { hideCountry: boolean }): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const searchBoxFilter = useAppSelector(selectSearchBoxFilter);
  const [showFilters, setShowFilters] = useState(false);
  const debounced = useDebouncedCallback((value: string) => {
    dispatch(changeSearchBoxFilter(value));
  }, 500);

  return (
    <>
      <div css={style.filtersContainer}>
        <SearchBox
          key='limitsSearch'
          defaultValue={searchBoxFilter}
          onChange={value => debounced(value)}
          placeholder={t('salesAssistant.purchaseLimits.search')}
        />
        <Icon
          css={style.filterIcon}
          name='filter'
          size='large'
          link
          onClick={() => {
            setShowFilters(true);
          }}
        />
      </div>
      <FiltersBadges hideCountry={hideCountry} />
      {showFilters && (
        <PurchaseFiltersModal
          onBack={() => {
            setShowFilters(false);
          }}
          hideCountry={hideCountry}
        />
      )}
    </>
  );
}
const FiltersBadges = ({ hideCountry = false }: { hideCountry: boolean }): JSX.Element => {
  const { t } = useTranslation();
  const filters = useAppSelector(selectSAPurchaseLimitFilters);

  return (
    <div css={style.badgesWrapper}>
      <div css={style.badgesContainer}>
        {!_.isEmpty(filters.employeeType) && (
          <Label css={style.filterBadge}>
            {t(`salesAssistant.purchaseLimits.employeeTypes.${filters.employeeType}`)}
          </Label>
        )}
        {!hideCountry && !_.isEmpty(filters.country) && (
          <Label css={style.filterBadge}>
            <div css={style.badgeTextWrapper}>
              <div css={style.badgeText}>{filters.country.description}</div>
            </div>
          </Label>
        )}
      </div>
    </div>
  );
};
