/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import style from './specialCases.style';
import { SearchBox } from '../../../../shared/components/SearchBox';
import SpecialCasesList from '../components/SpecialCasesList';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  fetchSpecialCases,
  selectSpecialCasesGoldCardsPercentage,
  selectFilter,
  selectFilteredAndSortedSpecialCasesGoldCards,
  setFilter,
  selectFilteredAndSortedSpecialCasesSilverCards,
} from '../../../admin/discounts/store/specialCasesSlice';
import { SpecialCasesDiscountPercentage } from '../components/SpecialCasesDiscountPercentage';
import { SpecialUser } from '../../../admin/discounts/models/specialCases';

enum TABS {
  'GOLD_CARDS',
  'SILVER_CARDS',
}

export const SpecialCases = (): JSX.Element => {
  const { t } = useTranslation();
  const specialCasesGoldCards = useAppSelector(selectFilteredAndSortedSpecialCasesGoldCards);
  const specialCasesGoldCardsPercentage = useAppSelector(selectSpecialCasesGoldCardsPercentage);
  const specialCasesSilverCards = useAppSelector(selectFilteredAndSortedSpecialCasesSilverCards);
  const dispatch = useAppDispatch();
  const specialCasesFilterValue = useAppSelector(selectFilter);
  const [activeTab, setActiveTab] = useState<TABS>(TABS.GOLD_CARDS);
  const setFilterDebounced = useDebouncedCallback((filterValue: string) => {
    dispatch(setFilter(filterValue));
  }, 100);

  useEffect(() => {
    dispatch(fetchSpecialCases());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <div css={style.headerContainer}>
        <div>{t('salesAssistant.specialCases.title')}</div>
      </div>
      <div css={style.stickyHeader}>
        <div css={style.tabsContainer}>
          <div css={style.tabButton(activeTab === TABS.GOLD_CARDS)} onClick={() => setActiveTab(TABS.GOLD_CARDS)}>
            {t('salesAssistant.specialCases.goldCards')}
          </div>
          <div css={style.tabButton(activeTab === TABS.SILVER_CARDS)} onClick={() => setActiveTab(TABS.SILVER_CARDS)}>
            {t('salesAssistant.specialCases.silverCards')}
          </div>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <SearchBox
            defaultValue={specialCasesFilterValue}
            placeholder={t('salesAssistant.specialCases.searchBoxPlaceHolder')}
            onChange={value => {
              setFilterDebounced(value);
            }}
          />
        </div>
        <div style={{ paddingTop: '20px' }}>
          {activeTab === TABS.GOLD_CARDS && (
            <SpecialCasesDiscountPercentage
              percentage={specialCasesGoldCardsPercentage}
              label={`${t(
                'salesAssistant.purchaseLimits.forGoldCardSpecialCases'
              )} ${specialCasesGoldCardsPercentage}${t('salesAssistant.purchaseLimits.discountApplies')}`}
            />
          )}
        </div>
      </div>
      {activeTab === TABS.GOLD_CARDS ? (
        <SpecialCasesTab specialCases={specialCasesGoldCards} />
      ) : (
        <SpecialCasesTab specialCases={specialCasesSilverCards} />
      )}
    </div>
  );
};

interface SpecialCasesTabProps {
  specialCases: SpecialUser[];
}

export const SpecialCasesTab = ({ specialCases }: SpecialCasesTabProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.specialCasesCardsContainer}>
      {specialCases.length === 0 ? (
        <div css={style.noSpecialCasesLabel}>{t('salesAssistant.specialCases.noSpecialCases')}</div>
      ) : (
        <SpecialCasesList userList={specialCases} />
      )}
    </div>
  );
};
