import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useAppSelector } from '../hooks';
import { selectPrincipal } from '../../features/auth/store/principalSlice';
import { getJwtToken, removeRedirectPagePath, setRedirectPagePath } from '../services/webStorageService';
import { checkRole, checkRoles, Principal, Role } from '../../features/auth/models/principal';
import { LoginError } from '../../features/auth/pages/LoginError';

interface RequireAuthProps {
  children: JSX.Element;
}

// A wrapper that redirects to the login
// screen if you're not yet authenticated.
// Note if token is expired the interceptor redirect to login
export const RequireAuth = ({ children }: RequireAuthProps) => {
  const principal = useAppSelector(selectPrincipal);
  const path = useLocation().pathname;
  setRedirectPagePath(path);
  if (getJwtToken()) {
    if (!principal) {
      return <Loader size='big' />;
    }
    removeRedirectPagePath();
    if (path.includes('/business-card')) {
      return children;
    }
    if (!isPageAccessible(principal, path)) {
      return <LoginError />;
    }
    return children;
  }

  if (path.includes('/business-card')) {
    return <Navigate to='/business-card/login' replace />;
  }

  return <Navigate to='/privilege-card/login' replace />;
};

const isPageAccessible = (principal: Principal, path: string): boolean => {
  if (
    (checkRole(principal, Role.ADMIN) && path.includes('/privilege-card/admin')) ||
    (checkRole(principal, Role.GROUP_ADMIN) && path.includes('/privilege-card/admin')) ||
    (checkRole(principal, Role.DC_EMPLOYEE) && path.includes('/privilege-card/employee')) ||
    (checkRole(principal, Role.SALES_ASSISTANT) && path.includes('/privilege-card/sales-assistant')) ||
    (checkRole(principal, Role.BC_EMPLOYEE) && path.includes('/business-card/my-card')) ||
    (checkRoles(principal, [Role.ADMIN, Role.GROUP_ADMIN, Role.SALES_ASSISTANT, Role.DC_EMPLOYEE]) &&
      path.includes('/privilege-card/home')) ||
    (checkRoles(principal, [Role.ADMIN, Role.GROUP_ADMIN, Role.SALES_ASSISTANT, Role.DC_EMPLOYEE]) &&
      path.includes('/privilege-card')) ||
    path === '/privilege-card/login' ||
    path === '/business-card/login'
  ) {
    return true;
  }
  return false;
};
