import { css } from '@emotion/react';
import style from '../../../../shared/global.style';

const container = css`
  display: flex;
  margin: 0 10px 20px 0;
  border-radius: 4px;
  background-color: var(--dcd-white-color);
  box-shadow: 2px 1px 2px 0px var(--dcd-secondary-color-100);
`;

const iconContainer = css`
  display: flex;
  padding: 0 15px;
  margin: 10px 0;
  border-right: 1px solid var(--dcd-secondary-color-100);
  color: var(--dcd-pink-color-100);
  @media (max-width: 540px) {
    display: none;
  }
`;

const userIcon = css`
  && {
    margin: auto;
    font-size: 24px;
  }
`;

const contentContainer = css`
  flex-grow: 1;
  padding: 10px 15px;
`;

const rowFieldsContainer = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
`;

const emailFieldContainer = css``;
const fieldContainer = css`
  min-width: 150px;
`;

const field = css`
  min-width: 150px;
  margin-right: 10px;
`;
const blockIntervalField = css`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const emailDropdownFieldContainer = css`
  ${fieldContainer};

  .edit-component-container {
    width: 100%;
  }
  .ui.fluid.dropdown {
    width: auto;
  }
  .ui.search.dropdown > .text {
    width: 100%;
  }
  && .menu > .selected.item {
    background-color: transparent;
  }

  .menu > :nth-child(even) {
    background-color: #fafafb;
  }
  && .menu > .item:hover {
    background-color: var(--dcd-kering-primary-color-20);
  }
`;

const dropdownItemName = css`
  font-weight: 500;
  margin-bottom: 2px;
`;

const multiFieldViewComponent = css`
  display: flex;
  flex-wrap: wrap;
  > span {
    margin-right: 10px;
  }
`;

const buttonContainer = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 10px 0;
`;

const creationInfoContainer = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const button = css`
  width: 125px;
  ${style.secondaryButton}
`;
const confirm = css`
  .actions .ui.button {
    color: var(--dcd-pink-color);
    background-color: var(--dcd-white-color);
    border: 2px solid var(--dcd-pink-color);
    &.primary {
      color: var(--dcd-white-color);
      background-color: var(--dcd-pink-color);
    }
  }
`;

const legendSection = css`
  ${style.bodySmall}
  margin-right: auto;
  @media (max-width: 600px) {
    flex-basis: 100%;
    margin-bottom: 10px;
    text-align: right;
  }
  && {
    font-size: 10px;
  }
`;
const historyList = css`
  &&&& {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }
`;
const historyItem = css`
  &&&& {
    display: flex;
    margin: 0;
  }
`;

export default {
  container,
  iconContainer,
  contentContainer,
  rowFieldsContainer,
  userIcon,
  fieldContainer,
  multiFieldViewComponent,
  field,
  emailDropdownFieldContainer,
  buttonContainer,
  creationInfoContainer,
  button,
  confirm,
  dropdownItemName,
  emailFieldContainer,
  legendSection,
  blockIntervalField,
  historyList,
  historyItem,
};
