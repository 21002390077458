import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const productDetailContainer = css`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding-top: 20px;
`;

const scrollableCard = css`
  overflow: auto;
  padding: 2px;
`;

const cardContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const card = css`
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  box-sizing: border-box;

  &&&&& {
    min-height: 430px;
    width: 100%;
    max-width: 340px;
    flex-grow: 1;
    max-height: 600px;
  }
`;
const itemImage = css`
  height: 230px;
`;
const noImageText = css`
  ${globalStyle.bodyMedium}
  ${itemImage};
  background-color: var(--dcd-kering-primary-color-10);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const loaderContainer = css`
  &&& {
    height: 230px;
  };
  display: flex;
  justify-content: center;
  align-items: center;
`

const cardContent = css`
  &&&&& {
    display: flex;
    flex-direction: column;
    gap: 15px;
    ${globalStyle.bodyNormal};
    :after {
      content: '';
      display: none;
    }
  }
`;

const notEligible = css`
  color: var(--dcd-error);
`;

const productInfo = css`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const notEligibleIcon = css`
  &&&& {
    vertical-align: middle;
    line-height: 0.75em;
  }
`;

const name = css`
  ${globalStyle.bodyMedium};
`;

const description = css`
  ${globalStyle.bodySmall};
  && {
    font-size: 12px;
  }
`;

const category = css`
  ${globalStyle.bodySmall};
`;

const currentPrice = css`
  ${globalStyle.bodyMedium};
`;
const originalPrice = css`
  text-decoration: line-through;
  margin-left: 5px;
`;

const basketActions = css`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: auto;
`;
const basketButtonAction = css`
  ${globalStyle.primaryButton};

  &&& {
    width: 100%;
    min-width: 166px;
    margin-right: 0;
    padding-right: 24px;
    padding-left: 24px;
  }
`;
const counterButton = css`
  ${globalStyle.primaryButton};
  min-width: auto;
  &&& {
    margin: 0;
  }
`;
const counterValue = css`
  width: 30px;
  display: flex;
  justify-content: center;
  font-weight: 500;
`;
const counterContainer = css`
  display: flex;
  align-items: center;
`;
export default {
  scrollableCard,
  productDetailContainer,
  cardContainer,
  card,
  noImageText,
  itemImage,
  loaderContainer,
  cardContent,
  productInfo,
  notEligible,
  notEligibleIcon,
  name,
  description,
  category,
  currentPrice,
  originalPrice,
  basketActions,
  basketButtonAction,
  counterButton,
  counterContainer,
  counterValue,
};
