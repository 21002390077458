/** @jsxImportSource @emotion/react */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, ListContent, ListDescription, ListIcon, ListItem } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as EmailValidator from 'email-validator';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import { FormField } from '../../../../shared/components/FormField';
import {
  BlockedEmployee,
  BlockedEmployeeCreationRequest,
  BlockedInterval,
  getLastBlockedInterval,
  isEmployeeBlocked,
  isEmployeeUnblocked,
  willEmployeeBeBlocked,
} from '../models/blockedEmployee';
import { useEmailSelector } from '../stores/blockedEmployeesSlice';
import style from './blockedEmployeeCard.style';
import AdUserDropdown from '../../../../shared/components/AdUserDropDown';
import { toISOStringWithTimezone } from '../../../../shared/utils/utils';

interface BlockedEmployeeFormProps {
  employee: BlockedEmployee;
  onCancel: () => void;
  onSave: (id: string, blockedEmployeeRequest: BlockedEmployeeCreationRequest) => void;
}

export const BlockedEmployeeForm = ({ employee, onCancel, onSave }: BlockedEmployeeFormProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(employee.email);
  const [selectedFirstName, setFirstName] = useState(employee.firstName);
  const [selectedFamilyName, setFamilyName] = useState(employee.familyName);

  const today = moment().startOf('day').toDate();
  const yesterday = moment().subtract(1, 'day').startOf('day').toDate();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [blockIntervalsHistory, setBlockIntervalsHistory] = useState<BlockedInterval[]>([]);

  const fullName = useMemo(() => {
    const name = `${selectedFirstName} ${selectedFamilyName}`;
    if (name.trim() === '') {
      return '-';
    }
    return name;
  }, [selectedFirstName, selectedFamilyName]);

  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const isEmailValid = useMemo(() => email.trim() !== '' && EmailValidator.validate(email), [email]);
  const isEmailNotUsed = useEmailSelector(email, employee.id);

  const getEmailValidationMessages = useCallback(() => {
    const messages: string[] = [];
    if (!isEmailValid) {
      messages.push(t('users.emailValidationMessage'));
    }
    if (!isEmailNotUsed) {
      messages.push(t('users.emailUsed'));
    }
    return messages;
  }, [isEmailNotUsed, isEmailValid, t]);

  const isFirstNameValid = useMemo(() => selectedFirstName.trim() !== '', [selectedFirstName]);

  const isFamilyNameValid = useMemo(() => selectedFamilyName.trim() !== '', [selectedFamilyName]);

  const validBlockPeriod = useMemo(() => {
    const isNewBlockedEmployee = employee.id === '';

    if (isNewBlockedEmployee || isEmployeeUnblocked(employee)) {
      return (
        startDate != null &&
        moment(startDate).isSameOrAfter(today, 'day') &&
        (endDate == null || moment(endDate).isSameOrAfter(startDate, 'day'))
      );
    }

    if (willEmployeeBeBlocked(employee)) {
      return (
        startDate != null &&
        moment(startDate).isSameOrAfter(today, 'day') &&
        (endDate == null || moment(endDate).isSameOrAfter(startDate))
      );
    }

    if (isEmployeeBlocked(employee)) {
      return (
        startDate != null &&
        moment(startDate).isSameOrBefore(today, 'day') &&
        (endDate == null ||
          (moment(endDate).isSameOrAfter(startDate, 'day') && moment(endDate).isSameOrAfter(yesterday, 'day')))
      );
    }

    return false;
  }, [employee, endDate, startDate, today, yesterday]);

  const getBlockedIntervalValidationMessages = useCallback(() => {
    const messages: string[] = [];
    const isNewBlockedEmployee = employee.id === '';

    if (
      (isNewBlockedEmployee || isEmployeeUnblocked(employee)) &&
      !(startDate != null && (endDate == null || moment(endDate).isSameOrAfter(startDate, 'day')))
    ) {
      messages.push(t('blockedEmployees.invalidBlockedIntervalValidationMessage1'));
    }
    if (
      willEmployeeBeBlocked(employee) &&
      !(
        startDate != null &&
        moment(startDate).isSameOrAfter(today, 'day') &&
        (endDate == null || moment(endDate).isSameOrAfter(startDate))
      )
    ) {
      messages.push(t('blockedEmployees.invalidBlockedIntervalValidationMessage2'));
    }

    if (
      !isNewBlockedEmployee &&
      isEmployeeBlocked(employee) &&
      !(
        startDate != null &&
        moment(startDate).isSame(getLastBlockedInterval(employee)?.startDate, 'day') &&
        (endDate == null ||
          (moment(endDate).isSameOrAfter(startDate, 'day') && moment(endDate).isSameOrAfter(yesterday, 'day')))
      )
    ) {
      messages.push(t('blockedEmployees.invalidBlockedIntervalValidationMessage3'));
    }

    return messages;
  }, [employee, endDate, startDate, t, today, yesterday]);

  const startDateReadOnly = useMemo(
    () => startDate !== null && employee.id !== '' && isEmployeeBlocked(employee),
    [employee, startDate]
  );

  const updateOrCreate = useCallback(() => {
    setShowValidationMessage(true);
    if (
      isEmailValid &&
      isEmailNotUsed &&
      isFirstNameValid &&
      isFamilyNameValid &&
      validBlockPeriod &&
      startDate != null
    ) {
      onSave(employee.id, {
        email,
        familyName: selectedFamilyName,
        firstName: selectedFirstName,
        startDate: toISOStringWithTimezone(startDate),
        endDate: endDate == null ? null : toISOStringWithTimezone(endDate),
      });
    }
  }, [
    isEmailValid,
    isEmailNotUsed,
    isFirstNameValid,
    isFamilyNameValid,
    validBlockPeriod,
    onSave,
    employee.id,
    email,
    selectedFamilyName,
    selectedFirstName,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (employee.blockedIntervals.length === 0 || employee.id === '') {
      setStartDate(today);
    } else if (isEmployeeBlocked(employee) || willEmployeeBeBlocked(employee)) {
      const history = [...employee.blockedIntervals];
      const lastInterval: BlockedInterval = history.pop() as BlockedInterval;
      setStartDate(moment(lastInterval.startDate).toDate());
      setEndDate(lastInterval.endDate != null ? moment(lastInterval.endDate).toDate() : null);
      setBlockIntervalsHistory(history);
    } else if (isEmployeeUnblocked(employee)) {
      setStartDate(today);
      setEndDate(null);
      setBlockIntervalsHistory(employee.blockedIntervals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  return (
    <div css={style.container}>
      <div css={style.contentContainer}>
        <div css={style.emailDropdownFieldContainer}>
          {employee.id !== '' ? (
            <FormField label={t('blockedEmployees.email')} viewComponent={<span css={style.field}>{email}</span>} />
          ) : (
            <AdUserDropdown
              onSelection={(userEmail: string, userFirstName: string, userFamilyName: string) => {
                setEmail(userEmail);
                setFirstName(userFirstName);
                setFamilyName(userFamilyName);
              }}
              validationMessages={showValidationMessage ? getEmailValidationMessages() : []}
            />
          )}
        </div>

        <div css={style.fieldContainer}>
          <FormField label={t('blockedEmployees.name')} viewComponent={<span css={style.field}>{fullName}</span>} />
        </div>
        <div>
          <FormField
            label={t('blockedEmployees.blockedInterval')}
            editMode
            mandatory
            validationMessages={showValidationMessage ? getBlockedIntervalValidationMessages() : []}
            editComponent={
              <div css={style.blockIntervalField}>
                <SemanticDatepicker
                  readOnly={startDateReadOnly}
                  value={startDate ?? undefined}
                  onChange={(event, data) => {
                    const date = data.value == null ? null : (data.value as Date);
                    setStartDate(date);
                  }}
                  minDate={today}
                />
                <SemanticDatepicker
                  value={endDate ?? undefined}
                  minDate={startDate != null ? moment(startDate).startOf('day').toDate() : yesterday}
                  maxDate={moment().endOf('year').toDate()}
                  onChange={(event, data) => {
                    const date = data.value == null ? null : (data.value as Date);
                    setEndDate(date);
                  }}
                />
              </div>
            }
          />
        </div>
        {blockIntervalsHistory.length > 0 && (
          <div>
            <FormField
              label={t('blockedEmployees.blockedIntervals')}
              viewComponent={
                <List horizontal css={style.historyList}>
                  {[...blockIntervalsHistory].reverse().map(interval => (
                    <ListItem key={interval.startDate.toString()} css={style.historyItem}>
                      <ListIcon name='clock' />
                      <ListContent>
                        <ListDescription>
                          {`${moment(interval.startDate).format('YYYY-MM-DD')}, ${
                            interval.endDate != null
                              ? moment(interval.endDate).format('YYYY-MM-DD')
                              : t('blockedEmployees.ongoing')
                          }`}
                        </ListDescription>
                      </ListContent>
                    </ListItem>
                  ))}
                </List>
              }
            />
          </div>
        )}
        <div css={style.legendSection}>{t('mandatoryFields')}</div>
        <div css={style.buttonContainer}>
          <Button css={style.button} onClick={onCancel} content={t('cancel')} icon='cancel' labelPosition='left' />
          <Button css={style.button} onClick={updateOrCreate} content={t('save')} icon='save' labelPosition='left' />
        </div>
      </div>
    </div>
  );
};
