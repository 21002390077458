/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import style from './moneyThresholdCard.style';
import { GlobalLimits } from '../../../admin/limits/models/limitRule';
import { Purchase } from '../../purchaseHistory/models/purchase';
import { isFashionBrand, isJewelryBrand } from '../../../../shared/models/brand';
import { useAppSelector } from '../../../../core/hooks';
import { selectSelectedBrand } from '../store/employeePurchaseLimitSlice';
import { toLocaleAmount } from '../../../../shared/utils/utils';
import { selectIsFetchingLimitRules } from '../../../admin/limits/stores/limitRulesSlice';
import {
  selectIsFetchingMyCurrentYearPurchaseHistory,
  selectIsFetchingMyCurrentYearPurchaseHistoryInEuros,
} from '../../purchaseHistory/store/purchaseHistorySlice';

interface MoneyThresholdCardProps {
  globalLimits: GlobalLimits;
  purchaseHistoryInEmployeeCurrency: Purchase[];
  purchaseHistoryInEuros: Purchase[];
}
export function MoneyThresholdCard({
  purchaseHistoryInEmployeeCurrency,
  purchaseHistoryInEuros,
  globalLimits,
}: MoneyThresholdCardProps): JSX.Element {
  const { t } = useTranslation();
  const { jewelryAmount, fashionAmount, converted } = globalLimits;
  const brandCode = useAppSelector(selectSelectedBrand)?.code;
  const isFetchingLimitRules = useAppSelector(selectIsFetchingLimitRules);
  const isFetchingPurchaseHistoryInEmployeeCurrency = useAppSelector(selectIsFetchingMyCurrentYearPurchaseHistory);
  const isFetchingPurchaseHistoryInEuros = useAppSelector(selectIsFetchingMyCurrentYearPurchaseHistoryInEuros);
  const isFashion = brandCode != null ? isFashionBrand(brandCode) : false;

  const fashionPurchasesInEuros = purchaseHistoryInEuros.filter(p => isFashionBrand(p.store.brand.code));
  const jewelryPurchasesInEuros = purchaseHistoryInEuros.filter(p => isJewelryBrand(p.store.brand.code));
  const fashionPurchasesInEmployeeCurrency = purchaseHistoryInEmployeeCurrency.filter(p =>
    isFashionBrand(p.store.brand.code)
  );
  const jewelryPurchasesInEmployeeCurrency = purchaseHistoryInEmployeeCurrency.filter(p =>
    isJewelryBrand(p.store.brand.code)
  );

  const spentAmountFashionInEuros = fashionPurchasesInEuros.reduce((sum, purchase) => {
    if (purchase.discountedPriceConverted.value != null) {
      return sum + purchase.discountedPriceConverted.value;
    }
    return sum + purchase.discountedPrice;
  }, 0);

  const spentAmountJewelryInEuros = jewelryPurchasesInEuros.reduce((sum, purchase) => {
    if (purchase.discountedPriceConverted.value != null) {
      return sum + purchase.discountedPriceConverted.value;
    }
    return sum + purchase.discountedPrice;
  }, 0);

  const spentAmountFashionInEmployeeCurrency = fashionPurchasesInEmployeeCurrency.reduce((sum, purchase) => {
    if (purchase.discountedPriceConverted.value != null) {
      return sum + purchase.discountedPriceConverted.value;
    }
    return sum + purchase.discountedPrice;
  }, 0);

  const spentAmountJewelryInEmployeeCurrency = jewelryPurchasesInEmployeeCurrency.reduce((sum, purchase) => {
    if (purchase.discountedPriceConverted.value != null) {
      return sum + purchase.discountedPriceConverted.value;
    }
    return sum + purchase.discountedPrice;
  }, 0);

  return (
    <div css={style.card}>
      <div css={style.content}>
        <div css={style.globalLimitTitle}>{t('purchaseHistory.headerTitle')}</div>
        {isFetchingLimitRules || isFetchingPurchaseHistoryInEuros || isFetchingPurchaseHistoryInEmployeeCurrency ? (
          <div css={style.threshold}>
            <div css={style.thresholdAmountsContainer}>
              <Loader active inline size='mini' />
            </div>
          </div>
        ) : isFashion ? (
          <div css={style.threshold}>
            {converted != null && converted.currency !== 'EUR' ? (
              <div css={style.thresholdAmountsContainer}>
                <MoneyThreshold amountSpent={spentAmountFashionInEuros} amountLimit={fashionAmount} currency='EUR' />
                <MoneyThreshold
                  amountSpent={spentAmountFashionInEmployeeCurrency}
                  amountLimit={Math.round(converted.fashionAmount)}
                  currency={converted.currency}
                />
              </div>
            ) : (
              <MoneyThreshold amountSpent={spentAmountFashionInEuros} amountLimit={fashionAmount} currency='EUR' />
            )}
          </div>
        ) : (
          <div css={style.threshold}>
            {converted != null && converted.currency !== 'EUR' ? (
              <div css={style.thresholdAmountsContainer}>
                <MoneyThreshold amountSpent={spentAmountJewelryInEuros} amountLimit={jewelryAmount} currency='EUR' />
                <MoneyThreshold
                  amountSpent={spentAmountJewelryInEmployeeCurrency}
                  amountLimit={Math.round(converted.jewelryAmount)}
                  currency={converted.currency}
                />
              </div>
            ) : (
              <MoneyThreshold amountSpent={spentAmountJewelryInEuros} amountLimit={jewelryAmount} currency='EUR' />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const MoneyThreshold = ({
  amountSpent,
  amountLimit,
  currency,
}: {
  amountSpent: number;
  amountLimit: number;
  currency: string;
}) => {
  return (
    <h2 css={style.thresholdAmount}>
      <span css={style.spentAmount}>{toLocaleAmount(Math.round(amountSpent))}</span>
      <span css={style.spendableAmount}> / {toLocaleAmount(Math.round(amountLimit))}</span>
      <span css={style.currency}> {currency}</span>
    </h2>
  );
};
