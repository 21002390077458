import { Brand } from '../../../../shared/models/brand';

export interface Store {
  city: string;
  address: string;
  zipCode: string;
  country: string;
  region: string;
  jdaCode: string;
  locationName: string;
  hfmCode: string;
  currency: string;
  brand: Brand;
}

export interface StoreResponse {
  city: string;
  address: string;
  zipCode: string;
  country: string;
  region: string;
  jdaCode: string;
  locationName: string;
  hfmCode: string;
  currency: string;
  brand: Brand;
}

export const toStore = (response: StoreResponse): Store => ({ ...response });
