import { css } from '@emotion/react';
import discountCardBackground from '../assets/discountCardBackground.svg';

const page = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: url(${discountCardBackground}) no-repeat;
  background-size: cover;

  @media (min-width: 821px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const appContainer = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 821px) {
    box-shadow: 0px 0px 4px var(--dcd-gray-color-60);
    border-radius: 5px;
    max-width: 600px;
  }
`;

const container = css`
  background-color: var(--dcd-white-color);
  flex: 1;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  @media (min-width: 821px) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export default {
  page,
  appContainer,
  container,
};
