import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk, RootState } from '../../../../core/store';
import { Country } from '../../../../shared/models/country';
import api from '../utils/api';

interface CountriesSliceState {
  data: Country[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: CountriesSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    startFetch: (state: Draft<CountriesSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<CountriesSliceState>, action: PayloadAction<Country[]>) => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    reset: () => initialState,
    httpError: (state: Draft<CountriesSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError, reset } = countriesSlice.actions;

export const fetchCountries =
  (brandCode: string): AppThunk =>
  async dispatch => {
    dispatch(reset());
    dispatch(startFetch());
    try {
      const countries = await api.getCountriesByBrand(brandCode);
      dispatch(finishFetch(countries));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const resetCountries = (): AppThunk => async dispatch => {
  dispatch(reset());
};

const selectCountries = (state: RootState): Country[] | [] => state.countries.data || [];

export const selectSortedCountries = createSelector(selectCountries, (countries: Country[]): Country[] => {
  return _.sortBy(countries, ['description']);
});
export const selectIsFetchingCountries = (state: RootState): boolean => state.countries.isFetching;

export default countriesSlice.reducer;
