import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { toastService } from '../../../../core/services/toastService';
import {
  BlockedEmployee,
  BlockedEmployeeCreationRequest,
  BlockedEmployeeUpdateRequest,
} from '../models/blockedEmployee';

interface BlockedEmployeeDetailSliceState {
  employee: BlockedEmployee | null;
  isFetching: boolean;
  error: string;
}

const initialState: BlockedEmployeeDetailSliceState = {
  employee: null,
  isFetching: false,
  error: '',
};

export interface UpdateLimitRulesPayload {
  ruleIndex: number | undefined;
  rule: BlockedEmployee;
}

export const blockedEmployeeDetailSlice = createSlice({
  name: 'blockedEmployeeDetail',
  initialState,
  reducers: {
    startFetch: (state: Draft<BlockedEmployeeDetailSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (
      state: Draft<BlockedEmployeeDetailSliceState>,
      payloadAction: PayloadAction<BlockedEmployee>
    ): BlockedEmployeeDetailSliceState => {
      return {
        employee: payloadAction.payload,
        isFetching: false,
        error: '',
      };
    },
    httpError: (state: Draft<BlockedEmployeeDetailSliceState>, payloadAction: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    reset: () => initialState,
  },
});

export const { startFetch, finishFetch, httpError, reset } = blockedEmployeeDetailSlice.actions;

export const resetBlockedEmployee = (): AppThunk => async dispatch => {
  dispatch(reset());
};

export const fetchBlockedEmployee =
  (id: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const blockedEmployee: BlockedEmployee = await api.getBlockedEmployee(id);
      dispatch(finishFetch(blockedEmployee));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const createBlockedEmployee =
  (request: BlockedEmployeeCreationRequest): AppThunk<Promise<BlockedEmployee | null>> =>
  async (dispatch): Promise<BlockedEmployee | null> => {
    try {
      const createdBlockedEmployee = await api.createBlockedEmployee(request);
      dispatch(finishFetch(createdBlockedEmployee));
      toastService.success();
      return createdBlockedEmployee;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };
export const updateBlockedEmployee =
  (id: string, request: BlockedEmployeeUpdateRequest): AppThunk<Promise<BlockedEmployee | null>> =>
  async (dispatch): Promise<BlockedEmployee | null> => {
    try {
      const updatedBlockedEmployee = await api.updateBlockedEmployee(id, request);
      dispatch(finishFetch(updatedBlockedEmployee));
      toastService.success();
      return updatedBlockedEmployee;
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
      return null;
    }
  };

export const selectBlockedEmployeeDetail = (state: RootState): BlockedEmployee | null =>
  state.blockedEmployeeDetail.employee;

export const selectIsFetchingBlockedEmployeeDetail = (state: RootState): boolean =>
  state.blockedEmployeeDetail.isFetching;

export default blockedEmployeeDetailSlice.reducer;
