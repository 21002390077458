import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const card = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  ${globalStyle.bodySmall}
  padding: 4px 0 20px 25px;
`;

const title = css`
  padding-top: 10px;
  ${globalStyle.bodyMedium};
  width: 130px;
`;

const outerContainer = css`
  display: flex;
  width: 100%;
`;

const readableField = css`
  ${globalStyle.bodySmall};
`;

const fieldValue = css`
  ${globalStyle.bodySmall};
  border: 1px solid #d9d9d9;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  margin-left: 10px;
  min-width: 85px;
`;

const percentage = css`
  & .ui.label {
    width: 100px;
    display: flex;
  }
`;

const groupButton = css`
  margin-left: auto;
  padding-right: 20px;
  margin-bottom: 10px;
  margin-top: 16px;
`;

const percentageInput = css`
  && {
    input {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      width: 45px;
      padding: 0 0 0 10px;
      margin-left: 12px;
      font-size: 15px;
      line-height: 2.4rem;
    }
  }
`;

const button = css`
  width: 125px;
  ${globalStyle.secondaryButton}
`;

const buttons = css`
  ${globalStyle.secondaryButton}
`;

const dropdownField = css`
  min-width: 150px;
  margin-right: 10px;
`;

const dropdownItemName = css`
  font-weight: 500;
  margin-bottom: 2px;
`;

const usersList = css`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 85%;
  margin-right: 25px;
  @media (max-width: 1400px) {
    max-width: 100%;
  }
`;

const usersListContent = css`
  min-width: 180px;
  min-height: 19px;
`;

export default {
  title,
  card,
  outerContainer,
  readableField,
  fieldValue,
  groupButton,
  percentageInput,
  button,
  buttons,
  dropdownField,
  dropdownItemName,
  percentage,
  usersList,
  usersListContent,
};
