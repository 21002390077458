/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, DropdownProps, Input, Modal } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import { useAppSelector } from '../../../../core/hooks';
import { DiscountRule, OrderedDiscountRule } from '../models/discountRuleSet';
import { Country } from '../../../../shared/models/country';
import { selectIsFetchingCountries, selectSortedCountries } from '../store/discountCountriesSlice';
import {
  selectIsFetchingProductCategories,
  selectSortedProductCategories,
} from '../store/discountProductCategoriesSlice';
import { FormField } from '../../../../shared/components/FormField';
import style from './discountRuleModalForm.style';

interface DiscountRuleFormModalProps {
  open: boolean;
  onClose: () => void;
  onRuleSave?: (discountRule: DiscountRule) => void;
  onRuleEdit?: (discountRule: OrderedDiscountRule) => void;
  discountRule?: OrderedDiscountRule;
}

export default function DiscountRuleModalForm({
  onClose,
  onRuleSave,
  onRuleEdit,
  open,
  discountRule,
}: DiscountRuleFormModalProps): JSX.Element {
  const { t } = useTranslation();
  const [discountPercentage, setDiscountPercentage] = useState(discountRule?.discountPercentage.toString() || '');
  const [productCategories, setProductCategories] = useState<string[] | null>(
    discountRule?.constraints.productCategories || null
  );
  const [countries, setCountries] = useState<Country[] | null>(discountRule?.constraints.countries || null);
  const countriesOptions = useAppSelector(selectSortedCountries);
  const isFetchingCountries = useAppSelector(selectIsFetchingCountries);
  const productCategoriesOptions = useAppSelector(selectSortedProductCategories);
  const isFetchingProductCategories = useAppSelector(selectIsFetchingProductCategories);
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    setDiscountPercentage(discountRule?.discountPercentage.toString() || '');
    setProductCategories(discountRule?.constraints.productCategories || null);
    setCountries(discountRule?.constraints.countries || null);
  }, [
    discountRule?.constraints.countries,
    discountRule?.constraints.productCategories,
    discountRule?.discountPercentage,
    open,
  ]);

  const onDiscountPercentageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowValidation(false);
    const { value } = event.target;
    if (value === '') {
      setDiscountPercentage('');
      return;
    }
    const regex = /^(([1-9]\d?)|0|100)$/;
    if (value !== '' && !regex.test(value)) {
      return;
    }
    setDiscountPercentage(value);
  };

  const handleClose = () => {
    setDiscountPercentage('');
    setCountries(null);
    setProductCategories(null);
    setShowValidation(false);
    onClose();
  };
  const handleConfirm = () => {
    if (discountPercentage === '0') {
      setShowValidation(true);
      return;
    }
    if (discountRule == null && onRuleSave != null) {
      onRuleSave({ discountPercentage: +discountPercentage, constraints: { productCategories, countries } });
    } else if (discountRule != null && onRuleEdit != null) {
      onRuleEdit({
        id: discountRule.id,
        discountPercentage: +discountPercentage,
        constraints: { productCategories, countries },
      });
    }
    handleClose();
  };

  const isPristineDiscountRule = (): boolean => {
    return (
      discountRule?.discountPercentage.toString() === discountPercentage &&
      isEqual(discountRule.constraints.countries, countries) &&
      isEqual(discountRule.constraints.productCategories, productCategories)
    );
  };

  return (
    <Modal onClose={handleClose} open={open} closeIcon css={style.modalContainer}>
      <Modal.Header css={style.header}>
        {discountRule != null ? t('discountRuleset.editDiscountRule') : t('discountRuleset.addDiscountRule')}
      </Modal.Header>
      <Modal.Content css={style.content} scrolling>
        <div css={style.section}>
          <div css={style.sectionLabel}>{t('discountRuleset.discount')}</div>
          <div css={style.sectionContent}>
            <FormField
              label={t('discountRuleset.discountPercentage')}
              mandatory
              editMode
              validationMessages={showValidation ? [t('discountRuleset.modalFormValidationMessage')] : []}
              editComponent={
                <Input
                  css={style.discountField}
                  placeholder={t('discountRuleset.enterDiscount')}
                  value={discountPercentage}
                  onChange={onDiscountPercentageChange}
                />
              }
            />
          </div>
        </div>
        <div css={style.section}>
          <div css={style.sectionLabel}>{t('discountRuleset.applicable')}</div>
          <div css={style.longerSectionContent}>
            <FormField
              css={style.formField}
              label={t('discountRuleset.countries')}
              editMode
              editComponent={
                <Dropdown
                  css={style.dropdown}
                  placeholder={t('discountRuleset.allCountries')}
                  value={countries == null ? [] : countries.map(c => c.code)}
                  selection
                  multiple
                  noResultsMessage={t('noResultsFound')}
                  search
                  clearable
                  loading={isFetchingCountries}
                  options={countriesOptions.map(c => ({
                    key: c.id,
                    text: c.description,
                    value: c.code,
                  }))}
                  onChange={(event, data: DropdownProps) => {
                    const selectedValues = data.value as string[];
                    const selectedCountries =
                      selectedValues.length > 0 ? countriesOptions.filter(c => selectedValues.includes(c.code)) : null;
                    setCountries(selectedCountries);
                  }}
                />
              }
            />
            <FormField
              css={style.formField}
              label={t('discountRuleset.productCategories')}
              editMode
              editComponent={
                <Dropdown
                  css={style.dropdown}
                  placeholder={t('discountRuleset.allProductCategories')}
                  value={productCategories ?? []}
                  selection
                  multiple
                  search
                  noResultsMessage={t('noResultsFound')}
                  clearable
                  loading={isFetchingProductCategories}
                  options={
                    productCategoriesOptions?.map(pc => ({
                      key: pc,
                      text: pc,
                      value: pc,
                    })) || []
                  }
                  onChange={(event, data: DropdownProps) => {
                    const selectedValues = data.value as string[];
                    const selectedProductCategories = selectedValues.length > 0 ? selectedValues : null;
                    selectedProductCategories?.sort();
                    setProductCategories(selectedProductCategories);
                  }}
                />
              }
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions css={style.actions}>
        <div css={style.legendSection}>{t('mandatoryFields')}</div>
        <Button
          css={style.confirmButton}
          icon='save'
          onClick={handleConfirm}
          disabled={discountPercentage === '' || isPristineDiscountRule()}
          content={t('confirm')}
          labelPosition='left'
        />
      </Modal.Actions>
    </Modal>
  );
}
