/** @jsxImportSource @emotion/react */
import React from 'react';
import style from './page.style';
import { MainMenu } from './MainMenu';
import { DiscountCardPoliciesModal } from './components/DiscountCardPoliciesModal';
import OnboardingScreens from './components/OnboardingScreens';

interface PageProps {
  children: JSX.Element;
}

export const DiscountCardPage = (props: PageProps): JSX.Element => {
  const { children } = props;
  return (
    <div css={style.page}>
      <div css={style.appContainer}>
        <DiscountCardPoliciesModal />
        <OnboardingScreens />
        <div css={style.container}>{children}</div>
        <MainMenu />
      </div>
    </div>
  );
};
