import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import globalStyle from '../../../../shared/global.style';

const title = css`
  text-align: center;
`;

const brandSelectionTitle = css`
  text-align: center;
`;

const brandSelectionContainer = css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  margin: 40px auto;
  @media (max-width: 540px) {
    margin: 30px 0 0;
  }
`;

const brandButton = css`
  ${globalStyle.primaryButton};
  width: calc(50% - 10px);
  && {
    padding: 12px;
    height: 50px;
    min-width: 0;
    margin: 0;
  }
`;

const backContainer = css`
  padding-bottom: 10px;
`;

const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const moneyThresholdCardContainer = css`
  margin-bottom: 7px;
`;

const tabsContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 2px 7px;
`;

const tab = css`
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 0;
  width: 50%;
  text-align: center;
  ${globalStyle.bodyNormal};
`;
const selectedTab = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--dcd-secondary-color-40);
`;

const tabButton = (selected: boolean): SerializedStyles => {
  return css`
    ${tab}
    ${selected ? selectedTab : ''};
  `;
};

const filtersContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  column-gap: 20px;
  ${globalStyle.stickyHeader(`var(--dcd-white-color)`, '20px')};
`;

const backIcon = css`
  && {
    font-size: 16px;
    cursor: pointer;
  }
`;

const loaderContainer = css`
  position: relative;
  flex: 1;
`;

const headerContainer = css`
  position: relative;
  margin-bottom: 14px;
  ${globalStyle.stickyHeader(`var(--dcd-white-color)`)};
`;

const badgesContainer = css`
  display: flex;
  align-items: center;
  height: 46px;
  padding-left: 2px;
  padding-right: 2px;
`;

const filterBadge = css`
  ${globalStyle.bodySmall};
  && {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dcd-secondary-color-40);
    border-radius: 20px;
    margin-right: 5px;
    padding: 4px 8px;
  }
`;
export default {
  title,
  headerContainer,
  brandSelectionTitle,
  brandSelectionContainer,
  brandButton,
  container,
  backContainer,
  backIcon,
  moneyThresholdCardContainer,
  tabsContainer,
  tabButton,
  filtersContainer,
  loaderContainer,
  badgesContainer,
  filterBadge,
};
