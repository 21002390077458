/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { selectFilteredDiscountRules, selectIsFetchingDiscountRules } from '../store/salesAssistantPurchaseLimitSlice';
import { DiscountRule } from '../../../admin/discounts/models/discountRuleSet';
import style from './storeDiscountRules.style';
import { SpecialCasesDiscountPercentage } from '../../specialCases/components/SpecialCasesDiscountPercentage';
import {
  fetchSpecialCases,
  selectSpecialCasesGoldCardsPercentage,
} from '../../../admin/discounts/store/specialCasesSlice';

export default function StoreDiscountRules(): JSX.Element {
  const { t } = useTranslation();
  const isFetching = useAppSelector(selectIsFetchingDiscountRules);
  const specialCasesGoldCardsDiscountPercentage = useAppSelector(selectSpecialCasesGoldCardsPercentage);
  const discountRules = useAppSelector(selectFilteredDiscountRules) || [];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSpecialCases());
  }, [dispatch]);

  return (
    <div css={style.cardsContainer}>
      <SpecialCasesDiscountPercentage
        percentage={specialCasesGoldCardsDiscountPercentage}
        label={`${t(
          'salesAssistant.purchaseLimits.forGoldCardSpecialCases'
        )} ${specialCasesGoldCardsDiscountPercentage}${t('salesAssistant.purchaseLimits.discountApplies')}`}
      />
      {isFetching ? (
        <div css={style.loaderContainer}>
          <Loader size='big' active />
        </div>
      ) : (
        [...discountRules]
          .reverse()
          .map(rule => (
            <DiscountRuleCard
              key={`${JSON.stringify(rule.constraints)}-${rule.discountPercentage}`}
              discountRule={rule}
            />
          ))
      )}
    </div>
  );
}

interface DiscountRuleCardProps {
  discountRule: DiscountRule;
}

function DiscountRuleCard({ discountRule }: DiscountRuleCardProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div css={style.cardWrapper}>
      <div css={style.constraintsSection}>
        <div css={style.constraint}>
          {discountRule.constraints.countries == null
            ? t(`purchaseLimits.allCountries`)
            : discountRule.constraints.countries.map(c => c.description).join(', ')}
        </div>
        <div css={style.constraint}>
          {discountRule.constraints.productCategories == null
            ? t(`purchaseLimits.allProductCategories`)
            : discountRule.constraints.productCategories.join(', ')}
        </div>
      </div>
      <div css={style.percentageSection}>
        <div css={style.percentage}>{discountRule.discountPercentage}%</div>
      </div>
    </div>
  );
}
