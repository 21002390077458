/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import { logoutAction } from '../../core/store';
import { acceptDiscountCardPolicies, selectDiscountCardPolicies } from '../../features/auth/store/principalSlice';
import { getEmployeePrivacyPolicyPdf } from '../../features/employee/seeMore/store/SeeMoreSlice';
import style from './policiesModal.style';

export const DiscountCardPoliciesModal = (): JSX.Element => {
  const policiesAccepted = useAppSelector(selectDiscountCardPolicies);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isBottom, setIsBottom] = useState(false);

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  const handlePoliciesAcceptance = () => {
    dispatch(acceptDiscountCardPolicies());
  };

  const downloadEmmployeePrivacyPolicyPdf = async () => {
    await dispatch(getEmployeePrivacyPolicyPdf());
  };

  const handleScroll = (event: { target: { scrollHeight: number; scrollTop: number; clientHeight: number } }) => {
    const bottom = event.target.scrollHeight - event.target.clientHeight <= event.target.scrollTop + 50;
    if (bottom) {
      setIsBottom(true);
    }
  };

  return (
    <Modal css={style.modalContainer} open={!policiesAccepted}>
      <Modal.Header css={style.modalHeader}>
        <div css={style.modalTitle}>
          <p>{t('termsAndConditions.termsAndConditions')}</p>
        </div>
      </Modal.Header>
      <Modal.Content scrolling css={style.modalContent} onScroll={handleScroll}>
        <Modal.Description css={style.modalDescription}>
          <div css={style.bodyContainer}>
            <div css={style.sectionContainer}>
              <div css={style.bodyTitle}>Privilege Card Mobile Application Terms of Use</div>
              <div css={style.pageParagraphs}>
                <i>Date: 30 March 2023</i>
              </div>
              <div css={style.pageParagraphs}>
                Welcome to the Privilege Card Mobile Application (the “<b>App</b>”). The App gives Kering group
                employees (“<b>you</b>”, “<b>your</b>”) access to the Kering Privilege Card which allows you to benefit
                from a special discount when purchasing products from the Kering Houses (the “<b>Privilege Card</b>”).
              </div>
              <ol css={style.list}>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>About the App – Legal Notice</li>
                  <div css={style.pageParagraphs}>
                    This App is owned and operated by Kering, a company incorporated under the laws of France with a
                    share capital of 496 283 112 €, registered with the Paris Trade and Corporate Register under unique
                    identification number 552 075 020, with registered office at 40 rue de Sèvres 75007 Paris (“
                    <b>Kering</b>” or “<b>we</b>”, “<b>us</b>”, “<b>our</b>”).
                  </div>
                  <div css={style.pageParagraphs}>This App’s publishing director is Mr. François-Henri Pinault.</div>
                  <div css={style.pageParagraphs}>This App is hosted by AWS (Amazon Web Services):</div>
                  <ul>
                    <li style={{ marginBottom: '0' }}>Amazon Web Services, Inc.</li>
                    <li style={{ marginBottom: '0' }}>P.O. Box 81226</li>
                    <li style={{ marginBottom: '0' }}>Seattle, WA 98108-1226</li>
                    <li>
                      <a href='http://aws.amazon.com' target='_blank' rel='noreferrer'>
                        http://aws.amazon.com
                      </a>
                    </li>
                  </ul>
                  <div css={style.pageParagraphs}>You can contact us in one of the following ways:</div>
                  <ul>
                    <li style={{ marginBottom: '0' }}>
                      By telephone: local Help Desk phone number available on Kering Connect
                    </li>
                    <li style={{ marginBottom: '0' }}>
                      By service request:{' '}
                      <a href='https://keringconnect.service-now.com/sp' target='_blank' rel='noreferrer'>
                        https://keringconnect.service-now.com/sp
                      </a>
                    </li>
                  </ul>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Acceptance of these Terms of Use</li>
                  <div css={style.disclaimer}>
                    PLEASE READ THE FOLLOWING TERMS OF USE (“ToU”) CAREFULLY BEFORE USING THE APP AND/OR THE PRIVILEGE
                    CARD.
                  </div>
                  <div css={style.pageParagraphs}>
                    These ToU apply to your access to, and use of, the App and your use of the Privilege Card. The term
                    “<b>you</b>” and “<b>your</b>” refer to any individual currently employed by a Kering group company
                    who uses the App.
                  </div>
                  <div css={style.pageParagraphs}>
                    When accessing the App and using the Privilege Card for the first time, you will be asked to agree
                    to these ToU. If you do not agree to be bound by these ToU, you must cease using the App and the
                    Privilege Card.
                  </div>
                  <div css={style.pageParagraphs}>
                    Once you have accepted these ToU, you will be granted access to the Privilege Card.
                  </div>
                  <div css={style.pageParagraphs}>
                    We reserve the right to amend or update all or part of these ToU from time to time at our
                    discretion. We will always post the most current version of these ToU on the App and display the
                    “Last Updated” date to reflect the date of the changes.
                  </div>
                  <div css={style.pageParagraphs}>
                    Please note that, when visiting the App for the first time after changes to the ToU have been
                    uploaded, you will be asked to agree to the updated ToU.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Access to the App</li>
                  <div css={style.pageParagraphs}>
                    In order to access the App and use the Privilege Card you must click on the App link that was
                    provided to you. The App is available on company phones by default.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Privacy</li>
                  <div css={style.pageParagraphs}>
                    Your personal information will be processed by your employer, acting as “data controller”, “data
                    handler” or any similar term indicating that it determines why and how your personal information is
                    processed. Your personal information will be processed for the following purposes: (i) to provide
                    you with the discounts you are entitled to; (ii) to allow you to access and monitor your purchasing
                    limitations; (iii) to assist you in case you encounter difficulties; (iv) to check whether purchase
                    quotas have been reached; (v) to detect, remedy and, as the case may be, sanction misbehaviors; (vi)
                    to manage and administer your access to the Privilege Card and ensure its proper functioning; (vii)
                    to comply with legal and/or regulatory obligations; (viii) to respond to public and governmental
                    authorities&apos; requests and manage potential or actual litigation; and (ix) to produce analytics
                    information on the discounts we offer.
                  </div>
                  <div css={style.pageParagraphs}>
                    Subject to applicable laws and regulations, you may benefit from the following rights: access to,
                    rectification or erasure of your personal information, limitation of the processing relating to you,
                    the right to object to processing and the right to the portability of your personal information in
                    accordance with the applicable legal framework. You may also set guidelines regarding the use of
                    your personal information following your death. Additionally, you may submit a complaint to the
                    competent supervisory authority.
                  </div>
                  <div css={style.pageParagraphs}>
                    For additional information (including contact details and means of exercising your rights), we
                    invite you to read the Privilege Card Privacy Policy accessible in the “See more” page of the
                    application.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Eligibility</li>
                  <div css={style.pageParagraphs}>
                    All current permanent or temporary employees of a Kering Group company and their immediate families
                    are eligible to use the Privilege Card. Immediate family members are partner/spouse, children,
                    parents, and siblings. Family members must be accompanied by a Kering Group employee in order to
                    benefit from the discount.
                  </div>
                  <div css={style.pageParagraphs}>
                    Interns, and external consultants or providers are excluded from the discount program.
                  </div>
                  <div css={style.pageParagraphs}>Each Kering House defines:</div>
                  <div css={style.pageParagraphs}>
                    <ul style={{ marginBottom: '0' }}>
                      <li style={{ marginBottom: '0' }}>
                        The eligibility of the employee category (permanent or temporary);
                      </li>
                      <li style={{ marginBottom: '0' }}>
                        The date from which the employee is eligible to the Privilege Card.
                      </li>
                    </ul>
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Discount program and process</li>
                  <div css={style.pageParagraphs}>
                    The discount is applied to products sold in all Kering Houses directly-operated stores (“DOS”)
                    worldwide.
                  </div>
                  <div css={style.pageParagraphs}>
                    The discount is generally not applicable to on-line purchases or purchases made in department
                    stores, franchised stores, duty free stores or specialty retail stores. Iconic products, products
                    from the latest collections and seasonal products for the first month of their launch in the stores
                    are also excluded from the discount program. Please refer to the list of the products excluded from
                    the discount established by each Kering House, which can be found on Kering Connect.
                  </div>
                  <div css={style.pageParagraphs}>
                    In order to receive the applicable discount, please simply present your smartphone or tablet showing
                    your Privilege Card (containing a QR Code) at the time of purchase in any of Kering Houses DOS.
                    Sales staff in each store will also ask you to present additional identification such as your ID
                    card or a passport as well as a credit card in your name, so be prepared to provide this
                    information.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Quotas and Purchase Limitations</li>
                  <div css={style.pageParagraphs}>
                    Please go to this{' '}
                    <a
                      href='https://keringconnect.service-now.com/sp/?id=form&table=kb_knowledge&filter=&sys_id=463432ea1b2d69d0d61610a1b24bcba2&v='
                      target='_blank'
                      rel='noreferrer'>
                      Kering Connect - Privilege Card
                    </a>{' '}
                    page to see the applicable Kering Houses global purchase quotas and limitations (amounts, number of
                    products, product category, specific SKU non eligible to discount).
                  </div>
                  <div css={style.pageParagraphs}>
                    Purchase quotas and limitations are defined by each Kering House. They are set on an annual basis
                    and are reviewed on January 1st.
                  </div>
                  <div css={style.pageParagraphs}>
                    Please note that the Kering House store manager has the authority to deny a discount for a specific
                    product if the quantity in stock is low or for other reasons in the best interest of the store’s
                    business.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Discount Amount</li>
                  <div>
                    Your discount percentage is determined according to the Kering House you work for. Generally, you
                    are entitled to a 50% discount on products purchased within your own House and a 30% discount on
                    other Kering Houses. Some exceptions apply. In certain countries the discount you are granted on
                    products purchased may vary:
                  </div>
                  <ul>
                    <li style={{ marginBottom: '0' }}>in France, your discount is limited to 30%,</li>
                    <li style={{ marginBottom: '0' }}>in Japan, your discount is limited to 40%,</li>
                    <li style={{ marginBottom: '0' }}>
                      in Austria and the Netherlands, your discount is limited to 20%.
                    </li>
                  </ul>
                  <div css={style.pageParagraphs}>
                    The above list is not exhaustive and may evolve according to the legislation of the respective
                    country of purchase. You are able to consult at any time in the App the discount percentage
                    applicable to the product.
                  </div>
                  <div css={style.pageParagraphs}>
                    The discount granted to employees of Kering corporate functions for products purchased, regardless
                    of the House where the purchase is made, is 30%.
                  </div>
                  <div css={style.pageParagraphs}>
                    The employee discount is applied to the retail sales prices and is also valid during the sale
                    periods in the DOS.
                  </div>
                  <div css={style.pageParagraphs}>
                    Please go to this{' '}
                    <a
                      href='https://keringconnect.service-now.com/sp/?id=form&table=kb_knowledge&filter=&sys_id=463432ea1b2d69d0d61610a1b24bcba2&v='
                      target='_blank'
                      rel='noreferrer'>
                      Kering Connect - Privilege Card
                    </a>{' '}
                    page for more details about the discounts granted by each Kering House.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Use of the Privilege Card</li>
                  <div css={style.pageParagraphs}>
                    You must pay for purchases with your own debit/credit card. Family members cannot under any
                    circumstances use their own form of payment.
                  </div>
                  <div css={style.pageParagraphs}>
                    When using your Privilege Card, you must avoid busy periods and be aware that priority will always
                    be given to full paying customers. Please note that rude or disrespectful behavior towards fellow
                    colleagues will not be tolerated and may lead to strong disciplinary action.
                  </div>
                  <div css={style.pageParagraphs}>
                    Upon entering a store you must approach sales staff discreetly and inform them you are an employee.
                  </div>
                  <div css={style.pageParagraphs}>
                    You cannot under any circumstances, delegate purchase to another person, not even an immediate
                    family member.
                  </div>
                  <div css={style.pageParagraphs}>
                    You are not allowed to reserve goods for a later collection at the stores.
                  </div>
                  <div css={style.pageParagraphs}>
                    Any purchases made with your Privilege Card are strictly for personal use and not intended for
                    resale, bartering or exchange for services or favors.
                  </div>
                  <div css={style.pageParagraphs}>
                    These ToU state general principles and rules on the use of the Privilege Card that are applicable
                    worldwide, subject to local policies or provisions. If additional or different local policies
                    regulating employee discounts exist in other countries/regions, employees who make purchases in
                    those countries must follow those local rules.
                  </div>
                  <div css={style.pageParagraphs}>
                    Where the obligations of local laws or regulations impose greater or more stringent requirements
                    than those included in the ToU, the more stringent obligations shall prevail.
                  </div>
                  <div css={style.pageParagraphs}>
                    Each House reserves the right to issue or implement stricter conditions to the discount program and
                    all employees must comply with those conditions. Stores managers will inform you of these rules.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Use of the App</li>
                  <div css={style.pageParagraphs}>You agree that in using the App, you will not:</div>
                  <ol type='a'>
                    <li>provide false or otherwise misleading information or impersonate another person;</li>
                    <li>
                      use anyone else’s account, as the Privilege Card’s account is personal and non-transferable;
                    </li>
                    <li>
                      use the App in any way that interrupts, causes or may cause damage to the App, impairs the App’s
                      availability or accessibility or renders the App less efficient;
                    </li>
                    <li>
                      circumvent or attempt to circumvent the App’s security measures and tamper with the technology
                      that forms part of the App;
                    </li>
                    <li>
                      obtain or attempt to obtain any information, materials or documents not purposely made available
                      through the App through any means (such as personal data, financial or other confidential or
                      sensitive information);
                    </li>
                    <li>breach any applicable law;</li>
                    <li>
                      use the App for any unlawful purpose or in a way which infringes the rights of any third parties;
                    </li>
                    <li>upload, post or otherwise transmit via the App any content that:</li>
                    <ol type='i'>
                      <li>
                        is misleading, harmful, threatening, abusive, harassing, defamatory, offensive, violent,
                        obscene, pornographic, vulgar, libelous, racially, ethnically, religiously or otherwise
                        inappropriate;
                      </li>
                      <li>constitutes unauthorized disclosure of personal data or confidential information;</li>
                      <li>infringes the intellectual property rights of any party;</li>
                      <li>contains viruses or other form of malware.</li>
                    </ol>
                  </ol>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Intellectual Property Rights</li>
                  <div css={style.pageParagraphs}>
                    Our App and all of the information and materials included on it including the design, layout, look,
                    appearance, graphics, organization of the contents of the App, photographs, images, illustrations,
                    text, fonts, video, music, sound, audio clips, logos, trademarks - whether they are registered or
                    not, figurative or not - all other marks, service marks, brand names, trade or business names,
                    domain names and URLs, software (“<b>Content</b>”) are either owned or licensed by us, and are
                    protected by applicable copyright, trademark, patent or other intellectual property laws around the
                    world. We reserve all such rights.
                  </div>
                  <div css={style.pageParagraphs}>
                    We, or our affiliates, hereby grant you a worldwide, revocable, non-exclusive, non-transferable,
                    free of charge, right to access and use the App, its Content and the Privilege Card, for your
                    personal and non-commercial use, all in accordance with these ToU.
                  </div>
                  <div css={style.pageParagraphs}>
                    You may not copy or reproduce publish, disclose, distribute, provide to the public, communicate,
                    modify, add to, the App or its Content or create derivative works of all or part of the App or its
                    Content for any purpose.
                  </div>
                  <div css={style.pageParagraphs}>
                    The only intellectual property rights granted to you are those expressly provided in these ToU. Any
                    use which is not expressly permitted by these ToU is prohibited. Unauthorized use of the App or the
                    Content contained on it may violate applicable intellectual property laws or other laws.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Links</li>
                  <div css={style.subTitle}>Links to Third Party Websites</div>
                  <div css={style.pageParagraphs}>
                    The App may include links to the Kering Connect website (“Linked Site”). Your use of the Linked Site
                    is subject to their terms and conditions. Any question or comment related to these Linked Sites must
                    be addressed to the relevant operators.
                  </div>
                  <div css={style.subTitle}>Links to our App</div>
                  <div css={style.pageParagraphs}>
                    You are not permitted to frame the App or its Content on any other website, or to link to our App,
                    any page of it and/or to the Content from a third-party website, without our prior written consent.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Exclusion of Warranties</li>
                  <div css={style.pageParagraphs}>
                    This App, its Content and the Privilege Card are provided free of charge on an “as-is” and “as
                    available” basis. Although we make all reasonable efforts to ensure that the App is accessible at
                    all times (except during maintenance, for technical reasons beyond our control or in the case of a
                    force majeure event) and secure and that the information made available through the App is accurate
                    and up to date, we do not guarantee that the App will (i) always be available, (ii) operate without
                    interruption and will be error-free and(iii) will be safe from malicious programs (such as viruses,
                    bugs, malware or similar).
                  </div>
                  <div css={style.pageParagraphs}>
                    We exclude all warranties or guarantees in connection with this App, its Content or the Privilege
                    Card, to the extent permitted by law.
                  </div>
                  <div css={style.pageParagraphs}>
                    If you qualify as a “consumer”, however, some of the exclusions above may not be applicable to you
                    by operation of applicable law.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Limitation of Liability</li>
                  <div css={style.pageParagraphs}>
                    To the fullest extent permitted by applicable law, Kering, its affiliates and its licensors shall
                    not be liable for any indirect damages or for any damages or losses arising out of, or in connection
                    with, the use of, or inability to use, the App and its Content.
                  </div>
                  <div css={style.pageParagraphs}>
                    Nothing in these ToU limits or excludes our liability or our licensors’ to you or any third party
                    for any liability which cannot be limited or excluded by law.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Suspension or Termination</li>
                  <div css={style.pageParagraphs}>
                    We may, without prior notice or any liability to you, deny, restrict, suspend or terminate, in whole
                    or in part, your access to, and use of, the App. its Content and the Privilege Card if:
                  </div>
                  <ul>
                    <li>
                      your use of the App is in breach of any of your obligations under these ToU, or any other
                      provisions of these ToU;
                    </li>
                    <li>
                      you have breached the Employee Discount Program policy (including by allowing the usage of your
                      Privilege Card by non-authorized individuals or making purchases over the purchase limitations
                      where they apply).
                    </li>
                  </ul>
                  <div css={style.pageParagraphs}>
                    We may also terminate, suspend or discontinue providing the App and/or the Content, without prior
                    notice nor any liability to you or any third party.
                  </div>
                  <div css={style.pageParagraphs}>
                    The provisions entitled “Exclusion of Warranties” and “Limitation of Liability” will survive
                    termination of these ToU.
                  </div>
                </div>
                <div css={style.listElement}>
                  <li css={style.listSubtitle}>Governing Law and Jurisdiction</li>
                  <div css={style.pageParagraphs}>
                    These ToU and any matter relating to your access to, or use of, the App, its Content or the
                    Privilege Card shall be governed by and will be interpreted in accordance with the laws of your
                    place of residence.
                  </div>
                  <div css={style.pageParagraphs}>
                    Any dispute arising out of, or relating to, these ToU including the validity, interpretation, breach
                    or termination thereof, that cannot be resolved amicably, shall be submitted to the jurisdiction of
                    your place of residence.
                  </div>
                </div>
              </ol>
            </div>
            <div css={style.sectionContainer}>
              <div css={style.bodyTitle}>
                Privilege Card Privacy Policy First layer (Full version in the “See more” page of the App).
              </div>
              <div css={style.pageParagraphs}>
                In the context of this Privilege Card, your personal information is processed by your employer, acting
                as “data controller”, “data handler” or any similar term indicating that it determines why and how your
                personal information is processed.
              </div>
              <div css={style.pageParagraphs}>
                Your personal information is going to be processed for the following purposes in relation to the
                Privilege Card: (i) to provide you with the discounts you are entitled to; (ii) to allow you to access
                and monitor your discount limits; (iii) to assist you in case you encounter difficulties; (iv) to check
                whether discount limits and restrictions are reached; (v) to detect, remedy and, as the case may be,
                sanction misbehaviours; (vi) to manage and administer your access to the Privilege Card and ensure its
                proper functioning; (vii) to comply with legal and/or regulatory obligations; (viii) to respond to
                public and governmental authorities&#39; requests and manage potential or actual litigation; and (ix) to
                produce analytics information on the discounts we offer.
              </div>
              <div css={style.pageParagraphs}>
                Subject to applicable laws and regulations, you may benefit from the following rights: access to,
                rectification or erasure of your personal information, limitation of processing relating to you, the
                right to object to processing and the right to portability of your personal information in accordance
                with the applicable legal framework. You may also set guidelines regarding the use of your personal
                information following your death. Additionally, you may submit a complaint to the competent supervisory
                authority.
              </div>
              <div css={style.pageParagraphs}>
                For additional information (including contact details and means of exercising your rights), we invite
                you to download the Privilege Card Privacy Policy{' '}
                <Button css={style.downloadPdfText} content='here.' onClick={downloadEmmployeePrivacyPolicyPdf} />
              </div>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions css={style.modalActions}>
        <Button css={style.declinePoliciesBtn} onClick={handleLogout}>
          Decline
        </Button>
        <Button css={style.acceptPoliciesBtn} onClick={handlePoliciesAcceptance} disabled={!isBottom}>
          Accept
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
