import { css } from '@emotion/react';
import globalStyle from '../global.style';

const header = css`
  margin-bottom: 14px;
  ${globalStyle.bodyMedium};
`;
export default {
  header,
};
