/** @jsxImportSource @emotion/react */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { JSX } from '@emotion/react/jsx-runtime';
import { LimitRule } from '../models/limitRule';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { deleteLimitRule, selectLimitTypeSelected } from '../stores/limitRulesSlice';
import { DeleteDiscountRuleSetModal } from '../../discounts/components/DeleteDiscountRuleSetModal';
import { toLocaleAmount } from '../../../../shared/utils/utils';
import { FormField } from '../../../../shared/components/FormField';
import style from './limitRuleCard.style';
import { formatDate } from '../../../../core/utils';
import { CreationData } from '../../../../shared/components/CreationData';

interface LimitRuleCardProps {
  limitRule: LimitRule;
}

export default function LimitRuleCard({ limitRule }: LimitRuleCardProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const limitType = useAppSelector(selectLimitTypeSelected);
  const navigateToLimitRuleDetailPage = () => {
    navigate(`/privilege-card/admin/rules/${limitRule.id}`);
  };

  const getNonEligibilityConstraintLabel = (): string => {
    switch (limitRule.constraints.nonEligibleConstraint) {
      case 'SKUs':
        return t('limits.limitRules.skus');
      case 'Style lines':
        return t('limits.limitRules.styleLines');
      case 'Style colors':
        return t('limits.limitRules.styleColors');
      default:
        return t('limits.limitRules.skus');
    }
  };

  const getNonEligibilityConstraintValue = (): string => {
    if (limitRule.constraints.skus != null) {
      return limitRule.constraints.skus.join(', ');
    }
    switch (limitRule.constraints.nonEligibleConstraint) {
      case 'Style colors':
        return t('limits.limitRules.allStyleColors');
      case 'Style lines':
        return t('limits.limitRules.allStyleLines');
      case 'SKUs':
        return t('limits.limitRules.allSkus');
      default:
        return t('limits.limitRules.allSkus');
    }
  };
  return (
    <div css={style.ruleCard}>
      <div css={style.ruleCardWrapper}>
        <div css={style.ruleCardContent}>
          <FormField
            css={style.ruleField}
            label={t('limits.limitRules.brand')}
            align='center'
            viewComponent={limitRule.constraints.brand.description}
          />
          <FormField
            css={style.ruleField}
            label={t('limits.limitRules.employeeType')}
            align='center'
            viewComponent={limitRule.constraints.employeeTypes
              .map(type => t(`limits.limitRules.employeeTypes.${type}`))
              .join(', ')}
          />
          <FormField
            css={style.ruleField}
            label={t('limits.limitRules.countries')}
            align='center'
            viewComponent={
              limitRule.constraints.countries == null
                ? t('limits.limitRules.allCountries')
                : limitRule.constraints.countries.map(c => c.description).join(', ')
            }
          />
          {limitRule.constraints.brand.code === 'GUCCI' && (
            <FormField
            css={style.ruleField}
            label={t('limits.limitRules.storeTypology')}
            align='center'
            viewComponent={
              limitRule.constraints.storeTypology == null || limitRule.constraints.storeTypology === ''
                ? t('limits.limitRules.allTypologies')
                : limitRule.constraints.storeTypology
            }
            />
          )}
          <FormField
            css={style.ruleField}
            label={t('limits.limitRules.productCategories')}
            align='center'
            viewComponent={
              limitRule.constraints.productCategories == null
                ? t('limits.limitRules.allProductCategories')
                : limitRule.constraints.productCategories.join(', ')
            }
          />
          {limitType === 'notEligible' && (
            <FormField
              css={style.ruleField}
              label={getNonEligibilityConstraintLabel()}
              align='center'
              viewComponent={getNonEligibilityConstraintValue()}
            />
          )}
          {limitType === 'maxAmount' && limitRule.limits.amount != null && (
            <div css={style.limitColumn}>
              <FormField
                css={style.ruleField}
                label={t('limits.limitRules.maxAmount')}
                align='center'
                viewComponent={`${toLocaleAmount(limitRule.limits.amount.value)} ${limitRule.limits.amount.currency}`}
              />
            </div>
          )}
          {limitType === 'maxItems' &&
            limitRule.limits.totalItemsCount != null &&
            limitRule.limits.itemsCountPerSku == null && (
              <div css={style.limitColumn}>
                <FormField
                  css={style.ruleField}
                  label={t('limits.limitRules.maxQuantity')}
                  align='center'
                  viewComponent={limitRule.limits.totalItemsCount.toString()}
                />
              </div>
            )}
          {limitType === 'maxItemsBySku' &&
            limitRule.limits.totalItemsCount == null &&
            limitRule.limits.itemsCountPerSku != null && (
              <div css={style.limitColumn}>
                <FormField
                  css={style.ruleField}
                  label={t('limits.limitRules.maxQuantityPerSku')}
                  align='center'
                  viewComponent={limitRule.limits.itemsCountPerSku.toString()}
                />
              </div>
            )}
        </div>
        <div css={style.buttonContainer}>
          <Button css={style.limitRuleSetsButtons} onClick={navigateToLimitRuleDetailPage} icon='edit' />
          <Button css={style.limitRuleSetsButtons} onClick={() => setOpen(true)} icon='trash' />
          <DeleteDiscountRuleSetModal
            onDelete={() => {
              setOpen(false);
              dispatch(deleteLimitRule(limitRule.id));
            }}
            onCancel={() => setOpen(false)}
            open={open}
          />
        </div>
      </div>
      <CreationData creationInfo={limitRule.creationInfo} updateInfo={limitRule.updateInfo} />
      <div css={style.textContainer}>
        {limitType === 'notEligible' &&
          (limitRule.constraints.validityStartDate != null && limitRule.constraints.validityEndDate != null ? (
            <p css={style.text}>
              {t('limits.limitRules.validFrom')} {formatDate(limitRule.constraints.validityStartDate.toString())}{' '}
              {t('limits.limitRules.validTo')} {formatDate(limitRule.constraints.validityEndDate.toString())}
            </p>
          ) : (
            limitRule.constraints.validityStartDate != null && (
              <p css={style.text}>
                {t('limits.limitRules.validFrom')} {formatDate(limitRule.constraints.validityStartDate.toString())}
              </p>
            )
          ))}
      </div>
    </div>
  );
}
