import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { t } from 'i18next';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { Brand } from '../../../../shared/models/brand';
import { QuotaLimitByCategory, QuotaLimitPerSku } from '../models/QuotaLimit';
import { MyDiscountRule } from '../models/MyDiscountRule';
import { checkValueInclude } from '../../../../shared/utils/utils';
import { selectCountryFilter, selectSearchBoxFilter } from './employeePurchaseLimitFiltersSlice';
import { Country } from '../../../../shared/models/country';
import { selectPrincipal } from '../../../auth/store/principalSlice';

export enum PurchaseLimitStep {
  BrandSelection,
  PurchaseLimitsConsulting,
}
interface EmployeePurchaseLimitSliceState {
  quotaByCategories: QuotaLimitByCategory[] | null;
  quotaPerSku: QuotaLimitPerSku[] | null;
  myDiscountRules: MyDiscountRule[] | null;
  currentStep: PurchaseLimitStep;
  selectedBrand: Brand | null;
  isFetching: boolean;
  error: string;
}

const initialState: EmployeePurchaseLimitSliceState = {
  quotaByCategories: null,
  quotaPerSku: null,
  myDiscountRules: null,
  isFetching: false,
  currentStep: PurchaseLimitStep.BrandSelection,
  selectedBrand: null,
  error: '',
};

export const employeePurchaseLimitSlice = createSlice({
  name: 'employeePurchaseLimit',
  initialState,
  reducers: {
    setStep: (
      state: Draft<EmployeePurchaseLimitSliceState>,
      payloadAction: PayloadAction<PurchaseLimitStep>
    ): EmployeePurchaseLimitSliceState => ({ ...state, currentStep: payloadAction.payload }),
    setBrand: (
      state: Draft<EmployeePurchaseLimitSliceState>,
      payloadAction: PayloadAction<Brand>
    ): EmployeePurchaseLimitSliceState => ({ ...state, selectedBrand: payloadAction.payload }),
    startFetch: (state: Draft<EmployeePurchaseLimitSliceState>): EmployeePurchaseLimitSliceState => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetch: (state: Draft<EmployeePurchaseLimitSliceState>): EmployeePurchaseLimitSliceState => {
      return {
        ...state,
        isFetching: false,
      };
    },
    finishQuotaLimitsByCategoriesFetch: (
      state: Draft<EmployeePurchaseLimitSliceState>,
      payloadAction: PayloadAction<QuotaLimitByCategory[]>
    ): EmployeePurchaseLimitSliceState => {
      return {
        ...state,
        quotaByCategories: payloadAction.payload,
        error: '',
      };
    },
    finishQuotaLimitsBySkusFetch: (
      state: Draft<EmployeePurchaseLimitSliceState>,
      payloadAction: PayloadAction<QuotaLimitPerSku[]>
    ): EmployeePurchaseLimitSliceState => {
      return {
        ...state,
        quotaPerSku: payloadAction.payload,
        error: '',
      };
    },
    finishMyDiscountRulesFetch: (
      state: Draft<EmployeePurchaseLimitSliceState>,
      payloadAction: PayloadAction<MyDiscountRule[]>
    ): EmployeePurchaseLimitSliceState => {
      return {
        ...state,
        myDiscountRules: payloadAction.payload,
        error: '',
      };
    },
    httpError: (
      state: Draft<EmployeePurchaseLimitSliceState>,
      payloadAction: PayloadAction<string>
    ): EmployeePurchaseLimitSliceState => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
  },
});

export const {
  startFetch,
  finishQuotaLimitsByCategoriesFetch,
  finishQuotaLimitsBySkusFetch,
  finishMyDiscountRulesFetch,
  finishFetch,
  httpError,
  setStep,
  setBrand,
} = employeePurchaseLimitSlice.actions;
export default employeePurchaseLimitSlice.reducer;

export const fetchQuotaLimitsAndDiscountRuleSets =
  (brandCode: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      dispatch(fetchQuotaByCategories(brandCode));
      dispatch(fetchQuotaPerSku(brandCode));
      dispatch(fetchMyDiscountRules(brandCode));
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    } finally {
      dispatch(finishFetch());
    }
  };

const fetchQuotaByCategories =
  (brandCode: string): AppThunk =>
  async dispatch => {
    const quotaLimitsByCategory = await api.getMyQuotaLimitsByCategories(brandCode);
    dispatch(finishQuotaLimitsByCategoriesFetch(quotaLimitsByCategory));
  };
const fetchQuotaPerSku =
  (brandCode: string): AppThunk =>
  async dispatch => {
    const quotaLimitsBySku = await api.getMyQuotaLimitsPerSku(brandCode);
    dispatch(finishQuotaLimitsBySkusFetch(quotaLimitsBySku));
  };
const fetchMyDiscountRules =
  (brandCode: string): AppThunk =>
  async dispatch => {
    const myDiscountRules = await api.getMyDiscountRules(brandCode);
    dispatch(finishMyDiscountRulesFetch(myDiscountRules));
  };

export const selectIsFetching = (state: RootState): boolean => state.employeePurchaseLimit.isFetching;
export const selectCurrentStep = (state: RootState): PurchaseLimitStep => state.employeePurchaseLimit.currentStep;
export const selectSelectedBrand = (state: RootState): Brand | null => state.employeePurchaseLimit.selectedBrand;

const selectQuotaLimitsByCategories = (state: RootState): QuotaLimitByCategory[] | null =>
  _.sortBy(state.employeePurchaseLimit.quotaByCategories, obj =>
    obj.limitRule.constraints.productCategories == null ? 0 : 1
  );

export const selectFilteredQuotaLimitsByCategories = createSelector(
  selectQuotaLimitsByCategories,
  selectSearchBoxFilter,
  (quotas, filter) => {
    if (quotas == null) {
      return null;
    }

    return quotas.filter(
      quota =>
        checkValueInclude(
          quota.limitRule.constraints.productCategories?.join(' ') || t('purchaseLimits.ALL_CATEGORIES'),
          filter
        ) || checkValueInclude(quota.limitRule.constraints?.categoryLabel || '', filter)
    );
  }
);
const selectQuotaLimitsPerSku = (state: RootState): QuotaLimitPerSku[] | null =>
  _.sortBy(state.employeePurchaseLimit.quotaPerSku, obj => (obj.productCategories == null ? 0 : 1));

export const selectFilteredQuotaLimitsPerSku = createSelector(
  selectQuotaLimitsPerSku,
  selectSearchBoxFilter,
  (quotas, filter) => {
    if (quotas == null) {
      return null;
    }

    return quotas.filter(
      quota =>
        checkValueInclude(quota.productCategories?.join(' ') || t('purchaseLimits.ALL_CATEGORIES'), filter) ||
        checkValueInclude(quota.categoryLabel || '', filter)
    );
  }
);

export const selectMyDiscountRules = (state: RootState): MyDiscountRule[] | null =>
  state.employeePurchaseLimit.myDiscountRules;

const selectMyDiscountRulesSorted = createSelector(selectMyDiscountRules, rules => {
  return rules == null ? [] : _.orderBy(rules, ['discountPercentage'], ['desc']);
});

const selectMyDiscountRulesSortedFilteredByCountry = createSelector(
  selectMyDiscountRulesSorted,
  selectCountryFilter,
  (rules: MyDiscountRule[], country) => {
    return rules.filter(r => {
      if (country.code === '') {
        return r.constraints.countries == null;
      }
      const ruleCountries = r.constraints.countries;
      if (ruleCountries == null) {
        return r;
      }
      return ruleCountries.map(c => c.code).includes(country.code);
    });
  }
);

export const selectFilteredMyDiscountRules = createSelector(
  selectMyDiscountRulesSortedFilteredByCountry,
  selectSearchBoxFilter,
  (rules, filter) =>
    rules == null
      ? null
      : rules.filter(
          rule =>
            checkValueInclude(rule.brand.description, filter) ||
            checkValueInclude(rule.constraints.countries?.map(c => c.description).join(' ') || '', filter) ||
            checkValueInclude(rule.constraints.productCategories?.join(' ') || '', filter)
        )
);

const countryAlreadyExists = (countriesList: Country[], countryCode: string): boolean => {
  return countriesList.find(country => country.code === countryCode) !== undefined;
};

export const selectSortedAvailableCountries = createSelector(
  selectMyDiscountRules,
  selectPrincipal,
  (rules, principal): Country[] => {
    if (rules == null) {
      return [];
    }
    const countriesArray: Country[] = [];
    rules.forEach(myRule => {
      if (myRule.constraints.countries != null) {
        countriesArray.push(...myRule.constraints.countries);
      }
    });

    const countries: Country[] = _.uniqBy(countriesArray, c => c.code);
    if (principal !== null && !countryAlreadyExists(countries, principal.countryCode)) {
      countries.push({
        id: principal.countryCode,
        code: principal.countryCode,
        description: principal.countryDescription,
      });
    }

    return _.sortBy(countries, 'description');
  }
);
