/** @jsxImportSource @emotion/react */

import React, { useCallback, useState } from 'react';
import { User } from '../models/user';
import { ReadonlyUserCard } from './ReadonlyUserCard';
import { CardToSave, UserCardWritable } from './UserCardWritable';
import { deleteUser, removeEmptyUser, saveUser, updateUser } from '../stores/usersManagementSlice';
import { useAppDispatch } from '../../../../core/hooks';

interface UserCardProps {
  user: User;
}

export const UserCard = ({ user }: UserCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const handleOnUpdate = useCallback(
    (cardToSave: CardToSave) => {
      dispatch(
        updateUser(cardToSave.id, {
          roles: cardToSave.roles,
          brands: cardToSave.brands.map(b => b.code),
        })
      );
      setEditMode(false);
    },
    [dispatch]
  );

  const handleOnDelete = useCallback(
    (id: string) => {
      dispatch(deleteUser(id));
      setEditMode(false);
    },
    [dispatch]
  );

  const handleOnSave = useCallback(
    (cardToSave: CardToSave) => {
      dispatch(
        saveUser({
          email: cardToSave.email,
          firstName: cardToSave.firstName,
          familyName: cardToSave.familyName,
          roles: cardToSave.roles,
          brands: cardToSave.brands.map(b => b.code),
        })
      );
      setEditMode(false);
    },
    [dispatch]
  );

  if (user.id === '') {
    return <UserCardWritable onSave={handleOnSave} user={user} onCancel={() => dispatch(removeEmptyUser())} />;
  }
  if (editMode) {
    return <UserCardWritable onSave={handleOnUpdate} user={user} onCancel={() => setEditMode(false)} />;
  }
  return <ReadonlyUserCard user={user} onEdit={() => setEditMode(true)} onDelete={handleOnDelete} />;
};
