/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './loginError.style';

export const LoginError = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div css={style.container}>
      <div>
        {t('login.errorWarning')}
        <br />
        <br />
        {t('login.errorClarification')}
      </div>
    </div>
  );
};
