/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dropdown, DropdownProps, Icon, Loader, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { Brand } from '../../../../shared/models/brand';
import { DiscountRule, OrderedDiscountRule } from '../models/discountRuleSet';
import { selectSortedPrincipalBrands } from '../../../auth/store/principalSlice';
import { selectEmployeeTypes } from '../../../../shared/store/employeeTypesSlice';
import {
  addRule,
  createDiscountRuleSet,
  fetchDiscountRuleSet,
  resetDiscountRuleSet,
  selectDiscountRules,
  selectDiscountRuleSetBrand,
  selectDiscountRuleSetEmployeeTypes,
  selectIsFetchingDiscountRuleSet,
  selectReversedDiscountRules,
  selectIsPristineDiscountRuleSet,
  setBrand,
  setDiscountRules,
  setEmployeeTypes,
  updateDiscountRuleSet,
} from '../store/discountRuleSetDetailSlice';
import { fetchProductCategories } from '../store/discountProductCategoriesSlice';
import { fetchCountries, resetCountries } from '../store/discountCountriesSlice';
import { FormField } from '../../../../shared/components/FormField';
import { AdminSectionHeader } from '../../../../shared/components/AdminSectionHeader';
import DiscountRuleModalForm from '../components/DiscountRuleModalForm';
import DraggableItem from '../components/draggable/DraggableItem';
import { DiscountRuleCard } from '../components/DiscountRuleCard';
import { selectSortedDiscountRuleSets } from '../store/discountRuleSetsSlice';
import style from './discountRuleSetDetail.style';

export default function DiscountRuleSetDetail(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const principalBrands: Brand[] = useAppSelector(selectSortedPrincipalBrands);
  const employeeTypesOptions = useAppSelector(selectEmployeeTypes);
  const isFetchingRuleSet = useAppSelector(selectIsFetchingDiscountRuleSet);
  const discountRules = useAppSelector(selectDiscountRules);
  const brand = useAppSelector(selectDiscountRuleSetBrand);
  const employeeTypes = useAppSelector(selectDiscountRuleSetEmployeeTypes);
  const isPristineDiscountRuleSet = useAppSelector(selectIsPristineDiscountRuleSet);
  const discountRuleSets = useAppSelector(selectSortedDiscountRuleSets);
  const [isCreatingRule, setIsCreatingRule] = useState(false);
  const [showUnsavedRulesModal, setShowUnsavedRulesModal] = useState(false);
  const [showDuplicatedRuleSetErrorModal, setShowDuplicatedRuleSetErrorModal] = useState(false);

  const ruleSetHasDuplicatedConstraints = useMemo((): boolean => {
    const existRuleSetWithConstraints = discountRuleSets
      .filter(ruleSet => ruleSet.id !== id)
      .find(
        ruleSet =>
          ruleSet.brand.code === brand.code && ruleSet.employeeTypes.some(eType => employeeTypes.includes(eType))
      );

    return existRuleSetWithConstraints !== undefined;
  }, [brand.code, discountRuleSets, employeeTypes, id]);

  const isRuleSetValid = useMemo(
    () =>
      discountRules.every(d => d.discountPercentage != null && d.discountPercentage !== 0) &&
      brand.code !== '' &&
      employeeTypes.length > 0 &&
      discountRules.length > 0 &&
      !ruleSetHasDuplicatedConstraints,

    [brand.code, discountRules, employeeTypes.length, ruleSetHasDuplicatedConstraints]
  );

  const saveDiscountRuleSet = useCallback(async () => {
    if (ruleSetHasDuplicatedConstraints) {
      setShowDuplicatedRuleSetErrorModal(true);
    }
    if (!isRuleSetValid) {
      return;
    }
    let result;
    if (id == null) {
      result = await dispatch(createDiscountRuleSet(brand.code, employeeTypes, discountRules as DiscountRule[]));
    } else {
      result = await dispatch(updateDiscountRuleSet(id, employeeTypes, discountRules as DiscountRule[]));
    }
    if (result != null) {
      navigate('/privilege-card/admin/discounts');
    }
  }, [brand, dispatch, employeeTypes, discountRules, id, navigate, isRuleSetValid, ruleSetHasDuplicatedConstraints]);

  const onExitConfirm = () => {
    setShowUnsavedRulesModal(false);
    navigate('/privilege-card/admin/discounts');
  };

  const checkForUnsavedDiscountRules = () => {
    if (!isPristineDiscountRuleSet && (brand.code !== '' || employeeTypes.length > 0)) {
      setShowUnsavedRulesModal(true);
    } else {
      navigate('/privilege-card/admin/discounts');
    }
  };

  useEffect(() => {
    if (id != null) {
      dispatch(fetchDiscountRuleSet(id));
    }

    return () => {
      dispatch(resetDiscountRuleSet());
      dispatch(resetCountries());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brand.code !== '') {
      dispatch(fetchCountries(brand.code));
      dispatch(fetchProductCategories(brand.code));
    }
  }, [brand, dispatch]);

  return (
    <div css={style.pageContainer}>
      <AdminSectionHeader
        title={t('discountRuleset.discount')}
        redirectPath='/privilege-card/admin/discounts'
        arrowBack
        checkForUnsavedChanges={checkForUnsavedDiscountRules}
      />
      <UnsavedDiscountRulesModal
        onConfirm={onExitConfirm}
        open={showUnsavedRulesModal}
        onClose={() => setShowUnsavedRulesModal(false)}
      />
      <div css={style.discountRuleSetFormContainer}>
        <div css={style.mainConstraints}>
          <FormField
            label={t('discountRuleset.brand')}
            editMode={id == null && discountRules.length === 0}
            mandatory
            viewComponent={<span css={style.readonlyField}>{brand.description}</span>}
            editComponent={
              <Dropdown
                css={style.brandField}
                placeholder={t('discountRuleset.brand')}
                value={brand?.code || ''}
                selection
                noResultsMessage={t('noResultsFound')}
                options={principalBrands.map(b => ({ key: b.code, text: b.description, value: b.code }))}
                onChange={(event, data: DropdownProps) => {
                  const brandCode = data.value as string;
                  const selectedBrand = principalBrands.find(b => b.code === brandCode) as Brand;
                  dispatch(setBrand(selectedBrand));
                }}
              />
            }
          />

          <FormField
            label={t('discountRuleset.employeeType')}
            editMode
            mandatory
            editComponent={
              <Dropdown
                placeholder={t('discountRuleset.employeeType')}
                value={employeeTypes || ''}
                selection
                multiple
                clearable
                options={
                  employeeTypesOptions?.map(et => ({
                    key: et,
                    text: t(`discountRuleset.employeeTypes.${et}`),
                    value: et,
                  })) || []
                }
                onChange={(event, data: DropdownProps) => {
                  const values = data.value as string[];
                  values.sort();
                  dispatch(setEmployeeTypes(values));
                }}
              />
            }
          />
        </div>
        <div css={style.addDiscountRuleBtnContainer}>
          <Button
            css={style.addRuleButton(t('discountRuleset.addRule'))}
            icon='plus'
            onClick={() => {
              if (!isRuleSetValid && ruleSetHasDuplicatedConstraints) {
                setShowDuplicatedRuleSetErrorModal(true);
              } else {
                setIsCreatingRule(true);
              }
            }}
            disabled={brand.code === '' || employeeTypes.length === 0}
          />
        </div>
        <DiscountRuleModalForm
          open={isCreatingRule}
          onClose={() => setIsCreatingRule(false)}
          onRuleSave={(discountRule: DiscountRule) => dispatch(addRule(discountRule))}
        />
        {isFetchingRuleSet ? <Loader size='big' active /> : <RulesResult discountRules={discountRules} />}
      </div>
      <div css={style.saveDiscountRuleSetButtonContainer}>
        <div css={style.legendSection}>{t('mandatoryFields')}</div>
        <Button
          css={style.saveRuleButton(t('discountRuleset.saveDiscountRuleset'))}
          icon='save'
          onClick={saveDiscountRuleSet}
          disabled={
            isPristineDiscountRuleSet || brand.code === '' || employeeTypes.length === 0 || discountRules.length === 0
          }
        />
      </div>
      <DuplicateDiscountRuleSetsWarningModal
        open={showDuplicatedRuleSetErrorModal}
        onClose={() => setShowDuplicatedRuleSetErrorModal(false)}
      />
    </div>
  );
}

interface RuleResultsProps {
  discountRules: OrderedDiscountRule[];
}

function RulesResult({ discountRules }: RuleResultsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reversedDiscountRules = useAppSelector(selectReversedDiscountRules);
  const getReversedIndex = (index: number): number => discountRules.length - 1 - index;
  const onItemMove = (items: OrderedDiscountRule[]) => {
    dispatch(setDiscountRules(items));
  };

  return discountRules.length === 0 ? (
    <div css={style.noItemsLabel}>{t('discountRuleset.noRules')}</div>
  ) : (
    <div>
      <DndProvider options={HTML5toTouch}>
        <div>
          {reversedDiscountRules.map((rule: OrderedDiscountRule, i: number) => (
            <DraggableItem
              key={rule.id}
              index={getReversedIndex(i)}
              id={rule.id}
              onItemMove={onItemMove}
              items={discountRules}>
              <DiscountRuleCard discountRule={rule} ruleIndex={getReversedIndex(i)} />
            </DraggableItem>
          ))}
        </div>
      </DndProvider>
    </div>
  );
}

interface UnsavedLimitRulesModalProps {
  onConfirm: () => void;
  open: boolean;
  onClose: () => void;
}

const UnsavedDiscountRulesModal = ({ onConfirm, open, onClose }: UnsavedLimitRulesModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal css={style.modalContainer} onClose={onClose} open={open} trigger=''>
      <Modal.Content css={style.modalContent}>
        {t('discountRuleset.backArrow.warningMessage')}
        <br />
        {t('discountRuleset.backArrow.unsavedDiscountRulesWarningMessage')}
      </Modal.Content>
      <Modal.Actions css={style.modalActions}>
        <Button css={style.refuteBtn} content={t('no')} onClick={onClose} />
        <Button css={style.confirmBtn} content={t('yes')} onClick={onConfirm} />
      </Modal.Actions>
    </Modal>
  );
};

interface DuplicateDiscountRuleSetsWarningModalProps {
  open: boolean;
  onClose: () => void;
}

const DuplicateDiscountRuleSetsWarningModal = ({
  open,
  onClose,
}: DuplicateDiscountRuleSetsWarningModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose} css={style.modalContainer}>
      <ModalContent css={style.modalContent}>
        <Icon name='warning sign' color='orange' size='large' />
        {t('discountRuleset.modalErrorWarningMessage')}
      </ModalContent>
      <ModalActions css={style.modalActions}>
        <Button onClick={onClose} css={style.modalErrorButton}>
          {t('discountRuleset.modalErrorOkButton')}
        </Button>
      </ModalActions>
    </Modal>
  );
};
