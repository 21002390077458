import { API_URL } from '../../constants';

const BASE_API = `${API_URL}/brands`;

const paths = {
  getBrands: (): string => `${BASE_API}`,
  getEmployeeTypes: (): string => `${BASE_API}/employee-types`,
};

export default paths;
