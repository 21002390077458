/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Loader } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { selectFilteredMyDiscountRules } from '../store/employeePurchaseLimitSlice';
import { getLogo } from '../../../../shared/models/brand';
import { MyDiscountRule } from '../models/MyDiscountRule';
import style from './discountRuleList.style';
import {
  fetchEmployeeCard,
  selectIsFetchingMyEmployeeCard,
  selectMyEmployeeCard,
} from '../../discountCard/store/principalEmployeeCardSlice';
import {
  fetchSpecialCases,
  selectIsFetching,
  selectSpecialCasesGoldCardsPercentage,
} from '../../../admin/discounts/store/specialCasesSlice';

export default function DiscountRuleList() {
  const dispatch = useAppDispatch();
  const myEmployeeCard = useAppSelector(selectMyEmployeeCard);
  const specialCasesGoldCardsPercentage = useAppSelector(selectSpecialCasesGoldCardsPercentage);
  const myDiscountRules: MyDiscountRule[] = useAppSelector(selectFilteredMyDiscountRules) || [];
  const isFetchingEmployeeCard = useAppSelector(selectIsFetchingMyEmployeeCard);
  const isFetchingSpecialCases = useAppSelector(selectIsFetching);

  useEffect(() => {
    dispatch(fetchEmployeeCard());
    dispatch(fetchSpecialCases());
  }, [dispatch]);

  return (
    <div css={style.discountLimitsContainer}>
      <div css={style.discountCardsContainer}>
        {isFetchingEmployeeCard || isFetchingSpecialCases ? (
          <div css={style.loaderContainer}>
            <Loader active size='large' inline />
          </div>
        ) : myEmployeeCard.isSpecialCaseGoldCard ? (
          <SpecialCaseDiscountCard specialCasesDiscount={specialCasesGoldCardsPercentage} />
        ) : (
          <DiscountRuleCards rules={myDiscountRules} />
        )}
      </div>
    </div>
  );
}

interface DiscountRuleCardsProps {
  rules: MyDiscountRule[];
}

function DiscountRuleCards({ rules }: DiscountRuleCardsProps): JSX.Element {
  const { t } = useTranslation();

  return rules.length === 0 ? (
    <div css={style.noItemsMessage}>{t('purchaseLimits.noItems')}</div>
  ) : (
    <>
      {rules.map(rule => (
        <DiscountRuleCard
          key={`${rule.brand.code}${JSON.stringify(rule.constraints)}${rule.discountPercentage}`}
          rule={rule}
        />
      ))}
    </>
  );
}

interface DiscountRuleCardProps {
  rule: MyDiscountRule;
}

function DiscountRuleCard({ rule }: DiscountRuleCardProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div css={style.discountCard}>
      <div css={style.brandContainer}>
        <Image css={style.brand} src={getLogo(rule.brand.code)} />
      </div>
      <div css={style.constraints}>
        <div>
          {rule.constraints.countries == null
            ? t('purchaseLimits.allCountries')
            : rule.constraints.countries.map(c => c.description).join(', ')}
        </div>
        <div>
          {rule.constraints.productCategories == null
            ? t('purchaseLimits.allProductCategories')
            : rule.constraints.productCategories.join(', ')}
        </div>
      </div>
      <div css={style.percentage}>{rule.discountPercentage}%</div>
    </div>
  );
}

interface SpecialCaseDiscountCardProps {
  specialCasesDiscount: number;
}

function SpecialCaseDiscountCard({ specialCasesDiscount }: SpecialCaseDiscountCardProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div css={style.discountCard}>
      <div css={style.constraints}>
        <div>{t('purchaseLimits.allCountries')}</div>
        <div>{t('purchaseLimits.allProductCategories')}</div>
      </div>
      <div css={style.percentage}>{specialCasesDiscount}%</div>
    </div>
  );
}
