/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Image } from 'semantic-ui-react';
import { OAUTH_AUTHORIZE_URL, OAUTH_CLIENT_ID, REDIRECT_URI } from '../../../constants';
import keringLogo from '../../../assets/keringLogo.png';
import style from './login.style';
import { OauthSenderButton } from '../components/OauthSenderButton';
import { setBusinessCardRedirectPagePath } from '../../../core/services/webStorageService';

export const BusinessCardLogin = (): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    setBusinessCardRedirectPagePath('/business-card');
  });

  return (
    <Card fluid css={style.card}>
      <Card.Content css={style.cardContent}>
        <div css={style.contentContainer}>
          <Image src={keringLogo} wrapped />
          <h3 css={style.title}>{t('login.businessCardTitle')}</h3>
          <OauthSenderButton authorizeUrl={OAUTH_AUTHORIZE_URL} clientId={OAUTH_CLIENT_ID} redirectUri={REDIRECT_URI} />
        </div>
      </Card.Content>
    </Card>
  );
};
