/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './mainMenu.style';

export const MainMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (currentPath: string) => {
    return location.pathname === currentPath;
  };

  return (
    <div css={style.menuContainer}>
      <Menu css={style.menu} icon='labeled' fluid widths={4} borderless>
        <Menu.Item
          name='card'
          active={isActive('/privilege-card/employee/my-card')}
          onClick={() => navigate('/privilege-card/employee/my-card')}>
          <Icon name='address card outline' />
          <span css={style.menuText}>{t('mainMenu.card')}</span>
        </Menu.Item>
        <Menu.Item
          name='limit'
          active={isActive('/privilege-card/employee/purchase-limit')}
          onClick={() => navigate('/privilege-card/employee/purchase-limit')}>
          <Icon name='tags' flipped='horizontally' />
          <span css={style.menuText}>{t('mainMenu.limit')}</span>
        </Menu.Item>
        <Menu.Item
          name='history'
          active={isActive('/privilege-card/employee/purchase-history')}
          onClick={() => navigate('/privilege-card/employee/purchase-history')}>
          <Icon name='history' flipped='horizontally' />
          <span css={style.menuText}>{t('mainMenu.history')}</span>
        </Menu.Item>
        <Menu.Item
          name='seeMore'
          active={isActive('/privilege-card/employee/see-more')}
          onClick={() => navigate('/privilege-card/employee/see-more')}>
          <Icon name='bars' />
          <span css={style.menuText}>{t('mainMenu.seeMore')}</span>
        </Menu.Item>
      </Menu>
    </div>
  );
};
