import { css } from '@emotion/react';

const field = css`
  min-width: 150px;
  margin-right: 10px;
`;

const dropdownItemName = css`
  font-weight: 500;
  margin-bottom: 2px;
`;

export default {
  field,
  dropdownItemName,
};
