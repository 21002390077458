import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import globalStyle from '../../../../shared/global.style';

const tabsContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 2px 7px;
`;

const tab = css`
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 0;
  width: 50%;
  text-align: center;
  ${globalStyle.bodyNormal};
`;
const selectedTab = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--dcd-secondary-color-40);
`;

const tabButton = (selected: boolean): SerializedStyles => {
  return css`
    ${tab}
    ${selected ? selectedTab : ''};
  `;
};

const smallTabButton = (selected: boolean): SerializedStyles => {
  return css`
    ${tabButton(selected)};
    &&& {
      font-size: 14px;
    }
  `;
};

const discountCardsContainer = css`
  overflow: auto;
  padding-top: 7px;
  flex: 1;
`;

const discountCard = css`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 18px;
  margin: 3px 3px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const constraints = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
`;

const brandContainer = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;
const brand = css`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
const percentage = css`
  width: fit-content;
  ${globalStyle.bodyNormal};
  &&& {
    font-size: 20px;
    font-weight: 600;
  }
`;
const noItemsMessage = css`
  ${globalStyle.bodySmall};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const discountLimitsContainer = css`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const loaderContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default {
  tabsContainer,
  smallTabButton,
  discountCardsContainer,
  discountCard,
  constraints,
  brandContainer,
  brand,
  percentage,
  noItemsMessage,
  discountLimitsContainer,
  loaderContainer,
};
