import { css } from '@emotion/react';

const container = css`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const headerContainer = css`
  width: 100%;
  margin-bottom: 14px;
`;

const dropdownContainer = css`
  width: 100%;
`;

const scannerContainer = css`
  margin: 20px -20px -20px;
  flex: 1;
`;

const storeSelectionContainerWithHeader = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const storeSelectionContainer = css`
  flex: 1;
  overflow: auto;
  padding: 5px;
`;

export default {
  container,
  headerContainer,
  dropdownContainer,
  scannerContainer,
  storeSelectionContainerWithHeader,
  storeSelectionContainer,
};
