/** @jsxImportSource @emotion/react */

import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { AdUser } from '../../features/admin/usersManagement/models/user';
import style from './adUserDropDowm.style';
import userApi from '../../features/admin/usersManagement/utils/api';
import { FormField } from './FormField';

interface AdUserDropdownProps {
  onSelection: (email: string, firstName: string, familyName: string) => void;
  validationMessages: string[];
}

export default function AdUserDropdown({ onSelection, validationMessages }: AdUserDropdownProps): JSX.Element {
  const { t } = useTranslation();
  const [adUsers, setAdUsers] = useState<AdUser[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getOptions = useCallback(() => {
    const generateItem = (user: AdUser): DropdownItemProps => ({
      key: user.email,
      value: user.email,
      text: `${user.email}`,
      content: (
        <div>
          <div css={style.dropdownItemName}>
            {user.firstName} {user.familyName}
          </div>
          <div>{user.email}</div>
        </div>
      ),
    });
    return adUsers.map(generateItem);
  }, [adUsers]);

  const searchAdUsers = useDebouncedCallback(async (userToSearch: string) => {
    if (userToSearch.trim() === '') {
      setAdUsers([]);
      return;
    }
    if (userToSearch.trim().length < 3) {
      return;
    }
    setIsFetching(true);
    try {
      const result = await userApi.getAdUsers(userToSearch);
      if (result.length > 0) {
        setAdUsers(result);
      }
    } catch {
      setAdUsers([]);
    } finally {
      setIsFetching(false);
    }
  }, 300);

  return (
    <FormField
      label={t('users.email')}
      editMode
      mandatory
      validationMessages={validationMessages}
      editComponent={
        <Dropdown
          css={style.field}
          placeholder={t('users.search')}
          fluid
          selection
          noResultsMessage={t('noResultsFound')}
          error={validationMessages.length > 0}
          search={() => getOptions()}
          options={getOptions()}
          onChange={(e, { value }) => {
            const { email, firstName, familyName } = adUsers.find(user => user.email === (value as string)) as AdUser;
            onSelection(email, firstName, familyName);
          }}
          onSearchChange={(e, { searchQuery }) => {
            searchAdUsers(searchQuery);
            if (searchQuery.trim() === '') {
              onSelection('', '', '');
            }
          }}
          selectOnBlur={false}
          disabled={isFetching}
          loading={isFetching}
        />
      }
    />
  );
}
