import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { GlobalLimits, LimitRule } from '../models/limitRule';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { toastService } from '../../../../core/services/toastService';
import { selectFilters } from './limitRulesFiltersSlice';

export type LimitType = 'maxAmount' | 'maxItems' | 'maxItemsBySku' | 'notEligible';

interface LimitRulesSliceState {
  globalLimits: GlobalLimits;
  limitRules: LimitRule[];
  limitSelected: LimitType;
  isFetching: boolean;
  isWaitingForResponse: boolean;
  error: string;
}

const initialState: LimitRulesSliceState = {
  globalLimits: { fashionAmount: 0, jewelryAmount: 0, updateInfo: null },
  limitRules: [],
  limitSelected: 'maxAmount',
  isFetching: false,
  isWaitingForResponse: false,
  error: '',
};

export const limitRulesSlice = createSlice({
  name: 'limitsSlice',
  initialState,
  reducers: {
    startFetch: (state: Draft<LimitRulesSliceState>): LimitRulesSliceState => ({ ...state, isFetching: true }),
    finishFetch: (
      state: Draft<LimitRulesSliceState>,
      payloadAction: PayloadAction<{
        globalLimits: GlobalLimits;
        limitRules: LimitRule[];
      }>
    ): LimitRulesSliceState => {
      const { globalLimits, limitRules } = payloadAction.payload;
      return {
        ...state,
        isFetching: false,
        error: '',
        globalLimits,
        limitRules,
      };
    },
    httpError: (state: Draft<LimitRulesSliceState>, payloadAction: PayloadAction<string>): LimitRulesSliceState => ({
      ...state,
      isFetching: false,
      error: payloadAction.payload,
    }),
    setGlobalLimits: (
      state: Draft<LimitRulesSliceState>,
      payloadAction: PayloadAction<GlobalLimits>
    ): LimitRulesSliceState => {
      const globalLimits = payloadAction.payload;
      return {
        ...state,
        globalLimits,
      };
    },
    removeLimitRule: (
      state: Draft<LimitRulesSliceState>,
      { payload: id }: PayloadAction<string>
    ): LimitRulesSliceState => {
      const updatedLimitRules = state.limitRules.filter(limitRuleSet => limitRuleSet.id !== id);
      return {
        ...state,
        limitRules: updatedLimitRules,
      };
    },
    setLimitType: (state: Draft<LimitRulesSliceState>, { payload: limitType }: PayloadAction<LimitType>) => {
      return {
        ...state,
        limitSelected: limitType,
      };
    },
  },
});

export const { startFetch, finishFetch, httpError, setGlobalLimits, removeLimitRule, setLimitType } =
  limitRulesSlice.actions;

export const fetchAllLimits =
  (currencyCountryCode?: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const limitRules = await api.getLimitRules();
      const globalLimits =
        currencyCountryCode == null
          ? await api.getGlobalLimits()
          : await api.getGlobalLimitsConverted(currencyCountryCode);
      dispatch(finishFetch({ globalLimits, limitRules }));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const updateGlobalLimits =
  (rules: GlobalLimits): AppThunk =>
  async dispatch => {
    try {
      const result = await api.updateGlobalLimits(rules);
      dispatch(setGlobalLimits(result));
      toastService.success();
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const deleteLimitRule =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      const limitRuleSetId = await api.deleteLimitRule(id);
      dispatch(removeLimitRule(limitRuleSetId));
      toastService.success();
    } catch (e) {
      dispatch(httpError(JSON.stringify(e)));
    }
  };

export const selectGlobalLimits = (state: RootState): GlobalLimits => state.limitRules.globalLimits;
export const selectLimitTypeSelected = (state: RootState): LimitType => state.limitRules.limitSelected;
export const selectIsFetchingLimitRules = (state: RootState): boolean => state.limitRules.isFetching;
const selectLimitRules = (state: RootState): LimitRule[] => state.limitRules.limitRules;

const selectLimitRulesByLimitType = createSelector(
  selectLimitRules,
  selectLimitTypeSelected,
  (limitRules, limitType) => {
    return limitRules.filter(limitRule => {
      const { amount, itemsCountPerSku, totalItemsCount } = limitRule.limits;
      switch (limitType) {
        case 'notEligible':
          return amount == null && totalItemsCount === 0 && itemsCountPerSku === 0;
        case 'maxAmount':
          return amount != null;
        case 'maxItems':
          return totalItemsCount != null && itemsCountPerSku == null;
        case 'maxItemsBySku':
          return itemsCountPerSku != null && totalItemsCount == null;
        default:
          return false;
      }
    });
  }
);

export const selectFilteredLimitRules = createSelector(
  selectFilters,
  selectLimitRulesByLimitType,
  (filters, limits): LimitRule[] => {
    return limits.filter(
      limit =>
        (filters.country !== ''
          ? limit.constraints.countries === null ||
            limit.constraints.countries.map(c => c.description).includes(filters.country)
          : true) &&
        (filters.employeeType !== '' ? limit.constraints.employeeTypes.includes(filters.employeeType) : true) &&
        (filters.brand !== '' ? limit.constraints.brand.description === filters.brand : true) &&
        (filters.storeTypology !== '' ? limit.constraints.storeTypology === filters.storeTypology : true)
    );
  }
);

export const selectSortedLimitRules = createSelector(selectFilteredLimitRules, (limitRules: LimitRule[]): LimitRule[] =>
  _.sortBy(limitRules, [
    'constraints.brand.description',
    'constraints.employeeTypes[0]',
    rule => {
      if (rule.constraints.countries == null) {
        return '';
      }
      const countries = rule.constraints.countries.map(country => country.description);
      return countries.join('');
    },
    rule => {
      if (rule.constraints.productCategories == null) return '';
      return rule.constraints.productCategories.join('');
    },
  ])
);

export const selectEligibleLimitRules = createSelector(selectLimitRules, (limitRules: LimitRule[]): LimitRule[] => {
  return limitRules.filter(
    (rule: LimitRule) =>
      rule.limits.amount != null || rule.limits.totalItemsCount !== 0 || rule.limits.itemsCountPerSku !== 0
  );
});

export default limitRulesSlice.reducer;
