import { API_URL } from '../../../../constants';

const paths = {
  getQuotaLimitsByCategories: (
    brandCode: string,
    employeeCountryCode: string,
    employeeCategory: string,
    storeCountryCode: string
  ): string =>
    `${API_URL}/quota-limits-by-categories?brandCode=${brandCode}&employeeCountryCode=${employeeCountryCode}&employeeCategory=${employeeCategory}&storeCountryCode=${storeCountryCode}`,
  getSalesAssistantDiscountRules: (brandCode: string, countryCode: string, employeeType: string): string =>
    `${API_URL}/sa-discount-rules?brandCode=${brandCode}&countryCode=${countryCode}&employeeType=${employeeType}`,
};
export default paths;
