import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import it from './i18n/it.json';
import zht from './i18n/zh-Hant.json';
import zhs from './i18n/zh-Hans.json';
import ko from './i18n/ko.json';
import ja from './i18n/ja.json';


// base options to see if real implementation of i18n
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en,
      fr,
      it,
      'zh-Hant': zht,
      'zh-Hans': zhs,
      ko,
      ja,
    },
    supportedLngs: ['en', 'fr', 'it', 'zh-Hant', 'zh-Hans', 'ko', 'ja'],
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
    },
    load: 'all',
    interpolation: {
      escapeValue: false, // react already saves from xss
    },
    debug: false,
  });


export default i18n;
