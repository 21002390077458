import { useEffect } from 'react';
import { SalesAssistantStore } from '../../../auth/models/principal';
import { BasketProduct } from '../models/basket';
import { EmployeeCard } from '../../../../shared/models/employeeCard';
import { useParamSelector } from '../../../../core/hooks';
import { Product } from '../models/product';
import { useBasket } from './useBasket';
import { selectBasketProductBySku, selectBasketProductCountBySku } from '../store/basketSlice';

export const useProductSelection = (
  employeeCard: EmployeeCard,
  store: SalesAssistantStore,
  product: Product,
) => {
  const basketProduct: BasketProduct = useParamSelector(selectBasketProductBySku, product.sku);
  const initialProductCounter: number = useParamSelector(selectBasketProductCountBySku, product.sku);
  const { handleBasketProductAction } = useBasket(
    employeeCard,
    store,
    basketProduct,
    product,
    initialProductCounter
  );

  useEffect(() => {
    if (product.sku !== '' && product.isEligible) {
        handleBasketProductAction(); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
};
