/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeRedirectPagePath } from '../core/services/webStorageService';

export const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    removeRedirectPagePath();
    navigate('/privilege-card/login');
  }, [navigate]);

  return <></>;
};
