/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { EmployeeCard } from '../../../../shared/models/employeeCard';
import api from '../utils/api';
import style from './employeeCardsDropDown.style';

interface EmployeeCardsDropdownProps {
  onSelection: (value: string) => void;
}

export const EmployeeCardsDropdown = ({ onSelection }: EmployeeCardsDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const [employeeCards, setEmployeeCards] = useState<EmployeeCard[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getOptions = useCallback(() => {
    const generateItem = (employeeCard: EmployeeCard): DropdownItemProps => ({
      key: employeeCard.mail,
      value: employeeCard.mail,
      text: `${employeeCard.mail}`,
      content: (
        <div>
          <div>
            {employeeCard.givenName} {employeeCard.surname}
          </div>
          <div>{employeeCard.mail}</div>
          <div>{employeeCard.workdayId}</div>
        </div>
      ),
    });
    return employeeCards.map(generateItem);
  }, [employeeCards]);

  const searchEmployeeCards = useDebouncedCallback(async (filter: string) => {
    if (filter.trim() === '') {
      setEmployeeCards([]);
      return;
    }
    if (filter.trim().length < 3) {
      return;
    }
    setIsFetching(true);
    try {
      const result = await api.getEmployeeCards(filter);
      setEmployeeCards(result);
    } catch {
      setEmployeeCards([]);
    } finally {
      setIsFetching(false);
    }
  }, 300);

  return (
    <Dropdown
      css={style.field}
      fluid
      selection
      selectOnBlur={false}
      icon='search'
      search={() => getOptions()}
      options={getOptions()}
      noResultsMessage={t('noResultsFound')}
      placeholder={t('salesAssistant.cardSearch.search')}
      onChange={(e, { value }) => {
        const { workdayId } = employeeCards.find(card => card.mail === value) as EmployeeCard;
        onSelection(workdayId);
      }}
      onSearchChange={(e, { searchQuery }) => {
        searchEmployeeCards(searchQuery);
      }}
      selectOnNavigation={false}
      disabled={isFetching}
      loading={isFetching}
    />
  );
};
