import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const container = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const content = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;

const productCardContainer = css`
  display: flex;
  flex-direction: column;
  padding: 4px;
  overflow-y: auto;
  flex: 1;
  row-gap: 15px;
`;

const productCard = css`
  &&&&&&&&&&&& {
    width: 100%;
    min-height: auto;
    margin: 0;
    :hover {
      transform: none;
    }
  }
`;
const productCardHeader = css`
  &&&& {
    display: flex;
    justify-content: space-between;
  }
`;

const productCardDiscountedPrice = css`
  ${globalStyle.bodySmall};
  &&&&& {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 600;
  }
`;

const noProducts = css`
  margin: auto;
  ${globalStyle.bodyNormal};
  color: var(--dcd-gray-color-70);
`;

const basketResult = (invalidBasket: boolean, warning: boolean) => css`
  ${globalStyle.bodySmall};
  display: flex;
  align-items: center;
  gap: 10px;
  && {
    border-radius: 4px;
    padding: 10px;
    ${invalidBasket
      ? 'border: 1px solid var(--dcd-error);'
      : warning
      ? 'border: 1px solid var(--dcd-warning);'
      : 'border: 1px solid var(--dcd-success);'}
  }
`;

const cardContent = css`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  ${globalStyle.bodySmall};
`;
const cardColumn = css`
  display: flex;
  flex-direction: column;
`;

const cardInfo = css`
  ${cardColumn};
  flex: 1;
`;
const cardRightColumn = css`
  ${cardColumn};
  align-items: flex-end;
`;
const productName = css`
  &&&&& {
    font-weight: 600;
  }
`;
const productCategory = css`
  &&&&& {
    text-transform: uppercase;
  }
`;

const basketButton = css`
  ${globalStyle.primaryButton};
  width: 100%;
`;

const basketResultMessages = css`
  display: flex;
  flex-direction: column;
`;

const trashButton = css`
 &&&& {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
 }
`

const cardAndIconContainer = css`
  display: flex;
  align-items: center; 
  gap: 10px;
`

export default {
  container,
  cardAndIconContainer,
  productCardContainer,
  productCardHeader,
  productCard,
  productCardDiscountedPrice,
  basketResultMessages,
  noProducts,
  basketResult,
  cardContent,
  cardInfo,
  cardRightColumn,
  productName,
  productCategory,
  basketButton,
  content,
  trashButton
};
