/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import style from './discounts.style';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import { fetchDiscountRuleSets, selectFilteredDiscountRuleSets } from '../store/discountRuleSetsSlice';
import DiscountRuleSetCard from '../components/DiscountRuleSetCard';
import { DiscountRuleSetsFilters } from '../components/DiscountRuleSetsFilters';
import { changeFilters, selectFilters } from '../store/discountRuleSetsFiltersSlice';
import { AdminSectionHeader } from '../../../../shared/components/AdminSectionHeader';
import { fetchSpecialCases } from '../store/specialCasesSlice';
import SpecialCases from '../components/SpecialCases';

export default function Discounts(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const discountRuleSets = useAppSelector(selectFilteredDiscountRuleSets);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchDiscountRuleSets());
    dispatch(fetchSpecialCases());
  }, [dispatch]);

  return (
    <div css={style.container}>
      <SpecialCases />
      <AdminSectionHeader title={t('discounts.discounts')} />
      <div css={style.buttonsContainer}>
        <Button
          css={style.addDiscountRuleSetButton(t('discounts.addRuleset'))}
          icon='plus'
          onClick={() => {
            navigate(`/privilege-card/admin/discount-rule-set`);
          }}
        />
        <div css={style.filtersContainer}>
          <div>
            <FiltersBadges />
          </div>
          <DiscountRuleSetsFilters />
        </div>
      </div>
      {discountRuleSets.length === 0 && <div css={style.noItemsLabel}>{t('discounts.noItems')}</div>}
      {discountRuleSets.length > 0 && (
        <div css={style.scrollableRuleSetsContainer}>
          {discountRuleSets.map(ruleSet => (
            <DiscountRuleSetCard discountRuleSet={ruleSet} key={ruleSet.id} />
          ))}
        </div>
      )}
    </div>
  );
}

const FiltersBadges = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);

  return (
    <div css={style.badgesWrapper}>
      <div css={style.badgesContainer}>
        {!_.isEmpty(filters.employeeType) && (
          <Label css={style.filterBadge}>
            {t(`discounts.ruleset.employeeTypes.${filters.employeeType}`)}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, employeeType: '' }))} />
          </Label>
        )}
        {!_.isEmpty(filters.brand) && (
          <Label css={style.filterBadge}>
            {filters.brand}
            <Icon name='delete' onClick={() => dispatch(changeFilters({ ...filters, brand: '' }))} />
          </Label>
        )}
      </div>
    </div>
  );
};
