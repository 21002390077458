import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const modal = css`
  &&&&& {
    max-width: 600px;
    margin-top: 100px;
    height: calc(100% - 102px);
    border-radius: 1rem;
  }
`;

const modalContent = css`
  &&&&& {
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 1rem;
    padding: 0 !important;
  }
`;

const header = css`
  background-color: var(--dcd-secondary-color-40);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

const headerTop = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const headerTopLeft = css`
  flex: 1;
  > div {
    cursor: pointer;
    width: fit-content;
  }
`;

const headerTopCenter = css``;

const headerTopRight = css`
  flex: 1;
`;

const caretDownIcon = css``;

const brandLogo = css`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: 10px;
`;

const headerBottom = css`
  ${globalStyle.bodySmall};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 25px 0 15px;
  width: 100%;
`;

const product = css`
  ${globalStyle.bodyMedium};
  && {
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
  }
`;

const discount = css`
  ${globalStyle.bodyNormal};
`;

const date = css`
  ${globalStyle.bodySmall};
  && {
    font-size: 16px;
  }
`;

const amount = css`
  ${globalStyle.bodyMedium};
  text-align: center;
  display: flex;
`;

const address = css`
  ${globalStyle.bodySmall};
  && {
    font-size: 16px;
  }
`;

const body = css`
  ${globalStyle.bodyMedium};
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  > div {
    border-bottom: 1px solid var(--dcd-gray-color-50);
    padding-bottom: 15px;
  }
`;

const detailTitle = css`
  & {
    font-weight: 600;
  }
`;

const productQuantity = css`
  text-align: center;
`;

const productNameText = css`
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`;

const productCategory = css`
  text-align: center;
  font-weight: 500;
`;

const temporaryLogo = css`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: black;
  color: white;
  width: 100px;
  height: 100px;
  font-size: 17px;
  text-align: center;
  line-height: 1.5em;
`;

export default {
  modal,
  modalContent,
  header,
  headerTop,
  headerTopLeft,
  headerTopCenter,
  headerTopRight,
  caretDownIcon,
  brandLogo,
  headerBottom,
  body,
  product,
  discount,
  date,
  amount,
  address,
  detailTitle,
  productNameText,
  productCategory,
  productQuantity,
  temporaryLogo,
};
